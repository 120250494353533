import { GradientSeparator, Title } from '@/components'
import { DescriptionStyled } from '../data-offering/styled'

interface IUseCasesProps {
  company: any
}

const UseCases: React.FC<IUseCasesProps> = ({ company }) => {
  return (
    <>
      <Title boldText="Use Cases" fontSize="30px" />
      <DescriptionStyled>{company.useCasesDescription}</DescriptionStyled>
      {/* TODO: add list of useCases that come from products created by this company */}
      <GradientSeparator margin="30px 0" />
    </>
  )
}

export default UseCases
