import { Sidebar, AppBarHeader } from '@components'
import { Container as Wrapper } from '@mui/material'
import { Container, Iframe } from './styled'
import qualityReport from './'

const QualityReport = (props) => {
  const ShowHtml = () => {
    let body = '<p>Sample text with <strong>bold</strong></p>'
    return <div dangerouslySetInnerHTML={{ __html: body }} />
  }

  return (
    <Container>
      <Sidebar />
      <Container flexDirection="column">
        <AppBarHeader />
        <Wrapper maxWidth={'xl'} sx={{ margin: '45px' }}>
          <Iframe
            src="https://fsabatekubikware.github.io/kamba-quality-report/"
            frameborder="0"
          ></Iframe>
        </Wrapper>
      </Container>
    </Container>
  )
}

export default QualityReport
