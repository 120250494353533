import styled from 'styled-components'
import { ContainerProps } from './types'

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  height: 100vh;
  width: 100%;
  overflow: hidden;
`
export const ErrorMessage = styled.div`
  display: flex;
  padding: 0 24px;
  margin-top: 25px;
  width: 100%;
  color: red;
  justify-content: center;
  text-align: center;
`

export const LoaderWrapper = styled.div`
  margin-top: 45px;
  display: flex;
  justify-content: center;
`
