import { useState } from 'react'
import { Tooltip } from '@/components'
import { InputProps, AbsoluteWrapperProps } from './types'
import {
  Label,
  InputWrapper,
  Input,
  AbsoluteWrapper,
  TextArea,
  Wrapper,
  CharactersMessage,
} from './styled'

type Props = InputProps & AbsoluteWrapperProps

const InputComponent = ({
  title,
  children,
  isTextArea,
  maxCharacters,
  minCharacters,
  top,
  getValue,
  hasTooltip,
  value,
  placeholder,
  type,
}: Props) => {
  const [isFocused, setIsFocused] = useState(false)
  const [characters, setCharacters] = useState(value ? value.length : 0)

  const setText: () => string = () => {
    if (minCharacters && maxCharacters)
      return `Between ${minCharacters} and ${maxCharacters} characters `
    if (minCharacters) return `More than ${minCharacters} characters `
    if (maxCharacters) return `Maximum ${maxCharacters} characters `
    return ''
  }

  const text = setText()

  return (
    <Wrapper>
      <Label>{title}</Label>
      <InputWrapper>
        {isTextArea ? (
          <TextArea
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            placeholder={placeholder}
            value={value}
            onChange={(e: any) => {
              getValue(e)
              setCharacters(e.target.value.length)
            }}
          />
        ) : (
          <Input
            type={type}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            placeholder={placeholder}
            value={value}
            onChange={(e: any) => {
              getValue(e)
              setCharacters(e.target.value.length)
            }}
          />
        )}
        {hasTooltip && isFocused && characters < 1 && (
          <AbsoluteWrapper top={top}>
            <Tooltip>{children}</Tooltip>
          </AbsoluteWrapper>
        )}
      </InputWrapper>
      {(minCharacters || maxCharacters) && (
        <CharactersMessage
          charactersOk={
            minCharacters && maxCharacters
              ? minCharacters <= characters && characters <= maxCharacters
              : minCharacters
              ? minCharacters <= characters
              : maxCharacters! >= characters
          }
        >
          {text}
          <span>(currently {characters} characters)</span>
        </CharactersMessage>
      )}
    </Wrapper>
  )
}

export default InputComponent
