import styled from 'styled-components'

type PageItemType = { selected: boolean }
export const PageItem = styled.li`
  cursor: pointer;
  color: ${(props: PageItemType) => (props.selected ? 'white' : 'black')};
  background-color: ${(props: PageItemType) =>
    props.selected ? 'black' : 'white'};
  padding: 10px 20px;
  border: 1px solid #e5e2e2;
`
type PageItemPrevNext = { disabled?: boolean }
export const PageItem2 = styled(PageItem)`
  cursor: ${(props: PageItemPrevNext) => (props.disabled ? 'auto' : 'pointer')};
  color: ${(props: PageItemPrevNext) => (props.disabled ? '#999999' : 'black')};
  background-color: ${(props: PageItemPrevNext) =>
    props.disabled ? '#eeeeee' : 'white'};
`

export const PageUl = styled.ul`
  list-style-type: none;
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
`
