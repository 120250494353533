import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const LongCardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 40px;
  align-items: flex-start;
  background-color: #fff;
  margin: 6px 0;
  min-height: 222px;
  padding: 30px 40px;
  border: 1px solid #e7ebef;

  &:last-child {
    margin-bottom: 356px;
  }

  &:before {
    content: '';
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    background: #000;
    width: 3px;
    height: 100%;
    transition: all 0.4s;
  }

  &:hover {
    cursor: pointer;
    background: #fafafa;
    transition: all 1s;
    min-height: 222px;

    .divider {
      transition: all 0.6s;
      height: 100px;
    }

    &:before {
      width: 5px;
      opacity: 1;
    }
  }
`

export const HalfContent = styled.div`
  width: calc(50% - 20px);
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const CardTitle = styled.h1`
  font-weight: 700;
  font-size: 23.7837px;
  line-height: 40px;
  color: #111111;
`

export const CardSubtitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

export const CustomDivider = styled.div`
  height: 15px;
  border: 1px solid #e7ebef;
  transition: all 0.4s;
  margin: 0 19px;
`

export const Price = styled.p`
  font-weight: 600;
  font-size: 22.8115px;
  line-height: 38px;
  color: #505050;
`

export const Subtitletext = styled.p`
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  color: #828282;
  width: fit-content;
`

export const Country = styled.div`
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  color: #828282;
  width: fit-content;
  margin-right: 19px;

  &:last-of-type {
    margin-right: 0;
  }
`

export const CardRequirements = styled.div`
  padding: 18px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #838383;
`

export const ProposalInfo = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fafafa;
  padding: 18px 36px;
`

export const ProposedProduct = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #757575;
`

export const ProposeProductTitle = styled.a`
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  text-decoration-line: underline;
  color: #111111;
  margin-top: 8px;
  margin-bottom: 16px;
`

export const ProposalMessage = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #838383;
`

export const FlagImg = styled.img`
  height: 15px;
  position: relative;
  top: 2px;
  width: 15px;
  margin-right: 6px;
`
