import { useState } from 'react'
import { Tooltip } from '../../../../../components'
import { InputProps, AbsoluteWrapperProps } from './types'
import {
  Label,
  InputWrapper,
  Input,
  AbsoluteWrapper,
  TextArea,
  Wrapper,
  CharactersMessage,
} from './styled'

type Props = InputProps & AbsoluteWrapperProps

const InputComponent = ({
  title,
  children,
  isTextArea,
  maxCharacters,
  minCharacters,
  top,
  getValue,
}: Props) => {
  const [isFocused, setIsFocused] = useState(false)
  const [characters, setCharacters] = useState(0)

  const text =
    minCharacters && maxCharacters
      ? `Between ${minCharacters} and ${maxCharacters} characters `
      : `More than ${minCharacters} characters `

  return (
    <Wrapper>
      <Label>{title}</Label>
      <InputWrapper>
        {isTextArea ? (
          <TextArea
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onChange={(e: any) => {
              getValue(e)
              setCharacters(e.target.value.length)
            }}
          />
        ) : (
          <Input
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onChange={(e: any) => {
              getValue(e)
              setCharacters(e.target.value.length)
            }}
          />
        )}
        {isFocused && characters < 1 && (
          <AbsoluteWrapper top={top}>
            <Tooltip>{children}</Tooltip>
          </AbsoluteWrapper>
        )}
      </InputWrapper>
      <CharactersMessage
        charactersOk={
          minCharacters && maxCharacters
            ? minCharacters <= characters && characters <= maxCharacters
            : minCharacters <= characters
        }
      >
        {text}
        <span>(currently {characters} characters)</span>
      </CharactersMessage>
    </Wrapper>
  )
}

export default InputComponent
