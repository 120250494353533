import styled, { css } from 'styled-components'
import check from '@assets/images/icons/GreenCheckIcon'
import { breakpoints } from '@utils/breakpoints'
import { ICardContainerProps, ITextProps } from './types'

const EllipsisText = styled.span<ITextProps>`
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: ${(p) => p.maxRows?.toString() || '3'};
  display: -webkit-box;
  -webkit-box-orient: vertical;
`

export const CardContainer = styled.div<ICardContainerProps>`
  padding: 36px 15px 30px;
  border: 1px solid #d9d9d9;
  background-color: #ffffff;
  height: 100%;
  min-height: 350px;
  max-height: 450px;
  transform: translate(0%);
  transition: 0.3s ease-out;
  caret-color: transparent;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  @media ${breakpoints.lg} {
    padding: 36px 18px 30px;
  }

  ${(p) =>
    p.isHovered &&
    css`
      &:hover {
        transition: 'transform 0.2s ease';
        transform: translateY(-8px);
        box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
      }
    `}
`
export const CardSubtitle = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: #000000;
  text-transform: uppercase;
`
export const CardTitle = styled(EllipsisText)`
  font-size: 19px;
  font-weight: 700;
  color: #202020;
  margin: 11px 0px 18px 0px;
`

export const VerifiedLabel = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: #2fa24f;
`

export const CheckIcon = styled.div`
  background-image: url(${check});
  color: #2fa24f;
  width: 10px;
  height: 10px;
`

export const CardDescription = styled(EllipsisText)`
  font-size: 15px;
  font-weight: 400;
  color: #747474;
`

export const CardImg = styled.img`
  position: relative;
  max-width: 100%;
  max-height: 70px;
`

export const CardUl = styled.ul`
  display: flex;
  align-content: start;
  width: 100%;
  padding-left: 15px;
  li {
    color: #666666;
  }
  li:first-child {
    margin-right: 25px;
  }
`

export const BannerImg = styled.img`
  /* max-height: 50%; */
  width: 100%;
  /* object-fit: cover; */
`
