import { Grid } from '@mui/material'
import { Button } from '@components'
import { useEffect, useState, useRef } from 'react'
import * as React from 'react'
import {
  DescriptionPrice,
  LabelPrice,
  ValuePrice,
  ErrorMessage,
} from './styled'
import { ISubscriptionProps } from './types'
import { useNavigate } from 'react-router'
import { useAppSelector } from '@/app/hooks'
import { axios } from '@/lib/axios'

const Subscription: React.FC<ISubscriptionProps> = ({
  buyingFormats,
  listingId,
  companyId,
}) => {
  const [isDisabled, setIsDisabled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [typeOfPrice, setTypeOfPrice] = useState('')
  const [minimunPrice, setMinimunPrice] = useState(0)
  const isPreSubscribed = useRef<boolean>()
  const [minimunPriceType, setMinimunPriceType] = useState('')
  const userId = useAppSelector((state) => state.authUser.userId)
  const navigate = useNavigate()
  const baseUrl = process.env.REACT_APP_URL_BASE

  useEffect(() => {
    if (!userId) {
      setIsDisabled(true)
    }
  }, [userId])

  useEffect(() => {
    setIsLoading(true)
    checkIfSubscribed().then(() => {
      setIsLoading(false)
    })
    priceValidator()
  }, [buyingFormats])

  const priceValidator = () => {
    if (!buyingFormats[0]) {
      setTypeOfPrice('negotiable')
    } else {
      // Puede tener mas de un formato (one, monthly, yearly) al mismo tiempo?
      let formats = buyingFormats.sort((a, b) => {
        return a.price - b.price
      })

      // Price 0 and type = one is free.
      if (formats[0].price === 0) {
        setTypeOfPrice('free')
      } else {
        setMinimunPrice(formats[0].price)
        setMinimunPriceType(formats[0].type)
        setTypeOfPrice('regular')
      }
    }
  }

  const checkIfSubscribed = async () => {
    const res = axios
      .get(
        `${baseUrl}/api/v1/presubscriptions?userId=${userId}&listingId=${listingId}`,
      )
      .then(function (response: any) {
        if (!response.data[0]) {
          isPreSubscribed.current = false
          return response
        } else {
          isPreSubscribed.current = true
          return response
        }
      })
      .catch(function (error: any) {
        return error
      })
    return res
  }

  const createPresubscription = async () => {
    const res = axios
      .post(`${baseUrl}/api/v1/presubscriptions`, {
        userId: userId,
        listingId: listingId,
      })
      .then(function (response: any) {
        return response
      })
      .catch(function (error: any) {
        console.log(error)
      })
    return res
  }

  const handleOnClick = () => {
    if (isPreSubscribed.current === false) {
      createPresubscription().then(() => {
        navigate(`/subscription-info/${listingId}`, {
          state: { companyId: companyId },
        })
      })
    } else {
      navigate(`/subscription-info/${listingId}`, {
        state: { companyId: companyId },
      })
    }
  }

  return (
    <>
      {!isLoading && (
        <Grid
          container
          spacing={0}
          sx={{ backgroundColor: '#000000', padding: 2 }}
        >
          {typeOfPrice === 'regular' ? (
            <>
              <Grid
                item
                container
                md={12}
                lg={4}
                direction="column"
                justifyContent={'center'}
              >
                <>
                  <Grid item xs={1}>
                    <LabelPrice>STARTS AT </LabelPrice>
                  </Grid>
                  <Grid item xs={2}>
                    <ValuePrice>${minimunPrice}</ValuePrice>
                  </Grid>
                  {minimunPriceType === 'one' && !buyingFormats[1] ? (
                    <Grid item xs={2}>
                      <DescriptionPrice>(one time purchase)</DescriptionPrice>
                    </Grid>
                  ) : (
                    <Grid item xs={2}>
                      <DescriptionPrice>
                        (per{' '}
                        {minimunPriceType === 'monthly'
                          ? 'month'
                          : minimunPriceType}
                        )
                      </DescriptionPrice>
                    </Grid>
                  )}
                </>
              </Grid>
              <Grid item container xs={1}></Grid>
              <Grid item container lg={7} sm={12} alignItems={'center'}>
                <Grid item>
                  {minimunPriceType === 'one' && !buyingFormats[1] ? (
                    <Button
                      variant="secondary"
                      onClick={handleOnClick}
                      disabled={isDisabled}
                    >
                      <span style={{ fontSize: '16px' }}>PURCHASE</span>
                    </Button>
                  ) : (
                    <Button
                      variant="secondary"
                      onClick={handleOnClick}
                      disabled={isDisabled}
                    >
                      <span style={{ fontSize: '16px' }}>
                        Continue to subscribe
                      </span>
                    </Button>
                  )}
                  {isDisabled && (
                    <ErrorMessage>
                      You must login to get further information on this product
                    </ErrorMessage>
                  )}
                </Grid>
              </Grid>
            </>
          ) : typeOfPrice === 'negotiable' ? (
            <>
              <Grid item container xs={12} direction="column">
                <Grid item xs={12}>
                  <ValuePrice>Message supplier</ValuePrice>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid item container xs={12} direction="column">
                <>
                  <Grid item xs={12}>
                    <LabelPrice>SUBSCRIBE FOR FREE</LabelPrice>
                  </Grid>
                </>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={12}>
                  <Button
                    variant="secondary"
                    onClick={handleOnClick}
                    disabled={isDisabled}
                  >
                    <span style={{ fontSize: '16px' }}>
                      Continue to subscribe
                    </span>
                  </Button>
                  {isDisabled && (
                    <ErrorMessage>
                      You must login to get further information on this product
                    </ErrorMessage>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </>
  )
}

export default Subscription
