import styled from '@emotion/styled'
import { Tabs } from '@mui/material'
import { StyledTabProps, FullWidthBackgroundProps } from './types'
import { breakpoints } from '@utils/breakpoints'

export const PageHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`

export const TabsStyled = styled(Tabs)((props: StyledTabProps) => ({
  marginTop: '30px',
  '& .MuiTab-textColorPrimary ': {
    color: '#757575',
    padding: '25px',
    fontSize: '16px',
    textTransform: 'capitalize',
  },
  '& .MuiTabs-indicator': {
    borderBottom: '5px solid #000000;',
  },
  '& .Mui-selected': {
    color: '#000000 !important',
    fontWeight: 600,
  },
  '& > div': {
    overflowX: 'auto !important',
  },
}))

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

export const FullWidthBackground = styled.div<FullWidthBackgroundProps>`
  width: 300vw;
  margin-left: calc(
    -50vw + ${({ marginPercentOffset }) => marginPercentOffset || '50%'}
  );
  background-color: ${({ backgroundColor }) => backgroundColor};
  position: absolute;
  height: 100vh;
  top: 0;
  left: 0;

  @media ${breakpoints.xl} {
    margin-left: calc(
      -49vw + ${({ marginPercentOffset }) => marginPercentOffset || '50%'}
    );
  }
`

export const TabPanelContainer = styled.div`
  width: 100%;
  /* height: 100%; */
  /* margin: 60px 15px; */
  margin: 0 0px 200px 0px;
`

export const TabContentContainer = styled.div`
  position: absolute;
  top: 0; //30px;
  left: 0;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  /* margin-right: 20px; */
  &.subscriptions-tabs {
    height: unset;
  }

  @media ${breakpoints.md} {
    // left: -34px;
  }

  @media ${breakpoints.xl} {
    left: -25px;
  }

  @media ${breakpoints.xl} {
    left: 0;
  }

  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
`
