import { Button } from '@/components'
import DomainList from '@/components/forms/create-product/domain-list'
import { ProductVisibility } from '@/components/forms/create-product/types'
import { visibilityMock } from '@/mocks'
import { Visibility } from '@/mocks/product/visibility'
import Filter from '@/pages/dashboard/messages/filters/filter'
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import React, { useState } from 'react'

interface VisibilityDialogProps {
  id: string
  visibility: ProductVisibility
  whitelist: string[]
  onClose: () => void
  onSave: (
    id: string,
    visibility: ProductVisibility,
    whitelist: string[],
  ) => void
}

const VisibilityDialog: React.FC<VisibilityDialogProps> = ({
  id,
  visibility: visibilityProp,
  onClose,
  onSave,
  whitelist: whitelistProp,
}) => {
  const [whitelist, setWhitelist] = useState(whitelistProp || [])
  const [visibility, setVisibility] = useState(visibilityProp)
  const [saving, setSaving] = useState(false)

  const addToWhitelist = (domain: string) => {
    const newWhitelist = [...whitelist, domain]
    setWhitelist(newWhitelist)
  }

  const removeFromWhitelist = (domain: string) => {
    const newWhitelist = whitelist.filter((a) => a !== domain)
    setWhitelist(newWhitelist)
  }

  const changeVisibility = (v: Visibility) => {
    setVisibility(v.value)
  }

  const save = () => {
    onSave(id, visibility, whitelist)
    setSaving(true)
  }

  const showHint = () => {
    const hints = {
      public: 'Everyone will see this product.',
      private: 'Only your company will see this product.',
      restricted: 'Only specified domains will see this product.',
    }
    return (
      <Box display={'flex'} alignItems={'center'} sx={{ paddingY: '.5rem' }}>
        <InfoIcon fontSize="small" sx={{ margin: '.175rem' }} />
        <span style={{ fontSize: '12px' }}>{hints[visibility]}</span>
      </Box>
    )
  }

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="visivility-dialog">
      <DialogTitle id="visivility-dialog">Product Visibility</DialogTitle>
      <DialogContent sx={{ minWidth: '600px', minHeight: '300px' }}>
        <Box>
          <Filter
            data={visibilityMock}
            onChange={(a) => changeVisibility(a as Visibility)}
            defaultValue={visibilityMock.find((a) => a.value === visibility)}
            isClearable={false}
          />
          {showHint()}
          {visibility === 'restricted' && (
            <DomainList
              whitelist={whitelist}
              addItem={addToWhitelist}
              removeItem={removeFromWhitelist}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="secondary"
          onClick={onClose}
          style={{ width: '150px' }}
        >
          Cancel
        </Button>
        <Button style={{ maxWidth: '150px' }} variant="primary" onClick={save}>
          {saving && (
            <CircularProgress
              color="inherit"
              size={20}
              style={{ marginBottom: '-5px', marginRight: '25px' }}
            />
          )}
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default VisibilityDialog
