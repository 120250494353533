import { ExtractFnReturnType } from '@/lib/react-query'
import { useMutation, useQuery } from 'react-query'
import { getBacktestingUrl } from './api/backtesting'

export const useGetBacktestingUrl = (onSuccess: () => void) => {
  return useMutation({
    mutationKey: ['backtesting'],
    mutationFn: (userId: string) => getBacktestingUrl(userId),
    onSuccess: () => onSuccess(),
  })
}

// export const useUpdateBilling = (onSuccess: () => void) => {
//   return useMutation({
//     mutationKey: ['billing'],
//     mutationFn: ({ id, billing }: { id: string; billing: Billing }) =>
//       updateBilling({ id: id, billing: billing }),
//     onSuccess: () => onSuccess(),
//   })
// }
