import * as React from 'react'
import { TitleStyled, BoldTitle, Description, TitleWrapper } from './styled'
import { ITitleProps } from './types'

const Title: React.FC<ITitleProps> = ({
  text,
  boldText,
  description,
  ...props
}) => {
  return (
    <TitleWrapper>
      <TitleStyled {...props}>
        <BoldTitle {...props}>{boldText}</BoldTitle>
        {text}
      </TitleStyled>
      {/* {!!description && <Description>{description}</Description>} */}
    </TitleWrapper>
  )
}

export default Title
