export const LogoFileTypes: string = 'SVG, PNG, JPG or GIF (min. 300x300px)'

export const BannerFileTypes: string =
  'SVG, PNG (min. 1200×200 pixels) - Max. size: 2MB'

export const LogoRestrictions: string[] = [
  'Please upload logos with a higher resolution than 300x300 pixels.',
  'Ideally upload a .PNG or SVG file with transparent background.',
  'For square images, please center the logo correctly both in vertical and horizontal direction.',
  'Please do not use majorly white logos with transparent background as they will not be visible on our mostly white backgrounds. Try inverting the colors.',
]

export const BannerRestrictions: string[] = [
  'Pictures must be at least 1200×200 pixels',
  'Landscape image orientation, ideal ratio is 6:1',
  'Maximum file size is 2MB',
]
