import {
  buildPageItems,
  getUrlPage,
  PageItemType,
  setUrlPage,
} from '@/utils/pagination'
import { PageItem, PageItem2, PageUl } from './styled'

const Item: PageItemType = ({ id, onClick, selected, label }) => {
  return (
    <PageItem onClick={onClick} id={`${id}`} selected={selected}>
      {label}
    </PageItem>
  )
}

const ItemPrevNext: ({
  id,
  // key,
  onClick,
  disabled,
  label,
}: {
  id: number
  // key: number
  onClick: (e: any) => any
  label: string
  disabled?: boolean
}) => JSX.Element = ({ id, onClick, disabled, label }) => {
  return (
    <PageItem2
      onClick={!disabled ? onClick : () => {}}
      id={`${id}`}
      // key={key}
      disabled={disabled}
      selected={false}
    >
      {label}
    </PageItem2>
  )
}

export function PaginationComponent<T>({
  page,
  setPage,
  totalPages,
}: {
  page: number
  setPage: (page: number) => any
  totalPages?: number
}) {
  const currentPage = page
  const items = buildPageItems(10, currentPage, Item, setPage, totalPages)

  return (
    <PageUl>
      <ItemPrevNext
        id={-1}
        // key={-1}
        disabled={currentPage === 1}
        label={'Previous'}
        onClick={() => {
          setPage(currentPage - 1)
        }}
      />
      {items}
      <ItemPrevNext
        id={-2}
        // key={-2}
        disabled={currentPage === totalPages}
        onClick={() => {
          setPage(currentPage + 1)
        }}
        label={'Next'}
      />
    </PageUl>
  )
}
