import ViewFile from '../../../ui/view-file'
import SelectComponent from '../select'
import CloseIcon from '@assets/images/close.svg'
import { ViewSelectFileDiv } from './styled'

export const ViewFileSelectType = ({
  name,
  options,
  type,
  onChange,
  deleteFile,
}: {
  name: string
  type: string
  options: any[]
  onChange: (arg0: { value: string }) => void
  deleteFile: (arg0: string) => void
}) => {
  return (
    <ViewSelectFileDiv>
      <ViewFile name={name} />
      <SelectComponent
        options={options
          .filter((val) => !val.file)
          .map((val, i) => ({
            id: i,
            value: val.type,
            label:
              val.type === 'Data Analytics' ? 'Data Quality Report' : val.type,
          }))}
        value={{
          id: 1,
          value: type,
          label: type === 'Data Analytics' ? 'Data Quality Report' : type,
        }}
        onChange={(e: any) => {
          onChange(e)
        }}
      />
      <div
        onClick={() => {
          deleteFile(type)
        }}
      >
        <CloseIcon style={{ transform: 'scale(1.25)' }} />
      </div>
    </ViewSelectFileDiv>
  )
}
