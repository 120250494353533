import { useState, useEffect } from 'react'
import { Container, Navigation } from '../../../../components'
import FirstStep from './first-step'
import SecondStep from './second-step'
import ThirdStep from './third-step'
import {
  ArrowLeft,
  ArrowLeftWrapper,
  FormWrapper,
  StepperBarWrapper,
  Wrapper,
} from './styled'
import StepperBar from './stepper-bar'

const CreatePost = () => {
  const [step, setStep] = useState(1)
  const [firstStepState, setFirstStepState] = useState({})
  const [secondStepState, setSecondStepState] = useState({})
  const [stepState, setStepState] = useState({})

  useEffect(() => {
    setStepState(Object.assign(stepState, firstStepState, secondStepState))
  }, [firstStepState, secondStepState])

  const firstStepSubmit = () => {
    setStep(2)
    window.scrollTo(0, 0)
  }

  const secondStepSubmit = () => {
    setStep(3)
    window.scrollTo(0, 0)
  }

  return (
    <>
      <Navigation />
      <Container backgroundColor="#000000" border={'unset'}>
        {step > 1 && (
          <ArrowLeftWrapper
            onClick={() => {
              window.scrollTo(0, 0)
              setStep(step - 1)
            }}
          >
            <ArrowLeft />
          </ArrowLeftWrapper>
        )}
        <Wrapper>
          <FormWrapper>
            <StepperBarWrapper>
              <StepperBar stepNumber={step} />
            </StepperBarWrapper>
            {step === 1 && (
              <FirstStep
                onClick={firstStepSubmit}
                setFirstStepState={setFirstStepState}
              />
            )}
            {step === 2 && (
              <SecondStep
                onClick={secondStepSubmit}
                setSecondStepState={setSecondStepState}
              />
            )}
            {step === 3 && <ThirdStep prevState={stepState} />}
          </FormWrapper>
        </Wrapper>
      </Container>
    </>
  )
}

export default CreatePost
