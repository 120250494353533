import { CheckboxProps } from './types'
import {
  CheckWrapper,
  CheckboxRow,
  CheckboxInput,
  CheckboxLabel,
  CheckboxImage,
} from './styled'

const Checkbox = ({
  label,
  name,
  image,
  fontWeight,
  fontColor,
  topPosition,
  isChecked,
  handleOnChange,
  value,
  listing,
  isNotCheckbox,
}: CheckboxProps) => {
  const handleCheck = (event: any) => {
    handleOnChange && handleOnChange(event)
  }
  return (
    <CheckWrapper>
      {!isNotCheckbox && (
        <CheckboxRow topPosition={topPosition}>
          <CheckboxInput
            type="checkbox"
            id={label}
            checked={isChecked}
            name={name}
            value={value}
            onChange={handleCheck}
          />
          <span />
        </CheckboxRow>
      )}
      <CheckboxLabel fontWeight={fontWeight} fontColor={fontColor}>
        {image && <CheckboxImage src={image} />}
        {label}
      </CheckboxLabel>
    </CheckWrapper>
  )
}

export default Checkbox
