import { AppBarHeader } from '@components'
import {
  Container,
  ContractContainer,
  ArrowButton,
  ArrowIcon,
  Button,
} from './styled'
import { Container as Wrapper } from '@mui/material'
import { useNavigate, useParams } from 'react-router'
import storage from '@/utils/storage'
import {
  useAcceptFeePolicy,
  useGetCompany,
} from '@/services/suppliers/suppliers.service'
import { ClipLoader } from 'react-spinners'
import { queryClient } from '@/lib/react-query'

interface ITAndCprops {
  content?: string
  isSupplier?: boolean
  isChecked?: boolean
  setIsChecked?: any
}

const FeePolicy: React.FC<ITAndCprops> = () => {
  const navigate = useNavigate()
  const userId = storage.getUserId()
  const companyId = storage.getCompanyId()
  const isLoggedIn = userId ? true : false

  const { refetch } = useGetCompany(companyId, false)
  const { mutate: acceptFeePolicy, isLoading: loadingAcceptance } =
    useAcceptFeePolicy(() => {
      queryClient.invalidateQueries({ queryKey: ['dashboard'] })
      navigate(-1)
    })

  const handleClick = async () => {
    const { data: companyData } = await refetch()
    acceptFeePolicy(companyData?.data.profile!)
  }

  return (
    <Container>
      <Container flexDirection="column">
        <AppBarHeader />
        <Wrapper maxWidth={'xl'} sx={{ margin: '45px' }}>
          <ContractContainer>
            <ArrowButton onClick={() => navigate(-1)}>
              <ArrowIcon />
            </ArrowButton>
            <p className="c2 c13">
              <span className="c10">KAMBA's FEE POLICY​</span>
            </p>
            <p className="c0 c2">
              <span className="c6 c3">LAST UPDATED ON: AUGUST 24th, 2023</span>
            </p>
            <p className="c0 c2">
              <div className="c6 c3">Transaction Fees</div>
              <span className="c1">
                When you make a sale to a buyer who connected with you through
                Kamba Group LLC, whether the sale is completed on the platform
                digitally or off it (directly with the buyer), you will incur a
                transaction fee of 15% of the transaction amount.
              </span>
            </p>
            <p className="c0 c2">
              <div className="c6 c3">Sales Taxes</div>
              <span className="c1">
                If the sale is completed on the platform using Stripe, sales
                taxes will be applied based on your company's account setup on
                Stripe. Transaction fees will be deducted from the payment at
                the time of transaction and will be reflected in your payment
                account.
              </span>
            </p>

            <p className="c0 c2">
              <div className="c6 c3">State Sales Tax</div>
              <span className="c1">
                In accordance with applicable state laws, state sales tax may
                also be applied to the transaction fee charged by Kamba. Kamba
                may be required to collect other fees, charges, or levies by
                certain jurisdictions and remit those amounts to the relevant
                governmental authorities. If such collection is mandated by law,
                these charges will be presented to buyers at checkout.
              </span>
            </p>

            <p className="c0 c2">
              <div className="c6 c3">Reporting to Governmental Authorities</div>
              <span className="c1">
                In cases where it is legally required, Kamba will report your
                personal details and transaction details to relevant
                governmental authorities. Kamba is committed to maintaining the
                confidentiality of your personal information and will not
                disclose it to any third parties unless legally obligated to do
                so.
              </span>
            </p>

            {isLoggedIn && (
              <div>
                <Button onClick={handleClick}>
                  {loadingAcceptance ? (
                    <ClipLoader color="#ffffff" size={15} />
                  ) : (
                    <span>I accept Kamba‘s Fee Policy</span>
                  )}
                </Button>
              </div>
            )}
          </ContractContainer>
        </Wrapper>
      </Container>
    </Container>
  )
}

export default FeePolicy
