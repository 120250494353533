import { Grid } from '@mui/material'
import { GridItemBorder } from '@components'
import * as React from 'react'
import { HeaderMock } from './headerMock'
import {
  BorderBlock,
  CheckShieldIcon,
  CompanyName,
  CompanyTitle,
  MarginSpace,
  ProfileImage,
  TotalScore,
  VerifiedLabel,
} from './styled'
import { useNavigate } from 'react-router-dom'

interface IHeaderProps {
  product: any
}

const Header: React.FunctionComponent<IHeaderProps> = (props) => {
  const { isVerifiedProvider, companyImg } = HeaderMock
  const navigate = useNavigate()
  const renderVerifiedText = () => (
    <>
      <Grid
        item
        xs={12}
        // lg={10}
        lg={12}
        // xl={8}
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <VerifiedLabel>VERIFIED DATA PROVIDER</VerifiedLabel>
        <CheckShieldIcon />
      </Grid>
    </>
  )

  const capitalize = (word: string) => {
    return word[0].toUpperCase() + word.slice(1).toLowerCase()
  }

  const goToStorefront = () =>
    navigate(`/storefront/${props.product.company.id}`)

  const renderSubTitle = () => {
    return (
      <>
        <Grid container item md={9} lg={11} xl={10} spacing={2}>
          <GridItemBorder
            item
            xs={'auto'}
            sx={{ justifyContent: 'flex-start' }}
            border={!false ? 'none' : undefined}
            paddingTop={'5px'}
          >
            By&nbsp;
            <CompanyName onClick={goToStorefront}>
              {props.product.company.name &&
                capitalize(props.product.company.name)}
            </CompanyName>
            <MarginSpace />
          </GridItemBorder>

          {props.product.verified && (
            <GridItemBorder item xs={'auto'}>
              {renderVerifiedText()}
              <MarginSpace />
            </GridItemBorder>
          )}
        </Grid>
      </>
    )
  }

  return (
    <>
      <Grid container columnSpacing={{ xs: 1, lg: 3 }} alignItems={'center'}>
        <Grid
          item
          xs={2}
          onClick={goToStorefront}
          style={{ cursor: 'pointer' }}
          alignItems={'center'}
          height={'fit-content'}
        >
          {props.product.company.avatar ? (
            <ProfileImage src={props.product.company.avatar}></ProfileImage>
          ) : (
            <ProfileImage src={companyImg}></ProfileImage>
          )}
        </Grid>
        <Grid item container xs={10}>
          <Grid item xs={12} sx={{ height: 'auto' }}>
            <CompanyTitle>{props.product?.title}</CompanyTitle>
          </Grid>
          <Grid item xs={12} lg={9} xl={8} sx={{ marginTop: '20px' }}>
            {renderSubTitle()}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default Header
