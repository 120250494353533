// import { cardsData } from '@/mocks/listings/card-listings'
import { Grid } from '@mui/material'
import * as React from 'react'
import { getCardsListings } from '@services/listing/selectors'
import { useDashboardContext } from '@/providers/dashboard/hooks/useDashboardContext'
import GridCards from '../grid-cards'
import { useSearchCompanies } from '@/services/suppliers/suppliers.service'
import { ICompanyCardProps } from '@/components/ui/company-card/types'
import CompanyCard from '@/components/ui/company-card'
import { PaginationComponent } from '@/components/pagination'
import { axios } from '@/lib/axios'

const baseUrl = process.env.REACT_APP_URL_BASE

export const SuppliersGridCards: React.FC<any> = ({
  filterAttributes,
  filterTitle,
  currentTab,
}) => {
  const [page, setPage] = React.useState(1)
  const [filters, setFilters] = React.useState<Record<string, string[]>>({})
  const [totalPages, setTotalPages] = React.useState<number | undefined>(
    undefined,
  )
  const { params: selectedFilters, setParams } = useDashboardContext()
  const sizeItems = 15

  const dataObject = useSearchCompanies({
    ...{ ...selectedFilters, size: sizeItems, from: (page - 1) * sizeItems },
  })

  const obtainFilters = async () => {
    const res = await axios.get(`${baseUrl}/api/v1/categories`)
    if (res.ok) {
      setFilters({
        dataMethods: res.data.dataMethod,
        useCases: res.data.useCases,
        geo: res.data.geo,
      })
    }
  }
  //This useEffect is here since FilterGrid doesn't reset the filter params
  React.useEffect(() => {
    setParams({ title: selectedFilters?.title })
    obtainFilters()
  }, [])

  React.useEffect(() => {
    setPage(1)
  }, [selectedFilters])

  React.useEffect(() => {
    const dataCount = dataObject.data?.data?.count
    if (dataCount) {
      setTotalPages(Math.ceil(dataCount / sizeItems))
    }
  }, [dataObject.data?.data?.count])

  const cardsData = dataObject.data?.data?.profiles
    ? (dataObject.data?.data?.profiles as ICompanyCardProps[])
    : []

  const content = cardsData
    ? cardsData.map((card) => {
        return (
          <Grid item xs={4} lg={4} md={6} sm={10} key={card.id}>
            <CompanyCard
              domain={card.domain}
              country={card.country}
              banner={card.banner}
              geo={card.geo}
              avatar={card.avatar}
              name={card.name}
              description={card.description}
              id={card.id}
              companyId={card.companyId}
              verified={card.verified}
              slogan={card.slogan}
            />
          </Grid>
        )
      })
    : []

  return (
    <>
      <GridCards
        productCount={{
          from: (page - 1) * sizeItems + 1,
          to:
            page * sizeItems < dataObject.data?.data?.count!
              ? page * sizeItems
              : dataObject.data?.data?.count!,
          total: dataObject.data?.data?.count!,
        }}
        filterAttributes={filters}
        filterTitle={filterTitle}
        currentTab={currentTab}
        dataObject={dataObject}
        cards={content}
      />
      {content.length > 0 && (
        <PaginationComponent
          totalPages={totalPages}
          page={page}
          setPage={setPage}
        />
      )}
    </>
  )
}
