import styled from 'styled-components'
import {
  color,
  space,
  fontSize,
  border,
  compose,
  variant,
  layout,
  flexbox,
} from 'styled-system'
import { IBaseButtonProps } from './types'

export const theme = {
  colors: {
    custom: '#444',
  },
  buttons: {
    primary: {
      color: '#FFFFFF',
      backgroundColor: '#000000',
      '&:disabled': {
        opacity: '0.5',
      },
    },
    secondary: {
      color: '#000000',
      backgroundColor: '#FFFFFF',
      border: '1px solid #000000',
    },
    active: {
      color: '#FFFFFF',
      backgroundColor: '#4BA765',
    },
  },
}

export const ButtonStyled = styled.button<IBaseButtonProps>`
  outline: 0;
  font-family: inherit;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  border: 1.25px solid #ffffff;
  padding: 10px 25px;
  cursor: pointer;
  ${compose(flexbox, color, space, fontSize, layout, border)};

  ${variant({
    variants: theme.buttons,
  })}
`

ButtonStyled.defaultProps = {
  variant: 'primary',
  backgroundColor: '#000000',
}
