import * as React from 'react'
import { useState, useEffect, KeyboardEvent } from 'react'
import { useAppSelector } from '../../app/hooks'
import { useLocation, useNavigate } from 'react-router-dom'
import kambaLogoWhite from '@assets/images/KambalogoWhite.png'
import SearchIcon from '@assets/images/icons/SearchIcon.png'
import ClearIcon from '@mui/icons-material/Clear'
import { Avatar } from '../navigation/styled'
import { Logo } from '..'
import {
  Search,
  StyledInputBase,
  SearchIconWrapper,
  appBarStyled as AppBar,
  LoginButton,
  SignUpButton,
  Title,
  Icon,
  ClearIconWrapper,
  DropdownContent,
  AvatarWrapper,
  DropdownItem,
  VerticalDivider,
  LogoSection,
} from './styled'
import { useDashboardContext } from '@/providers/dashboard/hooks/useDashboardContext'
import { useDebounce } from '@/hooks/useDebounce'
import storage from '../../utils/storage'
import { useAuth } from '@services/auth/auth'
import chevronDownWhite from '@assets/images/icons/chevron-down-white.png'
import supplierDashboardWhite from '@assets/images/icons/supplier-dashboard-white.png'
import marketplaceWhite from '@assets/images/icons/marketplace-white.png'
import usersIcon from '@assets/images/icons/users.png'
import settingsIcon from '@assets/images/icons/settings.png'
import softwareIcon from '@assets/images/icons/layers-two.png'
import logoutIcon from '@assets/images/icons/log-out.png'
import { queryClient } from '@/lib/react-query'
import ImpersonateModal from './impersonate-modal'

interface appBarProps {}

const AppBarHeader: React.FC<appBarProps> = (props) => {
  const { logout } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const [searchText, setSearchText] = useState('')
  const [impersonateDialog, setImpersonateDialog] = useState(false)
  const debouncedSearchText = useDebounce(searchText, 500)
  // const { isLoggedIn } = useAppSelector((state) => state.login)
  const isLoggedIn = storage.getUserId() ? true : false
  const isSupplier = storage.getUserRole() === 'seller' ? true : false
  const isAdmin = storage.getUserAdmin()
  const userMail = storage.getUserMail()
  const isImpersonating = !!storage.getImpersonation()
  const { params, setParams } = useDashboardContext()
  const userIdentity = useAppSelector((state) => state.authUser.userMail)
  const redirect = (path: string) => {
    navigate(`/${path}`)
  }

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value)
  }

  useEffect(() => {
    if (!!setParams) {
      if (!debouncedSearchText) {
        setParams({ ...params, title: undefined })
      } else {
        setParams({ ...params, title: debouncedSearchText })
      }
    }
  }, [debouncedSearchText])

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && setParams) {
      // console.info(setParams)
      setParams({ ...params, title: searchText })
    }
  }

  const handleSearch = () => {
    setParams({ ...params, title: searchText })
  }

  const handleClearSearch = () => {
    setSearchText('')
    setParams({ ...params, title: undefined })
  }

  const handleLogout = async () => {
    await logout()
    navigate('/')
  }

  const createAvatar = () => {
    if (userIdentity) {
      let avatar = userIdentity.slice(0, 2)
      return avatar
    }
  }

  const showImpersonateModal = () => {
    if (isImpersonating) {
      stopImpersonating()
    } else {
      setImpersonateDialog(true)
    }
  }

  const closeImpersonateModal = () => {
    setImpersonateDialog(false)
  }

  const stopImpersonating = () => {
    const user = storage.getImpersonation()
    if (user) {
      storage.setCognitoId(user.cognitoId!)
      storage.setCompanyId(user.companyId!)
      storage.setUserId(user.userId!)
      storage.setUserMail(user.userMail!)
      storage.setUserRole(user.userRole!)
      storage.clearImpersonation()
      window.location.href = '/'
    }
  }

  const renderDropDown = () => (
    <DropdownContent className="content">
      {isSupplier ? (
        <DropdownItem
          onClick={() => {
            navigate('/suppliers-dashboard')
          }}
        >
          <div>
            <Icon
              src={supplierDashboardWhite}
              padding="0"
              style={{ filter: 'invert(1)' }}
            />
            Open Supplier Dashboard
          </div>
        </DropdownItem>
      ) : (
        <DropdownItem
          onClick={() => {
            navigate('/')
          }}
        >
          <div>
            <Icon
              src={marketplaceWhite}
              padding="0"
              style={{ filter: 'invert(1)' }}
            />
            Back to Kamba Marketplace
          </div>
        </DropdownItem>
      )}
      {/* <DropdownItem onClick={}>
        <Icon src={userIcon} padding="0" />
        View Profie
      </DropdownItem>
      <DropdownItem onClick={}>
        <Icon src={usersIcon} padding="0" />
        Team
      </DropdownItem> */}
      {isSupplier && (
        <DropdownItem
          onClick={() => {
            queryClient.removeQueries({
              queryKey: ['company'],
            })
            queryClient.removeQueries({
              queryKey: ['company-profile'],
            })
            navigate('/storefront-admin')
          }}
        >
          <div>
            <Icon src={settingsIcon} padding="0" />
            Profile Settings
          </div>
        </DropdownItem>
      )}
      {isAdmin && (
        <DropdownItem onClick={showImpersonateModal}>
          <div>
            <Icon src={usersIcon} padding="0" />
            {isImpersonating ? `Stop Impersonating ${userMail}` : `Impersonate`}
          </div>
        </DropdownItem>
      )}
      {/* <DropdownItem onClick={handleLogout}>
        <Icon src={supplierDashboardWhite} padding="0" />
        Support
      </DropdownItem> */}
      <DropdownItem>
        <div>
          <Icon src={softwareIcon} padding="0" />
          <span>Software version</span>
        </div>
        <span className="version">0.4.7</span>
      </DropdownItem>
      <DropdownItem onClick={handleLogout} last>
        <div>
          <Icon src={logoutIcon} padding="0" />
          Log out
        </div>
      </DropdownItem>
    </DropdownContent>
  )

  return (
    <AppBar>
      <LogoSection>
        <Logo src={kambaLogoWhite} margin={'0'} />
        {/*<VerticalDivider />*/}
        <Title>DATA DISCOVERY</Title>
      </LogoSection>
      {location.pathname === '/' && (
        <Search>
          <StyledInputBase
            placeholder="Search"
            inputProps={{ 'aria-label': 'search' }}
            onChange={handleSearchInput}
            onKeyDown={handleKeyDown}
            value={searchText}
          />
          {searchText && searchText.length > 0 && (
            <ClearIconWrapper onClick={handleClearSearch}>
              <ClearIcon color="action" />
            </ClearIconWrapper>
          )}
          <SearchIconWrapper onClick={handleSearch}>
            <Icon src={SearchIcon} />
          </SearchIconWrapper>
        </Search>
      )}
      <AvatarWrapper>
        {isLoggedIn ? (
          <>
            {renderDropDown()}
            <Avatar>{createAvatar()}</Avatar>
            <Icon className="icon" src={chevronDownWhite} padding="0"></Icon>
          </>
        ) : (
          <>
            <LoginButton onClick={() => redirect('login')}>LOGIN</LoginButton>
            <SignUpButton onClick={() => redirect('create-account')}>
              SIGN UP
            </SignUpButton>
          </>
        )}
      </AvatarWrapper>
      {impersonateDialog && (
        <ImpersonateModal onClose={closeImpersonateModal} />
      )}
    </AppBar>
  )
}

export default AppBarHeader
