import styled from 'styled-components'

export const ViewFileDiv = styled.div`
  width: 100%;
  padding: 1rem;
  background-color: white;
`
export const FileInfoDiv = styled.div`
  span {
    font-weight: bold;
  }
  p {
    color: #5c5c5c;
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const DeleteFileDiv = styled.div``
