import ExplorerLight from '@assets/images/icons/sidebar/light/explore-light.png'
import ExplorerDark from '@assets/images/icons/sidebar/dark/explore-dark.png'
import MessagesLight from '@assets/images/icons/sidebar/light/messages-light.png'
import MessagesDark from '@assets/images/icons/sidebar/dark/messages-dark.png'
import SubscriptionsLight from '@assets/images/icons/sidebar/light/subscriptions-light.png'
import SubscriptionsDark from '@assets/images/icons/sidebar/dark/subscriptions-dark.png'
import ComplianceLight from '@assets/images/icons/sidebar/light/compliance-light.png'
import ComplianceDark from '@assets/images/icons/sidebar/dark/compliance-dark.png'
import PipelineLight from '@assets/images/icons/sidebar/light/pipeline-light.png'
import PipelineDark from '@assets/images/icons/sidebar/dark/pipeline-dark.png'
import ProductsLight from '@assets/images/icons/sidebar/light/products-light.png'
import ProductsDark from '@assets/images/icons/sidebar/dark/products-dark.png'
import LeadPoolLight from '@assets/images/icons/sidebar/light/leadpool-light.png'
import LeadPoolDark from '@assets/images/icons/sidebar/dark/leadpool-dark.png'
import Explorer from '../../pages/dashboard/explorer'
import Messages from '../../pages/dashboard/messages'
import Posting from '../../pages/dashboard/posting'
import Subscriptions from '../../pages/dashboard/subscriptions'
import Compliance from '../../pages/dashboard/compliance'
import SubscriptionPage from '@/pages/dashboard/subscriptions/subscription-page'
import Listings from '@/pages/dashboard/listings'
import Deals from '@/pages/dashboard/deals'
import LeadPoolPage from '@/pages/dashboard/lead-pool'
import StorefrontAdmin from '@/pages/storefront-admin'
import Backtesting from '@/pages/backtesting'
import RegisterClickPage from '@/pages/register-click/register-click-page'

const Routes = [
  {
    path: '/',
    sidebarName: 'Explore',
    iconLight: ExplorerLight,
    iconDark: ExplorerDark,
    component: Explorer,
    userRole: 'both',
    isPublic: true,
  },
  {
    path: '/messages',
    sidebarName: 'Messages',
    iconLight: MessagesLight,
    iconDark: MessagesDark,
    component: Messages,
    userRole: 'both',
    isPublic: false,
  },
  {
    path: '/listings',
    sidebarName: 'Products',
    iconLight: ProductsLight,
    iconDark: ProductsDark,
    component: Listings,
    userRole: 'seller',
    isPublic: false,
  },
  {
    path: '/lead-pool',
    sidebarName: 'Lead Pool',
    iconLight: LeadPoolLight,
    iconDark: LeadPoolDark,
    component: LeadPoolPage,
    userRole: 'seller',
    isPublic: false,
  },
  {
    path: '/posting',
    sidebarName: 'Postings',
    iconLight: LeadPoolLight,
    iconDark: LeadPoolDark,
    component: Posting,
    userRole: 'buyer',
    isPublic: false,
  },
  {
    path: '/backtesting',
    sidebarName: 'Backtesting',
    iconLight: ComplianceLight,
    iconDark: ComplianceDark,
    userRole: 'buyer',
    component: Backtesting,
  },
  {
    path: '/deals',
    sidebarName: 'Pipeline',
    iconLight: PipelineLight,
    iconDark: PipelineDark,
    component: Deals,
    userRole: 'both',
    isPublic: false,
  },
  {
    path: '/subscriptions',
    sidebarName: 'Subscriptions',
    iconLight: SubscriptionsLight,
    iconDark: SubscriptionsDark,
    component: Subscriptions,
    userRole: 'both',
    isPublic: false,
  },
  {
    path: '/compliance',
    sidebarName: 'Legal & Compliance',
    iconLight: ComplianceLight,
    iconDark: ComplianceDark,
    component: Compliance,
    userRole: 'both',
    isPublic: false,
  },
  {
    path: '/subscriptions/:id',
    sidebarName: 'Subscriptions',
    component: SubscriptionPage,
    userRole: 'buyer',
    isHidden: true,
  },
  {
    path: '/storefront-admin',
    sidebarName: 'Storefront Admin',
    component: StorefrontAdmin,
    userRole: 'seller',
    isHidden: true,
  },
  {
    path: '/data-valuation',
    sidebarName: 'Data Valuation',
    iconLight: ProductsLight,
    iconDark: ProductsDark,
    component: RegisterClickPage,
    userRole: 'seller',
    isPublic: false,
    target: '_blank',
  },
  {
    path: '/data-baked-lending',
    sidebarName: 'Data-backed Lending',
    iconLight: ProductsLight,
    iconDark: ProductsDark,
    component: RegisterClickPage,
    userRole: 'seller',
    isPublic: false,
    target: '_blank',
  },
]

export default Routes
