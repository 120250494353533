import styled from 'styled-components'
import { IButtonProps } from './types'
import storeIcon from '@assets/images/icons/bx_store'
import fileIcon from '@assets/images/icons/file-plus'

export const CTAWrapper = styled.div`
  display: flex;
  column-gap: 20px;
`

export const Button = styled.button.attrs(({ type }) => ({
  type: type || 'button',
}))<IButtonProps>`
  border: ${({ backgroundColor }) =>
    '1.5px solid' + (backgroundColor || '#000000')};
  background-color: ${({ backgroundColor }) =>
    backgroundColor || 'transparent'};
  background-image: ${({ icon }) =>
    icon ? `url(${fileIcon})` : `url(${storeIcon})`};
  background-repeat: no-repeat;
  background-position: ${({ icon }) => (icon ? `41px 50%` : `15px 50%`)};
  color: ${({ color }) => color || '#000000'};
  font-size: 15px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  padding: ${({ padding }) => padding || '16px 19px 16px 47px'};
  height: 51px;
  cursor: pointer;
  margin: 0;
`
