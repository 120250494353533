import { AppBarHeader, Sidebar, Button, Checkbox, Icon } from '@/components'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import {
  BulletDescription,
  BulletSeparator,
  BulletTitle,
  CardTitle,
  CompleteSubscription,
  Container,
  FinalStep,
  PageContainer,
  SubscriptionDetails,
  ArrowButton,
  ArrowIcon,
  DetailHeader,
  HeaderTitle,
  DetailsContainer,
  DetailTabs,
  Tab,
  SubscriptionDetailInfo,
  Step,
  StepNumber,
  StepTitle,
  StepSubtitle,
  StepOffer,
  StepOfferTitle,
  StepParagraph,
  DocumentsContainer,
  DocumentCard,
  DocumentURLButton,
  DocumentURLIcon,
  CardImgContainer,
  AutomaticRenewal,
  TermsParagraph,
  SealOfApproval,
  SealOfApprovalButton,
} from './styled'
import { documentCards } from '@/mocks/subscription/subscription'
import StripeLogo from '@assets/images/icons/StripeLogo'
import { ReactComponent as DatabaseIcon } from '../../assets/images/icons/database-icon.svg'
import ClipLoader from 'react-spinners/ClipLoader'
import storage from '@/utils/storage'
import { useAppSelector } from '@/app/hooks'
import { axios } from '@/lib/axios'
import BankLogo from '@assets/images/icons/bank'
import moment from 'moment'
import { ClickAwayListener, Grid, Tooltip } from '@mui/material'
import CopyIcon from '@assets/images/icons/CopyIcon'
import MessageIcon from '@assets/images/icons/MessagesIcon'

function SubscriptionInfo() {
  const [product, setProduct] = useState({})
  const [loading, setLoading] = useState(false)
  const [subscribing, setSubscribing] = useState(false)
  const [publicOfferApproved, setPublicOfferApproved] = useState(false)
  const [termsApproved, setTermsApproved] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState('')
  const [automaticalRenewal, setAutomaticalRenewal] = useState(false)
  const [freeProduct, setFreeProduct] = useState(false)
  const [paymentId, setPaymentId] = useState('')
  const [inConversation, setInConversation] = useState<any>()
  const preSubscriptionId = useRef<string>()
  const preSubscription = useRef<any>()
  const userIdentity = useAppSelector((state) => state.authUser.userMail)
  const userId = useAppSelector((state) => state.authUser.userId)
  const { listingId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const appEnv = process.env.APP_ENVIRONMENT
  const baseUrl = process.env.REACT_APP_URL_BASE
  const token = storage.getToken()
  const offer = useRef(null)
  const terms = useRef(null)
  const payment = useRef(null)
  const [selectedTab, setSelectedTab] = useState('')
  const [openToolTip, setOpenToolTip] = useState(false)
  const currentLocation = window.location.href.replace(
    'subscription-info',
    'product',
  )
  const handleCopyClipBoard = () => {
    navigator.clipboard.writeText(currentLocation)
    setOpenToolTip(true)
    closeTooltip()
  }

  const handleTooltipClose = () => {
    setOpenToolTip(false)
  }

  const closeTooltip = () => {
    setTimeout(() => {
      setOpenToolTip(false)
    }, 2000)
  }

  const getProduct = (listingId: string) => {
    const res = axios
      .get(`${baseUrl}/api/v1/listings/${listingId}`)
      .then(function (response: any) {
        if (
          response.data.buyingFormat[0].type === 'one' &&
          response.data.buyingFormat[0].price === 0
        ) {
          setFreeProduct(true)
          setPaymentMethod('otherMethods')
        }
        return response
      })
      .catch(function (error: any) {
        return error
      })
    return res
  }

  const checkIfSubscribed = async () => {
    const res = axios
      .get(
        `${baseUrl}/api/v1/presubscriptions?userId=${userId}&listingId=${listingId}`,
      )
      .then(function (response: any) {
        preSubscription.current = response.data
        preSubscriptionId.current = response.data[0].id
        return response
      })
      .catch(function (error: any) {
        return error
      })
    return res
  }

  const updatePresubscriptionPaymentId = async (
    paymentId: string,
    userIdWhoApprovesPayment: string | null,
  ) => {
    const res = axios
      .put(`${baseUrl}/api/v1/presubscriptions/${preSubscriptionId.current}`, {
        paymentId: paymentId,
        userIdWhoApprovesPayment: userIdWhoApprovesPayment,
      })
      .then(function (response: any) {
        return response
      })
      .catch(function (error: any) {
        return error
      })
    return res
  }

  const updatePresubscriptionLegal = async (
    userIdWhoApprovesLegal: string | null,
  ) => {
    const res = axios
      .put(`${baseUrl}/api/v1/presubscriptions/${preSubscriptionId.current}`, {
        userIdWhoApprovesLegal: userIdWhoApprovesLegal,
      })
      .then(function (response: any) {
        return response
      })
      .catch(function (error: any) {
        return error
      })
    return res
  }

  const updatePresubscriptionRenewal = async (automaticalRenewal: boolean) => {
    const res = axios
      .put(`${baseUrl}/api/v1/presubscriptions/${preSubscriptionId.current}`, {
        automaticalRenewal: automaticalRenewal,
      })
      .then(function (response: any) {
        return response
      })
      .catch(function (error: any) {
        return error
      })
    return res
  }

  const subscribeProduct = () => {
    const res = axios
      .post(`${baseUrl}/api/v1/subscriptions`, {
        subscription: {
          companyId: location.state.companyId,
          listingId: listingId,
          renews: '02-02-2023',
          userId: userId,
          payment: paymentId,
          paymentMethod: paymentMethod,
        },
        url: `${appEnv}/subscriptions`,
        preSubscriptionId: preSubscriptionId.current,
        /*subscription: {
          companyId: location.state.companyId,
          listingId: listingId,
          renews: '02-02-2023',
          userId: userId,
          payment: paymentId,
          paymentMethod: paymentMethod,
        },
        url: `${appEnv}/subscriptions`,*/
      })
      .then(function (response: any) {
        return response
      })
      .catch(function (error: any) {
        return error
      })
    return res
  }

  useEffect(() => {
    setLoading(true)
    getProduct(listingId).then((data) => {
      setProduct(data.data)
      checkIfSubscribed().then(() => {
        setLoading(false)
        const preSub = preSubscription?.current?.length
          ? preSubscription?.current[0]
          : undefined
        if (preSub?.paymentId) {
          setPaymentId(preSubscription.current[0].paymentId)
          setPublicOfferApproved(true)
        }
        if (preSub?.userIdWhoApprovesLegal) {
          setTermsApproved(true)
        }
        if (preSub?.automaticalRenewal === true) {
          setAutomaticalRenewal(true)
        }
        if (preSubscription?.current?.conversation) {
          setInConversation(preSubscription?.current?.conversation)
        }
      })
    })
  }, [])

  const handleOnClick = (value: any) => {
    navigate(value)
  }

  const approvePublicOffer = (event: Event) => {
    if (!publicOfferApproved && !paymentId) {
      setPublicOfferApproved(true)
      setPaymentId(event.target.value)
      updatePresubscriptionPaymentId(event.target.value, userId)
    } else if (publicOfferApproved && paymentId !== event.target.value) {
      setPaymentId(event.target.value)
      updatePresubscriptionPaymentId(event.target.value, userId)
    } else if (publicOfferApproved && paymentId === event.target.value) {
      setPublicOfferApproved(false)
      setPaymentId('')
      updatePresubscriptionPaymentId('', userId)
    }
  }

  const approveLegalDocuments = () => {
    if (termsApproved === true) {
      setTermsApproved(false)
      updatePresubscriptionLegal('')
    } else {
      setTermsApproved(true)
      updatePresubscriptionLegal(userId)
    }
  }

  const selectAutomaticRenewal = (boolean: boolean) => {
    if (boolean === true) {
      setAutomaticalRenewal(true)
      updatePresubscriptionRenewal(true)
    } else {
      setAutomaticalRenewal(false)
      updatePresubscriptionRenewal(false)
    }
  }

  const subscribeToProduct = async () => {
    setSubscribing(true)
    subscribeProduct().then((data) => {
      if (!data.data.url) {
        window.open(`${appEnv}/subscriptions`, '_self')
      } else {
        window.open(data.data.url, '_self')
      }
    })
  }

  const goToChat = async () => {
    let listings = ''
    let src = ''
    if (inConversation) {
      listings = inConversation.listingId
      src = inConversation.source
    } else {
      const resp = await axios.post(`/legals/conversations`, {
        listingId,
      })

      if (resp.data) {
        listings = resp.data.listingId
        src = resp.data.source
      }
    }

    if (listings && src) {
      window.location.href = `/messages?listings=${listings}&source=${src}`
    }
  }

  /*const handleClick = (section: string) => {
    if (section == 'offer') {
      offer.current?.scrollIntoView({ behavior: 'smooth' })
      setSelectedTab('offer')
    } else if (section == 'terms') {
      terms.current?.scrollIntoView({ behavior: 'smooth' })
      setSelectedTab('terms')
    } else if (section == 'payment') {
      payment.current?.scrollIntoView({ behavior: 'smooth' })
      setSelectedTab('payment')
    }
  }*/

  const handleCheckDocument = (url: string) => () => {
    window.open(url, '_blank')
  }

  const handlePaymentMethod = (method: string) => {
    if (paymentMethod === method) {
      setPaymentMethod('')
    } else {
      setPaymentMethod(method)
    }
  }

  // console.log(!appEnv?.includes('d26t4'))

  // console.log(appEnv)

  return (
    <Container>
      <Sidebar />
      <Container flexDirection="column">
        <AppBarHeader />

        {loading ? (
          <ClipLoader color="#ffffff" loading={loading} size={15} />
        ) : (
          <PageContainer>
            <CompleteSubscription>
              <CardTitle>Complete Subscription</CardTitle>
              <BulletTitle>1. Choose a product offer</BulletTitle>
              <BulletDescription>
                Select an offer to view the pricing and access duration options
                for the offer
              </BulletDescription>
              <BulletSeparator />
              <BulletTitle>2. Review the subscription terms</BulletTitle>
              <BulletDescription>
                All legal documents will be sent to your legal team to ensure
                legal compliance
              </BulletDescription>
              <BulletSeparator />
              <BulletTitle>3. Choose a payment method </BulletTitle>
              <BulletDescription>
                You can also choose whether to renew the offer automatically
                when it expires
              </BulletDescription>
              <BulletSeparator />
              <FinalStep>
                <BulletTitle>Subscribe</BulletTitle>
                {/*<BulletDescription>
                  If your legal department approves the request, product will be
                  ready for use in <b>Subscriptions </b>
                  <DatabaseIcon />
        </BulletDescription>*/}
                <BulletDescription>
                  The following offers are available for this product. Choose an
                  offer to lock in the pricing and access duration options for
                  the offer.
                </BulletDescription>
              </FinalStep>
            </CompleteSubscription>

            <SubscriptionDetails>
              <DetailHeader>
                <Grid container>
                  <Grid item sm={1}>
                    <ArrowButton onClick={() => navigate(-1)}>
                      <ArrowIcon />
                    </ArrowButton>
                  </Grid>
                  <Grid item sm={8}>
                    <HeaderTitle>{product?.title}</HeaderTitle>
                  </Grid>
                  <Grid item sm={1} />
                  <Grid item sm={2}>
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                      <Tooltip
                        title="COPIED!"
                        placement="top"
                        onClose={handleTooltipClose}
                        open={openToolTip}
                        disableHoverListener={true}
                      >
                        <span>
                          <Button
                            variant="primary"
                            height={'100%'}
                            onClick={handleCopyClipBoard}
                            display="flex"
                            justifyContent={'center'}
                            alignItems={'center'}
                          >
                            COPY URL
                            <Icon margin="0px 10px" src={CopyIcon}></Icon>
                          </Button>
                        </span>
                      </Tooltip>
                    </ClickAwayListener>
                  </Grid>
                </Grid>
              </DetailHeader>
              <DetailsContainer>
                {/*<DetailTabs>
                  <Tab onClick={() => handleClick('offer')}>Offer</Tab>
                  <Tab onClick={() => handleClick('terms')}>Terms</Tab>
                  <Tab onClick={() => handleClick('payment')}>Payment</Tab>
                </DetailTabs>*/}
                <SubscriptionDetailInfo>
                  <Step style={{ marginBottom: '45px' }}>
                    <BulletDescription>
                      By submitting this subscription request, you agree that
                      your use of this product is subject to the provider's
                      offer terms including pricing information and Data
                      Subscription Agreement.
                    </BulletDescription>
                  </Step>
                  <Step ref={offer}>
                    <StepNumber>Step Number 1/3</StepNumber>
                    <StepTitle>
                      Select an offer and continue to subscribe
                    </StepTitle>
                    <StepParagraph>
                      The following offers are available for this product.
                      Choose an offer to lock in the pricing and access duration
                      options for the offer.
                    </StepParagraph>
                    <StepSubtitle>Public Offer</StepSubtitle>
                    {product?.buyingFormat &&
                      product?.buyingFormat.map((format, i) => {
                        if (!!freeProduct) {
                          return (
                            <StepOffer key={i}>
                              <div style={{ display: 'flex' }}>
                                <Checkbox
                                  isChecked={format.id === paymentId}
                                  name="publicOffer"
                                  handleOnChange={() =>
                                    approvePublicOffer(event)
                                  }
                                  value={format.id}
                                />
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <StepOfferTitle>Free</StepOfferTitle>
                                  {/*<StepParagraph>
                                  Payment schedule:{' '}
                                </StepParagraph>
                                Upfront payment |
                                <StepParagraph>
                                  {' '}
                                  Offer auto-renewal:{' '}
                                </StepParagraph>
                        SupportedSS*/}
                                </div>
                              </div>
                            </StepOffer>
                          )
                        } else if (format.type !== 'one') {
                          return (
                            <StepOffer key={i}>
                              <div style={{ display: 'flex' }}>
                                <Checkbox
                                  isChecked={format.id === paymentId}
                                  name="publicOffer"
                                  handleOnChange={() =>
                                    approvePublicOffer(event)
                                  }
                                  value={format.id}
                                />
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  {format.type === 'yearly' ? (
                                    <StepOfferTitle>
                                      ${format.price} per year
                                    </StepOfferTitle>
                                  ) : format.type === 'monthly' ? (
                                    <StepOfferTitle>
                                      ${format.price} per month
                                    </StepOfferTitle>
                                  ) : format.type === 'weekly' ? (
                                    <StepOfferTitle>
                                      ${format.price} per week
                                    </StepOfferTitle>
                                  ) : (
                                    <StepOfferTitle>
                                      ${format.price} per {format.type}
                                    </StepOfferTitle>
                                  )}
                                  {/*<StepParagraph>
                                  Payment schedule:{' '}
                                </StepParagraph>
                                Upfront payment |
                                <StepParagraph>
                                  {' '}
                                  Offer auto-renewal:{' '}
                                </StepParagraph>
                        SupportedSS*/}
                                </div>
                              </div>
                            </StepOffer>
                          )
                        } else {
                          return (
                            <StepOffer key={i}>
                              <div style={{ display: 'flex' }}>
                                <Checkbox
                                  isChecked={format.id === paymentId}
                                  name="publicOffer"
                                  handleOnChange={() =>
                                    approvePublicOffer(event)
                                  }
                                  value={format.id}
                                />
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <StepOfferTitle>
                                    One time purchase of ${format.price}
                                  </StepOfferTitle>
                                  {/*<StepParagraph>
                                  Payment schedule:{' '}
                                </StepParagraph>
                                Upfront payment |
                                <StepParagraph>
                                  {' '}
                                  Offer auto-renewal:{' '}
                                </StepParagraph>
                        SupportedSS*/}
                                </div>
                              </div>
                            </StepOffer>
                          )
                        }
                      })}
                    <SealOfApproval
                      className={
                        publicOfferApproved && paymentId ? '' : 'inactive'
                      }
                    >
                      Approved by{' '}
                      {
                        /*!!preSubscription.current[0].userIdWhoApprovesPayment &&
                      preSubscription.current[0].userIdWhoApprovesPayment ===
                        userId
                        ? */ userIdentity /*
                    : 'Other user'*/
                      }
                    </SealOfApproval>
                  </Step>

                  <Step ref={terms}>
                    <StepNumber>Step Number 2/3</StepNumber>
                    <StepTitle>Subscription Terms</StepTitle>
                    <StepParagraph>
                      Need to Negotiate the Subscription’s Terms? Use Kamba’s
                      message module for a swift and compliant purchase process.
                      Access “Inquiries” on the menu bar, select the
                      conversation thread and message the Supplier in real time.
                      <br />
                      Negotiated Terms will be uploaded on an exclusive product
                      offer for your final approval.
                    </StepParagraph>
                    <DocumentsContainer>
                      {product?.legalCompliance &&
                        product.legalCompliance
                          ?.filter((val) => !!val.name)
                          .map((document, i) => {
                            return (
                              <DocumentCard
                                key={i}
                                onClick={handleCheckDocument(document.s3Link)}
                              >
                                {document.type}
                                <DocumentURLButton>
                                  <DocumentURLIcon />
                                </DocumentURLButton>
                              </DocumentCard>
                            )
                          })}
                    </DocumentsContainer>
                    <Grid container>
                      <Grid item sm={6}>
                        <SealOfApprovalButton
                          onClick={() => {
                            approveLegalDocuments()
                          }}
                          className={termsApproved ? 'inactive' : ''}
                        >
                          Approve
                        </SealOfApprovalButton>

                        <SealOfApproval
                          onClick={() => {
                            approveLegalDocuments()
                          }}
                          className={termsApproved ? '' : 'inactive'}
                        >
                          Approved by {userIdentity}
                        </SealOfApproval>
                      </Grid>
                      <Grid item sm={2} />
                      <Grid item sm={4} display="flex" alignItems={'end'}>
                        <Button
                          variant="primary"
                          height={'60px'}
                          onClick={goToChat}
                          display="flex"
                          justifyContent={'center'}
                          alignItems={'center'}
                        >
                          Legal Message
                          <Icon margin="0px 10px" src={MessageIcon}></Icon>
                        </Button>
                      </Grid>
                    </Grid>
                  </Step>
                  {!freeProduct && (
                    <>
                      <Step ref={payment}>
                        <StepNumber>Step Number 3/3</StepNumber>
                        <StepTitle>Payment</StepTitle>
                        <StepOffer>
                          <Checkbox
                            name="stripe"
                            handleOnChange={() => handlePaymentMethod('stripe')}
                            isChecked={paymentMethod === 'stripe'}
                          />
                          <CardImgContainer>
                            <img src={StripeLogo} alt="Stripe Logo" />
                          </CardImgContainer>
                          <div>
                            <StepOfferTitle>Stripe</StepOfferTitle>
                            <StepParagraph>
                              Credit card payment through Stripe
                            </StepParagraph>
                          </div>
                        </StepOffer>
                        <StepOffer>
                          <Checkbox
                            name="ach"
                            handleOnChange={() =>
                              handlePaymentMethod('stripe_ach_credit_transfer')
                            }
                            isChecked={
                              paymentMethod === 'stripe_ach_credit_transfer'
                            }
                          />
                          <CardImgContainer>
                            <img src={StripeLogo} alt="Stripe Logo" />
                          </CardImgContainer>
                          <div>
                            <StepOfferTitle>Stripe</StepOfferTitle>
                            <StepParagraph>
                              ACH transfer through Stripe
                            </StepParagraph>
                          </div>
                        </StepOffer>
                        <StepOffer>
                          <Checkbox
                            name="wireTransfer"
                            handleOnChange={() =>
                              handlePaymentMethod('otherMethods')
                            }
                            isChecked={paymentMethod === 'otherMethods'}
                          />
                          <CardImgContainer>
                            <img src={BankLogo} alt="Wire transfer Logo" />
                          </CardImgContainer>
                          <div>
                            <StepOfferTitle>Other Methods</StepOfferTitle>
                            <StepParagraph>
                              Buyer & Supplier agree on payment methods and
                              invoicing through Kamba’s Delivery Chat.
                            </StepParagraph>
                          </div>
                        </StepOffer>
                        <SealOfApproval
                          className={!!paymentMethod ? '' : 'inactive'}
                        >
                          Approved by{' '}
                          {
                            /*!!preSubscription.current[0].userIdWhoApprovesPayment &&
                      preSubscription.current[0].userIdWhoApprovesPayment ===
                        userId
                        ? */ userIdentity /*
                    : 'Other user'*/
                          }
                        </SealOfApproval>
                      </Step>
                      <StepSubtitle>
                        Do you want to automatically renew this offer when it
                        expires?
                      </StepSubtitle>
                      <AutomaticRenewal>
                        <div style={{ display: 'flex', gap: '18px' }}>
                          <Checkbox
                            name="noAutomaticRenewal"
                            handleOnChange={() => selectAutomaticRenewal(false)}
                            isChecked={automaticalRenewal === false}
                          />
                          <StepOfferTitle>No</StepOfferTitle>
                        </div>
                        <div style={{ display: 'flex', gap: '18px' }}>
                          <Checkbox
                            name="automaticRenewal"
                            handleOnChange={() => selectAutomaticRenewal(true)}
                            isChecked={automaticalRenewal === true}
                          />
                          <StepOfferTitle>Yes</StepOfferTitle>
                        </div>
                        <TermsParagraph>
                          I understand that when I renew, offer terms such as
                          pricing and the data subscription agreement might
                          change. Automatic renewal ensures entitlement
                          continuity.
                        </TermsParagraph>
                      </AutomaticRenewal>
                    </>
                  )}
                  <Button
                    variant="primary"
                    style={{
                      padding: '24px 0',
                      marginTop: '27px',
                      display:
                        !!paymentMethod && publicOfferApproved && termsApproved
                          ? 'block'
                          : 'none',
                    }}
                    onClick={subscribeToProduct}
                  >
                    {subscribing ? (
                      <ClipLoader
                        color="#ffffff"
                        loading={subscribing}
                        size={15}
                      />
                    ) : (
                      'COMPLETE SUBSCRIPTION'
                    )}
                  </Button>
                </SubscriptionDetailInfo>
              </DetailsContainer>
            </SubscriptionDetails>
          </PageContainer>
        )}
      </Container>
    </Container>
  )
}

export default SubscriptionInfo
