import { Collapsible, GradientSeparator, Title } from '@/components'
import { Separator } from '@/components/layout/gradient-separator/styled'
import withScrollToElement from '@/hocs/withScrollToElement'
import WithVisibility from '@/hocs/withVisibility'
import { faqsMock } from '@/mocks/product/faq'
import { Grid } from '@mui/material'
import * as React from 'react'
import { IFaqProps } from './types'

const Faq: React.FunctionComponent<IFaqProps> = ({ faqs }) => {
  return (
    <>
      <Grid container spacing={1}>
        <Title
          text=""
          boldText="Frequently Asked Questions"
          margin="25px 0"
          fontSize="30px"
        />
        {faqs.map((faq, i) => (
          <Collapsible
            label={faq.question}
            fontSize="20px"
            color="#000000"
            padding="40px 15px"
            key={i}
          >
            <p>{faq.answer}</p>
          </Collapsible>
        ))}
      </Grid>
      <GradientSeparator margin="30px 0" />
    </>
  )
}

export default WithVisibility(withScrollToElement(Faq, 'faq'))
