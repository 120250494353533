import { axios } from '@/lib/axios'
import storage from '@/utils/storage'
import { useEffect, useRef, useState } from 'react'
import Header from '@/pages/dashboard/header/index'
import { Container } from '@mui/material'

const Backtesting = () => {
  const [isLoading, setIsLoading] = useState(false)
  const cognitoId = storage.getCognitoId()
  const BASE_URL = process.env.REACT_APP_URL_BASE
  const backtestingData = useRef<any>()
  const parsedBody = useRef<any>()

  useEffect(() => {
    setIsLoading(true)
    checkBacktestingAPI().then((data) => {
      console.log(data)
    })
  }, [])

  const checkBacktestingAPI = async () => {
    getBacktestingUrl()?.then(() => {
      if (
        backtestingData.current?.statusCode !== 200 &&
        backtestingData.current?.statusCode !== 405
      ) {
        setTimeout(() => {
          checkBacktestingAPI()
        }, 10000)
      } else if (backtestingData.current?.statusCode === 200) {
        setIsLoading(false)
        parsedBody.current = backtestingData.current?.body.slice(1, -1)
        window.open(parsedBody.current, '_blank')
      } else if (backtestingData.current?.statusCode === 405) {
        setIsLoading(false)
      }
    })
  }

  const renderAPIMessage = () => {
    if (!isLoading && backtestingData.current) {
      if (backtestingData.current?.statusCode === 405) {
        return <h2>User does not have notebook approval</h2>
      }
      if (backtestingData.current?.statusCode === 200) {
        return <h2>Opening Notebook.</h2>
      }
      return <h2>{backtestingData.current?.body.slice(1, -1)}</h2>
    } else {
      return (
        <h2>
          The backtesting environment is loading. Please wait. This could take
          between 3 and 6 minutes.
        </h2>
      )
    }
  }

  const getBacktestingUrl = () => {
    if (BASE_URL == 'https://77adowqe78.execute-api.us-east-1.amazonaws.com') {
      const res = axios
        .post('https://vupitxqlrg.execute-api.us-east-1.amazonaws.com/dev/', {
          kamba_user_id: cognitoId,
        })
        .then(function (response: any) {
          backtestingData.current = response
          return response
        })
        .catch(function (error: any) {
          console.log(error)
        })
      return res
    } else if (
      BASE_URL == 'https://rokdfwze5m.execute-api.us-east-1.amazonaws.com'
    ) {
      const res = axios
        .post(
          'https://3a2mgp1gu9.execute-api.us-east-1.amazonaws.com/staging/',
          { kamba_user_id: cognitoId },
        )
        .then(function (response: any) {
          backtestingData.current = response
          return response
        })
        .catch(function (error: any) {
          console.log(error)
        })
      return res
    } else if (
      BASE_URL == 'https://ndk805qbw3.execute-api.us-east-1.amazonaws.com'
    ) {
      const res = axios
        .post('https://k2wqcfgv20.execute-api.us-east-1.amazonaws.com/prod/', {
          kamba_user_id: cognitoId,
        })
        .then(function (response: any) {
          backtestingData.current = response
          return response
        })
        .catch(function (error: any) {
          console.log(error)
        })
      return res
    }
  }

  return (
    <Container
      maxWidth={'xl'}
      sx={{ padding: '30px' }}
      style={{ position: 'relative' }}
    >
      <div style={{ display: 'flex' }}>
        <Header title={'Backtesting'}></Header>
      </div>
      {renderAPIMessage()}
    </Container>
  )
}

export default Backtesting
