import { Container } from '@mui/material'
import { useAppSelector } from '@/app/hooks'
import Header from '../header'
import { Box } from '@mui/system'
import AllMessagesList from './all-messages-list'
import Filters from './filters'
import useTwilioClient from '@/providers/twilio/hooks/useTwilioClient'

interface IMessagesProps {}

const Messages: React.FC<IMessagesProps> = (props) => {
  useTwilioClient()

  return (
    <>
      <div style={{ background: 'white' }}>
        <Container
          maxWidth={'xl'}
          sx={{
            minHeight: '150px',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Header title={'Messages'}></Header>
          <div style={{ paddingBottom: '1rem' }}>
            <Filters />
          </div>
        </Container>
        <Box
          alignContent={'center'}
          justifyContent="center"
          style={{ backgroundColor: '#F3F3F3' }}
          display="flex"
          paddingTop={'.125rem'}
        >
          <AllMessagesList />
        </Box>
      </div>
    </>
  )
}

export default Messages
