import styled from 'styled-components'
import arrowImage from '@/assets/images/arrow.png'
import { CollapsibleProps } from './types'

export const Wrapper = styled.div`
  width: 100%;
  .ReactCollapse--content {
    padding: 10px 30px 32px;
    border: 0.7px solid #d0d0d0;
    border-top: none;
  }
  .ReactCollapse--collapse {
    transition: height 300ms;
  }
`

export const Header = styled.div<CollapsibleProps>`
  width: 100%;
  height: 54px;
  cursor: pointer;
  font-weight: 600;
  font-size: ${({ fontSize }) => fontSize || '14px'};
  line-height: 14px;
  color: ${({ color }) => color || '#757575'};
  padding: ${({ padding }) => padding || '0 20px'};
  display: flex;
  justify-content: left;
  align-items: center;
  border: 0.7px solid #d0d0d0;
  border-bottom: ${({ isOpened }) =>
    isOpened ? '0.7px solid transparent' : '0.7px solid #d0d0d0'};
`

export const Arrow = styled.img.attrs({
  src: arrowImage,
  alt: 'arrow image',
})<CollapsibleProps>`
  color: transparent;
  margin-right: 10px;
  transform: ${({ isOpened }) =>
    isOpened ? 'rotate(180deg)' : 'rotate(0deg)'};
  transition: 0.3s ease-in;
`
