import styled from 'styled-components'
import close from '@assets/images/close'
import plus from '@/assets/images/plus-circle.png'
import { InputProps } from './types'

export const Input = styled.input`
  width: 100%;
  max-width: 294px;
  height: 54px;
  padding: 14px 18px;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  color: #000000b2;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  &:focus {
    border: 1px solid #000000;
  }
  &:focus-visible {
    border: 1px solid #00000;
    outline: 1px solid #000000;
  }
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  margin-bottom: 12px;
  position: relative;
`

export const CloseButton = styled.img.attrs({
  src: close,
  alt: 'close image',
})`
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 15px;
`

export const PlusIcon = styled.img.attrs({
  src: plus,
  alt: 'Plus image',
})`
  max-width: 24px;
  margin-right: 18px;
`

export const AddButton = styled.div`
  display: inline-flex;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #000000;
  align-items: center;
  cursor: pointer;
  margin-top: 32px;
`

export const InputWrapper = styled.div<InputProps>`
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : '100%')};
`
