import styled from 'styled-components'

export const ContainerVisibility = styled.div`
  overflow: hidden;
  border: 1px solid #d3d3d3;
  position: relative;
  margin: 20px 0;
  width: 100%;
`

export const VisibilityBlur = styled.div`
  filter: blur(11px);
`
