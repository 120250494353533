import { AppBar, ListSubheader } from '@mui/material'
import { Container, Stack } from '@mui/system'
import AppBarHeader from '../appbar-header'
import Sidebar from '../sidebar'
import { MainLayoutProps } from './types'

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  return (
    <Stack direction="row" flex={1} overflow="hidden" height="100vh">
      <Sidebar />
      <Stack direction="column" flex={1}>
        <AppBarHeader />
        <Stack
          direction="column"
          flex={1}
          sx={{ background: '#F3F3F3', overflowY: 'auto', overflowX: 'hidden' }}
        >
          {children}
        </Stack>
      </Stack>
    </Stack>
  )
}

export default MainLayout
