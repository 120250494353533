import styled from 'styled-components'

export const Button = styled.button.attrs(({ type }) => ({
  type: type || 'button',
}))`
  border: 1px solid #000000;
  background-color: #000000;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  padding: 10px 25px;
  cursor: pointer;
  margin: 0;
  width: fit-content;
  /* max-width: 230px; */
  min-height: 45px;
  text-transform: uppercase;
  white-space: pre;
`

export const DialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  height: fit-content;
  padding: 24px;
  position: relative;
  margin: 0 auto;
`
