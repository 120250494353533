import { useQuery } from 'react-query'
import { PostingsOptions } from './types'
import { ExtractFnReturnType, QueryConfig } from '@lib/react-query'
import { getPostings, getPostingsById } from './api/postings'

type QueryFnType = typeof getPostings
type QueryConfigFn = QueryConfig<QueryFnType>

export const usePosting = ({
  config,
  select,
  ...params
}: PostingsOptions<QueryConfigFn>) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['postings', { ...params }],
    queryFn: () => getPostings({ ...params }),
    select,
    ...config,
  })
}

export const usePostingById = ({
  config,
  select,
  userId,
  ...params
}: PostingsOptions<QueryConfigFn>) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['postingsById', { ...params }],
    queryFn: () => getPostingsById({ userId, ...params }),
    select,
    ...config,
  })
}
