import { Listing } from '@/services/listing/types'
import React, { createContext, useState, useEffect } from 'react'
import { DashboardContextProps, DashboardProviderProps } from './types'

const DashboardContext = createContext<DashboardContextProps | null>(null)

const DashboardProvider = ({ children }: DashboardProviderProps) => {
  const [params, setParams] = useState<any>({})

  return (
    <DashboardContext.Provider value={{ params, setParams }}>
      {children}
    </DashboardContext.Provider>
  )
}

export { DashboardContext, DashboardProvider }
