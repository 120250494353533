import styled from 'styled-components'
import TableCell from '@mui/material/TableCell'

interface CellProps {
  maxCharactersWidth?: number
}

export const StyledTableCell = styled(TableCell)<CellProps>`
  max-width: ${({ maxCharactersWidth }) =>
    !!maxCharactersWidth ? `${maxCharactersWidth}ch` : 'none'};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
export const StyledAnchor = styled.a`
  color: inherit;
  &:hover {
    opacity: 0.7;
  }
`

export const HeaderCell = styled.span<{ active: boolean }>`
  color: ${({ active }) => (active ? '#000000' : '#858585')};
  font-weight: bold;
`
