import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import ClipLoader from 'react-spinners/ClipLoader'
import {
  Container,
  LoginWrapper,
  Logo,
  LoginCard,
  InputBlock,
} from '../../components'
import { Divider, Title, FormGroup, Button, TextWrapper, Error } from './styled'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { useMutation } from 'react-query'
import { useResendCode } from '@/services/resend-code/resend-code.service'

type UserData = {
  username: string
}

const schema = yup
  .object({
    username: yup.string().required('Email is required'),
  })
  .required()

const ResendVerificationInstructions = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<UserData>({
    resolver: yupResolver(schema),
  })

  const workEmailRegister = { ...register('username') }

  const {
    mutate: resendCode,
    isError,
    isLoading,
  } = useResendCode(() => {
    navigate('/email-verification-code')
  })
  const navigate = useNavigate()
  const onSubmit = handleSubmit(async (userdata: UserData) => {
    resendCode(userdata)
  })

  return (
    <Container>
      <LoginWrapper>
        <Logo />
        <LoginCard maxWidth="584px">
          <Divider />
          <Title>Resend Verification Instructions</Title>
          <TextWrapper>
            We require you to verify your email address. For this purpose we
            have sent you a verification mail. If you do not find a mail in your
            inbox within the next five minutes, try to have it resent.
          </TextWrapper>
          <FormGroup onSubmit={onSubmit}>
            <InputBlock
              label="Work Email"
              isMail
              type="email"
              params={workEmailRegister}
              errorMessage={errors.username?.message}
            />
            <Button type="submit">
              {isLoading ? (
                <ClipLoader color="#ffffff" loading={isLoading} size={15} />
              ) : (
                `Resend verification instructions`
              )}
            </Button>
            {isError && <Error>An error ocurred. Please try again.</Error>}
          </FormGroup>
        </LoginCard>
      </LoginWrapper>
    </Container>
  )
}

export default ResendVerificationInstructions
