import styled from 'styled-components'
export interface ContainerProps {
  flexDirection?: string
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  width: 100%;
  overflow: hidden;
`
export const SideContainer = styled.div`
  background-color: #e5e5e5;
  height: 100%;
  width: 100%;
  padding: 50px 0 84px 0;
  &.tasks {
    width: 75%;
  }
  &.resources {
    width: 27%;
  }
`
export const YourTasksTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 24px;
`

export const ContentContainer = styled.div`
  display: flex;
  gap: 2%;
  background-color: #e5e5e5;
`

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 70vh;
`
