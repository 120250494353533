import styled from 'styled-components'
import { IIconProps } from './types'

export const IconStyled = styled.img.attrs<IIconProps>(({ src, ...props }) => ({
  src: src,
  alt: 'icon',
  ...props,
}))<IIconProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ position }) => position && `position: ${position};`}
  ${({ height }) => height && `height: ${height};`}
  ${({ width }) => width && `width: ${width};`}
  ${({ top }) => top && `top: ${top};`}
  ${({ left }) => left && `left: ${left};`}
`
