import styled from 'styled-components'
import check from '@assets/images/checkbox'
import { RadioButtonProps } from './types'

export const RadioWrapper = styled.div`
  align-items: center;
  margin-bottom: 36px;
`

export const Radio = styled.div`
  position: relative;
  display: flex;
`

export const RadioRow = styled.div<RadioButtonProps>`
  width: 100%;
  padding: 18px 24px;
  display: flex;
  align-items: center;
  border: ${({ isChecked }) =>
    isChecked ? '1px solid #000000' : '1px solid #d9d9d9'};
  margin-bottom: 12px;
  background-color: #ffffff;
`

export const RadioButton = styled.input`
  height: 22px;
  width: 22px;
  margin: 0;
  appearance: none;
  border: 1.6px solid #8c8c8c;
  cursor: pointer;
  &:checked {
    appearance: none;
    outline: none;
    padding: 0;
    content: none;
    border: none;
  }
  &:checked:after {
    width: 22px;
    height: 22px;
    border-radius: 0px;
    position: relative;
    background-image: url(${check});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 30px 30px;
    background-color: #ffffff;
    content: '';
    display: inline-block;
    visibility: visible;
  }
`

export const RadioLabel = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #303030;
  /* margin: 0 0 0 18px; */
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 18px;
  & span {
    font-size: 14px;
    white-space: nowrap;
    margin-top: 4px;
  }
`
