import PropTypes from 'prop-types'
import { default as ReactSelect } from 'react-select'
import { ISelectProps } from './types'

const CustomMultiSelect = (props: ISelectProps) => {
  const {
    options,
    value,
    onChange,
    allowSelectAll,
    allOption = { label: 'Select all', value: '*' },
  } = props
  if (allowSelectAll) {
    return (
      <ReactSelect
        {...props}
        options={[allOption, ...options]}
        onChange={(selected: any) => {
          if (
            selected !== null &&
            selected.length > 0 &&
            selected[selected.length - 1].value === allOption.value
          ) {
            return onChange(options)
          }
          return onChange(selected)
        }}
      />
    )
  }

  return <ReactSelect {...props} />
}

export default CustomMultiSelect
