import { Title } from '@/components'
import withScrollToElement from '@/hocs/withScrollToElement'
import KeyDifferentiors from '@/pages/product/detail/pricing-delivery/key-differentiatiors'
import { Grid } from '@mui/material'
import * as React from 'react'
import DataOffering from '../data-offering'
import { IAboutProps } from './types'

const About: React.FC<IAboutProps> = ({ company }) => {
  return (
    <>
      {company.keyDifferentiators && (
        <KeyDifferentiors text={company.keyDifferentiators} />
      )}
      {company.dataOfferingDescription && (
        <DataOffering description={company.dataOfferingDescription} />
      )}
    </>
  )
}

export default withScrollToElement(About, 'about')
