import { ButtonWrapper, IconContainer } from './styled'
import { IButtonProps } from './types'
import filePlus from '../../../../assets/images/icons/file-plus'
import Icon from '@/components/ui/icon'

const RequestButton = ({ onClick, ...props }: IButtonProps) => {
  return (
    <ButtonWrapper variant="primary" onClick={onClick} {...props}>
      {/*<IconContainer>
        <Icon src={filePlus} />
  </IconContainer>*/}
      <span>Post a Request in 3 minutes</span>
    </ButtonWrapper>
  )
}

export default RequestButton
