import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query'
import { useQuery } from 'react-query'
import { getAllProposals, getProposalByPostingId } from './api'
import { ProposalByPostingProps, ProposalsOptions } from './types'

type QueryFnType = typeof getAllProposals
type QueryGetProposalsFnType = typeof getProposalByPostingId
type QueryConfigFn = QueryConfig<QueryFnType>

export const useProposalsById = ({
  config,
  select,
  userId,
  ...params
}: ProposalsOptions<QueryConfigFn>) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['proposals', { ...params }],
    queryFn: () => getAllProposals({ userId, ...params }),
    select,
    enabled: false,
    refetchOnWindowFocus: false,
    ...config,
  })
}

export const useProposalsByPostingId = ({
  postingId,
  select,
}: ProposalByPostingProps) => {
  return useQuery<ExtractFnReturnType<QueryGetProposalsFnType>>({
    queryKey: ['proposals', postingId],
    queryFn: () => getProposalByPostingId({ postingId }),
    select,
    refetchOnWindowFocus: false,
  })
}
