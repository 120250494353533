import { Button } from '@/components'
import Filter from '@/pages/dashboard/messages/filters/filter'
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import React, { useMemo, useState } from 'react'
import { useAppSelector } from '@/app/hooks'
import { useGetCompanyListing } from '@/services/listing/listing.service'
import { ClipLoader } from 'react-spinners'
import { Options } from 'react-select'
import { Option } from '../filters/types'
import { Listing } from '@/services/listing/types'

interface ListingDialogProps {
  anonymous: boolean
  onClose: () => void
  onSave: (listing: Listing) => void
}

type FilterOptions = Options<{ label: string; value: string }>

const ListingDialog: React.FC<ListingDialogProps> = ({
  onClose,
  onSave,
  anonymous,
}) => {
  const sellerCompanyId =
    useAppSelector((state) => state.authUser.companyId) || ''
  const { isLoading, data } = useGetCompanyListing({ sellerCompanyId })
  const [saving, setSaving] = useState(false)
  const [product, setProduct] = useState<Listing | undefined>()
  const productListOptions: FilterOptions = useMemo(() => {
    if (data?.data) {
      return data.data
        .filter(
          (l) =>
            (anonymous && l.visibility === 'public') ||
            (!anonymous && l.visibility !== 'private'),
        )
        .map((l) => ({
          label: `(${l.visibility || 'public'}) - ${l.title || ''}`,
          value: l.id || '',
        }))
    }
    return []
  }, [data?.data])

  const onSelect = (opt: Option) => {
    const listing = data?.data?.find((l) => l.id === opt.value)
    if (listing) {
      setProduct(listing)
    }
  }

  const save = () => {
    if (product) {
      onSave(product)
    }
  }

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="product-dialog">
      <DialogTitle id="product-dialog">Product List</DialogTitle>
      <DialogContent sx={{ minWidth: '400px', minHeight: '200px' }}>
        {isLoading && <ClipLoader />}
        <Box>
          <Filter
            data={productListOptions}
            onChange={(a) => onSelect(a as Option)}
            defaultValue={productListOptions.find(
              (a) => a.value === product?.id,
            )}
            isClearable={false}
            placeholder="Select a product"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="secondary"
          onClick={onClose}
          style={{ width: '150px' }}
        >
          Cancel
        </Button>
        <Button
          style={{ maxWidth: '150px' }}
          variant="primary"
          onClick={save}
          disabled={!product}
        >
          {saving && (
            <CircularProgress
              color="inherit"
              size={20}
              style={{ marginBottom: '-5px', marginRight: '25px' }}
            />
          )}
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ListingDialog
