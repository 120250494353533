import styled from 'styled-components'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

export const SubscriptionPageWrapper = styled.div`
  padding: 2rem 4rem;
  background-color: #e5e5e5;
  height: 100vh;
`

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  gap: 1rem;
  max-width: 900px;
  h1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 900px;
  }
`

export const BackIcon = styled(ArrowBackIcon)`
  cursor: pointer;
`

export const BodyWrapper = styled.div`
  display: flex;
  height: 76vh;
`
export const SideBarContainer = styled.div`
  border: 1px solid #f0f0f0;
  background-color: white;
  width: 25%;
  margin-top: 1rem;
  padding: 1.5rem;
  height: 100%;
`
export const SideBarInner = styled.div`
  background-color: #fafafa;
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
  gap: 1rem;
  padding: 1.5rem;
  font-size: 1.1rem;
  overflow-y: scroll;
`
export const DetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`
export const DetailTitle = styled.span`
  color: #b0b0b0;
`
export const Detail = styled.span`
  font-weight: 800;
`

export const TabsContainer = styled.div`
  border: 1px solid #f0f0f0;
  margin-top: 1rem;
  width: 75%;
  background-color: white;
  overflow: hidden;
  height: 100%;
  .tabWrapper {
    width: 100%;
    height: 100%;
  }
  .fullWidthBackground {
    width: 100%;
    height: 100%;
  }
  .MuiTabs-root {
    margin-top: 0;
    padding: 0 2rem;
  }
`
