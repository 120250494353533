import { useContext } from 'react'
import { DashboardContext } from '..'

export const useDashboardContext = () => {
  const context = useContext(DashboardContext)

  if (context === undefined) {
    throw new Error('useDashboardContext was used outside of its Provider')
  }

  return { ...context }
}
