import { createSlice } from '@reduxjs/toolkit'
import storage from '../utils/storage'

export interface AuthUserInterface {
  userId: string | null
  userRole: string | null
  userMail: string | null
  companyId: string | null
  cognitoId: string | null
  dashboard: any
  stripeLoading: boolean
  isAdmin?: boolean
}

const initialState: AuthUserInterface = {
  userId: storage.getUserId() || null,
  userRole: storage.getUserRole() || null,
  companyId: storage.getCompanyId() || null,
  dashboard: storage.getDashboard() || null,
  stripeLoading: storage.getStripeLoading() || false,
  userMail: storage.getUserMail() || null,
  cognitoId: storage.getCognitoId() || null,
  isAdmin: storage.getUserAdmin() || undefined,
}

const authUserSlice = createSlice({
  name: 'authUser',
  initialState,
  reducers: {
    setUserId: (state, { payload }) => {
      state.userId = payload
      ;('')
    },
    setUserRole: (state, { payload }) => {
      state.userRole = payload
    },
    setCompanyId: (state, { payload }) => {
      state.companyId = payload
    },
    setDashboard: (state, { payload }) => {
      state.dashboard = payload
    },
    setStripeLoading: (state, { payload }) => {
      state.stripeLoading = payload
    },
    setUserMail: (state, { payload }) => {
      state.userMail = payload
    },
    setUserAdmin: (state, { payload }) => {
      state.isAdmin = payload
    },
    setCognitoId: (state, { payload }) => {
      state.cognitoId = payload
    },
  },
})

const { reducer, actions } = authUserSlice

export const {
  setUserId,
  setUserRole,
  setDashboard,
  setCompanyId,
  setStripeLoading,
  setUserMail,
  setUserAdmin,
  setCognitoId,
} = actions

export default reducer
