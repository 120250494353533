import styled from 'styled-components'

export const EmptyStateWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #d3d3d3;
  background-color: #ffffff;
  margin: 10% auto;
  padding: 20px 60px;
  max-width: 400px;
  gap: 20px;
`

export const Title = styled.div`
  font-family: 'Proxima Nova';
  font-weight: 700;
  font-size: 23px;
  text-align: center;
  color: #000000;
`

export const Info = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #878787;
  flex-direction: column;

  & > a {
    text-decoration: none;
    color: blue;
    font-weight: bold;
  }
`
