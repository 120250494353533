import { GradientSeparator } from '@/components'
import { CardTitle } from '@/components/ui/company-card/styled'
import WithScrollToElement from '@/hocs/withScrollToElement'
import { HeaderMock } from '@/mocks/storefront/headerMock'
import { createMarkup } from '@/utils/purify-html'
import { Grid } from '@mui/material'
import * as React from 'react'
import { string } from 'yup/lib/locale'
import Header from './header'
import { Banner, CompanyDescription, CompanyDescriptionTitle } from './styled'
import DOMPurify from 'dompurify'

interface ISummaryProps {
  company: any
  companyNative: any
}

const Summary: React.FunctionComponent<ISummaryProps> = ({
  company,
  companyNative,
}) => {
  const { companyBanner } = HeaderMock

  const capitalize = (word: string) => {
    return word[0].toUpperCase() + word.slice(1).toLowerCase()
  }
  //console.log(company)

  const createMarkup = (html: any) => {
    return {
      __html: DOMPurify.sanitize(html),
    }
  }

  return (
    <>
      <Grid item xs={12} sx={{ marginBottom: '20px' }}>
        <Banner src={company.banner ?? companyBanner} />
      </Grid>
      <Header company={company} companyNative={companyNative} />
      {/* <Grid item container xs={12} sx={{ margin: '35px 0' }}> */}
      {/* <Grid item xs={12} sx={{ marginBottom: '15px' }}>
          <CompanyDescriptionTitle>
            {capitalize(company.name)}
            {company.about ? <span> in a nutshell</span> : null}
          </CompanyDescriptionTitle>
        </Grid> */}
      {!!company.slogan && <CardTitle>{company.slogan}</CardTitle>}
      {!!company.description && (
        <>
          <Grid item xs={12}>
            <CompanyDescription
              dangerouslySetInnerHTML={createMarkup(company.description)}
            />
          </Grid>
          <Grid item xs={12} sx={{ margin: '30px 0' }}>
            <GradientSeparator />
          </Grid>
        </>
      )}
      {/* </Grid> */}
    </>
  )
}

export default WithScrollToElement(Summary, 'summary')
