import { Button, Icon, GradientSeparator } from '@/components'
import { pricingPropsMock } from '@/mocks/storefront/pricing'
import Delivery from '@/pages/product/detail/pricing-delivery/delivery'
import Pricing from '@/pages/product/detail/pricing-delivery/pricing'
import RightArrowIcon from '@assets/images/icons/RightArrowIcon'
import { Grid } from '@mui/material'
import * as React from 'react'

interface IPricingDeliveryProps {
  company: any
}

const PricingDelivery: React.FunctionComponent<IPricingDeliveryProps> = ({
  company,
}) => {
  const { pricings, title, titleDescription } = pricingPropsMock
  const capitalize = (word: string) => {
    return word[0].toUpperCase() + word.slice(1).toLowerCase()
  }
  const methods = [
    {
      text: 'METHODS',
      fontSize: '24px',
      color: '#858585',
      filters: ['SFTP'],
    },
    {
      text: 'FREQUENCY',
      fontSize: '24px',
      color: '#858585',
      filters: ['Monthly', 'Quarterly', 'Yearly'],
    },
    {
      text: 'FORMAT',
      fontSize: '24px',
      color: '#858585',
      filters: ['.json', '.csv', '.xls', '.sql'],
    },
  ]
  const renderPricing = () => (
    <Grid item xs={12}>
      <Pricing
        pricings={pricings}
        title={capitalize(company.name)}
        boldTitle="Pricing"
        titleDescription={company.pricingDescription}
      />
      {/* <Grid item xs={6} lg={5} sx={{ marginTop: '20px' }}>
        <Button
          variant="primary"
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          RECEIVE DETAILED PRICING
          <Icon src={RightArrowIcon}></Icon>
        </Button>
      </Grid> */}
    </Grid>
  )

  const renderDelivery = () => (
    <Grid item xs={12}>
      <Delivery methods={methods} />
    </Grid>
  )

  return (
    <>
      {!!company.pricingDescription && (
        <Grid container>
          {renderPricing()}
          {/* {renderDelivery()} */}
        </Grid>
      )}
    </>
  )
}

export default PricingDelivery
