import styled from 'styled-components'

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`

export const RightSide = styled.div`
  padding: 25px;
  background-color: #fafafa;
`

export const RightSideRow = styled.div`
  display: flex;
  margin-bottom: 12px;
`

export const RightSideColumn = styled.div`
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
`

export const RightSideItemLabel = styled.span`
  display: block;
  margin-top: 4px;
  font-size: 18px;
  font-weight: 500;
  color: #b0b0b0;

  &:first-child {
    margin-top: 0;
  }
`

export const RightSideItemValue = styled.span`
  display: block;
  margin-top: 4px;
  font-size: 18px;
  font-weight: 600;
  color: #000000;

  &:first-child {
    margin-top: 0;
  }
`

export const Actions = styled.div`
  margin-top: 12px;

  button {
    width: fit-content;
  }
`

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  text-align: center;

  p {
    font-size: 18px;
  }
`

export const EmptyStateWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #d3d3d3;
  background-color: #ffffff;
  margin: 10% auto;
  padding: 20px 60px;
  max-width: 400px;
  gap: 20px;
`

export const Title = styled.div`
  font-family: 'Proxima Nova';
  font-weight: 700;
  font-size: 23px;
  text-align: center;
  color: #000000;
`

export const Info = styled.div`
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #878787;
`
