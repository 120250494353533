import { AppBarHeader } from '@components'
import {
  Container,
  ContractContainer,
  ArrowButton,
  ArrowIcon,
  Button,
} from './styled'
import { Container as Wrapper } from '@mui/material'
import { useNavigate, useParams } from 'react-router'
import storage from '@/utils/storage'
import {
  useAcceptTermsAndConditions,
  useGetCompany,
} from '@/services/suppliers/suppliers.service'
import { ClipLoader } from 'react-spinners'
import { isQueryKey } from 'react-query/types/core/utils'
import { queryClient } from '@/lib/react-query'

interface ITAndCprops {
  content?: string
  isSupplier?: boolean
  isChecked?: boolean
  setIsChecked?: any
}

const TermsAndConditions: React.FC<ITAndCprops> = () => {
  const { title } = useParams()
  const navigate = useNavigate()
  const userId = storage.getUserId()
  const companyId = storage.getCompanyId()
  const isLoggedIn = userId ? true : false

  const { refetch } = useGetCompany(companyId, false)
  const { mutate: acceptTerms, isLoading: loadingAcceptance } =
    useAcceptTermsAndConditions(() => {
      queryClient.invalidateQueries({ queryKey: ['dashboard'] })
      navigate(-1)
    })

  const handleClick = async () => {
    if (isLoggedIn) {
      const { data: companyData } = await refetch()
      acceptTerms(companyData?.data.profile!)
    } else {
      navigate(-1)
    }
  }

  return (
    <Container>
      <Container flexDirection="column">
        <AppBarHeader />
        <Wrapper maxWidth={'xl'} sx={{ margin: '45px' }}>
          <ContractContainer>
            <ArrowButton onClick={handleClick}>
              <ArrowIcon />
            </ArrowButton>
            <p className="c2 c13">
              <span className="c10">KAMBA</span>
              <span className="c15 c18">&nbsp;TERMS AND CONDITIONS </span>
              {title === 'all' && <span>& PRIVACY POLICY</span>}
            </p>
            <p className="c0 c2">
              \<span className="c6 c3">LAST UPDATED ON: NOVEMBER 1, 2022</span>
            </p>
            <p className="c0 c2">
              <span className="c3 c6">
                ACCEPTANCE OF TERMS &amp; CONDITIONS
              </span>
            </p>
            <p className="c0 c2">
              <span className="c7 c3">
                Kamba Group LLC ( &ldquo;Kamba&rdquo; or &ldquo;we&rdquo; and
                sometimes &ldquo;us&rdquo;) provide the website{' '}
              </span>
              <span className="c3 c21">
                <a
                  className="c23"
                  href="https://www.google.com/url?q=http://www.kambagroup.com&amp;sa=D&amp;source=editors&amp;ust=1668807949313096&amp;usg=AOvVaw11lRRzQ3Ltd8JrxlFOI6sW"
                >
                  www.kambagroup.com
                </a>
              </span>
              <span className="c1">
                &nbsp;(the &ldquo;Website&rdquo;) and the Kamba software and
                services (collectively referred to herein as the/our
                &quot;Software&quot; or the/our &quot;Services&quot;), subject
                to your agreement to and compliance with these Terms and
                Conditions &nbsp;(the &ldquo;Terms&rdquo;). &nbsp;
              </span>
            </p>
            <p className="c0 c2">
              <span className="c1">
                If you are using the Software and Services on behalf of any
                entity, you represent and warrant that you are authorized to
                accept these Terms on such entity&rsquo;s behalf and that such
                entity agrees to indemnify you and Kamba for violations of these
                Terms. Anyone that accesses and/or uses the Website and/or
                Services is considered a &ldquo;User&rdquo; under these Terms.
                Nothing in these Terms will be deemed to confer any third-party
                rights or benefits. PLEASE READ THESE TERMS CAREFULLY BEFORE
                ACCESSING OR USING THE WEBSITE OR ANY CONTENT ON THE SITE. BY
                ACCESSING THE WEBSITE OR ANY CONTENT ON THE WEBSITE, YOU AGREE
                TO BECOME BOUND BY THESE TERMS. IF YOU DO NOT AGREE TO ALL THE
                TERMS, THEN YOU MAY NOT ACCESS THE WEBSITE OR USE THE CONTENT OR
                ANY SOFTWARE OR SERVICES ON THE WEBSITE. OUR ACCEPTANCE IS
                EXPRESSLY CONDITIONED UPON YOUR ASSENT TO ALL OF THESE TERMS; IF
                THESE TERMS ARE CONSIDERED AN OFFER BY US, ACCEPTANCE IS
                EXPRESSLY LIMITED TO THESE TERMS.
              </span>
            </p>
            <p className="c0 c2">
              <span className="c6 c3">
                THIS MIGHT NOT BE THE ONLY AGREEMENT YOU HAVE WITH US
              </span>
            </p>
            <p className="c0 c2">
              <span className="c1">
                These Terms do not alter the terms or conditions of any other
                agreement you may have with us for specific Software or
                Services. It is likely that you have entered into a separate
                licensing, pilot or services agreement with us. We refer to each
                of these as a &ldquo;Separate Agreement.&rdquo; The Separate
                Agreement shall take precedence if there is a conflict between
                those terms and these Terms, to the extent of such conflict and
                with respect to the particular subject matter of that Separate
                Agreement.
              </span>
            </p>
            <p className="c0 c2">
              <span className="c6 c3">MODIFICATIONS OF TERMS OF USE</span>
            </p>
            <p className="c0 c2">
              <span className="c1">
                We reserve the right, at our sole discretion, to modify or
                replace the Terms at any time. If the alterations constitute a
                material change to the Terms, we will notify you by posting an
                announcement on the Website. What constitutes a &ldquo;material
                change&rdquo; will be determined at our sole discretion, in good
                faith and using common sense and reasonable judgment. You are
                responsible for reviewing and becoming familiar with any such
                modifications. Use of our Website by you following such
                notification constitutes your acceptance of the Terms as
                modified.
              </span>
            </p>
            <p className="c0 c2">
              <span className="c15 c3">PRIVACY </span>
            </p>
            <p className="c0 c2">
              <span className="c7 c2 c3">
                By using our Website you acknowledge and accept
              </span>
              <span className="c2 c3">&nbsp;our Privacy Policy and co</span>
              <span className="c1 c2">
                nsent to the collection and use of your data in accordance
                therewith.
              </span>
            </p>
            <p className="c0 c2">
              <span className="c6 c2 c3">ELECTRONIC COMMUNICATIONS</span>
            </p>
            <p className="c0 c2">
              <span className="c1 c2">
                We use industry standard security measures and take reasonable
                system, process and administrative precautions to protect the
                security and integrity of email and other electronic
                communications that you may send to us. Despite all these
                precautions, no method of transmission over the Internet is
                entirely secure and we cannot guarantee the confidentiality or
                security of the electronic communications or its contents, even
                if there is a special note that a particular transmission is
                encrypted. You transmit such information at your own risk and
                you should decide very carefully which information you want to
                send us via any electronic communication.
              </span>
            </p>
            <p className="c0">
              <span className="c6 c2 c18">THE SERVICES </span>
            </p>
            <p className="c0">
              <span className="c1 c2">
                Our Service is intended to help any User or legal entity and its
                employees that source(s) or intends to source external data
                (&ldquo;Data Buyers&rdquo;) and any User or legal entity and its
                employees that provides or intends to provide data (&ldquo;Data
                Vendors&rdquo;) to share information, connect with, initiate and
                build business relationships with each other. As a platform,
                Kamba is a third-party to Users doing business with each other
                and not involved in any legal relationship between Users
                potentially resulting from using the Website and/or Services.
              </span>
            </p>
            <p className="c0">
              <span className="c15 c2 c3">CATEGORY LISTINGS</span>
            </p>
            <p className="c0">
              <span className="c1 c2">
                In order to help Data Buyers finding Data Vendors more easily,
                Kamba curates data categories (&ldquo;Category&rdquo;) and lists
                Data Vendors within a Category at Kamba&rsquo;s sole discretion.
                Users may propose the listing of a new Category and/or the
                listing of a Data Vendor within a specific Category, which Kamba
                may or may not take under consideration. We don&rsquo;t
                guarantee the accuracy of any Category or categorization of Data
                Vendors.
              </span>
            </p>
            <p className="c0">
              <span className="c6 c2 c3">VENDOR LISTINGS</span>
            </p>
            <p className="c0">
              <span className="c1 c2">
                In order to help Data Buyers discover, learn about, and connect
                with Data Vendors, we&rsquo;re offering a Service to list and
                find Data Vendors (&ldquo;Vendor Listing&rdquo;). You can
                explore, search for, and visit Vendor Listings in order to
                inform yourself and contact Data Vendors. In case you submit a
                contact request, you agree that we may share your contact
                information with employees from the listed company and contact
                you as well regarding your request. We do not guarantee that
                your contact request reaches the listed Data Vendor or that you
                will receive an answer.
              </span>
            </p>
            <p className="c0">
              <span className="c1 c2">
                Kamba automatically creates, curates, and publishes listings of
                Data Vendors based on publicly accessible information. We
                neither guarantee the accuracy or veracity of Vendor Listings,
                nor do we endorse any Vendor Listing on our Service.
              </span>
            </p>
            <p className="c0">
              <span className="c1 c2">
                Upon claiming or creating a Vendor Listing, Data Vendors bear
                the full legal responsibility for ensuring that the content
                defined by them contains authentic and accurate information and
                complies with any applicable statutory provisions. Each Data
                Vendor assures to the best of its knowledge and belief that its
                data offering protects and does not violate intellectual
                property and personal rights, as well as all rights of third
                parties. Furthermore, each Data Vendor ensures that it is the
                owner or is in legal possession of all necessary rights of all
                data that it offers. Each Data Vendor is further obliged to not
                offer data containing malicious programs (viruses, malware,
                etc.) or any other illegal content.
              </span>
            </p>
            <p className="c0">
              <span className="c1 c2">
                Users cannot delete Vendor Listings. Kamba may remove Vendor
                Listings that do not comply with quality standards, contain
                illegal content, abuse functionalities of the Service, or
                verifiably don&rsquo;t offer any data, or for any other reason
                that Kamba deems appropriate.
              </span>
            </p>
            <p className="c0">
              <span className="c6 c2 c3">PLATFORM LISTINGS</span>
            </p>
            <p className="c0">
              <span className="c1 c2">
                Kamba automatically creates, curates, and publishes listings of
                platforms, software, and services (&ldquo;Platform
                Listing&rdquo;) that integrate the data of Data Vendors based on
                publicly accessible information. We neither guarantee the
                accuracy or veracity of Platform Listings, nor do we endorse any
                Platform Listing on our Service.
              </span>
            </p>
            <p className="c0">
              <span className="c6 c2 c3">DATA REQUESTS &amp; POSTINGS</span>
            </p>
            <p className="c0">
              <span className="c7 c2 c3">
                Our Service offers Users a way to create Data Requests in order
                to get in touch with, get contacted by, and receive information
                from Data Vendors with a matching data offering. We review and
                publish Data Requests at our sole discretion. Data Vendors may
                also be invited directly by the User to submit information for a
                Data Request. Both the User creating the Data Request as well as
                the Data Vendors accessing the Data Request agree to keep all
                information exchanged confidential.
              </span>
            </p>
            <p className="c0">
              <span className="c6 c2 c18">USER ACCOUNTS</span>
            </p>
            <p className="c0">
              <span className="c15 c2 c3">REGISTRATION OF USER ACCOUNTS</span>
            </p>
            <p className="c0">
              <span className="c1 c2">
                In order to use our Website, you must have a valid account. To
                acquire an account for the Website, you must provide us with an
                electronic mail address and other information. You are
                responsible for maintaining the confidentiality of the access
                data for your account and are fully responsible for all
                activities that occur under your account. Any user that creates
                an account on our Service is defined herein as a
                &ldquo;Registered User&rdquo;. You agree to (a) immediately
                notify us of any unauthorized use of your account or any other
                breach of security, and (b) ensure that you exit from your
                account at the end of each session. We cannot and will not be
                liable for any loss or damage arising from your failure to
                comply with this section. In consideration of use of the
                Website, you agree to: (a) provide true, accurate, current, and
                complete information about yourself as prompted by our platform,
                and (b) maintain and promptly update your information to keep it
                true, accurate, current, and complete.
              </span>
            </p>
            <p className="c0">
              <span className="c1 c2">
                If you provide any information that is untrue, inaccurate, not
                current, or incomplete, or if we have reasonable grounds to
                suspect that such information is untrue, inaccurate, not
                current, or incomplete, we have the right to suspend or
                terminate your account and refuse any and all current or future
                use of the Website and platform (or any portion thereof). We
                may, at our sole discretion, refuse to offer the Website to any
                person or entity and change its eligibility criteria at any
                time.
              </span>
            </p>
            <p className="c0">
              <span className="c1 c2">
                You are solely responsible for ensuring that these Terms are in
                compliance with all laws, rules and regulations applicable to
                you and the right to access the Website is revoked where these
                Terms are prohibited or to the extent offering, sale or
                provision of the Software or Services conflicts with any
                applicable law, rule or regulation.
              </span>
            </p>
            <p className="c0">
              <span className="c6 c2 c18">DATA PROTECTION</span>
            </p>
            <p className="c0">
              <span className="c1 c2">
                By submitting contact information such as but not limited to
                your name, email address, or phone number, you agree to be
                contacted by Kamba via email or phone.
              </span>
            </p>
            <p className="c0">
              <span className="c1 c2">
                If you create a Vendor Listing, you agree to be contacted by
                Users that express interest in your company or data offering. In
                case you submit a contact request on a Vendor Listing, you agree
                that your provided contact information and message will be
                shared with the company associated with the Vendor Listing and
                that Kamba or the Data Vendor may contact you regarding your
                contact request.
              </span>
            </p>
            <p className="c0">
              <span className="c1 c2">
                In order to run and improve our Service, as well as to provide
                anonymized insights to Data Vendors, we record the usage of the
                Service such as the web pages visited by Users or keywords
                searched for. We do not share personal data of Users with other
                Users otherwise explicitly requested to do so.
              </span>
            </p>
            <p className="c0">
              <span className="c1 c2">
                Kamba hereby refers to the separately regulated Privacy Policy,
                accessible at: https://www.kambagroup.com/.
              </span>
            </p>
            <p className="c0">
              <span className="c6 c2 c18">USER CONDUCT</span>
            </p>
            <p className="c0">
              <span className="c1 c2">
                You will not violate any law, contract, intellectual property or
                other third-party right or commit a tort, and you are solely
                responsible for your conduct while on the Website. You will not
                do, and will not allow or authorize any third party to do, any
                of the following:
              </span>
            </p>
            <ul className="c17 lst-kix_list_2-0 start">
              <li className="c8 c14 li-bullet-0">
                <span className="c1 c2">
                  Use the Website in any manner that could interfere with,
                  disrupt, negatively affect or inhibit other users from fully
                  enjoying the Website, or that could damage, disable,
                  overburden or impair the functioning of the Website in any
                  manner;
                </span>
              </li>
            </ul>
            <ul className="c17 lst-kix_list_1-0 start">
              <li className="c11 c14 li-bullet-0">
                <span className="c1 c2">
                  Harvest or collect information about other users, including
                  their email addresses, without their consent;
                </span>
              </li>
              <li className="c11 c14 li-bullet-0">
                <span className="c1 c2">
                  Use any robot, spider, crawler, scraper or other automated
                  means or interface not provided by us to access the Website or
                  to extract data;
                </span>
              </li>
              <li className="c11 c14 li-bullet-0">
                <span className="c1 c2">
                  Reverse engineer any aspect of the Website or do anything that
                  might discover source code or bypass or circumvent measures
                  employed to prevent or limit access to any area, content or
                  code of the Services (except as otherwise expressly permitted
                  by law);
                </span>
              </li>
              <li className="c11 c14 li-bullet-0">
                <span className="c1 c2">
                  Use or attempt to use any account you are not authorized to
                  use;
                </span>
              </li>
              <li className="c11 c14 li-bullet-0">
                <span className="c1 c2">
                  Attempt to indicate in any manner that you have a relationship
                  with us or that we have endorsed you or any products or
                  services for any purpose without our prior written consent;
                </span>
              </li>
              <li className="c11 c14 li-bullet-0">
                <span className="c1 c2">
                  Develop any third-party applications that interact with the
                  Website without our prior written consent;
                </span>
              </li>
              <li className="c11 c14 li-bullet-0">
                <span className="c1 c2">
                  Use the Website for any illegal or unauthorized purpose or
                  engage in, encourage or promote any activity that violates
                  these Terms;
                </span>
                <span className="c1 c16">&nbsp;</span>
              </li>
              <li className="c2 c4 li-bullet-0">
                <span className="c1">
                  Probe, scan or test the vulnerability of the Website or any
                  network connected to the Website, nor breach the security or
                  authentication measures on the Website or any network
                  connected to the Website.
                </span>
              </li>
            </ul>
            <p className="c0 c2">
              <span className="c1">
                Any prohibited use of our Website and/or Services may result in
                immediate termination of your account.
              </span>
            </p>
            <p className="c0 c2">
              <span className="c6 c3">YOUR OBLIGATIONS TO US</span>
            </p>
            <ul className="c17 lst-kix_list_3-0 start">
              <li className="c8 c14 c2 li-bullet-0">
                <span className="c1">
                  You will upon request, provide all reasonable assistance to
                  allow us to monitor and audit your compliance with these
                  Terms, and to otherwise enable us to comply with our
                  obligations to third party data providers.
                </span>
              </li>
            </ul>
            <ul className="c17 lst-kix_list_4-0 start">
              <li className="c11 c14 c2 li-bullet-0">
                <span className="c1">
                  You will comply with all applicable laws and regulations,
                  including but not limited to privacy and data protection laws
                  when you use the platform services. &nbsp;You confirm that,
                  when required, you have lawful consent from your customers and
                  consumers to use their data.
                </span>
              </li>
              <li className="c11 c14 c2 li-bullet-0">
                <span className="c1">
                  You acknowledge that some data sources require specific
                  restrictions on use, and you agree to comply with any
                  additional terms and conditions specified in any Separate
                  Agreement.
                </span>
              </li>
              <li className="c11 c14 c2 li-bullet-0">
                <span className="c1">
                  You understand and acknowledge that we are not a
                  &quot;consumer reporting agency&quot; as defined under the
                  Fair Credit Reporting Act, but a software conduit to data for
                  your benefit.
                </span>
              </li>
              <li className="c11 c14 c2 li-bullet-0">
                <span className="c1">
                  You will not disclose, nor permit any person to disclose, to
                  any other person or entity any information we share and such
                  information shall be considered Confidential Information, as
                  defined in a Separate Agreement that you may have with us, and
                  you will protect the Confidential Information with the same
                  degree of care you use to safeguard your own information of a
                  similar character, provided that such degree of care shall not
                  be less than a reasonable degree of care.
                </span>
              </li>
              <li className="c11 c14 c2 li-bullet-0">
                <span className="c1">
                  You will implement appropriate administrative, physical,
                  technical and organizational measures, policies and procedures
                  to protect the personal consumer information and the
                  Confidential Information against accidental or unlawful
                  destruction or accidental loss, unauthorized alteration,
                  disclosure or access, and other unlawful forms of processing.
                </span>
              </li>
              <li className="c11 c14 c2 li-bullet-0">
                <span className="c1">
                  You will take commercially reasonable measures to prevent
                  unauthorized access to, or use of, such personal consumer
                  information and the Confidential Information.
                </span>
              </li>
              <li className="c11 c14 c2 li-bullet-0">
                <span className="c1">
                  You will maintain and enforce secure data destruction
                  procedures to protect the security and confidentiality of all
                  personal consumer information and Confidential Information as
                  it is being disposed.
                </span>
              </li>
              <li className="c11 c14 c2 li-bullet-0">
                <span className="c1">
                  You will immediately notify us in writing if you suspect, have
                  reason to believe or receive confirmation that any personal
                  consumer information or any Confidential Information is or has
                  been lost, stolen, compromised, misused or used, accessed or
                  acquired in an unauthorized manner or by any unauthorized
                  person.
                </span>
              </li>
              <li className="c11 c14 c2 li-bullet-0">
                <span className="c1">
                  You will comply with all applicable privacy and data
                  protection laws which may apply to the storage or transmission
                  of any personal consumer information and Confidential
                  Information.
                </span>
              </li>
              <li className="c11 c14 c2 li-bullet-0">
                <span className="c1">
                  You will comply with all requirements contained in any other
                  Separate Agreement that you have entered into with us.
                </span>
              </li>
              <li className="c4 c2 li-bullet-0">
                <span className="c1">
                  You have the right to use the data you upload to Website for
                  the permitted purposes and no other. &nbsp;You will permit us
                  to access your uploaded data for the purpose of providing our
                  Services to you.
                </span>
              </li>
            </ul>
            <p className="c0">
              <span className="c15 c18">FEES</span>
            </p>
            <p className="c0">
              <span className="c1">
                The use of our publicly accessible Service is free of charge.
              </span>
            </p>
            <p className="c0">
              <span className="c6 c3">BASIC SUBSCRIPTION</span>
            </p>
            <p className="c0">
              <span className="c1">
                When registering on the Kamba Website, you automatically
                subscribe to our Basic Subscription, which is free of charge.
                The Basic Subscription offers basic features only available to
                Registered Users, such as maintaining Vendor Listings,
                responding to Data Requests (only available to Data Vendors), or
                bookmarking Vendor Listings.
              </span>
            </p>
            <p className="c0">
              <span className="c6 c3">PREMIUM SUBSCRIPTION</span>
            </p>
            <p className="c0">
              <span className="c1">
                Registered Users may upgrade to the Premium Subscription for an
                agreed monthly or yearly fee. We may also offer selected Users a
                temporary Premium Subscription while providing managed services.
              </span>
            </p>
            <p className="c0">
              <span className="c6 c3">MANAGED SERVICES</span>
            </p>
            <p className="c0">
              <span className="c1">
                Kamba offers selected Data Buyers managed data sourcing services
                (&ldquo;Managed Services&rdquo;). Fees for those Managed
                Services are agreed individually and may entail fixed and/or
                success-based components.
              </span>
            </p>
            <p className="c0">
              <span className="c6 c3">BILLING</span>
            </p>
            <p className="c0">
              <span className="c1">
                Some parts of the Service are billed on a subscription basis
                (&quot;Subscription(s)&quot;). You will be billed in advance on
                a recurring and periodic basis (&quot;Billing Cycle&quot;).
                Billing cycles are set either on a monthly or annual basis,
                depending on the type of subscription plan you select when
                purchasing a Subscription.
              </span>
            </p>
            <p className="c0">
              <span className="c7 c3">
                At the end of each Billing Cycle, your Subscription will
                automatically renew under the exact same conditions unless you
                cancel it or Kamba cancels it. You may cancel your Subscription
                renewal eith
              </span>
              <span className="c3">
                er through your online account management page or by contacting
                Kamba at{' '}
              </span>
              <span className="c3">support@kambagroup.com.</span>
            </p>
            <p className="c0">
              <span className="c1">
                A valid payment method, including credit card, is required to
                process the payment for your Subscription. You shall provide
                Kamba with accurate and complete billing information including
                full name, address, state, zip code, telephone number, and a
                valid payment method information. By submitting such payment
                information, you automatically authorize Kamba to charge all
                Subscription fees incurred through your account to any such
                payment instruments.
              </span>
            </p>
            <p className="c0">
              <span className="c1">
                Should automatic billing fail to occur for any reason, Kamba
                will issue an electronic invoice indicating that you must
                proceed manually, within a certain deadline date, with the full
                payment corresponding to the billing period as indicated on the
                invoice.
              </span>
            </p>
            <p className="c0">
              <span className="c6 c3">FREE TRIAL</span>
            </p>
            <p className="c0">
              <span className="c1">
                Kamba may, at its sole discretion, offer a Subscription with a
                free trial for a limited period of time (&quot;Free
                Trial&quot;).
              </span>
            </p>
            <p className="c0">
              <span className="c1">
                You may be required to enter your billing information in order
                to sign up for the Free Trial.
              </span>
            </p>
            <p className="c0">
              <span className="c3 c7">
                If you do enter your billing information when signing up for the
                Free Trial, you will not be charged by Kamba until the Free
                Trial has expired. On the last day of the Free Trial period,
                unless you{' '}
              </span>
              <span className="c3">canceled</span>
              <span className="c1">
                &nbsp;your Subscription, you will be automatically charged the
                applicable Subscription fees for the type of Subscription you
                have selected.
              </span>
            </p>
            <p className="c0">
              <span className="c1">
                At any time and without notice, Kamba reserves the right to (i)
                modify the terms and conditions of the Free Trial offer, or (ii)
                cancel such Free Trial offer.
              </span>
            </p>
            <p className="c0">
              <span className="c6 c3">FEE CHANGES</span>
            </p>
            <p className="c0">
              <span className="c1">
                Kamba, in its sole discretion and at any time, may modify the
                Subscription fees for the Subscriptions. Any Subscription fee
                change will become effective at the end of the then-current
                Billing Cycle.
              </span>
            </p>
            <p className="c0">
              <span className="c1">
                Kamba will provide you with reasonable prior notice of any
                change in Subscription fees to give you an opportunity to
                terminate your Subscription before such change becomes
                effective.
              </span>
            </p>
            <p className="c0">
              <span className="c1">
                Your continued use of the Services after the Subscription fee
                change comes into effect constitutes your agreement to pay the
                modified Subscription fee amount.
              </span>
            </p>
            <p className="c0">
              <span className="c6 c3">REFUNDS</span>
            </p>
            <p className="c0">
              <span className="c1">
                Except when required by law, paid Subscription fees are
                non-refundable.
              </span>
            </p>
            <p className="c0">
              <span className="c6 c18">OTHER</span>
            </p>
            <p className="c0">
              <span className="c3 c15">CHILDREN UNDER THE AGE OF 13 </span>
            </p>
            <p className="c0">
              <span className="c1">
                By using the Website (and registering an account on the
                Website), you represent and warrant that you are at least 13
                years of age, and no one under the age of 13 may use the
                Website.
              </span>
            </p>
            <p className="c0 c2">
              <span className="c6 c3">CONTENT OWNERSHIP AND USAGE RIGHTS</span>
            </p>
            <p className="c0 c2">
              <span className="c1">
                We shall retain all worldwide rights in the intellectual
                property of the Software and Services and any content on the
                Website, including, but not limited to, trademarks, the
                &ldquo;look and feel&rdquo; of the Website, its color
                combinations, layout, and all other graphical elements, and the
                copyright in and to its original content. You should assume that
                everything you read or see on the Website is copyrighted or
                otherwise protected and owned by us, or a third party who
                licensed the right to use such content to us. Unless otherwise
                expressly noted, nothing that you read or see on the Website or
                other site content, or any of the source code or HTML code that
                we use to generate the Website may be copied, reproduced,
                modified, distributed, transmitted, republished, displayed, or
                performed for commercial use without our prior written consent,
                except as provided in the Terms, without prior written consent
                or otherwise permitted by relevant law.
              </span>
            </p>
            <p className="c0 c2">
              <span className="c6 c3">USE OF SOFTWARE</span>
            </p>
            <p className="c0 c2">
              <span className="c1">
                Our Software is protected by our applicable copyright, patent or
                other intellectual property rights or the third-party licensor.
                You are permitted to use the Software for your legitimate
                internal business objectives related to your role as a
                contractor, partner, or current or prospective customer of ours.
              </span>
            </p>
            <p className="c0 c2">
              <span className="c1">
                You own and retain all rights, title and interest in and to all
                intellectual property rights embodied in or associated with your
                data, your client&rsquo;s or customer&rsquo;s data, and any and
                all your products and services.
              </span>
            </p>
            <p className="c0">
              <span className="c7 c3">
                We own and retain all rights, title and interest in and to all
                intellectual property rights embodied in or associated with the
                Software and all modifications, enhancements and derivative
                works thereof including, without limitation, the Software, and
                all related inventions, know-how, scores, attributes, data
                delivery technology, modeling techniques, testing processes,
                designs, methodologies, software, flow charts, documentation,
                reports, specifications and all intellectual property rights
                relating to any of the foregoing, subject to your intellectual
                property ownership rights in and to any of your data included
                therein.
              </span>
            </p>
            <p className="c0 c2">
              <span className="c6 c3">LIMITED LICENSE AND RESTRICTIONS</span>
            </p>
            <p className="c0">
              <span className="c1">
                We grant to use, subject to all of the terms and conditions of
                any other Separate Agreement, a limited, revocable,
                non-exclusive, non-sublicensable, non-transferable license. You
                shall not (a) reproduce or modify the Software or Services, (b)
                rent, sell, lease or otherwise transfer the Software or Services
                to, or use the Software or Services for the benefit of any third
                party (c) reverse assemble, reverse compile or reverse engineer
                the Software or Services, or otherwise attempt to discover any
                of the source code or our underlying intellectual property.
              </span>
            </p>
            <p className="c0 c2">
              <span className="c1">
                Our Service allows you to post, link, store, share and otherwise
                make available certain data, information, text, graphics,
                videos, or other material (&quot;Content&quot;). You are
                responsible for the Content that you post to the Services,
                including its legality, reliability, and appropriateness. By
                posting Content to the Service, you grant us the right and
                license to use, modify, publicly perform, publicly display,
                reproduce, and distribute such Content on and through the
                Services. You retain any and all of your rights to any Content
                you submit, post or display on or through the Services and you
                are responsible for protecting those rights. You agree that this
                license includes the right for us to make your Content available
                to other users of the Services, who may also use your Content
                subject to these Terms.
              </span>
            </p>
            <p className="c0 c2">
              <span className="c1">
                You represent and warrant that: (i) the Content is yours (you
                own it) or you have the right to use it and grant us the rights
                and license as provided in these Terms, and (ii) the posting of
                your Content on or through the Services does not violate the
                privacy rights, publicity rights, copyrights, contract rights or
                any other rights of any person.
              </span>
            </p>
            <p className="c0 c2">
              <span className="c15 c3">REMEDIES FOR MISUSE </span>
            </p>
            <p className="c0">
              <span className="c7 c2 c3">
                You agree that any unauthorized use of our Website or its
                contents or any breach of these Terms may cause immediate and
                irreparable harm for which money damages may not constitute an
                adequate remedy. You agree that we may, with or without cause,
                without prior notice, without liability to you and at our
                discretion, immediately terminate your access to this Website.
                You further agree that injunctive relief, in addition to any
                other remedies available, may be warranted in order to enforce
                these Terms. You further understand that unauthorized use of
                this Website may expose you to civil and criminal liability and
                that we may report violations of these Terms and to and
                cooperate fully with the appropriate law enforcement authorities
                concerning any violations hereof.
              </span>
            </p>
            <p className="c0 c2">
              <span className="c6 c3">THIRD PARTY CONTENT</span>
            </p>
            <p className="c0 c2">
              <span className="c1">
                You may gain access through the Website to third party sites or
                data on the Internet. Third party sites are not within our
                supervision or control. Unless explicitly otherwise provided, we
                do not make any representation or warranty whatsoever about any
                third party site or data that is linked to our Website. We
                disclaim: (a) all responsibility and liability for content on
                third party websites and (b) any representations or warranties
                as to the security of any information (including, without
                limitation, credit card and other personal information) you
                might be requested to give any third party, and you hereby
                irrevocably waive any claim against us with respect to such
                sites and third party content.
              </span>
            </p>
            <p className="c0 c2">
              <span className="c6 c3">INTERNATIONAL USE</span>
            </p>
            <p className="c0 c2">
              <span className="c1">
                Recognizing the global nature of the Internet, you agree to
                comply with all local rules regarding online conduct and
                acceptable user content. Specifically, you agree to comply with
                all applicable laws regarding the transmission of technical data
                exported from the United States or the country in which you
                reside.
              </span>
            </p>
            <p className="c0 c2">
              <span className="c6 c3">INDEMNITY</span>
            </p>
            <p className="c0 c2">
              <span className="c1">
                You will indemnify and hold harmless Kamba, its parents,
                subsidiaries, affiliates, customers, vendors, officers and
                employees from any liability, damage or cost (including
                reasonable attorney&rsquo;s fees and costs) from any claim or
                demand made by any third party due to or arising out of your
                access to or use of the Website or any content on the Website,
                violation of the Terms by you, or the infringement by you of any
                intellectual property or other right of any person or entity.
              </span>
            </p>
            <p className="c0 c2">
              <span className="c1">
                In no event shall we, our directors, officers, shareholders,
                employees, members, agents, consultants, contractors, partners,
                vendors or service providers (including, without limitation,
                hosting and telecommunications providers) be liable with respect
                to the Website for (a) any indirect, incidental, punitive, or
                consequential damages of any kind whatsoever; (b) damages for
                loss of use, profits, data, images or other intangibles; (c)
                damages for unauthorized use, non-performance of the Website,
                errors or omissions; or (d) damages related to downloading
                content. Our collective liability under this agreement shall be
                limited to five hundred United States Dollars. Some states do
                not allow the exclusion or limitation of incidental or
                consequential damages, so the above limitations and exclusions
                may not apply.
              </span>
            </p>
            <p className="c0 c2">
              <span className="c6 c3">WARRANTY DISCLAIMERS</span>
            </p>
            <p className="c22">
              <span className="c1">
                Diligent care has been taken in acquiring and providing the
                information included and posted on the Website. Nonetheless, we
                make no guarantee or warranty, express or implied, as to the
                reliability, accuracy, timeliness or completeness of that
                information and assume no responsibility for any errors or
                omissions therein. USER ACCESSES THIS WEBSITE AT HIS OR HER OWN
                RISK. THE WEBSITE AND ALL CONTENT ON THE WEBSITE IS PROVIDED ON
                AN &ldquo;AS IS, AS AVAILABLE&rdquo; BASIS WITHOUT WARRANTY OF
                ANY KIND AND ANY AND ALL WARRANTIES OF MERCHANTABILITY OR
                FITNESS FOR A PARTICULAR{' '}
              </span>
              <span className="c3">PURPOSE OR</span>
              <span className="c1">
                &nbsp;NON-INFRINGEMENT ARE SPECIFICALLY DISCLAIMED. IN ADDITION,
                WE DO NOT REPRESENT OR WARRANT OR GUARANTEE THAT THE UNDERLYING
                DATABASES USED TO PROVIDE THE SOFTWARE OR SERVICES ARE COMPLETE,
                ACCURATE OR CORRECT AND, ACCORDINGLY, WE DO NOT REPRESENT OR
                WARRANT OR GUARANTEE THAT ANY INFORMATION PROVIDED BY US IS
                COMPLETE, ACCURATE OR CORRECT.
              </span>
              <span className="c12">&nbsp;</span>
              <span className="c1">
                WE NOR OUR, AGENTS OR THIRD PARTY CONTENT PROVIDERS SHALL BE
                LIABLE FOR ANY LOSS RESULTING FROM USE OR UNAVAILABILITY OF
                INFORMATION OR CONTENT ON THIS WEBSITE, INCLUDING BUT NOT
                LIMITED TO ANY LOST PROFITS, LOSS OR DAMAGE TO DATA, OR ANY
                DIRECT, INDIRECT, SPECIAL, CONSEQUENTIAL, COMPENSATORY OR
                INCIDENTAL DAMAGES, EVEN IF THEY HAVE BEEN ADVISED OF THE
                POSSIBILITY OF SUCH DAMAGES. THIS DISCLAIMER IS APPLICABLE TO
                ANY DAMAGE OR INJURY RESULTING FROM OUR NEGLIGENCE OR OMISSION,
                COMPUTER VIRUS OR OTHER SIMILAR ITEM, TELECOMMUNICATIONS ERRORS,
                OR UNAUTHORIZED ACCESS TO OR USE OF USER INFORMATION THROUGH
                THEFT OR ANY OTHER MEANS. WE ARE NOT &nbsp;LIABLE FOR CRIMINAL,
                TORTIOUS, OR NEGLIGENT ACTIONS OR OMISSIONS OF THIRD PARTIES
                THAT AFFECT THIS WEBSITE. IN NO EVENT WILL WE OR OUR AGENTS,
                ASSIGNS OR THIRD PARTY CONTENT PROVIDERS BE HELD LIABLE FOR ANY
                TORTIOUS OR ILLEGAL CONDUCT OF OTHER USERS. IN NO EVENT WILL WE
                OR ANY OF OUR AFFILIATES, AGENTS, OR ASSIGNS BE HELD LIABLE FOR
                ANY DAMAGE TO EQUIPMENT, HARDWARE OR OTHER PROPERTY OF USER OR
                PERSONAL INJURY THAT ARISES IN CONNECTION WITH USE OF THE
                WEBSITE.
              </span>
            </p>
            <p className="c0 c2">
              <span className="c6 c3">TRADEMARKS AND TRADE NAMES</span>
            </p>
            <p className="c0 c2">
              <span className="c1">
                The company name, graphics, logos, designs, page headers, button
                icons, scripts, and other product and service names are the
                trademarks and trade names of Kamba. Kamba trademarks and trade
                names may not be used, including as part of trademarks and/or as
                part of domain names, in connection with any product or service
                in any manner that is likely to cause confusion.
              </span>
            </p>
            <p className="c0 c2">
              <span className="c6 c3">UNITED STATES GOVERNMENT RIGHTS</span>
            </p>
            <p className="c0 c2">
              <span className="c1">
                The Software is a &ldquo;commercial item&rdquo; as defined at 48
                C.F.R. 2.101, consisting of &ldquo;commercial computer
                software&rdquo;, &ldquo;commercial computer software
                documentation&rdquo; and &ldquo;commercial services.&rdquo; If
                you are a U.S. governmental entity, then you acknowledge and
                agree that (i) use, duplication, reproduction, release,
                modification, disclosure, or transfer of the Software and any
                related documentation of any kind, including, without
                limitation, technical data and manuals, will be restricted in
                accordance with Federal Acquisition Regulation 12.212 for
                civilian purposes and Defense Federal Acquisition Regulation
                Supplement 227.7202 for military purposes, (ii) the Software
                were developed fully at private expense and (iii) all other use
                of the Software except in accordance with the license grant
                provided above is strictly prohibited.
              </span>
            </p>
            <p className="c0 c2">
              <span className="c6 c3">DMCA NOTICE OF INFRINGEMENT</span>
            </p>
            <p className="c0 c2">
              <span className="c1">
                We respect the intellectual property rights of others, and we
                expect users of our Website do the same.
              </span>
            </p>
            <p className="c0 c2">
              <span className="c1">
                If you believe that the use or display of any Content on the
                Website infringes any copyright that you own or control, please
                contact our designated agent for copyright claims at:
              </span>
            </p>
            <p className="c2 c11">
              <span className="c1">Kamba Group LLC</span>
            </p>
            <p className="c11 c2">
              <span className="c7 c3">227 East 59</span>
              <span className="c7 c3 c20">th</span>
              <span className="c1">&nbsp;Street, 4d</span>
            </p>
            <p className="c11 c2">
              <span className="c1">New York, NY 10022</span>
            </p>
            <p className="c11 c2">
              <span className="c1">Attn: Legal, DMCA Notice Agent</span>
            </p>
            <p className="c11 c2">
              <span className="c3">legal</span>
              <span className="c7 c3">@kambagroup.com</span>
            </p>
            <p className="c0 c2">
              <span className="c1">
                When providing notification of alleged infringement of a
                copyright that you own or control (&ldquo;DMCA Notice&rdquo;),
                please provide our designated agent the following information:
              </span>
            </p>
            <p className="c0 c2">
              <span className="c1">
                A description of the copyrighted work that you claim has been
                infringed and a description of the infringing activity;
              </span>
            </p>
            <p className="c0 c2">
              <span className="c1">
                A description of where the material that you claim is infringing
                is located, such as the URL where it is posted;
              </span>
            </p>
            <p className="c0 c2">
              <span className="c1">
                Your name, address, telephone number, and email address;
              </span>
            </p>
            <p className="c0 c2">
              <span className="c1">
                A statement by you that you have a good faith belief that the
                disputed use of the material is not authorized by the copyright
                owner, its agent, or the law;
              </span>
            </p>
            <p className="c0 c2">
              <span className="c1">
                A statement by you, made under penalty of perjury, that the
                above information in your DMCA Notice is accurate and that you
                are the copyright owner or are authorized to act on the
                copyright owner&rsquo;s behalf; and
              </span>
            </p>
            <p className="c0 c2">
              <span className="c1">
                The electronic or physical signature of the owner of the
                copyright or the person authorized to act on the owner&rsquo;s
                behalf. &nbsp;
              </span>
            </p>
            <p className="c0 c2">
              <span className="c15 c3">GOVERNING LAW</span>
            </p>
            <p className="c0 c2">
              <span className="c1">
                Terms and the relationship between you and Kamba (including any
                dispute) shall be governed in all respects by the laws of the
                State of New York, United States of America, without regard to
                its conflict of law provisions.
              </span>
            </p>
            <p className="c0 c2">
              <span className="c1">
                If any provision of these Terms is found by a court of competent
                jurisdiction to be invalid, the parties nevertheless agree that
                the court should endeavor to give effect to the parties&rsquo;
                intentions as reflected in the provision, and the other
                provisions of the Terms remain in full force and effect.
              </span>
            </p>
            <p className="c0 c2">
              <span className="c6 c3">&nbsp;MISCELLANEOUS</span>
            </p>
            <p className="c0 c2">
              <span className="c1">
                These Terms constitute the entire agreement between users of the
                Website and Kamba, and regarding the subject matter hereof. If
                you breach any term of the Terms, we may pursue any legal or
                equitable remedy available, including but not limited to,
                direct, consequential, and punitive damages and injunctive
                relief. &nbsp;Our remedies are cumulative and not exclusive. Our
                failure to exercise any remedy or enforce any portion of the
                Terms at any time shall not operate as a waiver of any remedy or
                of the right to enforce any portion of the Agreement at any time
                thereafter. Users of this Website are responsible for compliance
                with all applicable regulations and laws.
              </span>
            </p>
            <p className="c0 c2">
              <span className="c6 c3">CONTACT US</span>
            </p>
            <p className="c2 c8">
              <span className="c7 c3">Qu</span>
              <span className="c3">
                estions or comments about the Website may be directed to Kamba
                at the email address{' '}
              </span>
              <span className="c3">
                legal@kamba.com If you believe there is a violation of these
                Terms and you would like to report it to us, please direct the
                information to legal@kamba.com. Any reports of violations you
                submit to us, including your identity, may not be kept
                confidential. In response to your report, we may take actions in
                accordance with these Terms or other applicable agr
              </span>
              <span className="c1">
                eements between us and the alleged violator of the Terms, and
                may pursue other remedies available under all applicable local,
                state, federal or international laws, regulations, rules, orders
                and other requirements, now or hereafter in effect. However, we
                are under no obligation to respond to any reports we receive.
                Please note that a personal reply to reports you submit to us
                may not always be possible.
              </span>
            </p>

            {title === 'all' && (
              <>
                <h2>Privacy Policy</h2>
                <p className="c3">
                  <span className="c2 c1">
                    &nbsp;
                    <br />
                  </span>
                  <span className="c14">KAMBA PRIVACY POLICY</span>
                </p>
                <p className="c6">
                  <span className="c2 c1"></span>
                </p>
                <p className="c6">
                  <span className="c0"></span>
                </p>
                <p className="c8">
                  <span className="c1 c7">
                    Effective Date: Last Updated on November 1, 2022
                  </span>
                </p>
                <p className="c6">
                  <span className="c2 c1"></span>
                </p>
                <p className="c9">
                  <span className="c2 c1">
                    In this Privacy Policy, we, Kamba Group LLC
                    (&ldquo;Kamba&rdquo; or &ldquo;we&rdquo; and sometimes
                    &ldquo;us&rdquo;), describe the types of Personally
                    Identifiable Information (defined below) we collect; how we
                    use, process, or share that information; and what rights you
                    have while we hold that information.
                  </span>
                </p>
                <p className="c8">
                  <span className="c2 c1">
                    We take the private nature of your Personally Identifiable
                    Information (defined below) very seriously and are committed
                    to protecting it. To do that, we&#39;ve set up procedures to
                    ensure that such information is handled responsibly and in
                    accordance with applicable data protection and privacy laws.
                    Your trust is paramount to us, and we act accordingly.
                  </span>
                </p>
                <p className="c6">
                  <span className="c2 c1"></span>
                </p>
                <p className="c8">
                  <span className="c2 c1">
                    This Privacy Policy describes what information we collect
                    when you use our Sites (defined below), how we use that
                    information, and what choices we offer you to access,
                    update, and control it.
                  </span>
                </p>
                <p className="c9">
                  <span className="c2 c5">
                    What Type of Information is Covered by this Policy
                  </span>
                </p>
                <p className="c8">
                  <span className="c2 c5">
                    &quot;Personally Identifiable information&quot;
                  </span>
                  <span className="c2 c1">
                    &nbsp;(&ldquo;PII&rdquo;) is any information that we could
                    use to identify an individual, including, for example, their
                    name, address, geographic location of their computer or
                    mobile device, telephone number, credit card number, email
                    address and bank account information. It does not include
                    Personally Identifiable Information that is encoded or
                    anonymized (such as through encryption or hashing), or
                    publicly available information that has not been combined
                    with non-public information.
                  </span>
                </p>
                <p className="c8">
                  <span className="c2 c1">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </p>
                <p className="c8">
                  <span className="c2 c5">
                    &quot;Sensitive Personally Identifiable Information&quot;
                  </span>
                  <span className="c2 c1">
                    &nbsp;is information that meets the &quot;Personally
                    Identifiable Information&quot; criteria outlined above and
                    also (i) reveals race, ethnic origin, political opinions,
                    religious or philosophical beliefs, trade union membership,
                    or (ii) concerns health or sex life, information about
                    Social Security benefits, or information on criminal or
                    administrative proceedings other than in the context of
                    pending legal proceedings.&nbsp;We will never ask you to
                    share sensitive Personally Identifiable Information with us,
                    but if it is shared with us, this Privacy Policy applies.
                  </span>
                </p>
                <p className="c6">
                  <span className="c2 c5"></span>
                </p>
                <p className="c8">
                  <span className="c2 c5">What does this Policy Cover?</span>
                </p>
                <p className="c9">
                  <span className="c2 c1">
                    This Privacy Policy covers your use of Kamba websites,
                    including sites located on Kambagroup.com and any other
                    pages that link to this Privacy Policy (the
                    &ldquo;Sites&rdquo;), as well as Account Information and
                    Usage Data (each defined below). This Privacy Policy does
                    not cover any other data submitted through or otherwise made
                    available to Kamba or other written agreement in place
                    between Kamba and our platform customers
                    (&ldquo;Customers&rdquo;), or any other data collection and
                    processing, including, without limitation, data that we
                    collect offline.
                  </span>
                </p>
                <p className="c9">
                  <span className="c2 c1">
                    Customers are solely responsible for establishing policies
                    for and ensuring compliance with all applicable laws and
                    regulations, as well as any and all privacy policies,
                    agreements or other obligations relating to such
                    Customers&rsquo; use or collection of personal data in
                    connection with the use of our platform services (the
                    &ldquo;Platform Services&rdquo;) by individuals with whom
                    our Customers interact. If you are an individual who
                    interacts with a Customer using our Platform Services or you
                    otherwise believe that a Customer uses our Platform Services
                    to process your personal data, and you contact us regarding
                    this data, you will be directed to contact the applicable
                    Customer for assistance with any requests or questions
                    relating to your personal data, including without limitation
                    any requests to access, amend or erase your personal data.
                  </span>
                </p>
                <p className="c9">
                  <span className="c2 c5">
                    What information do we collect and how?
                  </span>
                </p>
                <p className="c9">
                  <span className="c2 c1">
                    We collect information and data from you in two ways. First,
                    we collect information you manually provide to us, including
                    registration information like your name and email address.
                    Second, we collect information automatically from your use
                    of our Sites and Platform Services, including how and when
                    our Sites and Platform Services are used.
                  </span>
                </p>
                <p className="c9">
                  <span className="c7 c1">
                    Information you submit or make available.
                  </span>
                </p>
                <p className="c9">
                  <span className="c2 c1">
                    If you create an account for our Platform Services or
                    otherwise create an account through our Sites, we may ask,
                    and in some cases require, you to enter certain personal
                    data such as your name, email address, phone number,
                    physical address, gender, job title, job role, company name,
                    company size and other information relating to your proposed
                    use of our products and services. You will know which data
                    categories you provide to us, because you will affirmatively
                    enter and submit the data. Any such information you provide
                    us in connection with the registration for our Platform
                    Services is deemed &ldquo;Account Information.&rdquo; Kamba
                    may receive information from third parties regarding the
                    companies and users who use our Sites and Platform Services
                    and may combine such information with the information we
                    receive or collect from you.
                  </span>
                </p>
                <p className="c9">
                  <span className="c7 c1">
                    Information we collect about your use of our Sites.
                  </span>
                </p>
                <p className="c9">
                  <span className="c2 c1">
                    When you use our Sites, your device automatically generates
                    and provides data to us. We collect and use such data to
                    help us to understand how you are using our Sites, and how
                    to better provide those Sites to you.
                  </span>
                </p>
                <p className="c9">
                  <span className="c2 c1">
                    When you use the Sites, we use standard automated data
                    collection tools such as{' '}
                  </span>
                  <span className="c2 c1">cookies</span>
                  <span className="c2 c1">
                    , web beacons, tracking pixels and the like (&ldquo;Web
                    Data&rdquo;). While the vast majority of Web Data is not
                    personal data, some Web Data may include certain information
                    which may be considered personal data depending on where you
                    live, like a User&rsquo;s IP address. To the extent Web Data
                    contains personal data, it will be treated as personal data
                    under this Privacy Policy.
                  </span>
                </p>
                <p className="c9">
                  <span className="c7 c1">
                    Information we collect about your use of our Platform
                    Services.
                  </span>
                </p>
                <p className="c9">
                  <span className="c2 c1">
                    When you use our Platform Services, information and data
                    gets automatically generated and collected that can help us
                    to understand how you are using our Platform Services, and
                    how to better provide the Platform Services to you.
                  </span>
                </p>
                <p className="c9">
                  <span className="c2 c1">
                    When you use the Platform Services, we automatically record
                    information about how our users (both account holders and
                    other users on an account) (&ldquo;Users&rdquo;) use the
                    Platform Services (&ldquo;Usage Data&rdquo;). Most Usage
                    Data is not personal data, and includes information like
                    browser type, operating system, the pages or features of our
                    Platform Services accessed or used by User and the time
                    spent on those pages or features, search terms entered into
                    our Platform Services to browse, commands executed when
                    using our Platform Services, information about the types and
                    size of files analyzed via the Platform Services, and other
                    statistical information. Some Usage Data may include certain
                    information which may be considered personal data depending
                    on where you live, like a User&rsquo;s IP address. To the
                    extent Usage Data contains personal data, it will be treated
                    as personal data and is covered under this Privacy Policy.
                  </span>
                </p>
                <p className="c9">
                  <span className="c2 c5">
                    How do we use the information we collect from you?
                  </span>
                </p>
                <p className="c9">
                  <span className="c2 c1">
                    We may use your personal data for the following purposes:
                  </span>
                </p>
                <ul className="c10 lst-kix_list_6-0 start">
                  <li className="c16 li-bullet-0">
                    <span className="c2 c1">
                      to provide, maintain, improve and update the Sites, the
                      Platform Services, and our services;
                    </span>
                  </li>
                  <li className="c4 li-bullet-0">
                    <span className="c2 c1">
                      to develop new products and services;
                    </span>
                  </li>
                  <li className="c4 li-bullet-0">
                    <span className="c2 c1">
                      for billing, payment, or account management; for example,
                      to identify your account and correctly identify your usage
                      of our products and services;
                    </span>
                  </li>
                  <li className="c4 li-bullet-0">
                    <span className="c2 c1">
                      to investigate security issues, prevent fraud, or combat
                      the illegal use of our products and services;
                    </span>
                  </li>
                  <li className="c4 li-bullet-0">
                    <span className="c2 c1">
                      to provide you with support and respond to your questions,
                      comments, and requests, including to keep in contact with
                      you regarding the products and services you use;
                    </span>
                  </li>
                  <li className="c4 li-bullet-0">
                    <span className="c2 c1">
                      to tailor and send you newsletters, emails and other
                      content to promote our products and services (you can
                      always unsubscribe from our marketing emails by emailing
                      us at{' '}
                    </span>
                    <span className="c1">privacy@kamba.com</span>
                    <span className="c1">&nbsp;</span>
                    <span className="c2 c1">
                      and to allow third-party partners to send you marketing
                      communications about their services, in accordance with
                      your preferences;
                    </span>
                  </li>
                  <li className="c4 li-bullet-0">
                    <span className="c2 c1">
                      to generate and analyze statistical information about how
                      our Sites and Platform Services are used in the aggregate;
                    </span>
                  </li>
                  <li className="c4 li-bullet-0">
                    <span className="c2 c1">
                      where Kamba otherwise has a legitimate interest or lawful
                      business purposes;
                    </span>
                  </li>
                  <li className="c4 li-bullet-0">
                    <span className="c2 c1">
                      where required by applicable law, legal process, or
                      government regulation; or
                    </span>
                  </li>
                  <li className="c4 li-bullet-0">
                    <span className="c2 c1">where you have given consent.</span>
                  </li>
                </ul>
                <p className="c9">
                  <span className="c2 c5">
                    How do we share your information?
                  </span>
                </p>
                <p className="c9">
                  <span className="c2 c1">
                    We may share your personal data with third parties as
                    follows:
                  </span>
                </p>
                <ul className="c10 lst-kix_list_7-0 start">
                  <li className="c16 li-bullet-0">
                    <span className="c2 c1">
                      with businesses and service providers that help us conduct
                      our business, subject to confidentiality obligations and
                      the requirement that those businesses and service
                      providers do not sell your personal data;
                    </span>
                  </li>
                  <li className="c4 li-bullet-0">
                    <span className="c2 c1">
                      with our trusted partners such as with third-party
                      partners who are working with us to enable them to contact
                      you about their services but only where we have a lawful
                      basis to do so (such as your consent where required by
                      applicable law);
                    </span>
                  </li>
                  <li className="c4 li-bullet-0">
                    <span className="c2 c1">
                      when necessary to deliver our products and services, such
                      as with a payment card provider to process your credit
                      card transaction;
                    </span>
                  </li>
                  <li className="c4 li-bullet-0">
                    <span className="c2 c1">
                      where you have instructed us to share your personal data,
                      such as to provide you with support;
                    </span>
                  </li>
                  <li className="c4 li-bullet-0">
                    <span className="c2 c1">
                      when authorized by law or necessary to comply with a legal
                      process;
                    </span>
                  </li>
                  <li className="c4 li-bullet-0">
                    <span className="c2 c1">
                      when required to protect and defend the rights or property
                      of Kamba, our Customers, vendors or other users of our
                      Sites, including the security of our Sites, products and
                      services (including the Platform Services);
                    </span>
                  </li>
                  <li className="c4 li-bullet-0">
                    <span className="c2 c1">
                      when necessary to protect the personal safety, property or
                      other rights of the public, Kamba or its customers or
                      employees;
                    </span>
                  </li>
                  <li className="c4 li-bullet-0">
                    <span className="c2 c1">
                      where you have consented to the sharing of your
                      information with third parties; or
                    </span>
                  </li>
                  <li className="c4 li-bullet-0">
                    <span className="c2 c1">
                      in connection with a sale or reorganization of all or part
                      of our business.
                    </span>
                  </li>
                </ul>
                <p className="c9">
                  <span className="c2 c1">
                    Kamba does not &lsquo;sell&rsquo; personal information of
                    California consumers (as defined in the California Consumer
                    Privacy Act (CCPA)) and we will not do so without offering
                    you the right to opt out of any &lsquo;sale&rsquo;.
                  </span>
                </p>
                <p className="c9">
                  <span className="c2 c5">
                    What rights do you have over your information?
                  </span>
                </p>
                <p className="c9">
                  <span className="c2 c1">
                    We offer you choices regarding the collection, use and
                    sharing of your personal data and we will respect the
                    choices you make. Where you have consented to Kamba&rsquo;
                    processing of your personal data, you may withdraw that
                    consent at any time by contac
                  </span>
                  <span className="c1">ting </span>
                  <span className="c1">privacy@kamba.com</span>
                  <span className="c1">
                    &nbsp;with the subject &ldquo;Withdraw Consent&rdquo;.
                    Please note that if you decide not to provide us with
                  </span>
                  <span className="c2 c1">
                    &nbsp;your personal data, you may not be able to access the
                    Platform Services or certain features of the Sites.
                  </span>
                </p>
                <p className="c9">
                  <span className="c7 c1">
                    Opt Out of Certain Communications.
                  </span>
                </p>
                <p className="c9">
                  <span className="c2 c1">
                    We may periodically send you free communications that
                    directly promote our products and services. When you receive
                    such promotional or marketing communications from us, you
                    will have the opportunity to opt out (either by following
                    the unsubscribe instructions in the communication you
                    receive or by emailing us
                  </span>
                  <span className="c1">&nbsp;at </span>
                  <span className="c1">privacy@kamba.com</span>
                  <span className="c1">. </span>
                  <span className="c2 c1">
                    We may also send you certain necessary communications
                    regarding your use of our products or services and you may
                    not be able to opt out of those communications; for example,
                    we may need to send you communications regarding updates to
                    our terms, this Privacy Policy, or relating to payment and
                    billing.
                  </span>
                </p>
                <p className="c9">
                  <span className="c7 c1">
                    Rights of Access, Modification, Deletion and Restriction of
                    Your Information.
                  </span>
                </p>
                <p className="c9">
                  <span className="c2 c1">
                    If you are located in certain parts of the world, including
                    the European Economic Area, the United Kingdom, and certain
                    other legal jurisdictions (for example California), you may
                    have certain rights in relation to certain of your personal
                    data. Those rights may include asking us to provide
                    information (including categories of sources), access,
                    export, modification, deletion, or to restrict processing of
                    certain of your personal data. If you wish to exercise these
                    rights, please emai
                  </span>
                  <span className="c1">l us at </span>
                  <span className="c1">legal@kamba.com.</span>
                </p>
                <p className="c9">
                  <span className="c2 c1">
                    Kamba may take certain steps to verify requests submitted
                    using information available to us, including your email
                    address and any information associated with your account.
                    Where allowed, we may ask you to provide your government
                    identification to verify your account. Personal data you
                    provide to us for verification will only be used to verify
                    and maintain records regarding your request.
                  </span>
                </p>
                <p className="c9">
                  <span className="c2 c1">
                    We will endeavor to promptly respond to your request.
                    Although Kamba makes good faith efforts to provide users of
                    our Sites and Platform Services with access to their
                    personal data, there may be circumstances in which Kamba is
                    unable to provide access including but not limited to: where
                    the information is legally privileged, would compromise the
                    privacy or other legitimate rights of others, where the
                    burden or expense of providing the information would be
                    disproportionate to the risks to the User&rsquo;s privacy in
                    the case in question, where the request is manifestly
                    unfounded or excessive or where the information is
                    proprietary. If you request deletion of your personal data,
                    please note that we may continue to retain certain of your
                    personal data in archived/backup copies for our records or
                    as otherwise required or allowed by law.
                  </span>
                </p>
                <p className="c9">
                  <span className="c2 c5">
                    How long do we store your information?
                  </span>
                </p>
                <p className="c9">
                  <span className="c2 c1">
                    Kamba may retain the personal data we collect from you as
                    described in this Privacy Policy for as long as you use our
                    services or as necessary to fulfill the purpose(s) for which
                    it was collected, provide our services, resolve disputes,
                    establish legal defenses, conduct audits, pursue our
                    legitimate interests, for our business or commercial
                    purposes, enforce our agreements, and comply with applicable
                    laws.
                  </span>
                </p>
                <p className="c9">
                  <span className="c2 c5">
                    What steps do we take to secure your Information?
                  </span>
                </p>
                <p className="c9">
                  <span className="c1">
                    Kamba considers protecting the security of your data to be
                    its number one responsibility.{' '}
                  </span>
                  <span className="c1">
                    Kamba aligns its security practices to the internationally
                    recognized industry standard code of practice for protecting
                    personal data in the cloud. Kamba encrypts communications
                    you make with our Sites and the Platform Services, for
                    example, entering your username and password or information
                    into forms, using TLS (transport layer security) or other
                    industry standard{' '}
                  </span>
                  <span className="c1">technologies</span>
                  <span className="c1">.</span>
                  <span className="c1">&nbsp;Plea</span>
                  <span className="c2 c1">
                    se be aware, however, that no method of transmitting
                    information over the Internet or storing information is
                    completely secure or safe. Accordingly, we cannot guarantee
                    the absolute security of any information.
                  </span>
                </p>
                <p className="c9">
                  <span className="c2 c5">
                    What about international data transfer?
                  </span>
                </p>
                <p className="c9">
                  <span className="c2 c1">
                    Kamba may transfer your personal data to countries other
                    than the one from which you provide it to us. Specifically,
                    if you are located outside the United States and provide
                    your personal data to us, we may transfer your personal data
                    to the United States and process it there, subject to the
                    following safeguards:
                  </span>
                </p>
                <ul className="c10 lst-kix_list_9-0 start">
                  <li className="c16 li-bullet-0">
                    <span className="c2 c5">
                      E.U.-U.S. Privacy Shield and Swiss-U.S. Privacy Shield
                    </span>
                    <span className="c2 c1">
                      . To comply with European Union and Swiss data protection
                      laws, Kamba adheres to and h
                    </span>
                    <span className="c1">as </span>
                    <span className="c1">
                      self-certified to the EU&ndash;U.S. and Swiss-US Privacy
                      Shield{' '}
                    </span>
                    <span className="c1">Frameworks</span>
                    <span className="c1">
                      &nbsp;set forth by the U.S. Department of Commerce
                      regarding the collection, use, and retention of personal
                      data tr
                    </span>
                    <span className="c2 c1">
                      ansferred from the European Economic Area and Switzerland
                      to the United States. Kamba complies with the E.U.-U.S.
                      Privacy Shield in respect of the collection, use and
                      retention of personal data transferred from the United
                      Kingdom to the United States in reliance on the E.U.-U.S.
                      Privacy Shield until such time as a successor framework or
                      other transfer mechanism exists between the U.S. and the
                      U.K.
                    </span>
                  </li>
                </ul>
                <ul className="c10 lst-kix_list_10-0 start">
                  <li className="c16 li-bullet-0">
                    <span className="c2 c1">
                      If you are a resident of the European Economic Area or
                      Switzerland and feel that Kamba is not abiding by the ter
                    </span>
                    <span className="c1">
                      ms of the Privacy Policy, or is not in compliance with the
                      Privacy Shield Principles, please contact{' '}
                    </span>
                    <span className="c1">legal@kamba.com</span>
                    <span className="c1">
                      &nbsp;with the subject &ldquo;Privacy Shield&rdquo;. Kamba
                      will respond to any such requests within 45 days. If Kamba
                      does not resolve your complaint, you may have the
                      possibility, under certain condi
                    </span>
                    <span className="c2 c1">
                      tions, to invoke binding arbitration through the Privacy
                      Shield Panel. If we receive personal data subject to our
                      certification under the Privacy Shield and then transfer
                      it to a third-party service provider acting as an agent on
                      our behalf, we may have certain liability under the
                      Privacy Shield Principles if both (i) the agent processes
                      the personal data in a manner inconsistent with the
                      Privacy Shield Principles and (ii) we are responsible for
                      the event giving rise to the damage.
                    </span>
                  </li>
                </ul>
                <ul className="c10 lst-kix_list_11-0 start">
                  <li className="c16 li-bullet-0">
                    <span className="c2 c1">
                      Kamba&rsquo; commitments under the Privacy Shield are
                      subject to the investigatory and enforcement powers of the
                      United States Federal Trade Commission. European Union
                      Model Clauses. Kamba offers our Customers the ability to
                      enter into a data processing addendum (DPA) that contains
                      the European Economic Area Model Clauses, also known as
                      Standard Contractual Clauses, to meet the adequacy and
                      security requirements for our Customers with users located
                      in the European Economic Area or are otherwise using Kamba
                      to process any data originating from the European Economic
                      Area. If you represent a Customer that you have determined
                      is subject to the GDPR or other applicable data protection
                      law and you do not yet have in place an updated data
                      processing addendum (DPA) with us, please review and
                      complete the instructions on our&nbsp;
                    </span>
                    <span className="c5 c13">
                      <a
                        className="c11"
                        href="https://www.google.com/url?q=https://www.databricks.com/dpa&amp;sa=D&amp;source=editors&amp;ust=1668807870972464&amp;usg=AOvVaw0r5s4quLdyx2WAUIWYSSFr"
                      >
                        DPA
                      </a>
                    </span>
                    <span className="c2 c1">.</span>
                  </li>
                </ul>
                <p className="c9">
                  <span className="c2 c5">
                    What about third party services?
                  </span>
                </p>
                <p className="c9">
                  <span className="c2 c1">
                    We may make available certain features and functionality
                    that allow you to sign into our Sites using third party
                    login credentials (such as LinkedIn, Facebook, Twitter and
                    Google+ and others) or access certain third-party services
                    from our Platform Services (each such third party services,
                    a &ldquo;Third Party Service&rdquo;). Any data you submit to
                    any Third Party Services will be subject to the terms of
                    service and privacy policy of such Third Party Service.
                  </span>
                </p>
                <p className="c9">
                  <span className="c2 c1">
                    We may also link to co-branded websites or products that are
                    maintained by Kamba and one or more of our business
                    partners. Please note that these co-branded websites and
                    products may have their own privacy policy, which we
                    encourage you to read and understand. When you click a link
                    to a third-party site, you will leave our site and we
                    don&rsquo;t control or endorse anything on third-party
                    sites.
                  </span>
                </p>
                <p className="c9">
                  <span className="c2 c5">
                    Do we collect children&rsquo;s data?
                  </span>
                </p>
                <p className="c9">
                  <span className="c1 c2">
                    Kamba products and services are not directed to children
                    under 18 years of age and Kamba does not knowingly collect
                    personal data from children under 18 years of age. If we
                    learn that we have collected any personal data from children
                    under 18 years old, we will promptly take steps to delete
                    suc
                  </span>
                  <span className="c1">
                    h information. If you are aware that a child has submitted
                    us such information, please contact us at{' '}
                  </span>
                  <span className="c1">privacy@kamba.com</span>
                  <span className="c1">&nbsp;w</span>
                  <span className="c2 c1">
                    ith the subject &ldquo;Child Data&rdquo;.
                  </span>
                </p>
                <p className="c9">
                  <span className="c2 c5">
                    How can we make changes to this Privacy Policy?
                  </span>
                </p>
                <p className="c9">
                  <span className="c2 c1">
                    Kamba may change this Privacy Policy from time to time by
                    updating this site. If there are material changes to Privacy
                    Policy that may impact your rights, Kamba will attempt to
                    notify you by email or as otherwise required by applicable
                    law. You understand and agree that you will be deemed to
                    have accepted the updated Privacy Policy if you visit our
                    Sites or use any of our products or services after such
                    changes have been made.{' '}
                  </span>
                  <span className="c2 c5">
                    If you do not agree with this policy, do not access or use
                    our Services or interact with any other aspect of our
                    business
                  </span>
                  <span className="c2 c1">.&nbsp;&nbsp;</span>
                </p>
                <p className="c9">
                  <span className="c2 c5">How can you contact us?</span>
                </p>
                <p className="c9">
                  <span className="c2 c1">Please co</span>
                  <span className="c1">ntact us at </span>
                  <span className="c1">privacy@kamba.com</span>
                  <span className="c1">&nbsp;if </span>
                  <span className="c2 c1">
                    you have any questions about our Privacy Policy.
                  </span>
                </p>
                <p className="c6">
                  <span className="c2 c1"></span>
                </p>
                <p className="c6">
                  <span className="c2 c1"></span>
                </p>
                <p className="c6">
                  <span className="c2 c12"></span>
                </p>
              </>
            )}
            {/* {isLoggedIn && (
              <div>
                <Button onClick={handleClick}>
                  {loadingAcceptance ? (
                    <ClipLoader color="#ffffff" size={15} />
                  ) : (
                    <span>I agree with Terms of Service</span>
                  )}
                </Button>
              </div>
            )} */}
          </ContractContainer>
        </Wrapper>
      </Container>
    </Container>
  )
}

export default TermsAndConditions
