import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export const getUrlPage = (): number => {
  const { search } = useLocation()
  const searchParamsObject = useMemo(
    () => new URLSearchParams(search),
    [search],
  )
  const urlPage = parseInt(searchParamsObject.get('page')!)
  return urlPage ? urlPage : 1
}
export const setUrlPage = (): ((page: number) => any) => {
  const { search } = useLocation()
  const navigate = useNavigate()
  const searchParamsObject = useMemo(
    () => new URLSearchParams(search),
    [search],
  )
  const changePage = (page: number) => {
    searchParamsObject.set('page', `${page}`)
    navigate(`?${searchParamsObject.toString()}`)
  }
  return changePage
}
export type PageItemType = ({
  id,
  key,
  onClick,
  selected,
  label,
}: {
  id: number
  key: number
  onClick: (e: any) => any
  label: string
  selected: boolean
}) => JSX.Element

const calculateFirstPage = (
  amountItems: number,
  currentPage: number,
  maxPages?: number,
): number => {
  const middleAmount = Math.floor(amountItems / 2)
  const firstPageWithoutMax = currentPage - middleAmount //Takes the currentPage and reduces half the length of the array items, this makes it so the array puts the current Page in the middle
  if (firstPageWithoutMax <= 0) {
    return 0 //If the first Page with the array in the middle is negative, it takes 0 as the first page
  }

  const reachedMax = maxPages && amountItems + firstPageWithoutMax > maxPages
  if (reachedMax) {
    return maxPages - amountItems //If the ceiling is reached, so the last item in the array has a page bigger than max, it takes takes max - the amount of pages as the first page in the array.
  } else {
    return firstPageWithoutMax
  }
}

export const buildPageItems = (
  amountItems: number,
  currentPage: number,
  Item: PageItemType,
  setPage: (page: number) => any,
  maxPages?: number,
) => {
  const correctAmount =
    maxPages && amountItems > maxPages ? maxPages : amountItems //In cases where the amount given is larger than the actual available pages

  const firstItemPage = calculateFirstPage(correctAmount, currentPage, maxPages)

  const items = Array.from({ length: correctAmount }, (_, i) => {
    const page = firstItemPage + i + 1
    return (
      <Item
        id={i}
        key={i}
        label={`${page}`}
        selected={currentPage === page}
        onClick={() => {
          setPage(page)
        }}
      ></Item>
    )
  })
  return items
}
