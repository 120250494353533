import DragAndDrop from '@/components/ui/drag-n-drop-form-style'
import UploadInput from '@/components/ui/upload-input'
import { validateFunction } from '@/utils/form-errors'
import { SampleDto } from '../../second-step/types'
import * as yup from 'yup'
import { ViewFileSelectType } from '../../view-file-select-type'

type AllLegalType = {
  state: SampleDto[]
  setValidationError: (arg0: yup.ValidationError[]) => void
  dispatch: (arg0: SampleDto[]) => void
}

export const AllLegal = ({
  state,
  dispatch,
  setValidationError,
}: AllLegalType) => {
  const fileExtensions: Record<string, any> = {
    default: {
      extension: '(pdf)|(doc)|(docx)',
      error: 'File must be either .pdf, .doc, or .docx',
    },
  }

  const validate = async (data: File, type: string) => {
    const dataSchema = {
      file: yup
        .mixed()
        .test('required', 'You need to provide a file', (data: File) => {
          return !!(data && data.size)
        })
        .test(
          'fileType',
          fileExtensions[type]
            ? fileExtensions[type].error
            : fileExtensions.default.error,
          (data: File) => {
            return (
              data &&
              !!data.type.match(
                fileExtensions[type]
                  ? fileExtensions[type].extension
                  : fileExtensions.default.extension,
              )
            )
          },
        ),
    }
    const errors = await validateFunction(data, dataSchema, 'file')
    setValidationError(errors)
    return errors.length === 0
  }

  const viewFiles = state
    .filter((sample) => !!sample.file)
    .map((sample) => (
      <ViewFileSelectType
        name={sample.file!.name}
        type={sample.type}
        options={state}
        onChange={async (e) => {
          const valid = await validate(sample.file!, e.value)
          if (!valid) {
            return
          }
          const newState = state.map((val) => {
            if (val.type === sample.type) {
              return {
                ...val,
                file: undefined,
                s3Link: undefined,
                name: undefined,
              }
            }
            if (val.type === e.value) {
              return { ...val, file: sample.file, name: sample.file?.name }
            }
            return val
          })
          dispatch(newState)
        }}
        deleteFile={(e) => {
          const newState = state.map((val) => {
            if (val.type === sample.type) {
              return {
                ...val,
                file: undefined,
                s3Link: undefined,
                name: undefined,
              }
            }
            return val
          })
          dispatch(newState)
        }}
      />
    ))

  return (
    <>
      {viewFiles.length < state.length && (
        <DragAndDrop
          dispatch={async (file?: File) => {
            const unusedType = state.findIndex((val) => {
              return !val.file
            })
            if (!!file && unusedType !== -1) {
              const valid = await validate(file, state[unusedType].type)
              if (!valid) {
                return
              }
            }
            dispatch([
              ...state.slice(0, unusedType),
              { ...state[unusedType], file, name: file?.name },
              ...state.slice(unusedType + 1),
            ])
          }}
        />
      )}
      {viewFiles}
    </>
  )
}
