import { Button, Checkbox, Container, InputBlock } from '@components'
import { Grid } from '@mui/material'
import { Client } from '@twilio/conversations'
import * as React from 'react'
import { useState, useEffect, useRef } from 'react'
import {
  ContactCallText,
  ContactLabel,
  ContactOneCallTitle,
  ContactPhoneNumber,
  ContactTitle,
  PoweredBy,
  SuccessMessage,
} from './styled'
import { ErrorMessage } from '@/pages/product/styled'
import { IContactProps } from './types'
import MoneyIcon from '@assets/images/icons/MoneyIcon'
import StockIcon from '@assets/images/icons/StockIcon'
import InfoIcon from '@assets/images/icons/InfoIcon'
import IconCheck from './icon-check'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '@/app/hooks'
import storage from '@/utils/storage'
import ClipLoader from 'react-spinners/ClipLoader'
import { getTwilioClient } from '@/utils/twilio'
import { axios } from '@/lib/axios'
import twilioLogo from '@/assets/images/icons/twilio.png'

const Contact: React.FC<IContactProps> = ({
  companyName,
  phoneNumber,
  showIconsInfo,
  listingId,
  authorId,
  companyId,
  origin,
}) => {
  const [isDisabled, setIsDisabled] = useState(true)
  const [selectedContact, setSelectedContact] = useState('')
  const [message, setMessage] = useState('')
  const [termsApproved, setTermsApproved] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [successMessage, setSuccessMessage] = useState(false)
  const client = useRef<Client | undefined>()
  const conversationSid = useRef<string | undefined>()
  const userId = useAppSelector((state) => state.authUser.userId)
  const navigate = useNavigate()
  const baseUrl = process.env.REACT_APP_URL_BASE
  const token = storage.getToken()

  const iconsCheck = [
    {
      icon: MoneyIcon,
      contactName: 'quote',
    },
    {
      icon: StockIcon,
      contactName: 'sample',
    },
    {
      icon: InfoIcon,
      contactName: 'info',
    },
  ]

  useEffect(() => {
    const effect = async () => {
      if (userId && authorId && userId !== authorId) {
        const newClient = await getTwilioClient(userId)
        client.current = newClient
      }
    }
    effect()
  }, [])

  const createInquiry = async () => {
    if (token && userId) {
      if (origin === 'listing') {
        const res = axios
          .post(`${baseUrl}/api/v1/inquiry`, {
            buyerId: userId,
            listingId: listingId,
            companyId: companyId,
            message: message,
          })
          .then(function (response: any) {
            return response
          })
          .catch(function (error: any) {
            console.log(error)
          })
        return res
      } else {
        const res = axios
          .post(`${baseUrl}/api/v1/inquiry`, {
            buyerId: userId,
            companyId: companyId,
            message: message,
          })
          .then(function (response: any) {
            return response
          })
          .catch(function (error: any) {
            console.log(error)
          })
        return res
      }
    }
  }

  const sendMessage = async () => {
    if (termsApproved && message) {
      setButtonLoading(true)
      createInquiry().then(() => {
        setSuccessMessage(true)
        setButtonLoading(false)
      })
    } else {
      setErrorMessage(true)
    }
  }

  const handleOnChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value
    setMessage(value)
    setIsDisabled(!(value?.length > 0))
  }

  // const handleTypeOfContact = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   console.info(event.currentTarget.value, event.target.value)
  //   setSelectedContact(event.target.value)
  // }

  const handleTypeOfContactClick = (opt: string) => {
    setSelectedContact(opt)
  }

  const handleAcceptTerms = (e: React.BaseSyntheticEvent) => {
    if (e.target.checked === false) {
      setTermsApproved(false)
    } else {
      setTermsApproved(true)
      setErrorMessage(false)
    }
  }

  const renderIconsInfo = () => (
    <Grid
      item
      container
      spacing={2}
      xs={12}
      xl={10}
      style={{ maxWidth: '365px !important' }}
    >
      {iconsCheck.map((check) => (
        <IconCheck
          key={check.contactName}
          contactName={check.contactName}
          icon={check.icon}
          selectedContact={selectedContact}
          onClick={handleTypeOfContactClick}
        />
      ))}
    </Grid>
  )

  const isButtonDisabled = !selectedContact || !termsApproved || !message.length

  return (
    <>
      {userId !== authorId ? (
        <Container
          backgroundColor="#FFFF"
          minHeight="40vh"
          padding={'25px 16px'}
        >
          <ContactTitle>Connect anonymously and in real time</ContactTitle>
          <ContactLabel>What are you looking for?</ContactLabel>
          <Grid container spacing={1}>
            {showIconsInfo && renderIconsInfo()}

            <Grid item xs={12}>
              <ContactLabel>Your message for {companyName} </ContactLabel>
            </Grid>
            <Grid item xs={12}>
              <InputBlock
                type="text"
                onChange={handleOnChangeInput}
                isTextArea={true}
                maxHeight="300px"
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="primary"
                disabled={isButtonDisabled}
                onClick={sendMessage}
              >
                {buttonLoading ? (
                  <ClipLoader
                    color="#ffffff"
                    loading={buttonLoading}
                    size={15}
                  />
                ) : (
                  'SUBMIT REQUEST'
                )}
              </Button>
              {successMessage && (
                <SuccessMessage>
                  Your message has been succesfully sent to the provider
                </SuccessMessage>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              sx={{ display: 'flex-column', margin: '10px 0' }}
            >
              <Checkbox
                name="termOfService"
                label="I agree that this Provider and Kamba may contact me regarding my request."
                fontColor="#000000"
                topPosition="-8px"
                handleOnChange={(e) => handleAcceptTerms(e)}
              />
              <PoweredBy>
                <span>Powered by</span>
                <img src={twilioLogo} />
              </PoweredBy>
              {errorMessage && (
                <ErrorMessage>
                  You must accept our terms to contact this supplier
                </ErrorMessage>
              )}
            </Grid>

            {/* {phoneNumber && (
              <>
                <Grid item xs={12}>
                  <ContactOneCallTitle>
                    Need a one-one-call?
                  </ContactOneCallTitle>
                </Grid>
                <Grid item xs={12}>
                  <ContactCallText>Call {companyName} Now:</ContactCallText>
                  <ContactPhoneNumber>{phoneNumber}</ContactPhoneNumber>
                </Grid>
              </>
            )} */}
          </Grid>
        </Container>
      ) : (
        <ErrorMessage>You created this product</ErrorMessage>
      )}
    </>
  )
}

export default Contact
