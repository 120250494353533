import { transformToInt } from '@/utils/transform-to-int'
import { Tooltip } from '@mui/material'
import { useState } from 'react'
import { Checkbox } from '../../../../'
import { Label } from '../../styled'
import { BuyingFormatCheckDto } from '../types'
import {
  AbsoluteWrapper,
  Column,
  Input,
  Row,
  UnitText,
  CurrencyInput,
} from './styled'
import { CheckInputProps } from './types'

const CheckInput = ({
  index,
  checkboxLabel,
  inputLabel,
  value,
  state,
  onChange,
  forceFalse,
  disabled,
}: CheckInputProps) => {
  return (
    <Row
      style={{
        opacity: disabled ? '.3' : '',
        cursor: disabled ? 'not-allowed' : 'unset',
      }}
    >
      <>
        <Column
          maxWidth="163px"
          width="100%"
          style={{ pointerEvents: disabled ? 'none' : 'unset' }}
        >
          {index === 0 && (
            <AbsoluteWrapper top="-37px">
              <Label marginBottom="0">Format</Label>
            </AbsoluteWrapper>
          )}
          <Checkbox
            label={checkboxLabel}
            value={value}
            isChecked={forceFalse ? false : state.isActive}
            fontWeight="600"
            handleOnChange={(checked) => {
              onChange({
                ...state,
                isActive: forceFalse ? true : checked.target.checked,
              })
            }}
          />
        </Column>
        <Column style={{ pointerEvents: disabled ? 'none' : 'unset' }}>
          {index === 0 && (
            <AbsoluteWrapper top="-28px">
              <Label marginBottom="0">Price</Label>
            </AbsoluteWrapper>
          )}
          <CurrencyInput
            name="price"
            placeholder="e.g.: “100”"
            decimalsLimit={0}
            onValueChange={(value, name) =>
              onChange({
                ...state,
                price: value ? parseInt(value) : '',
              })
            }
            value={!!state.price ? `${state.price}` : ''}
            allowNegativeValue={false}
            allowDecimals={false}
            prefix=""
          />
          {/* <Input
            name="price"
            placeholder="e.g.: “100”"
            value={!!state.price ? `${state.price}` : null}
            onChange={(e) => {
              onChange({
                ...state,
                price: transformToInt(e.target.value),
              })
            }}
            disabled={disabled}
            onKeyDown={(e) => {
              const whiteList = [
                'ArrowLeft',
                'ArrowRight',
                'ArrowUp',
                'ArrowDown',
                'Backspace',
              ]
              const reg = new RegExp(/[0-9]/)
              if (!reg.test(e.key) && !whiteList.includes(e.key)) {
                e.preventDefault()
              }
            }}
          /> */}
        </Column>
        <UnitText style={{ pointerEvents: disabled ? 'none' : 'unset' }}>
          {inputLabel}
        </UnitText>
        {index === 3 && (
          <Input
            name="unit"
            placeholder="e.g.: “API call”"
            value={!!state.unit ? `${state.unit}` : ''}
            maxWidth="154px"
            onChange={(e) => {
              onChange({ ...state, unit: e.target.value })
            }}
            style={{ pointerEvents: disabled ? 'none' : 'unset' }}
          />
        )}
      </>
    </Row>
  )
}

export default CheckInput
