import styled from 'styled-components'
import { ITitleProps } from './types'

type TitleProps = Omit<ITitleProps, 'text' | 'boldText'>

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const TitleStyled = styled.h2<TitleProps>`
  font-size: ${({ fontSize }) => fontSize || '35px'};
  color: ${({ color }) => color || '#404040'};
  ${({ margin }) => margin && `margin:${margin};`}
  font-weight: 400;
`

export const BoldTitle = styled.p<TitleProps>`
  font-size: ${({ fontSize }) => fontSize || '35px'};
  color: ${({ color }) => color || '#000000'};
  font-weight: 700;
`

export const Description = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 34px;
  margin-top: 5px;
  color: rgba(64, 64, 64, 0.7);
  display: block;
`
