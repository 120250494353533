import { AuthUserInterface } from '@/slices/authUserSlice'

const storagePrefix = 'kamba_react_'

const storage = {
  getImpersonation: (): AuthUserInterface | null => {
    return JSON.parse(
      window.localStorage.getItem(`${storagePrefix}impersionation`) as string,
    )
  },
  setImpersonation: (adminUser: AuthUserInterface) => {
    window.localStorage.setItem(
      `${storagePrefix}impersionation`,
      JSON.stringify(adminUser),
    )
  },
  clearImpersonation: () => {
    window.localStorage.removeItem(`${storagePrefix}impersionation`)
  },
  getUserAdmin: () => {
    return JSON.parse(
      window.localStorage.getItem(`${storagePrefix}isAdmin`) as string,
    )
  },
  setUserAdmin: (isAdmin: boolean) => {
    window.localStorage.setItem(
      `${storagePrefix}isAdmin`,
      JSON.stringify(isAdmin),
    )
  },
  clearUserAdmin: () => {
    window.localStorage.removeItem(`${storagePrefix}isAdmin`)
  },
  getToken: () => {
    return JSON.parse(
      window.localStorage.getItem(`${storagePrefix}token`) as string,
    )
  },
  setToken: (token: string) => {
    window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token))
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}token`)
  },
  getRefreshToken: () => {
    return JSON.parse(
      window.localStorage.getItem(`${storagePrefix}refreshToken`) as string,
    )
  },
  setRefreshToken: (token: string) => {
    window.localStorage.setItem(
      `${storagePrefix}refreshToken`,
      JSON.stringify(token),
    )
  },
  clearRefreshToken: () => {
    window.localStorage.removeItem(`${storagePrefix}refreshToken`)
  },
  getUserId: () => {
    return JSON.parse(
      window.localStorage.getItem(`${storagePrefix}userId`) as string,
    )
  },
  setUserId: (userId: string) => {
    window.localStorage.setItem(
      `${storagePrefix}userId`,
      JSON.stringify(userId),
    )
  },
  clearUserId: () => {
    window.localStorage.removeItem(`${storagePrefix}userId`)
  },
  getUserRole: () => {
    return JSON.parse(
      window.localStorage.getItem(`${storagePrefix}userRole`) as string,
    )
  },
  setUserRole: (userRole: string) => {
    window.localStorage.setItem(
      `${storagePrefix}userRole`,
      JSON.stringify(userRole),
    )
  },
  getUserMail: (real = false) => {
    const impersonation = storage.getImpersonation()
    if (real && impersonation && impersonation.userMail) {
      return impersonation.userMail
    }

    return JSON.parse(
      window.localStorage.getItem(`${storagePrefix}userMail`) as string,
    )
  },
  setUserMail: (userMail: string) => {
    window.localStorage.setItem(
      `${storagePrefix}userMail`,
      JSON.stringify(userMail),
    )
  },
  clearUserRole: () => {
    window.localStorage.removeItem(`${storagePrefix}userRole`)
  },
  getCompanyId: () => {
    return JSON.parse(
      window.localStorage.getItem(`${storagePrefix}companyId`) as string,
    )
  },
  setCompanyId: (companyId: string) => {
    window.localStorage.setItem(
      `${storagePrefix}companyId`,
      JSON.stringify(companyId),
    )
  },
  clearCompanyId: () => {
    window.localStorage.removeItem(`${storagePrefix}companyId`)
  },
  getDashboard: () => {
    return JSON.parse(
      window.localStorage.getItem(`${storagePrefix}dashboard`) as string,
    )
  },
  setDashboard: (dashboard: string) => {
    window.localStorage.setItem(
      `${storagePrefix}dashboard`,
      JSON.stringify(dashboard),
    )
  },
  clearDashboard: () => {
    window.localStorage.removeItem(`${storagePrefix}dashboard`)
  },
  getStripeLoading: () => {
    return JSON.parse(
      window.localStorage.getItem(`${storagePrefix}stripeLoading`) as string,
    )
  },
  setStripeLoading: (stripeLoading: string) => {
    window.localStorage.setItem(
      `${storagePrefix}stripeLoading`,
      JSON.stringify(stripeLoading),
    )
  },
  clearStripeLoading: () => {
    window.localStorage.removeItem(`${storagePrefix}stripeLoading`)
  },
  getCognitoId: () => {
    return JSON.parse(
      window.localStorage.getItem(`${storagePrefix}cognitoId`) as string,
    )
  },
  setCognitoId: (userId: string) => {
    window.localStorage.setItem(
      `${storagePrefix}cognitoId`,
      JSON.stringify(userId),
    )
  },
  clearCognitoId: () => {
    window.localStorage.removeItem(`${storagePrefix}cognitoId`)
  },
  clearAll: () => {
    window.localStorage.clear()
  },
  clearData: () => {
    storage.clearToken()
    storage.clearRefreshToken()
    storage.clearCompanyId()
    storage.clearCognitoId()
    storage.clearDashboard()
    storage.clearStripeLoading()
    storage.clearUserRole()
    storage.clearUserId()
    storage.clearImpersonation()
    storage.clearUserAdmin()
  },
}

export default storage
