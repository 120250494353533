import { Grid, styled } from '@mui/material'
import { GridItemBorderProps } from './types'

export const GridItemBorder = styled(Grid)<GridItemBorderProps>`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-right: ${({ border }) => border || '1px solid #d9d9d9'};
`
