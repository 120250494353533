import styled from 'styled-components'

export const Button = styled.button.attrs(({ type }) => ({
  type: type || 'button',
}))`
  border: 1px solid #000000;
  background-color: #000000;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  padding: 11px 50px;
  height: 55px;
  cursor: pointer;
  margin: 0;
  width: fit-content;
  max-width: 230px;
  text-transform: uppercase;
`

export const DialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  max-width: 552px;
  height: fit-content;
  padding: 36px;
  position: relative;
`
