import { CollapsibleCheckGroup, GradientSeparator, Title } from '@/components'
import GeoMap from '@/components/geo-map'
import { CountryDto } from '@/data/geographicData'
import withScrollToElement from '@/hocs/withScrollToElement'
import WithVisibility from '@/hocs/withVisibility'
import { findCountriesByString } from '@/utils/countryFind'
import { Grid } from '@mui/material'
import * as React from 'react'
import { useState } from 'react'

interface IGeoCoverageProps {
  countries: string[]
}

const GeoCoverage: React.FunctionComponent<IGeoCoverageProps> = (props) => {
  const [selectedCountries, setSelectedCountries] = useState<string[]>([])

  const countriesDataList = findCountriesByString(props.countries)
  const countriesData = countriesDataList.reduce(
    (dic, country): Record<string, CountryDto[]> => {
      if (!dic[country.continent]) {
        dic[country.continent] = []
      }
      dic[country.continent].push(country)
      return dic
    },
    {} as Record<string, CountryDto[]>,
  )
  //TODO: defaultSelected data for GeoMap ==> selectedGeographicalData

  const handleSelectedCountries = (value: string) => {
    const index = selectedCountries.indexOf(value)
    const selectedIds =
      index >= 0
        ? selectedCountries.filter((country) => country !== value)
        : [...selectedCountries, value]
    setSelectedCountries(selectedIds)
  }

  return (
    <Grid container>
      <Title margin="0 0 15px 0" boldText="Geo Coverage" fontSize="30px" />
      <GeoMap
        height="500px"
        selectedCountriesId={countriesDataList.map((val) =>
          val.code.toUpperCase(),
        )}
      />
      <Grid item>
        {countriesData &&
          Object.keys(countriesData).map((key, index) => (
            <CollapsibleCheckGroup
              origin={'product'}
              key={index}
              label={key.charAt(0).toUpperCase() + key.slice(1)}
              data={countriesData[key]}
              //defaultData={selectedGeographicalData}
              handleOnChange={handleSelectedCountries}
            />
          ))}
      </Grid>
      <GradientSeparator margin="30px 0 0 0" />
    </Grid>
  )
}

export default WithVisibility(withScrollToElement(GeoCoverage, 'coverage'))
