import { SupportedFileTypesDiv } from './styled'

const SupportedFileTypes = () => {
  return (
    <SupportedFileTypesDiv>
      <b>Supported file types</b>
      <p>
        At the moment we only support files with the following file extensions:
        .csv. Please mak sure that your files are in a valid format and encoded
        in UTF-8 before uploading
      </p>
    </SupportedFileTypesDiv>
  )
}

export default SupportedFileTypes
