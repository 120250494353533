import * as React from 'react'
import { useState } from 'react'
import { Button, DialogWrapper } from './styled'
import Dialog from '@mui/material/Dialog'
import A from '@mui/material/'
import { IModalProps } from './types'
import CloseIcon from '@assets/images/close'

const Modal: React.FC<IModalProps> = ({ buttonLabel, children }) => {
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const childrenWithProps = () =>
    React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { ...child.props })
      }
      return child
    })

  return (
    <div>
      <Button onClick={handleClickOpen}>{buttonLabel}</Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogWrapper>
          <img
            src={CloseIcon}
            alt="close button"
            onClick={handleClose}
            style={{ alignSelf: 'flex-end', cursor: 'pointer' }}
          />
          {childrenWithProps()}
        </DialogWrapper>
      </Dialog>
    </div>
  )
}

export default Modal
