import { DeleteFile, FileInfo, FileWrapper, ViewFileWrapper } from './styled'
import ExplorerFile from '@assets/images/icons/PostingIcon.svg'
// import { Divider } from '../../styled'
import RedCloseIcon from '@assets/images/icons/red-close-icon.svg'
import { ViewFilesProps } from './types'
import { useState } from 'react'
import { Divider } from '@/pages/dataset-create-dataset/styled'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

const ViewFile = ({ data, deleteFile, hideData }: ViewFilesProps) => {
  const sizeKb = data.size / 1000
  const sizeMB = sizeKb / 1000
  return (
    <ViewFileWrapper>
      <FileWrapper>
        <FileInfo>
          <img src={data.preview} />
          {hideData && (
            <div>
              <span>{data.name}</span>
              <p>
                {sizeMB >= 1
                  ? `${sizeMB.toFixed(2)}MB`
                  : `${sizeKb.toFixed(2)}KB`}
              </p>
            </div>
          )}
        </FileInfo>
        <DeleteFile onClick={() => deleteFile()}>
          <DeleteOutlineIcon />
        </DeleteFile>
      </FileWrapper>
      <Divider />
    </ViewFileWrapper>
  )
}

export default ViewFile
