import checkShieldIcon from '@assets/images/icons/IconShield'
import styled from 'styled-components'

export const ProfileImage = styled.img`
  width: 100%;
  height: auto;
`
export const VerifiedLabel = styled.span`
  font-size: 11px;
  font-weight: 700;
  color: #2fa24f;
  line-height: 34px;
`
export const TotalScore = styled.span`
  color: #000000;
  font-weight: 600;
  font-size: 22px;
  line-height: 34px;
`

export const CheckShieldIcon = styled.div`
  background-image: url(${checkShieldIcon});
  background-repeat: no-repeat;
  color: #2fa24f;
  width: 22px;
  height: 30px;
`

export const BorderBlock = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
  border-right: 1px solid #d9d9d9;
`

export const CompanyName = styled.span`
  font-size: 30px;
  font-weight: 700;
  color: #000000;
  /* white-space: pre; */
`

export const CompanyTitle = styled.h1`
  color: #404040;
  line-height: 41px;
  font-weight: 400;
  font-size: 35px;
`

export const MetricList = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  height: 100%;

  li:last-child {
    .gridItemBorder {
      border: none;
    }
  }
`
