import { GradientSeparator, Title } from '@/components'
import { createMarkup } from '@/utils/purify-html'
import { Grid } from '@mui/material'
import * as React from 'react'
import { KeyDiffText, KeyDiffTitle } from './styled'
import { IKeyDifferentiorsProps } from './types'

const KeyDifferentiors: React.FunctionComponent<IKeyDifferentiorsProps> = ({
  title,
  text,
}) => {
  return (
    // <Grid container spacing={1} sx={{ marginTop: '20px' }}>
    <>
      <Title
        margin="0 0 15px 0"
        boldText="Key Differentiators"
        fontSize="30px"
      />
      <KeyDiffTitle>{title}</KeyDiffTitle>
      <KeyDiffText dangerouslySetInnerHTML={createMarkup(text)} />
      <GradientSeparator margin="30px 0" />
    </>
    // </Grid>
  )
}

export default KeyDifferentiors
