import styled from 'styled-components'

export const FlexButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: pre;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`
export const KambaSupport = styled.div`
  white-space: pre;
  margin-right: 10px;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
`
