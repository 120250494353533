import { FilterRedirect, GradientSeparator, Title } from '@/components'
import WithScrollToElement from '@/hocs/withScrollToElement'
import * as React from 'react'
import { DeliveryContainer } from './styled'
//import { IDeliveryProps } from './types'

interface IDeliveryProps {
  methods: any
  text: string
  boldText: string
  filters: string[]
  columnSize?: any
  isSeparatorEnabled?: boolean
  marginContainer?: any
}

const Delivery: React.FC<IDeliveryProps> = ({ methods }) => {
  // console.log(methods)
  return (
    <DeliveryContainer>
      <Title boldText="Delivery" fontSize="30px" />
      {methods.map((method: any, i: number) => (
        <FilterRedirect
          key={i}
          filters={method.filters}
          text={method.text}
          isSeparatorEnabled={false}
          marginContainer="30px 0"
          fontSize="24px"
        />
      ))}
      <GradientSeparator margin="30px 0" />
    </DeliveryContainer>
  )
}

export default WithScrollToElement(Delivery, 'delivery')
