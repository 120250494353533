import { useQuery, useMutation } from 'react-query'
import {
  acceptTermsAndConditions,
  getDashboard,
  setupStripeAccount,
  getCompany,
  searchCompanyProfiles,
  getCompanyByDomain,
  acceptFeePolicy,
} from './api/suppliers'
import { SupplierDashboardQuery } from './types'
import { ExtractFnReturnType, QueryConfig } from '@lib/react-query'
import e from 'express'

type GetDashboardQueryFnType = typeof getDashboard
type GetDashboardQueryConfigFn = QueryConfig<GetDashboardQueryFnType>

export const useGetDashboard = ({
  userId,
  enabled,
}: SupplierDashboardQuery<GetDashboardQueryConfigFn>) => {
  return useQuery<ExtractFnReturnType<GetDashboardQueryFnType>>({
    queryKey: ['dashboard'],
    queryFn: () => getDashboard(userId),
    enabled: enabled,
    refetchOnMount: true,
  })
}

export const useSetupStripeAccount = (onSuccess: () => void) => {
  return useMutation(setupStripeAccount, {
    onSuccess: () => {
      onSuccess()
    },
  })
}

export const useGetCompany = (id: string, enabled: boolean) => {
  return useQuery({
    queryKey: ['company'],
    queryFn: () => getCompany(id),
    enabled: enabled,
    refetchOnWindowFocus: false,
  })
}

export const useGetCompanyByDomain = (domain: string, enabled: boolean) => {
  return useQuery({
    queryKey: ['company', 'domain'],
    queryFn: () => getCompanyByDomain(domain),
    enabled: enabled,
    refetchOnWindowFocus: false,
  })
}

export const useAcceptTermsAndConditions = (onSuccess: () => void) => {
  return useMutation({
    mutationKey: ['acceptTermsAndConditions'],
    mutationFn: (companyProfileId: string) =>
      acceptTermsAndConditions({
        companyProfileId: companyProfileId,
      }),
    onSuccess: () => onSuccess(),
  })
}

export const useSearchCompanies = ({ ...params }: any) => {
  return useQuery({
    queryKey: ['company', params.from, { ...params }],
    queryFn: () => searchCompanyProfiles({ ...params }),
  })
}

export const useAcceptFeePolicy = (onSuccess: () => void) => {
  return useMutation({
    mutationKey: ['acceptFeePolicy'],
    mutationFn: (companyProfileId: string) =>
      acceptFeePolicy({
        companyProfileId: companyProfileId,
      }),
    onSuccess: () => onSuccess(),
  })
}
