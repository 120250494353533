import { useForm } from 'react-hook-form'
import { useAppDispatch } from '../../app/hooks'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import ClipLoader from 'react-spinners/ClipLoader'
import { loginPending, loginSuccess, loginFail } from '../../slices/loginSlice'
import {
  Container,
  LoginWrapper,
  Logo,
  LoginCard,
  InputBlock,
} from '../../components'
import { Divider, Title, FormGroup, Button, Success } from './styled'
import { useState } from 'react'
import { Error } from '../create-account/styled'
import { useNavigate } from 'react-router-dom'

type PassData = {
  password: string
  confirmPassword: string
}

const axios = require('axios').default

const schema = yup
  .object({
    password: yup.string().required('Password is required'),
    confirmPassword: yup
      .string()
      .required('Confirm Password is required')
      .oneOf([yup.ref('password')], 'Passwords does not match'),
  })
  .required()

const ChangePassword = () => {
  const baseUrl = process.env.REACT_APP_URL_BASE
  const dispatch = useAppDispatch()
  const params = new URLSearchParams(window.location.search)
  const username = params.get('username')
  const code = params.get('code')
  const [error, setError] = useState('')

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<PassData>({
    resolver: yupResolver(schema),
  })

  const passwordRegister = { ...register('password') }
  const confirmPasswordRegister = { ...register('confirmPassword') }

  const fetchRecoverPass = ({ password, confirmPassword }: PassData) => {
    const res = axios
      .post(`${baseUrl}/api/v1/new-password`, {
        username: username,
        code: code,
        newPassword: password,
      })
      .then(function (response: any) {
        return response
      })
      .catch(function (error: any) {
        setError(error.response.data.response)
        return error
      })

    return res
  }
  const navigate = useNavigate()
  const [success, setSuccess] = useState(false)
  const onSubmit = handleSubmit(async (data: PassData) => {
    dispatch(loginPending())
    try {
      const isAuth = await fetchRecoverPass(data)
      if (isAuth.message) {
        return dispatch(loginFail(isAuth.message))
      }
      dispatch(loginSuccess())
      setSuccess(true)
      setTimeout(() => {
        navigate('/login')
      }, 10000)
    } catch (error) {
      dispatch(loginFail('error'))
    }
  })

  return (
    <Container>
      <LoginWrapper>
        <Logo />
        <LoginCard maxWidth="528px">
          <Divider />
          <Title>Change Password</Title>
          <FormGroup onSubmit={onSubmit}>
            <InputBlock
              label="New Password"
              type="password"
              onChange={register('password').onChange}
              params={passwordRegister}
              errorMessage={errors.password?.message}
              showStrenghtBar
              isPassword
            />
            <InputBlock
              isPassword
              label="Confirm Password"
              type="password"
              params={confirmPasswordRegister}
              onChange={register('confirmPassword').onChange}
              errorMessage={errors.confirmPassword?.message}
            />
            <Button type="submit">
              {isSubmitting ? (
                <ClipLoader color="#ffffff" loading={isSubmitting} size={15} />
              ) : (
                `Submit`
              )}
            </Button>
            {error && <Error>An error ocurred. Please check your email.</Error>}
            {success && (
              <Success>Your password has been successfully updated!</Success>
            )}
          </FormGroup>
        </LoginCard>
      </LoginWrapper>
    </Container>
  )
}

export default ChangePassword
