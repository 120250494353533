// import { cardsData } from '@/mocks/listings/card-listings'
import { Grid } from '@mui/material'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { ICardProps } from '../../ui/card/types'
import { useSearchListing } from '@/services/listing/listing.service'
import { getCardsListings } from '@services/listing/selectors'
import Card from '../../ui/card'
import { useDashboardContext } from '@/providers/dashboard/hooks/useDashboardContext'
import GridCards from '../grid-cards'
import { PaginationComponent } from '@/components/pagination'
import { axios } from '@/lib/axios'

const baseUrl = process.env.REACT_APP_URL_BASE

export const ListingGridCards: React.FC<any> = ({
  filterTitle,
  injectFilter,
  currentTab,
}) => {
  const { params: selectedFilters, setParams } = useDashboardContext()
  const [page, setPage] = React.useState(1)
  const [filters, setFilters] = useState<Record<string, string[]>>({})
  const [totalPages, setTotalPages] = React.useState<number | undefined>(
    undefined,
  )

  const obtainFilters = async () => {
    const res = await axios.get(`${baseUrl}/api/v1/categories`)
    if (res.ok) {
      delete res.data.licence
      setFilters({ ...res.data })
    }
  }

  const sizeItems = 30

  const dataObject = useSearchListing({
    select: getCardsListings,
    ...{
      ...selectedFilters,
      ...currentTab,
      size: sizeItems,
      from: (page - 1) * sizeItems,
    },
  })
  //This useEffect is here since FilterGrid doesnt reset the filter params
  React.useEffect(() => {
    setParams({ title: selectedFilters?.title })
    obtainFilters()
  }, [])

  React.useEffect(() => {
    setPage(1)
  }, [selectedFilters])

  React.useEffect(() => {
    const dataCount = dataObject.data?.count
    if (dataCount) {
      setTotalPages(Math.ceil(dataCount / sizeItems))
    }
  }, [dataObject.data?.count])
  const cardsData = dataObject.data?.cards as ICardProps[]

  const content = cardsData
    ? cardsData.map((card) => (
        <Grid item xs={4} lg={4} md={6} sm={10} key={card.id}>
          <Card
            title={card.title}
            companyName={card.companyName}
            description={card.description}
            verified={card.verified}
            isInstantPurchase={card.isInstantPurchase}
            id={card.id}
          />
        </Grid>
      ))
    : []

  return (
    <>
      <GridCards
        productCount={{
          from: (page - 1) * sizeItems + 1,
          to:
            page * sizeItems < dataObject.data?.count
              ? page * sizeItems
              : dataObject.data?.count,
          total: dataObject.data?.count,
        }}
        filterAttributes={filters}
        injectFilter={injectFilter}
        filterTitle={filterTitle}
        currentTab={currentTab}
        dataObject={dataObject}
        cards={content}
      />
      {content.length > 0 && (
        <PaginationComponent
          totalPages={totalPages}
          page={page}
          setPage={setPage}
        />
      )}
    </>
  )
}
