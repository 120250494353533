import styled from 'styled-components'
import { ContainerProps } from './types'

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  height: ${({ height }) => height || '100vh'};
  width: 100%;
  overflow: hidden;
`
