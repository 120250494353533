import styled from 'styled-components'

export const SupportedFileTypesDiv = styled.div`
  width: 100%;
  padding: 1rem 2rem;
  margin-top: 2rem;
  background-color: white;
  border-top: solid 1rem #bcbeb6;
  p {
    margin-top 15px;
    font-size: 14px;
    color: #5c5c5c;
  }
`
