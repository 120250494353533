import { styled as styledMaterial } from '@mui/material/styles'
import styled from 'styled-components'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'

export const StyledTitleColumn = styled.span`
  font-weight: 800;
  font-family: Proxima Nova;
  font-size: 16px;
  line-height: 25px;
  color: #858585;
`

export const StyledAttributeName = styled.span`
  font-family: inherit;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
`

export const StyledTableCell = styledMaterial(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

export const StyledTableRow = styledMaterial(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))
