import { useEffect, useState, useRef } from 'react'
import { Sidebar, AppBarHeader } from '@/components'
import {
  AvatarIcon,
  ColLeft,
  ColRight,
  Columns,
  Container,
  Header,
  HeaderColLeft,
  MissingBackground,
  PageHeaderWrapper,
  Section,
  SectionHR,
  SectionInfo,
  SectionTitle,
  SectionWrapper,
  StageBackground,
  TabsStyled,
  UserAvatar,
} from './styled'
import { useAppSelector } from '@/app/hooks'
import { Client, Conversation } from '@twilio/conversations'
import storage from '@/utils/storage'
import { useNavigate, useParams } from 'react-router'
import { getTwilioClient } from '@/utils/twilio'
import { axios } from '@/lib/axios'
import DealChat from '../deal-chat'
import Arrow from '@assets/images/icons/arrow-left'
import Warning from '@assets/images/icons/alert-triangle'
import { TabsBase } from '@/components/tabs-navigation/types'
import { Tab } from '@mui/material'
import UpdateDealModal from '../update-deal-modal'

const DealConversation = () => {
  const [activeTab, setActiveTab] = useState('messages')
  const [renderFlag, setRenderFlag] = useState(false)
  const [loading, setLoading] = useState(true)
  const client = useRef<Client | undefined>()
  const conversation = useRef<Conversation | undefined>()
  const deal = useRef<any>()
  const userId = useAppSelector((state) => state.authUser.userId)
  const userMail = useAppSelector((state) => state.authUser.userMail)
  const navigate = useNavigate()
  const { dealId } = useParams()
  const token = storage.getToken()
  const baseUrl = process.env.REACT_APP_URL_BASE

  const tabs: TabsBase[] = [
    { label: 'Messages', id: '1' },
    { label: 'Legal & Compliance', id: '2' },
    { label: 'Activity', id: '3' },
  ]

  useEffect(() => {
    const effect = async () => {
      if (!!token && !!userId) {
        const currentDeal = await getDealById()
        deal.current = currentDeal.data
        const newClient = await getTwilioClient(userId)
        client.current = newClient
        if (deal.current.TwilioConversationId) {
          conversation.current = await newClient.getConversationBySid(
            deal.current.TwilioConversationId,
          )
        }
        setLoading(false)
        setRenderFlag(false)
      }
    }
    effect()
  }, [renderFlag])

  const getDealById = () => {
    const res = axios
      .get(`${baseUrl}/api/v1/deals/${dealId}`)
      .then(function (response: any) {
        return response
      })
      .catch(function (error: any) {
        return error
      })
    return res
  }

  const createAvatar = () => {
    let avatar = userMail.slice(0, 2)
    return avatar
  }

  const missingInfo = (
    <SectionInfo>
      <MissingBackground>
        <img
          src={Warning}
          alt="arrow"
          style={{
            width: '16px',
            height: '16px',
          }}
        />
        Missing
      </MissingBackground>
    </SectionInfo>
  )

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue)
  }

  return (
    <Container>
      <Sidebar />
      <Container flexDirection="column">
        <AppBarHeader />
        <Container flexDirection="column">
          {!loading && (
            <>
              <Header>
                <HeaderColLeft>
                  <img
                    src={Arrow}
                    alt="arrow"
                    style={{
                      width: '26px',
                      height: '26px',
                      cursor: 'pointer',
                      marginRight: '10px',
                    }}
                    onClick={() => navigate('/deals')}
                  />
                  Your Deal with {deal?.current?.buyerId?.usernameBuyer}
                </HeaderColLeft>
                <UpdateDealModal />
              </Header>
              <Columns>
                <ColLeft>
                  <UserAvatar>
                    <AvatarIcon>{createAvatar()}</AvatarIcon>
                    <div>
                      <SectionTitle>Job title</SectionTitle>
                      <SectionInfo>{userMail}</SectionInfo>
                    </div>
                  </UserAvatar>
                  <SectionWrapper>
                    <Section>
                      <SectionTitle>Deal Owner</SectionTitle>
                      <SectionInfo>
                        {deal?.current?.buyerId?.usernameBuyer}
                      </SectionInfo>
                    </Section>
                    <Section>
                      <SectionTitle>Progress</SectionTitle>
                      <SectionInfo>
                        <StageBackground>
                          {deal?.current?.stage}
                        </StageBackground>
                      </SectionInfo>
                    </Section>
                    <Section>
                      <SectionTitle>Deal Size</SectionTitle>
                      {deal.current.dealSize ? (
                        <SectionInfo>{deal?.current?.stage}</SectionInfo>
                      ) : (
                        missingInfo
                      )}
                    </Section>
                    <Section>
                      <SectionTitle>Closing Confidence</SectionTitle>
                      {deal.current.closingConfidence ? (
                        <SectionInfo>
                          {deal?.current?.closingConfidence}
                        </SectionInfo>
                      ) : (
                        missingInfo
                      )}
                    </Section>
                    <Section>
                      <SectionTitle>Estimated Closing Date</SectionTitle>
                      {deal.current.estimatedClosingDate ? (
                        <SectionInfo>
                          {deal?.current?.estimatedClosingDate}
                        </SectionInfo>
                      ) : (
                        missingInfo
                      )}
                    </Section>
                    <SectionHR />
                    {deal.current.intent && (
                      <Section>
                        <SectionTitle>Intent</SectionTitle>
                        <SectionInfo>{deal?.current?.intent}</SectionInfo>
                      </Section>
                    )}
                    {deal.current.listingId && (
                      <>
                        <Section>
                          <SectionTitle>Product</SectionTitle>
                          <SectionInfo>{deal?.current?.listing}</SectionInfo>
                        </Section>
                        <SectionHR />
                      </>
                    )}
                    <Section>
                      <SectionTitle>Buyer Company</SectionTitle>
                      <SectionInfo>
                        {deal?.current?.buyerId?.usernameBuyer}
                      </SectionInfo>
                    </Section>
                    {deal?.current?.buyerObject?.headquarters && (
                      <Section>
                        <SectionTitle>Country</SectionTitle>
                        <SectionInfo>
                          {deal?.current?.buyerObject?.headquarters}
                        </SectionInfo>
                      </Section>
                    )}
                    {deal?.current?.buyerObject?.industry && (
                      <Section>
                        <SectionTitle>Industry</SectionTitle>
                        <SectionInfo>
                          {deal?.current?.buyerObject?.industry}
                        </SectionInfo>
                      </Section>
                    )}
                  </SectionWrapper>
                </ColLeft>
                <ColRight>
                  <PageHeaderWrapper>
                    <TabsStyled value={activeTab} onChange={handleChange}>
                      <Tab id={1} value={'messages'} label={'messages'} />
                      <Tab id={2} value={'legal'} label={'legal'} />
                      <Tab id={3} value={'activity'} label={'activity'} />
                    </TabsStyled>
                  </PageHeaderWrapper>
                  {deal.current.origin === 'proposal' &&
                    activeTab === 'messages' && (
                      <DealChat
                        key={conversation?.current?.sid}
                        id={conversation?.current?.sid}
                        conversation={conversation?.current}
                        client={client.current}
                        renderFlag={setRenderFlag}
                        deal={deal.current}
                      />
                    )}
                  {deal.current.origin === 'inquiry' &&
                    activeTab === 'messages' && (
                      <DealChat
                        key={conversation?.current?.sid}
                        id={conversation?.current?.sid}
                        conversation={conversation?.current}
                        client={client.current}
                        renderFlag={setRenderFlag}
                        firstMessage={deal?.current?.message}
                        deal={deal.current}
                      />
                    )}
                </ColRight>
              </Columns>
            </>
          )}
        </Container>
      </Container>
    </Container>
  )
}

export default DealConversation
