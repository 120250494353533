import styled from 'styled-components'

export const Container = styled.div`
  padding: 45px 110px;
`

export const Title = styled.h1`
  font-weight: 700;
  font-size: 30px;
  color: #000000;
  width: auto;
`

export const Description = styled.p`
  margin-top: 8px;
  font-size: 20px;
  font-weight: 400;
  color: #757575;
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 16px;
  align-items: center;
  margin-right: 7.5%;
`
