import styled from 'styled-components'

export const Wrapper = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  text-align: center;
  letter-spacing: -0.025em;
  color: #9e9e9e;
`

export const TextWrapper = styled.div`
  margin: 24px auto 0;
  max-width: 350px;
`
