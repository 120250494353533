import React from 'react'
import {
  Colaborate,
  Contact,
  Invite,
  NeedSupport,
  Profile,
  ResourcesContainer,
  ProfilePic,
  ProfileInfo,
  StyledText,
} from './styled'

function Resources() {
  const supportEmail = process.env.SUPPORT_EMAIL

  return (
    <ResourcesContainer>
      {/* <Colaborate>
        <StyledText>Invite a Team Member</StyledText>
        <StyledText>Collaborate to make the most of Kamba</StyledText>
      </Colaborate>
      <Invite>Invite a Team Member</Invite> */}
      <NeedSupport>Need Support?</NeedSupport>
      <Profile>
        <ProfilePic />
        <ProfileInfo>Kamba Customer Support</ProfileInfo>
      </Profile>
      <Contact>Email: {supportEmail}</Contact>
    </ResourcesContainer>
  )
}

export default Resources
