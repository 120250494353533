import Icon from '../icon'
import {
  FileTypes,
  IconWrapper,
  InputFile,
  Instructions,
  RestrictionList,
  UploadContainer,
  UploadWrapper,
} from './styled'
import upload from '@assets/images/icons/upload.png'
import { IUploadProps } from './types'
import { ClipLoader } from 'react-spinners'

const UploadInput: React.FC<IUploadProps> = ({
  fileTypes,
  restrictionList,
  dispatch,
  type,
  isLoading,
}) => {
  const restrictions = () =>
    restrictionList?.map((restriction, index) => (
      <li key={index}>{restriction}</li>
    ))

  const handleDragEnter = (event: any) => {
    event.preventDefault()
  }

  const handleDragOver = (event: any) => {
    event.preventDefault()
  }

  const handleDrop = (event: any) => {
    event.preventDefault()
    let files = [...event.dataTransfer.files]

    files.map((file) => {
      file['preview'] = URL.createObjectURL(file)
    })

    if (files) {
      dispatch(files[0], type)
    }
  }

  const setFile = (event: any) => {
    const files = [...event.target.files]

    files.map((file) => {
      file['preview'] = URL.createObjectURL(file)
    })

    dispatch(files[0], type)
  }

  return (
    <UploadContainer>
      <UploadWrapper
        onDrop={(event) => handleDrop(event)}
        onDragOver={(event) => handleDragOver(event)}
        onDragEnter={(event) => handleDragEnter(event)}
      >
        <InputFile type="file" onChange={setFile} />
        {isLoading ? (
          <ClipLoader size={15} />
        ) : (
          <>
            <IconWrapper>
              <Icon src={upload} padding="20px"></Icon>
            </IconWrapper>
            <Instructions>
              <span>Click to upload </span>
              <span>or drag and drop</span>
            </Instructions>
            <FileTypes>{fileTypes}</FileTypes>
          </>
        )}
      </UploadWrapper>
      <RestrictionList>{restrictions()}</RestrictionList>
    </UploadContainer>
  )
}

export default UploadInput
