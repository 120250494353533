import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  height: 68.5vh;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`

export const Header = styled.div`
  width: 100%;
  padding: 24px 36px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
`

export const Column = styled.div`
  width: 100%;
  max-width: 265px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: #000000;
`

export const ColumnWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-left: 24px;
  justify-content: space-between;
  column-gap: 32px;
`

export const Title = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.035em;
  color: #717171;
`

export const ChatWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 24px 24px 80px;
  row-gap: 24px;
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  overflow-y: scroll;
`

export const InputWrapper = styled.div`
  background: #ffffff;
  width: 100%;
  padding: 12px 17px;
  display: flex;
  justify-content: space-between;
`

export const Button = styled.button`
  background-color: #000000;
  border: 2px solid #ffffff;
  color: #ffffff;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  padding: 12px 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 43px;
`

export const Input = styled.input`
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  color: #000000b2;
  background: #ffffff;
  border: 1px solid transparent;
  width: 60%;
  &:focus {
    border: 1px solid transparent;
  }
  &:focus-visible {
    border: 1px solid transparent;
    outline: 1px solid transparent;
  }
`

export const MessagesWrapper = styled.div`
  width: calc(100% - 48px);
  height: fit-content;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 80px;
  left: 24px;
`
