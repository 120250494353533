import { AppBarHeader, Sidebar } from '@/components'
import React, { useEffect, useState } from 'react'
import {
  Container,
  ContentContainer,
  SideContainer,
  YourTasksTitle,
  LoaderContainer,
} from './styled'
import { Container as Wrapper } from '@mui/material'
import {
  useGetDashboard,
  useSetupStripeAccount,
} from '@/services/suppliers/suppliers.service'
import { tasks as tasksArray } from './mockedData'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { ClipLoader } from 'react-spinners'
import { setStripeLoading } from '../../slices/authUserSlice'
import { useNavigate } from 'react-router-dom'
import Header from '../dashboard/header'
import Tasks from '@/pages/suppliers-dashboard/tasks'
import Resources from './resources'
import { queryClient } from '@/lib/react-query'

interface IProductProps {}

const SuppliersDashboard: React.FC<IProductProps> = (props) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { userId, companyId, dashboard } = useAppSelector(
    (state: any) => state.authUser,
  )
  const [parsedTasks, setParsedTasks] = useState([] as any)
  const [localDashboard, setLocalDashboard] = useState(dashboard as any)
  const { isLoading, data: fetchedDashboard } = useGetDashboard({
    userId: userId ?? '', // TODO: error handler
    // enabled: localDashboard ? false : true,
    enabled: true,
  })

  const { mutate: setupStripeAccount, data: stripeData } =
    useSetupStripeAccount(() => {
      dispatch(setStripeLoading(false))
      queryClient.invalidateQueries({ queryKey: ['dashboard'] })
    })

  useEffect(() => {
    if (fetchedDashboard && fetchedDashboard.data && userId)
      setLocalDashboard(fetchedDashboard.data)
  }, [fetchedDashboard])

  useEffect(() => {
    if (stripeData && stripeData.data && stripeData.data.url)
      window.open(stripeData.data.url)
  }, [stripeData])

  useEffect(() => {
    if (localDashboard && tasksArray) {
      const setSize = (task: any) => {
        switch (task.id) {
          case 'acceptFeePolicy':
            return localDashboard.acceptFeePolicy ? 1 : 0
          case 'acceptTermsAndConditions':
            return localDashboard.acceptTermsAndConditions ? 1 : 0
          case 'linkAccountWithStripe':
            return localDashboard.linkAccountWithStripe ? 1 : 0
          default:
            return localDashboard[`${task.id}`].size
        }
      }

      const setDisabled = (task: any) => {
        switch (task.id) {
          case 'acceptFeePolicy':
            return false
          case 'acceptTermsAndConditions':
            return false
          case 'linkAccountWithStripe':
            return localDashboard.acceptTermsAndConditions ? false : true
          default:
            return localDashboard.acceptTermsAndConditions
              ? // &&
                //   localDashboard.linkAccountWithStripe
                false
              : true
        }
      }

      setParsedTasks(
        tasksArray.map((task) => {
          if (task.id && localDashboard.hasOwnProperty(`${task.id}`)) {
            return {
              ...task,
              size: setSize(task),
              total: localDashboard[`${task.id}`].total
                ? localDashboard[`${task.id}`].total
                : 1,
              onCTAClick: () => {
                if (task.id === 'linkAccountWithStripe') {
                  dispatch(setStripeLoading(true))
                  setupStripeAccount({
                    companyId: companyId,
                    returnUrl: window.location.href,
                  })
                } else {
                  if (task.CTALink) navigate(task.CTALink)
                }
              },
              disabled: setDisabled(task),
            }
          } else {
            return { ...task }
          }
        }),
      )
    }
  }, [localDashboard])

  return (
    <Container>
      <Sidebar />
      <Container flexDirection="column">
        <AppBarHeader />
        <Wrapper maxWidth={'xl'} sx={{ margin: '45px' }}>
          <Header
            title={'Dashboard'}
            description={
              'Complete all the steps to activate your Kamba account and start operating digitally.'
            }
          />
        </Wrapper>
        <ContentContainer>
          {!isLoading && localDashboard && parsedTasks.length > 0 ? (
            <Wrapper
              style={{
                borderBottom: '1px solid #D3D3D3',
                display: 'flex',
                gap: '3%',
                padding: '0 24px',
              }}
              maxWidth={'xl'}
              sx={{ marginRight: '45px', marginLeft: '45px' }}
            >
              <SideContainer className={'tasks'}>
                <YourTasksTitle>Your Tasks</YourTasksTitle>
                <Tasks tasks={parsedTasks} />
              </SideContainer>
              <SideContainer className={'resources'}>
                <YourTasksTitle>Resources</YourTasksTitle>
                <Resources />
              </SideContainer>
            </Wrapper>
          ) : (
            <LoaderContainer>
              <ClipLoader />
            </LoaderContainer>
          )}
        </ContentContainer>
      </Container>
    </Container>
  )
}

export default SuppliersDashboard
