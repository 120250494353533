import styled from 'styled-components'
import check from '@assets/images/checkbox'
import { Link } from 'react-router-dom'

type Props = {
  type?: string
  marginLeft?: boolean
  marginBottom?: string
}

export const Divider = styled.div`
  width: 45px;
  height: 6px;
  background-color: #000000;
  margin-bottom: 16px;
`

export const Title = styled.h1<Props>`
  font-size: 36px;
  line-height: 44px;
  font-weight: 300;
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom : `55px`};
`

export const FormGroup = styled.form`
  display: flex;
  flex-direction: column;
`

export const Label = styled.label`
  font-size: 16px;
  line-height: 23px;
  font-weight: 400;
  margin-bottom: 7px;
  color: #000000b2;
`

export const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;

  & > div {
    flex-direction: row;
  }
`

export const Radio = styled.div<Props>`
  position: relative;
  margin-left: ${({ marginLeft }) => (marginLeft ? `27px` : ``)};
`

export const Button = styled.button.attrs(({ type }) => ({
  type: type || 'button',
}))`
  background-color: #060606;
  border: 1px solid #060606;
  color: #ffffff;
  font-size: 14px;
  line-height: 22px;
  padding: 17px 34px;
  cursor: pointer;
  letter-spacing: 2px;
  text-transform: uppercase;
`

export const Line = styled.div`
  background-color: #d9d9d9;
  width: 100%;
  height: 1px;
  margin: 38px 0;
`

export const TextLink = styled(Link)`
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
  color: #000000;
  text-decoration: none;
`

export const TextWrapper = styled.div`
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  color: #000000;
  text-decoration: none;
  margin-top: 12px;
`

export const PrivacyWrapper = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: #00000080;
  text-decoration: none;
  margin-top: 24px;
`

export const Error = styled.p`
  color: #f00000;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  margin: 12px auto;
`

export const Text = styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  margin-bottom: 24px;
  max-width: 412px;
`
