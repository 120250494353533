import { useMutation } from 'react-query'
import { signupValidation } from './api/signup-validation'

export const useSignupValidation = (onSuccess: () => void) => {
  return useMutation(signupValidation, {
    onSuccess: () => {
      onSuccess()
    },
  })
}
