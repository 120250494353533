import styled from 'styled-components'
import pointer from '@assets/images/triangle-tooltip'
import close from '@assets/images/close'

export const Wrapper = styled.div`
  max-width: 391px;
  width: 100%;
  padding: 30px 36px 30px 30px;
  border: 1px solid #b3b3b3;
  background-color: #ffffff;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: #5c5c5c;
  font-weight: 500;
`

export const Pointer = styled.img.attrs({
  src: pointer,
  alt: 'pointer image',
})`
  position: absolute;
  top: 48%;
  bottom: auto;
  left: -10.7px;
`

export const CloseButton = styled.img.attrs({
  src: close,
  alt: 'close image',
})`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`

export const VerticalPointer = styled.img.attrs({
  src: pointer,
  alt: 'pointer image',
})`
  position: absolute;
  top: -15px;
  bottom: auto;
  left: 60px;
  transform: rotateZ(90deg);
`
