import { useState } from 'react'
import InputComponent from '../input'
import { Multiselect } from '@components'
import {
  Stepper,
  Pagination,
  Title,
  Divider,
  Label,
  Block,
  Item,
  Button,
  Arrow,
} from '../styled'
import { geographicalData } from '@/mocks'
import { TitleTooltip } from '../tooltip-content'
import { StepOneParams } from './types'
import CustomMultiSelect from '../custom-multi-select'
import { components } from 'react-select'
import makeAnimated from 'react-select/animated'

const FirstStep = ({ onClick, setFirstStepState }: StepOneParams) => {
  const [title, setTitle] = useState('')
  const [geo, setGeo] = useState([])

  const firstStepValues = {
    title,
    geo: geo.map(({ label }) => label),
  }

  const Option = (props: any) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{' '}
          <label>{props.label}</label>
        </components.Option>
      </div>
    )
  }

  const MultiValue = (props: any) => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  )

  const animatedComponents = makeAnimated()

  const handleChange = (selected: []) => {
    setGeo(selected)
  }

  return (
    <Stepper>
      <Block>
        <Pagination>Step 1/3</Pagination>
        <Title>Data Needs</Title>
        <Divider />
        <InputComponent
          title="What data are you looking for?"
          minCharacters={30}
          getValue={(e: any) => setTitle(e.target.value)}
        >
          <TitleTooltip />
        </InputComponent>
      </Block>
      <Block>
        <Item>
          <Title>Geo-Coverage</Title>
          <Divider />
          <Label>Which countries should be covered?</Label>
          <CustomMultiSelect
            options={geographicalData}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{ Option, MultiValue, animatedComponents }}
            onChange={handleChange}
            allowSelectAll={true}
            value={geo}
          />
        </Item>
      </Block>
      <Button
        onClick={(e) => {
          e.preventDefault
          onClick(e)
          setFirstStepState(firstStepValues)
        }}
      >
        Continue to next step
        <Arrow />
      </Button>
    </Stepper>
  )
}

export default FirstStep
