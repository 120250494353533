// import { useGetBilling } from '@/services/billing/billing.service'
import { useGetCompanyProfile } from '@/services/storefront/storefront.service'
import { useGetCompany } from '@/services/suppliers/suppliers.service'
import storage from '@/utils/storage'
import { useState } from 'react'
import Benefits from './benefits'
// import Billing from './billing'
import Branding from './branding'
import { categories as CompanyProfileCategories } from './categories'
import CompanyInfo from './company-info'
import Offering from './offering'
import Pricing from './pricing'
import Privacy from './privacy'
import {
  Active,
  Breadcrum,
  CompanyName,
  MenuPanel,
  MenuItem,
  MenuWrapper,
  ProfileStrength,
  StorefrontWrapper,
  CompanyProfileTitle,
} from './styled'
import { ICategories } from './types'
import ClipLoader from 'react-spinners/ClipLoader'

const StorefrontAdmin = () => {
  const [selectedCategory, setSelectedCategory] = useState<string>('branding')
  const [categoryError, setCategoryError] = useState<boolean>(false)
  const companyId = storage.getCompanyId()

  const { data: companyData, isLoading: isLoadingCompany } = useGetCompany(
    companyId,
    true,
  )

  // TODO: Only for whitelisted clients upon request. To be implemented in backend
  // const { data: billingData, isLoading: isLoadingBilling } = useGetBilling(
  //   companyId,
  //   true,
  // )

  const {
    data: companyProfileData,
    isLoading: isLoadingCompanyProfile,
    isFetching,
  } = useGetCompanyProfile(
    companyData?.data.profile!,
    !!companyData?.data.profile,
  )

  const categories: ICategories[] = CompanyProfileCategories
  const renderCategories = () => {
    return categories.map((category) => (
      <MenuItem
        key={category.id}
        isSelected={category.id === selectedCategory}
        onClick={() => {
          setSelectedCategory(category.id)
          setCategoryError(false)
        }}
      >
        {category.name}
      </MenuItem>
    ))
  }

  const renderForm = () => {
    switch (selectedCategory) {
      case 'branding':
        return (
          <Branding
            id={companyData?.data.profile!}
            companyProfile={companyProfileData?.data!}
          />
        )
      case 'company-info':
        return (
          <CompanyInfo
            id={companyData?.data.profile!}
            companyProfile={companyProfileData?.data!}
          />
        )
      case 'offering':
        return (
          <Offering
            id={companyData?.data.profile!}
            companyProfile={companyProfileData?.data!}
          />
        )
      case 'benefits':
        return (
          <Benefits
            id={companyData?.data.profile!}
            companyProfile={companyProfileData?.data!}
          />
        )
      case 'pricing':
        return (
          <Pricing
            id={companyData?.data.profile!}
            companyProfile={companyProfileData?.data!}
          />
        )
      case 'privacy':
        return (
          <Privacy
            id={companyData?.data.profile!}
            companyProfile={companyProfileData?.data!}
          />
        )
      // case 'billing':
      //   return (
      //     <Billing
      //       id={companyData?.data?.profile!}
      //       billing={billingData?.data!}
      //     />
      //   )
      default:
        setCategoryError(true)
        break
    }
  }

  return (
    <StorefrontWrapper>
      {isLoadingCompany || isLoadingCompanyProfile ? (
        //  || isLoadingBilling
        <ClipLoader color="#000000" size={30} />
      ) : (
        <>
          <MenuPanel>
            {/* <Breadcrum>
              Settin... / Storefront... / <Active>Company...</Active>
            </Breadcrum> */}
            <CompanyName>{companyProfileData?.data?.name}</CompanyName>
            <CompanyProfileTitle>Profile</CompanyProfileTitle>
            <MenuWrapper>{renderCategories()}</MenuWrapper>
            {/* <ProfileStrength>0% Profile Strength</ProfileStrength> */}
            {/* TODO: add progress bar */}
          </MenuPanel>
          {categoryError ? <h1>Please, select a Category!</h1> : renderForm()}
        </>
      )}
    </StorefrontWrapper>
  )
}

export default StorefrontAdmin
