import { ApiResponse } from '@/services/types'
import { axios } from '@lib/axios'
import {
  SupplierDashboard,
  SetupeStripeAccountQuery,
  AcceptTermsAndConditionsQuery,
  Company,
  FoundCompanyProfileResponse,
} from '../types'
import qs from 'qs'

type ApiSupplierDashboardResponse = ApiResponse<SupplierDashboard>
type APITermsAndConditions = ApiResponse<AcceptTermsAndConditionsQuery>
type ApiSearchSuppliersResponse = ApiResponse<FoundCompanyProfileResponse>

export const getDashboard = (
  userId: string,
): Promise<ApiSupplierDashboardResponse> => {
  return axios.get(`/users/${userId}/dashboard`)
}

export const setupStripeAccount = ({
  companyId,
  returnUrl,
}: SetupeStripeAccountQuery) =>
  axios.post(`/ccompanies/${companyId}/stripe-account`, {
    return_url: returnUrl,
  })

export const acceptTermsAndConditions = ({
  companyProfileId,
}: AcceptTermsAndConditionsQuery): Promise<APITermsAndConditions> => {
  return axios.put(
    `company-profiles/${companyProfileId}/terms-and-conditions`,
    { acceptance: true },
  )
}

export const acceptFeePolicy = ({
  companyProfileId,
}: AcceptTermsAndConditionsQuery): Promise<APITermsAndConditions> => {
  return axios.put(`company-profiles/${companyProfileId}/fee-policy`, {
    acceptance: true,
  })
}

export const getCompany = (
  id: string,
): Promise<{
  ok: string
  data: Company
}> => axios.get(`ccompanyById/${id}`)

export const getCompanyByDomain = (
  domain: string,
): Promise<{
  ok: string
  data: Company
}> => axios.get(`ccompanies/${domain}`)

export const searchCompanyProfiles = ({
  ...params
}: any): Promise<ApiSearchSuppliersResponse> => {
  return axios.get('/company-profiles/search', {
    params: { ...params },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    },
  })
}
