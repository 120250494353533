import Details from './details'
import Legal from './legal'
import { TabsSubscriptions } from './types'
import Delivery from './delivery'

export const tabsSubs: TabsSubscriptions[] = [
  /*{
    label: 'Details',
    Component: Details,
    id: 'details',
  }*/
  {
    label: 'Delivery',
    Component: Delivery,
    id: 'integration',
  },
  {
    label: 'Legal',
    Component: Legal,
    id: 'legal',
  },
]
