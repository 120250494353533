import { Button } from '@/components'
import InputComponent from '@/components/ui/input'
import { queryClient } from '@/lib/react-query'
import { useUpdateCompanyProfile } from '@/services/storefront/storefront.service'
import { CompanyProfile } from '@/services/storefront/types'
import { useState } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import { ClipLoader } from 'react-spinners'
import { formats, modules } from '../company-info/rich-text-config'
import { FormTitle, FormWrapper, InputTitle, SavedChanges } from '../styled'
import { IFormProps } from '../types'
import { Label } from '@/components/ui/input/styled'
import FileUploader from 'quill-file-uploader'
Quill.register('modules/fileUploader', FileUploader)

const Offering: React.FC<IFormProps> = ({ id, companyProfile }) => {
  const [savedChanges, setSavedChanges] = useState(false)
  const [dataOfferingInput, setDataOfferingInput] = useState<
    string | undefined
  >(companyProfile.dataOfferingDescription)
  const [dataCollectionInput, setDataCollectionInput] = useState<
    string | undefined
  >(companyProfile.dataCollection)

  let updatedCompanyProfile: CompanyProfile = { ...companyProfile }

  const { mutate, isLoading } = useUpdateCompanyProfile(() => {
    setSavedChanges(true)
    queryClient.invalidateQueries({ queryKey: ['company-profile'] })
  })

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    updatedCompanyProfile = {
      ...updatedCompanyProfile,
      dataOfferingDescription: dataOfferingInput,
      dataCollection: dataCollectionInput,
    }

    mutate({ id: id, companyProfile: updatedCompanyProfile })
  }

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <FormTitle>Offering</FormTitle>
      <InputTitle>Data Offering</InputTitle>
      <>
        <Label marginBottom="0">
          Describe what makes your data unique in 3-4 sentences.
        </Label>
        <ReactQuill
          theme="snow"
          modules={modules}
          formats={formats}
          value={dataOfferingInput}
          onChange={setDataOfferingInput}
          style={{ background: '#FFFFFF' }}
        ></ReactQuill>
      </>
      <InputTitle>Data Sources & Collection</InputTitle>
      <InputComponent
        title="Where do you get the data from? How do you collect it? Do you apply any specific transformations?"
        minCharacters={500}
        isTextArea
        value={dataCollectionInput}
        getValue={(e: any) => setDataCollectionInput(e.target.value)}
      ></InputComponent>
      <Button variant="primary" width={'100%'} type="submit">
        {isLoading ? (
          <ClipLoader color="#ffffff" size={12} />
        ) : (
          <span>SAVE CHANGES</span>
        )}
      </Button>

      {savedChanges && <SavedChanges>Changes Saved!</SavedChanges>}
    </FormWrapper>
  )
}

export default Offering
