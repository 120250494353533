import {
  Arrow,
  Block,
  Button,
  Divider,
  Item,
  Pagination,
  Stepper,
  Title,
} from '../styled'
import * as yup from 'yup'
import { deleteError, findError, validateFunction } from '@/utils/form-errors'
import { ErrorWrapper } from '@/components/forms/validation-errors'
import { useState } from 'react'
import DragAndDrop from './drag-n-drop'
import ViewFile from './view-file'
import SupportedFileTypes from './supported-files'
import { CommonStepProps, LastStepProps } from '../types'
import Preview from './preview'

const StepTwo = ({ globalState, dispatch, onClick }: LastStepProps<File>) => {
  const file: File | null = globalState.file
  const setFile = async (data: File) => {
    internalDeleteError('file')
    const isValid = await internalValidateFunction(data, 'file')
    //const isValid = true
    if (isValid) {
      dispatch({ file: data })
    }
  }
  const deleteFile = async () => {
    dispatch({ file: null })
  }

  const [validationErrors, setValidationErrors] = useState<
    yup.ValidationError[]
  >([])

  type stateType = {
    file: File | null
  }
  const validateSchema = {
    file: yup
      .mixed()
      .test('required', 'You need to provide a file', (data: File) => {
        return !!(data && data.size)
      })
      .test('fileType', 'File must be of type csv', (data: File) => {
        return data && !!data.type.match(/csv/)
      }),
  }

  const internalValidateFunction = async (
    value: stateType | stateType[keyof stateType],
    lookup?: keyof stateType,
  ): Promise<boolean> => {
    const newErrors = await validateFunction<stateType>(
      value,
      validateSchema,
      lookup,
    )
    setValidationErrors(newErrors)
    return newErrors.length === 0
    //return true to dismiss errors
  }

  const internalDeleteError = (identifier: string) => {
    deleteError(identifier, validationErrors, setValidationErrors)
  }

  const internalFindError = (identifier: string) => {
    const error = findError(identifier, validationErrors)
    return !!error && <ErrorWrapper>{error.message}</ErrorWrapper>
  }
  return (
    <>
      <Stepper>
        <Block>
          <Pagination>Step 2/3</Pagination>
          <Title>Data Source & Preview</Title>
          <Divider />
          <Item>
            {!file ? (
              <DragAndDrop dispatch={setFile} />
            ) : (
              <ViewFile data={file} deleteFile={deleteFile} />
            )}
            {internalFindError('file')}
          </Item>
          <SupportedFileTypes></SupportedFileTypes>
        </Block>
        <Block>{file && <Preview data={file} />}</Block>

        <Button
          onClick={async (e) => {
            e.preventDefault
            const isValid = await internalValidateFunction({
              file,
            })
            if (isValid) {
              onClick(file!)
            }
          }}
        >
          Continue to next step
          <Arrow />
        </Button>
        {validationErrors.length > 0 && (
          <ErrorWrapper>You must resolve the errors to continue</ErrorWrapper>
        )}
      </Stepper>
    </>
  )
}

export default StepTwo
