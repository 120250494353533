import React, { useState } from 'react'
import TableComponent from '@/components/table'
import { columns } from './table-data/columns'
import moment from 'moment'
import { ClipLoader } from 'react-spinners'
import { LoaderContainer } from './styled'
import { useLocation, useParams } from 'react-router'
import { useGetSubsById } from '@/services/subscriptions/subscriptions.service'
import { queryClient } from '@/lib/react-query'

const Legal = ({ data }: any) => {
  //const { legalCompliance } = data
  const [enableQuery, setEnableQuery] = useState(true)
  let { id } = useParams()
  const { state } = useLocation()
  const { isLoading, data: subscription } = useGetSubsById({
    subId: id ?? '', // TODO: replace userId with the id saved at login in context when logic its done
    enabled: enableQuery,
    onSuccess: () => {
      // console.log(!isLoading && subscription)
      setEnableQuery(false)
      // console.log(enableQuery)
    },
  })

  const getRows = (obj: any) => {
    return obj
      .filter((item: any) => !!item.name)
      .map((row: any) => {
        return {
          /*name: row.name,*/
          type: row.type,
          /*status: row.status,*/
          /*dueDate: moment(row.dueDate).format('MMM DD, YYYY'),*/
          /*notes: row.notes,*/
          lastUpdate: `${moment(row.updatedAt).format('MMM DD, YYYY')}`,
          actions: `https://mytestbucketfpasdfas.s3.amazonaws.com/${row.s3Link}`,
        }
      })
  }

  return (
    <>
      {!isLoading ? (
        <TableComponent
          rows={getRows(subscription?.data?.legalCompliance!)}
          headers={columns}
        />
      ) : (
        <LoaderContainer>
          <ClipLoader />
        </LoaderContainer>
      )}
    </>
  )
}

export default Legal
