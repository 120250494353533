import styled from 'styled-components'

export const UploadContainer = styled.div`
  min-width: 150px;
  display: flex;
  flex-direction: column;
  align-items: left;
`

export const UploadWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 30px 100px;
  border: 2px dashed #ededed;
  cursor: pointer;
`

export const IconWrapper = styled.div`
  border-radius: 100%;
  background-color: #fafafa;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Instructions = styled.div`
  font-size: 14px;
  line-height: 20px;
  white-space: pre;
  font-weight: 500;

  span:last-child {
    color: #757575;
    font-weight: 400;
  }
`

export const FileTypes = styled.p`
  font-size: 12px;
  line-height: 18px;
  color: #757575;
  font-weight: 400;
`

export const RestrictionList = styled.ul`
  margin-left: -25px;
  color: #5c5c5c;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.015em;
  font-style: italic;
`

export const InputFile = styled.input`
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
`
