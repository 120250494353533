import * as React from 'react'
import Contact from '@/components/contact'
import Detail from './detail'
import { Sidebar, AppBarHeader, TabsNavigation } from '@components'
import { Container } from './styled'
import { tabs } from './tabStorefront'
import { Grid, Container as Wrapper } from '@mui/material'
import ClipLoader from 'react-spinners/ClipLoader'
import {
  useGetCompany,
  useGetCompanyByDomain,
} from '@/services/suppliers/suppliers.service'
import { useGetCompanyProfile } from '@/services/storefront/storefront.service'
import { IStorefrontProps } from './types'
import { useParams } from 'react-router'
import { LoaderWrapper } from '../product/styled'
import { useAppSelector } from '@/app/hooks'

const Storefront: React.FC<IStorefrontProps> = (props) => {
  const userRole = useAppSelector((state) => state.authUser.userRole)
  const userId = useAppSelector((state) => state.authUser.userId)
  const { companyId } = useParams()
  /*const { data: companyData, isLoading: isCompanyLoading } =
    useGetCompanyByDomain(domain!, !!domain)*/

  const { data: companyData, isLoading: isCompanyLoading } = useGetCompany(
    companyId!,
    !!companyId,
  )

  const { data: companyProfileData, isLoading: isCompanyProfileLoading } =
    useGetCompanyProfile(
      companyData?.data.profile!,
      !!companyData?.data.profile,
    )

  const capitalize = (word: string) => {
    return word[0].toUpperCase() + word.slice(1).toLowerCase()
  }

  const renderFullDetail = () => {
    return (
      <Grid container spacing={3} sx={{ marginTop: '20px' }}>
        {userRole != 'buyer' && <Grid container item xs={2} />}
        <Grid item xs={7}>
          <Detail company={companyProfileData?.data} />
        </Grid>
        {userId && userRole === 'buyer' && (
          <Grid
            container
            item
            xs={4}
            spacing={3}
            direction="column"
            justifyContent="flex-start"
          >
            <Grid item xs={4}>
              <Contact
                companyName={capitalize(companyProfileData?.data?.name!)}
                phoneNumber={companyProfileData?.data?.phoneNumber}
                showIconsInfo={true}
                companyId={companyData?.data.id}
                origin="storefront"
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    )
  }

  return (
    <Container>
      <Sidebar />
      <Container flexDirection="column">
        <AppBarHeader />
        <Wrapper maxWidth={'xl'} sx={{ margin: '45px 20px' }}>
          {/* <Wrapper maxWidth={'xl'}> */}
          {isCompanyLoading || isCompanyProfileLoading ? (
            <LoaderWrapper>
              <ClipLoader
                color="#000000"
                loading={isCompanyLoading || isCompanyProfileLoading}
                size={30}
              />
            </LoaderWrapper>
          ) : (
            <>
              {/* <TabsNavigation
                tabs={tabs(companyProfileData?.data?.name!)}
                backgroundMarginOffset={'46%'}
                isScrollable
              >
                {renderFullDetail()}
              </TabsNavigation> */}
              {renderFullDetail()}
            </>
          )}
        </Wrapper>
      </Container>
    </Container>
  )
}

export default Storefront
