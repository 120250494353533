import { GradientSeparator } from '@/components'
import { PreviewContainer } from './styled'
import WithScrollToElement from '@/hocs/withScrollToElement'
import GridPreview from './grid-preview'
import DataAttributes from './data-attributes'
import { Listing } from '@/services/listing/types'
import { useEffect, useState } from 'react'
import { downloadS3URL } from '@/utils/downloadS3Url'
import DataAnalytics from './data-analytics'
import WithVisibility from '@/hocs/withVisibility'
import { SampleDto } from '@/components/forms/create-product/second-step/types'

interface IPreviewProps {
  product: Listing
  dataset: { url: string }
}

const PreviewAnalytics: React.FC<IPreviewProps> = ({ product, dataset }) => {
  const [sampleFile, setSampleFile] = useState<{
    file: File
    url: string
  } | null>(null)
  const [chartIQFile, setChartIQFile] = useState<
    | {
        file: File
        url: string
      }
    | undefined
  >()
  const [backtestingFile, setBacktestingFile] = useState<
    | {
        file: File
        url: string
      }
    | undefined
  >()
  const [dataAnalyticsUrl, setDataAnalyticsUrl] = useState<string | undefined>()
  const [backtestingUrl, setBacktestingUrl] = useState<string | undefined>()
  const [url, setUrl] = useState<string | undefined>(undefined)

  const downloadFile = async (
    fileToDownload: SampleDto,
    setState: (
      arg0: {
        file: File
        url: string
      } | null,
    ) => void,
  ) => {
    const metaFile = await downloadS3URL(fileToDownload.s3Link!)
    const newFile = new File([metaFile.data], fileToDownload.name!, {
      type: metaFile.headers['content-type'],
    })
    setState({ file: newFile, url: fileToDownload.s3Link! })
  }

  useEffect(() => {
    const foundSample =
      product.samples &&
      product.samples.find((val) => val.type === 'Free Sample' && !!val.name)
    if (foundSample) {
      setUrl(foundSample.s3Link!)
      downloadFile(foundSample, setSampleFile)
    }
    const foundChartIQ =
      product.samples &&
      product.samples.find((val) => val.type === 'Chart IQ' && !!val.name)
    if (foundChartIQ) {
      downloadFile(foundChartIQ, setChartIQFile)
    }

    const foundDataAnalytics =
      product.samples &&
      product.samples.find((val) => val.type === 'Data Analytics' && !!val.name)
    if (foundDataAnalytics) {
      setDataAnalyticsUrl(foundDataAnalytics.s3Link)
    }

    const foundBacktesting =
      product.samples &&
      product.samples.find((val) => val.type === 'Backtesting' && !!val.name)
    if (foundBacktesting) {
      setBacktestingUrl(foundBacktesting.s3Link)
      downloadFile(foundBacktesting, setBacktestingFile)
    }
  }, [])

  return (
    <PreviewContainer>
      <GridPreview
        file={sampleFile?.file}
        url={url!}
        backtesting={backtestingUrl}
        backtestingFile={backtestingFile}
      />
      {/* <Grid item xs={12} sx={{ margin: '30px 0' }} /> */}
      <GradientSeparator margin="30px 0" />
      {(!!chartIQFile || !!dataAnalyticsUrl) && (
        <DataAnalytics
          chartIQFile={chartIQFile}
          dataAnalytics={dataAnalyticsUrl}
          companyId={product?.company?.id}
        />
      )}
      {product.attributes && product.attributes.length > 0 && (
        <DataAttributes rows={product.attributes} />
      )}
    </PreviewContainer>
  )
}

export default WithVisibility(
  WithScrollToElement(PreviewAnalytics, 'previewAnalytics'),
)
