export const frequencyData = [
  { id: 1, value: 'Secondly' },
  { id: 2, value: 'Monthly' },
  { id: 3, value: 'Minutely' },
  { id: 4, value: 'Quarterly' },
  { id: 5, value: 'Hourly' },
  { id: 6, value: 'Yearly' },
  { id: 7, value: 'Daily' },
  { id: 8, value: 'Real-Time' },
  { id: 9, value: 'Weekly' },
  { id: 10, value: 'On-Demand' },
]
