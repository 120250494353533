import { components } from 'react-select'
import makeAnimated from 'react-select/animated'
import InputComponent from '@/components/ui/input'
import CustomMultiSelect from '@/pages/dashboard/posting/create-post/custom-multi-select'
import {
  FormTitle,
  FormWrapper,
  InputTitle,
  InputWrapper,
  SavedChanges,
} from '../styled'
import { geographyList } from '@/data/geographicData'
import { useEffect, useState } from 'react'
import { ClipLoader } from 'react-spinners'
import { Button } from '@/components'
import { CompanyProfile } from '@/services/storefront/types'
import { useUpdateCompanyProfile } from '@/services/storefront/storefront.service'
import { IFormProps } from '../types'
import { queryClient } from '@/lib/react-query'
import { Label } from '@/components/ui/input/styled'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { formats, formats2, modules, modules2 } from './rich-text-config'
import FileUploader from 'quill-file-uploader'
import EmailList from '../email-list'

Quill.register('modules/fileUploader', FileUploader)

const CompanyInfo: React.FC<IFormProps> = ({ id, companyProfile }) => {
  const [savedChanges, setSavedChanges] = useState(false)
  const [countryInput, setCountryInput] = useState<string | undefined>(
    companyProfile.country,
  )
  const [websiteUrlInput, setWebsiteUrlInput] = useState<string | undefined>(
    companyProfile.websiteUrl,
  )
  const [linkedinUrlInput, setLinkedinUrlInput] = useState<string | undefined>(
    companyProfile.linkedinUrl,
  )
  const [companyNameInput, setCompanyNameInput] = useState<string | undefined>(
    companyProfile.name,
  )
  const [contactEmailInput, setContactEmailInput] = useState<
    string | undefined
  >(companyProfile.contactEmail)
  const [aboutInput, setAboutInput] = useState<string | undefined>(
    companyProfile.about,
  )
  const [geoInput, setGeoInput] = useState<string[] | undefined>(
    companyProfile.geo,
  )

  const Option = (props: any) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{' '}
          <label>{props.label}</label>
        </components.Option>
      </div>
    )
  }

  const MultiValue = (props: any) => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  )

  const animatedComponents = makeAnimated()

  const handleChange = (selected: any) => {
    setCountryInput(selected.country)
  }

  const handleChangeGeo = (selected: any) => {
    const updatedGeo = selected.map((item: any) => item.country)
    setGeoInput(updatedGeo)
  }

  let updatedCompanyProfile: CompanyProfile = { ...companyProfile }

  const { mutate, isLoading } = useUpdateCompanyProfile(() => {
    setSavedChanges(true)
    queryClient.invalidateQueries({ queryKey: ['company-profile'] })
  })

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    updatedCompanyProfile = {
      ...updatedCompanyProfile,
      websiteUrl: websiteUrlInput,
      linkedinUrl: linkedinUrlInput,
      contactEmail: contactEmailInput,
      about: aboutInput,
      country: countryInput,
      geo: geoInput,
      name: companyNameInput,
    }

    mutate({ id: id, companyProfile: updatedCompanyProfile })
  }

  const addEmail = (email: string) => {
    const mails = contactEmailInput ? contactEmailInput.split(';') : []
    mails.push(email)
    setContactEmailInput(mails.join(';'))
  }

  const removeEmail = (email: string) => {
    const mails = contactEmailInput ? contactEmailInput.split(';') : []
    const newMails = mails?.filter((m) => m !== email)
    setContactEmailInput(newMails.join(';'))
  }

  const companyEmails = contactEmailInput ? contactEmailInput.split(';') : []

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <FormTitle>Basic Information</FormTitle>
      <InputTitle>Online Presense</InputTitle>
      <InputComponent
        title="Website URL"
        value={websiteUrlInput}
        getValue={(e: any) => setWebsiteUrlInput(e.target.value)}
      />
      <InputComponent
        title="LinkedIn URL"
        value={linkedinUrlInput}
        getValue={(e: any) => setLinkedinUrlInput(e.target.value)}
      />
      <InputTitle>About Company</InputTitle>
      <InputComponent
        title="Company Name"
        value={companyNameInput}
        getValue={(e: any) => setCompanyNameInput(e.target.value)}
      />
      {/*<InputWrapper>
         <InputComponent
          title="Company e-mail"
          value={contactEmailInput}
          getValue={(e: any) => setContactEmailInput(e.target.value)}
        />
      </InputWrapper> */}
      <EmailList
        addItem={addEmail}
        removeItem={removeEmail}
        emails={companyEmails}
      />
      <>
        <Label marginBottom="0">
          Tell us more about {companyProfile.name}. When were you founded? How
          many employees do you have? You do not have to be specific about data
          here.
        </Label>
        <ReactQuill
          theme="snow"
          modules={modules}
          formats={formats}
          value={aboutInput}
          onChange={setAboutInput}
          style={{ background: '#FFFFFF' }}
        ></ReactQuill>
      </>
      <>
        <InputTitle>Location</InputTitle>
        <Label marginBottom="0">Where's your headquarters?</Label>
        <CustomMultiSelect
          defaultValue={
            companyProfile.country
              ? [
                  {
                    label: companyProfile.country!,
                    value: companyProfile.country!,
                    country: companyProfile.country!,
                  },
                ]
              : undefined
          }
          options={geographyList}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{ Option, MultiValue, animatedComponents }}
          onChange={handleChange}
          allowSelectAll={false}
          placeholder="Select country..."
        />
      </>
      <InputTitle>Geo-Coverage</InputTitle>
      <>
        <Label marginBottom="0">Which countries should be covered?</Label>
        <CustomMultiSelect
          defaultValue={
            companyProfile.geo && companyProfile.geo[0] != ''
              ? companyProfile.geo!.map((country) => {
                  return { label: country, value: country, country: country }
                })
              : undefined
          }
          options={geographyList}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{ Option, MultiValue, animatedComponents }}
          onChange={handleChangeGeo}
          allowSelectAll={true}
          placeholder="Select countries..."
        />
      </>
      <Button variant="primary" width={'100%'} marginTop={'50px'} type="submit">
        {isLoading ? (
          <ClipLoader color="#ffffff" size={12} />
        ) : (
          <span>SAVE CHANGES</span>
        )}
      </Button>
      {savedChanges && <SavedChanges>Changes Saved!</SavedChanges>}
    </FormWrapper>
  )
}

export default CompanyInfo
