import React from 'react'
import { TaskType } from '.'
import {
  BlackButton,
  LeftContainer,
  ProgressBar,
  SeparatorDiv,
  StyledCheckIcon,
  TaskContainer,
  TaskCTA,
  TaskDesc,
  TaskTitle,
  Texts,
  WhiteButton,
  ButtonLabel,
  IconContainer,
} from './styled'
import { CircularProgressbar } from 'react-circular-progressbar'
import { Icon } from '@components'
import { useAppSelector } from '../../../app/hooks'
import ClipLoader from 'react-spinners/ClipLoader'
import 'react-circular-progressbar/dist/styles.css'
import newProductBlack from '@/assets/images/icons/newFileBlack.png'

interface TasksProps {
  task: TaskType
  last: boolean
  first: boolean
  onCTAClick: () => void
}

function Task({ task, last, first, onCTAClick }: TasksProps) {
  const { stripeLoading } = useAppSelector((state: any) => state.authUser)
  return (
    <TaskContainer>
      <SeparatorDiv className={last ? 'taskContainer' : ''}>
        <LeftContainer>
          <ProgressBar>
            {task.size < task.total ? (
              <CircularProgressbar
                strokeWidth={6}
                styles={{
                  text: {
                    // Text color
                    fill: '#000000',
                    // Text size
                    fontSize: '30px',
                    fontWeight: '700',
                  },
                  trail: {
                    // Trail color
                    stroke: '#bfbebe',
                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: 'butt',
                    transformOrigin: 'center center',
                  },
                  path: {
                    // Path color
                    stroke: `#000000`,
                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: 'butt',
                  },
                }}
                value={task.size}
                maxValue={task.total}
                text={`${task.size}/${task.total}`}
              />
            ) : (
              <StyledCheckIcon />
            )}
          </ProgressBar>
          <Texts>
            <TaskTitle dangerouslySetInnerHTML={{ __html: task.title }} />
            <TaskDesc>{task.description}</TaskDesc>
          </Texts>
        </LeftContainer>
        <TaskCTA>
          {last ? (
            <WhiteButton
              disabled={task.disabled ? true : false}
              onClick={() => onCTAClick()}
            >
              {task.id === 'linkAccountWithStripe' && stripeLoading ? (
                <ClipLoader color="#ffffff" loading={stripeLoading} size={15} />
              ) : (
                <>
                  {task.CTAIcon !== null && (
                    <IconContainer>
                      <Icon
                        src={task.disabled ? newProductBlack : task.CTAIcon}
                      />
                    </IconContainer>
                  )}
                  <ButtonLabel>{task.CTAText}</ButtonLabel>
                </>
              )}
            </WhiteButton>
          ) : (
            <WhiteButton
              disabled={task.disabled ?? false}
              onClick={() => onCTAClick()}
            >
              {task.id === 'linkAccountWithStripe' && stripeLoading ? (
                <ClipLoader color="#000000" loading={stripeLoading} size={15} />
              ) : (
                <>
                  {task.CTAIcon !== null && (
                    <IconContainer>
                      <Icon src={task.CTAIcon} />
                    </IconContainer>
                  )}
                  <ButtonLabel>{task.CTAText}</ButtonLabel>
                </>
              )}
            </WhiteButton>
          )}
        </TaskCTA>
      </SeparatorDiv>
    </TaskContainer>
  )
}

export default Task
