import { useMutation, useQuery } from 'react-query'
import { ListingsOptions, GetCompanyListingsProps } from './types'
import {
  ExtractFnReturnType,
  MutationConfig,
  queryClient,
  QueryConfig,
} from '@lib/react-query'
import {
  createListing,
  getListings,
  deleteListing,
  getCompanyListings,
} from './api/listings'

type QueryFnType = typeof getListings
type QueryFnGetCompanyListingType = typeof getCompanyListings
type QueryConfigFn = QueryConfig<QueryFnType>

export const useSearchListing = ({
  // config,
  select,
  ...params
}: ListingsOptions<QueryConfigFn>) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['listings', params.from, { ...params }],
    queryFn: () => getListings({ ...params }),
    select,
    // ...config,
  })
}

export const useGetCompanyListing = ({
  sellerCompanyId,
}: GetCompanyListingsProps) => {
  return useQuery<ExtractFnReturnType<QueryFnGetCompanyListingType>>({
    queryKey: ['user_company_listing', sellerCompanyId],
    queryFn: () => getCompanyListings({ sellerCompanyId }),
  })
}

type UseCreateListingOptions = {
  config?: MutationConfig<typeof createListing>
}

export const useCreateListing = ({ config }: UseCreateListingOptions) => {
  return useMutation({
    onSuccess: (...args) => {
      queryClient.invalidateQueries('listings')
      config?.onSuccess?.(...args)
    },
    onError: (...args) => {
      config?.onError?.(...args)
    },
    ...config,
    mutationFn: createListing,
  })
}

type UseDeleteListingOptions = {
  config?: MutationConfig<typeof deleteListing>
}

export const useDeleteListing = ({ config }: UseDeleteListingOptions) => {
  return useMutation({
    ...config,
    mutationFn: deleteListing,
  })
}
