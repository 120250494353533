import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useNavigate } from 'react-router-dom'
import { TextWrapper } from './styled'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import ViewInNewTabIcon from '@mui/icons-material/OpenInNew'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import moment from 'moment'
import ClipLoader from 'react-spinners/ClipLoader'
import { ResultsTitle } from '@/components/grid-cards/styled'
import copyListing from '@/utils/copy-listing/copy-listing'
import { useAppSelector } from '@/app/hooks'
import { EmptyStateWrapper } from '@/pages/dashboard/posting/styled'
import { Tab } from './types'
import { IconButtonWrapper } from '../icon-button'
import TooltipButton from '@/components/ui/tooltip-button'
import { useDeleteListing } from '@/services/listing/listing.service'
import { setVisibility } from '@/utils/update-listing'
import { useState } from 'react'
import ConfirmDialog from '@/components/ui/confirm-dialog'
import {
  CircularProgress,
  Collapse,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material'
import VisibilityDialog from '../visibility/visibility-modal'
import { ProductVisibility } from '@/components/forms/create-product/types'
import {
  ArrowDropDown,
  ChatBubbleOutline,
  ExpandLess,
  ExpandMore,
  StarBorder,
} from '@mui/icons-material'
import ChatList from './chat-list'

const TabComponent = ({
  isLoading,
  error,
  setError,
  listings,
  setListings,
}: Tab) => {
  const baseUrl = process.env.REACT_APP_URL_BASE
  const navigate = useNavigate()
  const userInfo = useAppSelector((state) => state.authUser)
  const { mutate: deleteListing } = useDeleteListing({})
  const [confirmDialog, setConfirmDialog] = useState(false)
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(false)
  const [openIndex, setOpenIndex] = useState(-1)
  const [visibilityDialog, setVisibilityDialog] = useState(false)
  const [processingIdx, setProcessingIdx] = useState<number | null>(null)
  const [rowData, setRowData] = useState<{ id: string; index: number } | null>(
    null,
  )

  const deleteProduct = () => {
    if (rowData?.id) {
      const { id, index } = rowData
      setProcessingIdx(index)
      deleteListing(id, {
        onSuccess: (args) => {
          setProcessingIdx(null)
          // console.log(args)
          setListings([
            ...listings.slice(0, index),
            ...listings.slice(index + 1),
          ])
        },
        onError: async (args) => {
          // setError(true)
          if (args.response?.status === 409) {
            setRowData({ id, index })
            setConfirmDialog(true)
          } else {
            setProcessingIdx(null)
          }
        },
      })
    }
  }

  const setProdAsPrivate = async () => {
    if (rowData?.id) {
      const { id, index } = rowData
      setProcessingIdx(index)
      // Product has a subscription
      const res = await setVisibility(id, 'private')
      setListings([
        ...listings.map((l) => {
          if (l.id === id) {
            l.visibility = 'private'
          }
          return l
        }),
      ])

      setProcessingIdx(null)
    }
  }

  const closeConfirmDialog = (b: boolean) => {
    setConfirmDialog(b)
    setProcessingIdx(null)
  }

  const closeConfirmDeleteDialog = (b: boolean) => {
    setDeleteConfirmDialog(b)
    setProcessingIdx(null)
  }

  const closeVisibilityDialog = () => {
    setVisibilityDialog(false)
    setProcessingIdx(null)
  }

  const updateVisibility = async (
    id: string,
    visibility: ProductVisibility,
    whitelist: string[],
  ) => {
    const res = await setVisibility(id, visibility, whitelist)
    setListings([
      ...listings.map((l) => {
        if (l.id === id) {
          l.visibility = visibility
          l.whitelist = whitelist
        }
        return l
      }),
    ])

    closeVisibilityDialog()
  }

  const handleClick = (index: number) => {
    const openIdx = openIndex === index ? -1 : index
    setOpenIndex(openIdx)
  }

  const visibilityValue: Record<string, string> = {
    public: 'public',
    private: 'hidden',
    restricted: 'restricted',
  }

  const product = typeof processingIdx === 'number' && listings[processingIdx]
  return (
    <>
      {confirmDialog && (
        <ConfirmDialog
          title="This product has a subscription and can not be deleted. Would you like to set the product as hidden?"
          onConfirm={setProdAsPrivate}
          setOpen={closeConfirmDialog}
          open={true}
        />
      )}
      {deleteConfirmDialog && (
        <ConfirmDialog
          title="Are you sure you want to delete this product?"
          onConfirm={deleteProduct}
          setOpen={closeConfirmDeleteDialog}
          open={true}
        />
      )}
      {visibilityDialog && product && product.id && (
        <VisibilityDialog
          id={product.id}
          whitelist={product.whitelist}
          visibility={product.visibility}
          onClose={closeVisibilityDialog}
          onSave={updateVisibility}
        />
      )}
      {!isLoading && !error ? (
        <TableContainer component={Paper} style={{ marginBottom: '60px' }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell style={{ width: '11%' }}>Categories</TableCell>
                <TableCell style={{ width: '8%' }}>Countries</TableCell>
                <TableCell style={{ width: '11%' }}>Last Updated</TableCell>
                <TableCell style={{ width: '7%' }}>Visibility</TableCell>
                <TableCell style={{ width: '15%' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listings
                .sort((a: any, b: any) => {
                  const dateA = new Date(a.updatedAt!)
                  const dateB = new Date(b.updatedAt!)
                  return dateB.getTime() - dateA.getTime()
                })
                .map((row: any, index) => {
                  const open = index === openIndex
                  const click = () => {
                    handleClick(index)
                  }
                  return (
                    <>
                      <TableRow
                        key={row.name + '_' + index}
                        sx={{ 'td, th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          <TextWrapper>{row.title}</TextWrapper>
                        </TableCell>

                        <TableCell>
                          <TextWrapper
                            weight="450"
                            width="8vw"
                            color="#474747"
                            fontSize="15px"
                          >
                            {row.dataCategories
                              ? row.dataCategories.map((cat: any) => `${cat}, `)
                              : null}
                          </TextWrapper>
                        </TableCell>
                        <TableCell>
                          <TextWrapper
                            weight="450"
                            width="5vw"
                            color="#474747"
                            fontSize="15px"
                          >
                            {row.geo
                              ? row.geo.map((geo: any) => `${geo}, `)
                              : null}
                          </TextWrapper>
                        </TableCell>
                        <TableCell>
                          {moment(row.updatedAt, 'YYYY-MM-DD').format(
                            'MMM DD, YYYY',
                          )}
                        </TableCell>
                        <TableCell>
                          <Tooltip
                            title="This product is now in private mode; only buyers who subscribed to the product or that you chose to share it with, have access."
                            disableHoverListener={row.visibility !== 'private'}
                          >
                            <span>
                              {row.visibility
                                ? visibilityValue[row.visibility]
                                : 'public'}
                            </span>
                          </Tooltip>
                        </TableCell>
                        <TableCell valign="top">
                          <IconButtonWrapper
                            tooltip={
                              <TooltipButton verticalPointer>
                                Product Visibility
                              </TooltipButton>
                            }
                            size="small"
                            onClick={() => {
                              setProcessingIdx(index)
                              setVisibilityDialog(true)
                            }}
                          >
                            <RemoveRedEyeOutlinedIcon
                              fontSize="inherit"
                              sx={{ color: '#000000' }}
                            />
                          </IconButtonWrapper>
                          <IconButtonWrapper
                            tooltip={
                              <TooltipButton verticalPointer>
                                View Product
                              </TooltipButton>
                            }
                            size="small"
                            onClick={() =>
                              window.open(`/product/${row.id}`, '_blank')
                            }
                          >
                            <ViewInNewTabIcon
                              fontSize="inherit"
                              sx={{ color: '#000000' }}
                            />
                          </IconButtonWrapper>
                          <IconButtonWrapper
                            tooltip={
                              <TooltipButton verticalPointer>
                                Edit Product
                              </TooltipButton>
                            }
                            size="small"
                            onClick={() => navigate(`/product/${row.id}/edit`)}
                          >
                            <ModeEditOutlineOutlinedIcon
                              fontSize="inherit"
                              sx={{ color: '#000000' }}
                            />
                          </IconButtonWrapper>
                          <IconButtonWrapper
                            tooltip={
                              <TooltipButton verticalPointer>
                                Copy this Product
                              </TooltipButton>
                            }
                            size="small"
                            onClick={async () => {
                              setProcessingIdx(index)
                              const ok = await copyListing(row.id, userInfo)
                              setProcessingIdx(null)
                              if (!ok) {
                                setError(ok)
                              }
                              navigate(0)
                            }}
                          >
                            <ContentCopyOutlinedIcon
                              fontSize="inherit"
                              sx={{ color: '#000000' }}
                            />
                          </IconButtonWrapper>
                          <IconButtonWrapper
                            tooltip={
                              <TooltipButton verticalPointer>
                                Delete this Product
                              </TooltipButton>
                            }
                            size="small"
                            onClick={() => {
                              setRowData({ id: row.id, index })
                              setDeleteConfirmDialog(true)
                            }}
                          >
                            <DeleteOutlineIcon
                              fontSize="inherit"
                              sx={{ color: '#000000' }}
                            />
                          </IconButtonWrapper>
                          {processingIdx === index && (
                            <CircularProgress
                              color="inherit"
                              size={20}
                              style={{
                                marginBottom: '-5px',
                                marginLeft: '5px',
                              }}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell colSpan={6} sx={{ paddingTop: 0 }}>
                          <ListItemButton onClick={click} sx={{ padding: 0 }}>
                            <IconButton>
                              <ChatBubbleOutline fontSize="small" />
                            </IconButton>
                            <ListItemText
                              secondary="Messages"
                              sx={{ flexGrow: 0 }}
                            />
                            {open ? <ExpandLess /> : <ExpandMore />}
                          </ListItemButton>
                          {open && <ChatList listingId={row.id} />}
                        </TableCell>
                      </TableRow>
                    </>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : !error ? (
        <div
          style={{
            margin: '60px auto 0 auto',
            width: '100%',
            textAlign: 'center',
          }}
        >
          <ClipLoader color="#000000" size={30} />
        </div>
      ) : (
        <EmptyStateWrapper>
          <ResultsTitle>No results found</ResultsTitle>
        </EmptyStateWrapper>
      )}
    </>
  )
}

export default TabComponent
