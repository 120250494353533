import * as React from 'react'
import Header from '../header/index'
// import TabsNavigation from '@/components/tabs-navigation'
import { Container } from '@mui/material'
// import { TabsBase } from '@/components/tabs-navigation/types'
import Tab from './tab'
// import DataSetsTab from './data-sets-tab'
// import DataPlatformTab from './data-platforms-tab'
// import DataServiceTab from './data-service-tab'
import Buttons from './buttons'

interface IListingsProps {}

const Listings: React.FC<IListingsProps> = (props) => {
  // const tabs: TabsBase[] = [
  //   { label: 'All', Component: Tab, id: '1' },
  //   {
  //     label: 'Datasets, Databases, Feeds, APIs',
  //     Component: DataSetsTab,
  //     id: '2',
  //   },
  //   {
  //     label: 'Data Platforms or Softwares',
  //     Component: DataPlatformTab,
  //     id: '3',
  //   },
  //   {
  //     label: 'Data Services or Consulting',
  //     Component: DataServiceTab,
  //     id: '4',
  //   },
  // ]
  return (
    <Container maxWidth={'lg'} sx={{ padding: '30px' }}>
      <Header
        title={'Products'}
        description={'Generate leads with data product'}
      >
        <Buttons />
      </Header>
      <div style={{ marginTop: '25px' }}>
        <Tab />
      </div>
      {/*<TabsNavigation tabs={tabs} backgroundMarginOffset={'50%'} />*/}
    </Container>
  )
}

export default Listings
