import { useState, useEffect } from 'react'
import InputComponent from '../input'
import * as yup from 'yup'
import { Multiselect } from '@components'
import RadioButtons, { RadioButtonsCompanySize } from '../radio-buttons'
import {
  Stepper,
  Pagination,
  Title,
  Divider,
  Label,
  Block,
  Item,
  Button,
  Arrow,
} from '../styled'
import { Label as InputTitle } from '@/components/ui/input/styled'
import {
  categoriesData,
  companySizeData,
  useCasesData,
  visibilityMock,
} from '@/mocks'
import { typeData } from '@/data'
import { DescriptionTooltip, TitleTooltip } from '../tooltip-content'
import { StepOneParams } from './types'
import { ErrorWrapper } from '../common/styled'
import { deleteError, findError, validateFunction } from '@/utils/form-errors'
import { arrayCheckboxesHandler } from '@/utils/array-checkboxes'
import { categories } from '@/data/various'
import ReactQuill, { Quill } from 'react-quill'
import {
  formats,
  modules,
} from '@/pages/storefront-admin/company-info/rich-text-config'
import FileUploader from 'quill-file-uploader'
import Filter from '@/pages/dashboard/messages/filters/filter'
import { ProductVisibility } from '../types'
import { Visibility } from '@/mocks/product/visibility'
import { Option } from '@/pages/dashboard/messages/filters/types'
import DomainList from '../domain-list'
import { Box } from '@mui/material'
import InfoIcon from '@mui/icons-material/InfoOutlined'
Quill.register('modules/fileUploader', FileUploader)

const FirstStep = ({ onClick, state, dispatch }: StepOneParams) => {
  const [title, setTitle] = [
    state.title,
    (title: string) => {
      dispatch({ title })
    },
  ]
  const [shortDesc, setShortDesc] = [
    state.shortDescription,
    (shortDescription: string) => {
      dispatch({ shortDescription })
    },
  ]
  const [description, setDescription] = [
    state.longDescription,
    (description: string) => {
      dispatch({ longDescription: description })
    },
  ]
  const [dataCategories, setDataCategories] = [
    state.dataCategories,
    (dataCategories: any[]) => {
      dispatch({ dataCategories: dataCategories.map((value) => value.value) })
    },
  ]
  const [companySize, setCompanySize] = [
    state.optimalCompanySize,
    (optimalCompanySize: string[]) => {
      dispatch({ optimalCompanySize })
    },
  ]
  const [useCases, setUseCases] = [
    state.useCases,
    (useCases: any[]) => {
      dispatch({ useCases: useCases.map((value) => value.value) })
    },
  ]

  const [visibility, setVisibility] = [
    state.visibility,
    (v: Visibility) => {
      dispatch({ visibility: v.value })
    },
  ]

  const [whitelist, setWhitelist] = [
    state.whitelist,
    (domains: string[]) => {
      dispatch({ whitelist: domains })
    },
  ]

  const addToWhitelist = (domain: string) => {
    const newWhitelist = [...whitelist, domain]
    setWhitelist(newWhitelist)
  }

  const removeFromWhitelist = (domain: string) => {
    const newWhitelist = whitelist.filter((a) => a !== domain)
    setWhitelist(newWhitelist)
  }

  const [errors, setErrors] = useState<yup.ValidationError[]>([])

  type stateType = {
    title: string
    description: string
    dataCategories: string[]
    useCases: string[]
  }
  const validateSchema = {
    title: yup
      .string()
      .required('Title is required.')
      .min(30, "Title can't be shorter than 30 characters")
      .max(150, "Title can't be longer than 150 characters"),
    description: yup
      .string()
      .required('Description is required.')
      .min(80, "Description can't be shorter than 80 characters")
      .max(3000, "Description can't be longer than 3000 characters"),
    dataCategories: yup
      .array()
      .max(5, 'You can\t choose more than 5 data categories'),
    useCases: yup.array().max(5, 'You can\t choose more than 5 use cases'),
  }

  const internalValidateFunction = async (
    value: stateType | stateType[keyof stateType],
    lookup?: keyof stateType,
  ): Promise<boolean> => {
    const newErrors = await validateFunction<stateType>(
      value,
      validateSchema,
      lookup,
    )
    setErrors(newErrors)
    return newErrors.length === 0
    //return true to dismiss errors
  }

  const internalDeleteError = (identifier: string) => {
    deleteError(identifier, errors, setErrors)
  }

  const internalFindError = (identifier: string) => {
    const error = findError(identifier, errors)
    return !!error && <ErrorWrapper>{error.message}</ErrorWrapper>
  }

  const firstStepValues = {
    title,
    shortDescription: description,
    longDescription: description,
    optimalCompanySize: companySize,
    dataCategories,
    useCases,
  }

  const showHint = () => {
    const hints = {
      public: 'Everyone will see this product.',
      private: 'Only your company will see this product.',
      restricted: 'Only specified domains will see this product.',
    }
    return (
      <Box display={'flex'} alignItems={'center'} sx={{ paddingY: '.5rem' }}>
        <InfoIcon fontSize="small" sx={{ margin: '.175rem' }} />
        <span style={{ fontSize: '12px' }}>{hints[visibility]}</span>
      </Box>
    )
  }

  return (
    <Stepper>
      <Block>
        <Pagination>Step 1/3</Pagination>
        <Title>Basics</Title>
        <Divider />
        <Item
          onFocus={() => {
            internalDeleteError('title')
          }}
          onBlur={(e: any) => internalValidateFunction(title, 'title')}
        >
          <InputComponent
            title="Title"
            defaultValue={title}
            minCharacters={30}
            maxCharacters={106}
            getValue={(e: any) => setTitle(e.target.value.trim())}
          >
            <TitleTooltip />
          </InputComponent>
          {internalFindError('title')}
        </Item>
        {/* <Item
          onFocus={() => {
            internalDeleteError('title')
          }}
          onBlur={(e: any) => internalValidateFunction(title, 'title')}
        > */}
        <InputComponent
          title="Card description"
          defaultValue={shortDesc}
          minCharacters={30}
          maxCharacters={230}
          maxCharMessage="We recommend between %d and %d characters"
          getValue={(e: any) => setShortDesc(e.target.value.trim())}
          noError={true}
        >
          <TitleTooltip />
        </InputComponent>
        {/* {internalFindError('title')} */}
        {/* </Item> */}
        <InputTitle>In-depth Description</InputTitle>
        <Item
          onFocus={() => {
            internalDeleteError('description')
          }}
          onBlur={(e: any) =>
            internalValidateFunction(description, 'description')
          }
        >
          {/* <InputComponent
            title="In-depth Description"
            isTextArea
            defaultValue={description}
            minCharacters={80}
            maxCharacters={3000}
            top="-190px"
            getValue={(e: any) => setDescription(e.target.value.trim())}
          >
            <DescriptionTooltip />
          </InputComponent> */}

          <ReactQuill
            theme="snow"
            modules={modules}
            formats={formats}
            value={description}
            onChange={setDescription}
            style={{ background: '#FFFFFF' }}
          ></ReactQuill>
          {internalFindError('description')}
        </Item>
      </Block>
      <Block>
        <Title>Product</Title>
        <Divider />
        <Item
          onFocus={() => {
            internalDeleteError('dataCategories')
          }}
          onBlur={(e: any) =>
            internalValidateFunction(dataCategories, 'dataCategories')
          }
        >
          <Label>Data Categories</Label>
          <Multiselect
            state={dataCategories.map((category, i) => ({
              id: i,
              label: category,
              value: category,
            }))}
            options={categories.map((category, i) => ({
              id: i,
              label: category,
              value: category,
            }))}
            isMulti
            getValue={(value: any) => setDataCategories(value)}
          />
          {internalFindError('dataCategories')}
        </Item>
        <Item
          onFocus={() => {
            internalDeleteError('useCases')
          }}
          onBlur={(e: any) => internalValidateFunction(useCases, 'useCases')}
        >
          <Label>Use Cases</Label>
          <Multiselect
            state={useCases.map((category, i) => ({
              id: i,
              label: category,
              value: category,
            }))}
            options={useCasesData}
            isCreatable
            isMulti
            getValue={(value: any) => setUseCases(value)}
          />
          {internalFindError('useCases')}
        </Item>
        <Item>
          <Label>Optimal Company Size</Label>

          <RadioButtonsCompanySize
            state={companySize}
            values={companySizeData}
            onChange={(e: any) =>
              arrayCheckboxesHandler(
                e.target.checked,
                companySize,
                setCompanySize,
                (stateValue) => {
                  return stateValue === e.target.value
                },
                e.target.value,
              )
            }
          />
        </Item>
      </Block>
      <Block>
        <Title>Visiblity</Title>
        <Divider />
        <Filter
          data={visibilityMock}
          onChange={(a) => setVisibility(a as Visibility)}
          defaultValue={visibilityMock.find((a) => a.value === visibility)}
          isClearable={false}
        />
        {showHint()}
        {visibility === 'restricted' && (
          <DomainList
            whitelist={whitelist}
            addItem={addToWhitelist}
            removeItem={removeFromWhitelist}
          />
        )}
      </Block>
      <Button
        onClick={async (e) => {
          e.preventDefault
          const isValid = await internalValidateFunction({
            title,
            description,
            dataCategories,
            useCases,
          })
          if (isValid) {
            onClick(e)
          }
        }}
      >
        Continue to next step
        <Arrow />
      </Button>
      {errors.length > 0 && (
        <ErrorWrapper>You must resolve the errors to continue</ErrorWrapper>
      )}
    </Stepper>
  )
}

export default FirstStep
