import * as React from 'react'
import { GridCards } from '@components'
import { injectFilters } from './filterAttributes'
import { ICardProps } from '@/components/ui/card/types'
import { IExploreProps, TabsTypeExplore } from '../types'
import { ListingGridCards } from '@/components/grid-cards/grid-cards-wrappers/listing-grid-cards'

const AllListings: React.FunctionComponent<IExploreProps> = () => {
  return (
    <ListingGridCards
      injectFilter={injectFilters}
      filterTitle={'Products'}
      currentTab={{}}
    />
  )
}

export default AllListings
