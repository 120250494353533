export const injectFilters = {
  'Type of Product': {
    options: [
      {
        id: 1,
        label: 'Datasets, Databases, Feeds, APIs',
        value: 1,
      },
      {
        id: 2,
        label: 'Data Platforms Or Softwares',
        value: 2,
      },
      {
        id: 3,
        label: 'Data Services or Consulting',
        value: 3,
      },
    ],
    value: 'typeOfData',
  },
}
