import React from 'react'
import { Element } from 'react-scroll'
import { IWithScrollToElementProps } from './types'

const WithScrollToElement = <P extends object & IWithScrollToElementProps>(
  Component: React.ComponentType<P>,
  elementId: string,
) => {
  return (props: any) => {
    return (
      <>
        <Element name={elementId}>
          <Component {...(props as P)} />
        </Element>
      </>
    )
  }
}

export default WithScrollToElement
