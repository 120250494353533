import styled from 'styled-components'
import CheckIcon from '@mui/icons-material/Check'
import Button from '@mui/material/Button'
import { IButtonProps } from './types'

export const TasksContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-bottom: 6px;
  border: 1px solid #95a4b2;
  background: white;
`
export const TaskContainer = styled.div`
  /* border: 1px solid #95a4b2; */
  background: white;

  padding: 18px 28px 0 28px;
`
export const SeparatorDiv = styled.div`
  border-bottom: 2px solid #c4c4c4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-bottom: 28px;
  &.taskContainer {
    box-shadow: none;
    border-bottom: none;
  }
`
export const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`
export const ProgressBar = styled.div`
  height: 58px;
  min-width: 50px;
  width: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const StyledCheckIcon = styled(CheckIcon)`
  fill: black !important;
`
export const Texts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  max-width: 450px;
`

export const WhiteButton = styled(Button)`
  color: #000000 !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  border: 1.5px solid #000000 !important;
  padding: 10px 0 !important;
  border-radius: 0 !important;

  &:disabled {
    background-color: rgba(0, 0, 0, 0.15) !important;
  }
`

export const BlackButton = styled(WhiteButton)`
  /* color: ${({ disabled }) =>
    disabled ? '#000000 !important' : '#ffffff !important'}; */
  color: #ffffff !important;
  background: #000000;
  white-space: nowrap;
`
export const IconContainer = styled.div`
  margin-right: 12px;
`

export const ButtonLabel = styled.span``

export const TaskTitle = styled.h2`
  font-size: 18px;
  font-weight: 700;
  color: #000000;
`

export const TaskDesc = styled.p`
  line-height: 1.5;
  color: #303030;
`

export const TaskCTA = styled.div`
  button {
    min-width: 250px;
    max-width: 250px;
    white-space: pre;
    text-transform: uppercase !important;
  }
`
