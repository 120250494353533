import { StepperBarParams } from './types'
import {
  Breadcumb,
  Wrapper,
  Title,
  Step,
  StepNumber,
  StepTitle,
  StepLine,
  Progress,
  ProgressBar,
  ProgressBarFill,
} from './styled'

const StepperBar = ({ stepNumber }: StepperBarParams) => {
  const progress = () => {
    if (stepNumber === 1) {
      return '0'
    } else if (stepNumber === 2) {
      return '33%'
    } else {
      return '66%'
    }
  }

  return (
    <Wrapper>
      <Breadcumb>
        Postings /{' '}
        <span style={{ fontWeight: '700', color: '#ffffff' }}>New...</span>
      </Breadcumb>
      <Title>Data Request</Title>
      <Step active>
        <StepNumber>1</StepNumber>
        <StepTitle>
          Data Need &<br /> Geo-Coverage
        </StepTitle>
        <StepLine active={stepNumber === 2 || stepNumber === 3} />
      </Step>
      <Step active={stepNumber === 2 || stepNumber === 3}>
        <StepNumber>2</StepNumber>
        <StepTitle>
          Budget &<br /> Delivery
        </StepTitle>
        <StepLine active={stepNumber === 3} />
      </Step>
      <Step active={stepNumber === 3}>
        <StepNumber>3</StepNumber>
        <StepTitle>
          Custom
          <br />
          Requirements
        </StepTitle>
      </Step>
      <Progress>
        {stepNumber === 1 ? '0%' : stepNumber === 2 ? '33%' : '66%'} progress
        <ProgressBar>
          <ProgressBarFill width={progress} />
        </ProgressBar>
      </Progress>
    </Wrapper>
  )
}

export default StepperBar
