import { ExtractFnReturnType } from '@/lib/react-query'
import { useInfiniteQuery, useMutation, useQuery } from 'react-query'
import {
  getConversations,
  updateConversationAnonymousUser,
  updateConversationTimestamp,
} from './conversations.api'
import { ConversationQueryStringFilters } from './types'

type GetFiltersQueryFnType = typeof getConversations

/// @TODO: Filtering, pagination parametrization & default values.
export const useGetConversations = (
  filters: ConversationQueryStringFilters,
  refetchInterval?: number,
) => {
  return useQuery<ExtractFnReturnType<GetFiltersQueryFnType>>({
    queryKey: ['conversations', filters],
    queryFn: () => getConversations(filters),
    refetchInterval,
    keepPreviousData: true,
  })
}

export const useInfiniteConversations = (
  filters: ConversationQueryStringFilters,
  refetchInterval?: number,
) => {
  return useInfiniteQuery<ExtractFnReturnType<GetFiltersQueryFnType>>({
    queryKey: ['conversations', filters],
    queryFn: ({ pageParam }) =>
      getConversations({ ...filters, from: pageParam }),
    getNextPageParam: (lastPage, pages) => lastPage.data?.from || undefined,
    refetchInterval,
  })
}

export const useUpdateConvoTimestamp = (onSuccess?: () => void) => {
  return useMutation(updateConversationTimestamp, {
    onSuccess: () => {
      onSuccess && onSuccess()
    },
  })
}

export const useUpdateConvoAnonymousUser = (onSuccess: () => void) => {
  return useMutation(updateConversationAnonymousUser, {
    onSuccess: () => {
      onSuccess()
    },
  })
}
