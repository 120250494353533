import { Message, MessageAttachment, MessageHeader, Wrapper } from './styled'
import { CardAvatar } from '../contact-card/styled'
import { IChat } from './types'
import moment from 'moment'
import { IChatAttachment, IChatProduct } from '../chat/types'
import {
  FilePresentOutlined,
  LinkOutlined,
  OpenInNew,
} from '@mui/icons-material'

const ChatBox = ({ isAlignLeft, message, author, date, attributes }: IChat) => {
  const createAvatar = () => {
    if (author) {
      let avatar = author.slice(0, 2)
      return avatar
    }
  }

  const showMessage = () => {
    const attr = attributes as Record<string, any>
    if (attr?.hasAttachment) {
      const { href, fileName, fileSize } = attr as IChatAttachment
      const size = fileSize ? fileSize / 1024 / 1024 : 0
      const url = href
      return (
        <MessageAttachment href={url} download="download" target="_blank">
          <FilePresentOutlined sx={{ marginRight: '.5rem' }} />
          <span>
            {fileName} ({size.toFixed(2)}mb)
          </span>
        </MessageAttachment>
      )
    } else if (attr?.hasProduct) {
      const { href, title } = attr as IChatProduct
      const url = href
      return (
        <MessageAttachment href={url} target="_blank">
          <LinkOutlined sx={{ marginRight: '.5rem' }} />
          <span>{title}</span>
          <OpenInNew fontSize="inherit" sx={{ marginLeft: '.5rem' }} />
        </MessageAttachment>
      )
    } else {
      return message
    }
  }

  return (
    <Wrapper alignLeft={isAlignLeft}>
      <CardAvatar>{createAvatar()}</CardAvatar>
      <Message>
        <MessageHeader>
          <span>{author}</span> {moment(date).format('MMM Do | h:mm A')}
        </MessageHeader>
        {showMessage()}
      </Message>
    </Wrapper>
  )
}

export default ChatBox
