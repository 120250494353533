import { ApiResponse } from '@/services/types'
import { axios as customAxios } from '@lib/axios'
import axios from 'axios'
import { CompanyProfile, IUpdateCompanyProfile } from '../types'

type ApiCompanyProfileResponse = ApiResponse<CompanyProfile>

export const getCompanyProfile = (
  id: string,
): Promise<ApiCompanyProfileResponse> =>
  customAxios.get(`/company-profiles/${id}`)

export const updateCompanyProfile = ({
  id,
  companyProfile,
}: IUpdateCompanyProfile): Promise<ApiCompanyProfileResponse> =>
  customAxios.put(`/company-profiles/${id}`, { ...companyProfile })

export const getFile = (uuid: string): Promise<string> =>
  customAxios.get(`/getFile/${uuid}`)

export const getImage = (url: string) => axios.get(url)

export const getIndustries = () => {
  return customAxios.get(`/industries`)
}
