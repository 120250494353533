import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Contact = styled.div`
  margin: 0 auto 41px auto;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.5px;
  color: #959595;
  text-align: center;
`

export const LoginCard = styled.div`
  max-width: 682px;
  width: 100%;
  padding: 54px 48px;
  margin: 0 auto;
  background-color: #ffffff;
  text-align: center;
`

export const CardContent = styled.div`
  width: 100%;
  max-width: 382px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`

export const Title = styled.h1`
  font-size: 36px;
  line-height: 44px;
  font-weight: 300;
  margin-bottom: 42px;
`

export const Text = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #939393;
  margin-bottom: 42px;
`

export const Button = styled.button.attrs(({ type }) => ({
  type: type || 'button',
}))`
  background-color: #060606;
  border: 1px solid #060606;
  color: #ffffff;
  font-size: 14px;
  line-height: 22px;
  padding: 17px 34px;
  cursor: pointer;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 32px;
`

export const Line = styled.div`
  background-color: #d9d9d9;
  width: 100%;
  height: 1px;
  margin: 38px 0;
`

export const TextLink = styled(Link)`
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
  color: #000000;
  text-decoration: none;
  overflow-wrap: break-word;
  margin-bottom: 60px;
  &:hover {
    opacity: 0.5;
  }
  &.white {
    color: white;
  }
`

export const TextSmall = styled.div`
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  color: #939393;
  text-decoration: none;
  margin-top: 12px;
  letter-spacing: -0.3px;
`

export const TextBold = styled.div`
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: #282828;
  margin-bottom: 24px;
`

export const CopyrightWrapper = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 200%;
  text-align: center;
  color: #929292;
  margin-top: 74px;
`
