import { useSearchParams } from 'react-router-dom'
import { Container, LoginWrapper, Logo } from '../../components'
import {
  LoginCard,
  Title,
  Button,
  Text,
  TextLink,
  TextSmall,
  Contact,
  CardContent,
  TextBold,
  CopyrightWrapper,
} from './styled'

const EmailVerification = () => {
  const axios = require('axios').default
  const [params] = useSearchParams()
  const mail = params.get('username')
  const baseUrl = process.env.REACT_APP_URL_BASE

  const fetchPost = () => {
    const res = axios
      .post(`${baseUrl}/api/v1/signup-validation`, {
        username: mail,
      })
      .then(function (response: any) {
        return response
      })
      .catch(function (error: any) {
        return error
      })
    return res
  }

  const onSubmit = async () => {
    try {
      const isSend = await fetchPost()
      if (isSend.message) {
        return console.log(isSend.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Container>
      <LoginWrapper>
        <Logo maxWidth="230px" margin="0 auto 47px auto" />
        {/* <Contact>
          Need help?{' '}
          <TextLink className={'white'} to="/">
            Contact us.
          </TextLink>
        </Contact> */}
        <LoginCard>
          <CardContent>
            <Title>Email Verification</Title>
            <Text>
              To complete email verification, please click the verify button
              below.
            </Text>
            <Button type="submit" onClick={onSubmit}>
              Verify your email
            </Button>
            <Text>Or verify using this link:</Text>
            <TextLink to="/email-verification" rel="noopener noreferrer">
              https://confirmation.dly/mail/mu/mp/673/#cv/priority/#5Esmartlabel_personal
            </TextLink>
            <TextBold>
              This link is valid for one use only. It will expire in 2 hours.
            </TextBold>
            <TextSmall>
              If you didn’t request this password reset or you received this
              message in error, please disregard this email.
            </TextSmall>
          </CardContent>
        </LoginCard>
        <CopyrightWrapper>
          Please do not reply to this email. Emails sent to this address will
          not be answered.
          <br /> Copyright 2020-2022 Kamba, LLC. 14455 N. Hayden Rd, Ste. 219,
          Scottsdale, AZ 85260 USA. All rights reserved
        </CopyrightWrapper>
      </LoginWrapper>
    </Container>
  )
}

export default EmailVerification
