import { useState } from 'react'
import { RadioButtonsArrayProps } from './types'
import {
  RadioWrapper,
  RadioRow,
  Radio,
  RadioButton,
  RadioLabel,
  TextWrapper,
} from './styled'

const RadioButtons = ({
  values,
  setValue,
  onChange,
  value,
}: RadioButtonsArrayProps) => {
  // const [checkValue, setCheckValue] = useState('')

  return (
    <RadioWrapper>
      {values.length > 0 &&
        values.map((option: any, index: number) => (
          <RadioRow isChecked={value === option.value} key={index}>
            <Radio>
              <RadioButton
                type="radio"
                value={option.value}
                name="type"
                onClick={(e: any) => {
                  setValue(e.target.value)
                }}
                // onChange={onChange}
              />
              <span />
            </Radio>
            <TextWrapper>
              <RadioLabel>{option.label}</RadioLabel>
              {option.info && <span>{option.info}</span>}
            </TextWrapper>
          </RadioRow>
        ))}
    </RadioWrapper>
  )
}

export default RadioButtons
