import { TextWrapper, Wrapper } from './styled'
import message from '@assets/images/icons/message-square'

interface EmptyStateProps {
  msg?: string
}

const EmptyState: React.FC<EmptyStateProps> = ({ msg }) => {
  const text = msg || 'Select a conversation from the list on the left'

  return (
    <Wrapper>
      <img src={message} alt="chat image" />
      <TextWrapper>{text}</TextWrapper>
    </Wrapper>
  )
}

export default EmptyState
