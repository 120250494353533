import styled from 'styled-components'
import check from '@assets/images/checkbox'
import { IRadioButtonStylingProps } from './types'

export const RadioButtonStyled = styled.input<IRadioButtonStylingProps>`
  height: ${({ heigth }) => `${heigth || '22px'}`};
  width: ${({ width }) => `${width || '22px'}`};
  margin: ${({ margin }) => `${margin || 0}`};
  ${({ position }) => position && `position: ${position};`}
  ${({ alignSelf }) => alignSelf && `align-self: ${alignSelf};`}
  ${({ top }) => top && `top: ${top};`}
  ${({ right }) => right && `right: ${right};`}
  ${({ flexBasis }) => flexBasis && `flex-basis: ${flexBasis};`}
  appearance: none;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  &:checked {
    appearance: none;
    outline: none;
    padding: 0;
    content: none;
    border: none;
  }
  &:checked:after {
    height: ${({ heigth }) => `${heigth || '22px'}`};
    width: ${({ width }) => `${width || '22px'}`};
    border-radius: 0px;
    position: relative;
    background-image: url(${check});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 30px 30px;
    background-color: #ffffff;
    content: '';
    display: inline-block;
    visibility: visible;
  }
`

export const RadioLabel = styled.p`
  color: #000000b3;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  margin: 0 20px 0 5px;
`
