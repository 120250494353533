import { HeadCell } from '@/components/table/auxiliar/types'

export const columns: HeadCell[] = [
  /*{
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },*/
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'File',
  },
  /*{
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    badge: true,
  },*/
  /*{
    id: 'dueDate',
    numeric: false,
    disablePadding: false,
    label: 'Due Date',
  },*/
  /*{
    id: 'notes',
    numeric: false,
    disablePadding: false,
    label: 'Notes',
    maxCharactersWidth: 7,
  },*/
  {
    id: 'lastUpdates',
    numeric: false,
    disablePadding: false,
    label: 'Last Updates',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'Actions',
    upload: true,
    notSortable: true,
  },
]
