import { TabsBase } from 'components/tabs-navigation/types'

type PostingSections = 'all' | 'active'

export interface TabsPosting extends TabsBase {
  id: PostingSections
}

export interface IPostingsProps {
  createdAt?: string
  updatedAt?: string
  id: string
  title: string
  geo?: string[]
  budgetLow?: number
  budgetHigh?: number
  details?: string
  delivery?: string
}

enum Tabs {
  toreview = 'toreview',
  proposed = 'proposed',
  accepted = 'accepted',
  canceled = 'canceled',
}

export type TabsTypePosting = {
  [tab in Tabs]: number
}

export const TabsPosting: TabsTypePosting = {
  toreview: 1,
  proposed: 2,
  accepted: 3,
  canceled: 4,
}
