import Select from 'react-select'
import { SelectStyled } from './styled'
import { SelectProps } from './types'

const SelectComponent = ({
  value,
  options,
  placeholder,
  onChange,
  defaultValue,
}: SelectProps) => {
  const customStyles = {
    container: (provided: any) => ({
      ...provided,
      width: '100%',
    }),
    control: (provided: any) => ({
      ...provided,
      height: '54px',
    }),
    dropdownIndicator: (provided: any, state: any) => ({
      ...provided,
      transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
      transition: '250ms',
      color: '#000000',
      '&:hover': {
        color: '#000000',
      },
    }),
  }

  return (
    <Select
      value={value}
      styles={customStyles}
      placeholder={placeholder}
      options={options}
      theme={(theme: any) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary: 'black',
        },
      })}
      noOptionsMessage={() => 'Loading...'}
      components={{ IndicatorSeparator: () => null }}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  )
}

export const SelectComponent2 = ({
  options,
  placeholder,
  onChange,
  defaultValue,
}: SelectProps) => {
  const customStyles = {
    container: (provided: any) => ({
      ...provided,
      width: '100%',
    }),
    control: (provided: any) => ({
      ...provided,
      height: '54px',
    }),
    dropdownIndicator: (provided: any, state: any) => ({
      ...provided,
      transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
      transition: '250ms',
      color: '#000000',
      '&:hover': {
        color: '#000000',
      },
    }),
  }
  return (
    <SelectStyled onChange={onChange}>
      {options.map((option) => {
        return (
          <option id={`${option.id}`} value={option.value}>
            {option.label}
          </option>
        )
      })}
    </SelectStyled>
  )
}

export default SelectComponent
