import { BlockContainer } from './styled'
import { ContainerProps } from './types'

const Container = ({ children, backgroundColor, ...props }: ContainerProps) => {
  return (
    <BlockContainer backgroundColor={backgroundColor} {...props}>
      {children}
    </BlockContainer>
  )
}

export default Container
