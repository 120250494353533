import { Grid } from '@mui/material'
import * as React from 'react'
import { IMetricProps } from './types'
import { ValueDesc, ValueMetric } from './styled'

const Metric: React.FC<IMetricProps> = ({ value, description }) => {
  return (
    <Grid
      container
      spacing={0}
      sx={{ height: '100%', display: 'flex', justifyContent: 'center' }}
    >
      <Grid xs={12} item>
        {/* <ValueMetric>{value ? value : '-----'}</ValueMetric> */}
        <ValueMetric>{value}</ValueMetric>
      </Grid>
      <Grid xs={12} item>
        <ValueDesc>{description}</ValueDesc>
      </Grid>
    </Grid>
  )
}

export default Metric
