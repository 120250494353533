import { TabsExplore } from './types'
import DataSuppliers from './data-suppliers'
import { TabsBase } from '@/components/tabs-navigation/types'
import AllListings from './data-all'

export const tabs: TabsBase[] = [
  {
    label: 'Products',
    Component: AllListings,
    id: TabsExplore.datasets,
  },
  {
    label: 'Suppliers',
    Component: DataSuppliers,
    id: TabsExplore.datasuppliers,
  },
]
