import { ApiResponse } from '@/services/types'
import { axios } from '@lib/axios'
import { Subscription } from '../types'

type ApiSubscriptionsResponse = ApiResponse<Subscription[]>
type ApiSubscriptionResponse = ApiResponse<Subscription>

export const getSubscriptions = (
  userId: string,
): Promise<ApiSubscriptionsResponse> => {
  return axios.get(`/subscriptions/${userId}`)
}

export const getSubsById = (
  subId: string,
): Promise<ApiSubscriptionResponse> => {
  return axios.get(`/subscription/${subId}`)
}
