import { Button, GradientSeparator, Title } from '@/components'
import { DescriptionStyled } from '../data-offering/styled'
import { ButtonsWrapper } from './styled'

interface ILegalComplianceProps {
  privacy: string
  terms: string
}

const LegalCompliance: React.FC<ILegalComplianceProps> = ({
  privacy,
  terms,
}) => {
  return (
    <>
      <Title
        margin="0 0 15px 0"
        boldText="Legal and Compliance"
        fontSize="30px"
      />
      <ButtonsWrapper>
        {privacy && (
          <Button
            variant="secondary"
            onClick={() =>
              window.open(privacy, '_blank', 'noopener,noreferrer')
            }
            maxWidth="200px"
          >
            Privacy Policy
          </Button>
        )}
        {terms && (
          <Button
            variant="secondary"
            onClick={() => window.open(terms, '_blank', 'noopener,noreferrer')}
            maxWidth="200px"
          >
            Terms and Conditions
          </Button>
        )}
      </ButtonsWrapper>
      <GradientSeparator margin="30px 0" />
    </>
  )
}

export default LegalCompliance
