import { axios } from '@/lib/axios'
import { ApiResponse } from '../types'
import { ConversationQueryStringFilters, ConversationResponse } from './types'
import qs from 'qs'

type ApiConversationsResponse = ApiResponse<ConversationResponse>

// @TODO: Add pagination via params
export const getConversations = (
  filters: ConversationQueryStringFilters,
): Promise<ApiConversationsResponse> => {
  return axios.get(`/conversations`, {
    params: filters,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    },
  })
}

interface UpdateConvoTimestamp {
  id: string
  timestamp: number
}

export const updateConversationTimestamp = ({
  id,
  timestamp,
}: UpdateConvoTimestamp) =>
  axios.patch(`/conversations/${id}`, {
    lastMessage: timestamp,
  })

interface UpdateConvoAnonymousUsr {
  id: string
}

export const updateConversationAnonymousUser = ({
  id,
}: UpdateConvoAnonymousUsr) =>
  axios.patch(`/conversations/${id}`, {
    anonymous: false,
  })
