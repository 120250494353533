import axios from 'axios'

const baseUrl = process.env.REACT_APP_URL_BASE

export type AccountData = {
  code: string
  username: string
}

export const signupValidation = ({ username, code }: AccountData) =>
  axios.post(`${baseUrl}/api/v1/signup-validation`, {
    username: username,
    code: code,
  })
