// import { styled } from '@mui/material/styles'
import styled from 'styled-components'
import AppBar from '@mui/material/AppBar'
import InputBase from '@mui/material/InputBase'
import ClearIcon from '@mui/icons-material/Clear'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { IconProps, IDropdownItemProps } from './types'
import { pointer } from 'd3'
import { blue } from '@mui/material/colors'
import { DEGREES } from '@amcharts/amcharts5/.internal/core/util/Math'

export const appBarStyled = styled.div`
  display: flex;
  align-items: center;
  background: #000000;
  border: 1px solid #1f1f1f;
  justify-content: space-between;
  padding: 14px 24px;
`

export const LogoSection = styled.div`
  display: flex;
  align-items: center;
`

export const VerticalDivider = styled.div`
  margin-left: 16px;
  margin-right: 12px;
  border-left: 1px solid #444444;
  height: 24px;
`

export const Search = styled.div`
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  margin-left: 0;
  width: 40%;
`

export const ClearIconWrapper = styled.div`
  position: absolute;
  cursor: pointer;
  top: 13px;
  right: 60px;
`

export const SearchIconWrapper = styled.div`
  position: absolute;
  cursor: pointer;
  height: 100%;
  background-color: #f8f8f8;
  background-repeat: no-repeat;
  border: 1px solid #f6f6f6;
  background-position: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Icon = styled('img')<IconProps>(
  ({ theme, src, maxWidth, padding }) => ({
    src: src,
    alt: 'icon',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: padding ? padding : '20px',
    maxWidth: maxWidth ? maxWidth : '119px',
  }),
)

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: '#9E9E9E',
  fontFamily: 'inherit',
  fontWeight: 500,
  fontSize: '17px',
  backgroundColor: '#FFFFFF',
  border: '1px solid #F6F6F6',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: '12px 21px',
    width: '100%',
  },
}))

export const Title = styled.span`
  height: 12px;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #b8b8b8;
`

export const LoginButton = styled.div`
  padding: 13px 36px;
  width: 100%;
  color: #ffffff;
  font-weight: 700;
  font-size: 14px;
  border: 1.5px solid #ffffff;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
`

export const SignUpButton = styled(LoginButton)`
  color: #000000;
  white-space: pre;
  background: #ffffff;
  margin-left: 18px;
  &:hover {
    background-color: #f1f1f1;
  }
`

export const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 270px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 100%;
  right: 0;
`

export const AvatarWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  & .icon {
    transition: all 0.4s;
  }
  &:hover .content {
    display: block;
  }
  &:hover .icon {
    transform: rotate(180deg);
  }
`

export const DropdownItem = styled.div<IDropdownItemProps>`
  background: ${({ first }) => first && '#000000'};
  color: ${({ first }) => (first ? '#ffffff' : '#000000')};
  padding: 18px 16px;
  text-decoration: none;
  display: flex;
  white-space: pre;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  border-top: ${({ last }) => last && '1px #757575 solid'};
  justify-content: space-between;

  & > div {
    display: flex;
    align-items: center;
  }

  & img {
    width: 13px;
    height: 12px;
    margin-right: 10px;
  }

  & .version {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #757575;
  }

  &:hover {
    cursor: pointer;
    background-color: ${({ first }) => !first && '#ddd'};
    transition: background-color 0.2s;
  }
`
