import { useContext } from 'react'
import { TwilioContext } from '..'

export const useTwilioContext = () => {
  const context = useContext(TwilioContext)

  if (context === undefined) {
    throw new Error('useTwilioContext was used outside of its Provider')
  }

  return { ...context }
}
