import { Button } from '@/components'
import InputComponent from '@/components/ui/input'
import { useUpdateCompanyProfile } from '@/services/storefront/storefront.service'
import { CompanyProfile } from '@/services/storefront/types'
import { useState } from 'react'
import { ClipLoader } from 'react-spinners'
import {
  Benefit,
  CustomDivider,
  FormTitle,
  FormWrapper,
  InputTitle,
  KeyBenefits,
  Label,
  ValueWrapper,
  Modifier,
  Preview,
  SavedChanges,
  Value,
} from '../styled'
import { IFormProps } from '../types'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { queryClient } from '@/lib/react-query'
import ReactQuill, { Quill } from 'react-quill'
import { Label as TitleLabel } from '@/components/ui/input/styled'
import { formats, modules } from '../company-info/rich-text-config'
import FileUploader from 'quill-file-uploader'
Quill.register('modules/fileUploader', FileUploader)

type TBenefits =
  | { value: number | undefined; unit: string | undefined }[]
  | undefined

const Benefits: React.FC<IFormProps> = ({ id, companyProfile }) => {
  const [savedChanges, setSavedChanges] = useState(false)
  const [benefitsObj, setBenefitsObj] = useState<TBenefits>(
    companyProfile?.variables?.length! > 1
      ? companyProfile.variables
      : [
          { value: undefined, unit: undefined },
          { value: undefined, unit: undefined },
          { value: undefined, unit: undefined },
          { value: undefined, unit: undefined },
        ],
  )
  const [keyDifferentiatorsInput, setKeyDifferentiatorsInput] = useState<
    string | undefined
  >(companyProfile.keyDifferentiators)
  const [error, setError] = useState<boolean>(false)

  let updatedCompanyProfile: CompanyProfile = { ...companyProfile }

  const { mutate, isLoading } = useUpdateCompanyProfile(() => {
    setSavedChanges(true)
    queryClient.invalidateQueries({ queryKey: ['company-profile'] })
  })

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    updatedCompanyProfile = {
      ...updatedCompanyProfile,
      keyDifferentiators: keyDifferentiatorsInput,
      variables: benefitsObj,
    }

    mutate({ id: id, companyProfile: updatedCompanyProfile })
  }

  const handleChange = (e: any, slot: number, attribute: string) => {
    let updatedValue = e.target.value
    if (attribute === 'value' && isNaN(updatedValue)) {
      updatedValue = parseFloat(updatedValue)
    }
    setBenefitsObj((prevState) =>
      prevState?.map(
        (
          benefit: { value: number | undefined; unit: string | undefined },
          index: number,
        ) => {
          if (slot === index) {
            return { ...benefit, [attribute]: updatedValue }
          }

          return benefit
        },
      ),
    )
  }

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <FormTitle>Benefits</FormTitle>
      <InputTitle>
        {`Showcase the main metrics that make ${companyProfile.name} stand out from the crowd`}
      </InputTitle>
      <Preview>
        <span>Preview</span>
        <VisibilityOutlinedIcon />
      </Preview>
      <KeyBenefits>
        <Benefit>
          <Label>
            {benefitsObj && benefitsObj[0].value
              ? benefitsObj[0].value
              : 'Value'}
          </Label>
          <Value>
            {benefitsObj && benefitsObj[0].unit ? benefitsObj[0].unit : 'Unit'}
          </Value>
        </Benefit>
        <CustomDivider />
        <Benefit>
          <Label>
            {benefitsObj && benefitsObj[1].value
              ? benefitsObj[1].value
              : 'Value'}
          </Label>
          <Value>
            {benefitsObj && benefitsObj[1].unit ? benefitsObj[1].unit : 'Unit'}
          </Value>
        </Benefit>
        <CustomDivider />
        <Benefit>
          <Label>
            {benefitsObj && benefitsObj[2].value
              ? benefitsObj[2].value
              : 'Value'}
          </Label>
          <Value>
            {benefitsObj && benefitsObj[2].unit ? benefitsObj[2].unit : 'Unit'}
          </Value>
        </Benefit>
        <CustomDivider />
        <Benefit>
          <Label>
            {benefitsObj && benefitsObj[3].value
              ? benefitsObj[3].value
              : 'Value'}
          </Label>
          <Value>
            {benefitsObj && benefitsObj[3].unit ? benefitsObj[3].unit : 'Unit'}
          </Value>
        </Benefit>
      </KeyBenefits>
      <Modifier>
        <p>#1</p>
        <CustomDivider height={'85px'} />
        <InputComponent
          type="number"
          title="Value (numbers only)"
          value={benefitsObj && benefitsObj[0].value}
          getValue={(e: any) => handleChange(e, 0, 'value')}
        ></InputComponent>
        <ValueWrapper>
          <InputComponent
            title="Unit"
            value={benefitsObj && benefitsObj[0].unit}
            getValue={(e: any) => handleChange(e, 0, 'unit')}
          ></InputComponent>
        </ValueWrapper>
      </Modifier>
      <Modifier>
        <p>#2</p>
        <CustomDivider height={'85px'} />
        <InputComponent
          type="number"
          title="Value (numbers only)"
          value={benefitsObj && benefitsObj[1].value}
          getValue={(e: any) => handleChange(e, 1, 'value')}
        ></InputComponent>
        <ValueWrapper>
          <InputComponent
            title="Unit"
            value={benefitsObj && benefitsObj[1].unit}
            getValue={(e: any) => handleChange(e, 1, 'unit')}
          ></InputComponent>
        </ValueWrapper>
      </Modifier>
      <Modifier>
        <p>#3</p>
        <CustomDivider height={'85px'} />
        <InputComponent
          type="number"
          title="Value (numbers only)"
          value={benefitsObj && benefitsObj[2].value}
          getValue={(e: any) => handleChange(e, 2, 'value')}
        ></InputComponent>
        <ValueWrapper>
          <InputComponent
            title="Unit"
            value={benefitsObj && benefitsObj[2].unit}
            getValue={(e: any) => handleChange(e, 2, 'unit')}
          ></InputComponent>
        </ValueWrapper>
      </Modifier>
      <Modifier>
        <p>#4</p>
        <CustomDivider height={'85px'} />
        <InputComponent
          type="number"
          title="Value (numbers only)"
          value={benefitsObj && benefitsObj[3].value}
          getValue={(e: any) => handleChange(e, 3, 'value')}
        ></InputComponent>
        <ValueWrapper>
          <InputComponent
            title="Unit"
            value={benefitsObj && benefitsObj[3].unit}
            getValue={(e: any) => handleChange(e, 3, 'unit')}
          ></InputComponent>
        </ValueWrapper>
      </Modifier>
      <FormTitle>Differentiators</FormTitle>
      <InputTitle>Key Differentiators</InputTitle>
      <>
        <TitleLabel marginBottom="0">
          What makes you different from your competitors in terms of quality,
          quantity, service, pricing or other factors?
        </TitleLabel>
        <ReactQuill
          theme="snow"
          modules={modules}
          formats={formats}
          value={keyDifferentiatorsInput}
          onChange={setKeyDifferentiatorsInput}
          style={{ background: '#FFFFFF' }}
        ></ReactQuill>
      </>
      <Button variant="primary" width={'100%'} type="submit">
        {isLoading ? (
          <ClipLoader color="#ffffff" size={12} />
        ) : (
          <span>SAVE CHANGES</span>
        )}
      </Button>

      {savedChanges && <SavedChanges>Changes Saved!</SavedChanges>}
    </FormWrapper>
  )
}

export default Benefits
