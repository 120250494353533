import styled from 'styled-components'

export const ErrorWrapper = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  margin: 20px auto 0;
  color: #ff0000;
`
