import styled from 'styled-components'

export const Input = styled.input`
  width: 100%;
  max-width: 104px;
  height: 54px;
  padding: 14px 18px;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  color: #000000b2;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  &:focus {
    border: 1px solid #000000;
  }
  &:focus-visible {
    border: 1px solid #00000;
    outline: 1px solid #000000;
  }
`

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
`
