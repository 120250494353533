import AppProvider from './providers/app'
import Routing from './routing'

function App() {
  return (
    <AppProvider>
      <Routing />
    </AppProvider>
  )
}

export default App
