import React, { useRef, useState } from 'react'
import { IconButtonWrapper } from '@/pages/dashboard/listings/components/icon-button'
import { TextWrapper } from '@/pages/dashboard/listings/tab/styled'
import {
  Box,
  // Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import AddOutline from '@mui/icons-material/AddOutlined'
import InputComponent from '../input'
import { ButtonStyled } from '@/components/ui/button/styled'
import { Input } from '@/components/ui/input/styled'
import isValid from 'is-valid-domain'
import { CharactersMessage } from '../input/styled'

interface DomainListProps {
  whitelist: string[]
  addItem: (item: string) => void
  removeItem: (item: string) => void
}

const DomainList: React.FC<DomainListProps> = ({
  whitelist,
  addItem,
  removeItem,
}) => {
  // @TODO: Add validation
  const [newItem, setNewItem] = useState('')
  const [error, setError] = useState('')

  const add = () => {
    if (isValid(newItem)) {
      addItem(newItem)
      setNewItem('')
    } else {
      setError('Invalid Domain Name. eg: guerrillamail.de')
    }
  }

  return (
    <>
      <Box display={'flex'} flexDirection={'row'} paddingY={'1rem'}>
        <Input
          value={newItem}
          onChange={(e) => setNewItem(e.target.value.trim().toLowerCase())}
          style={{ width: '75%' }}
          placeholder="type a domain. eg: guerrillamail.de"
        />
        <ButtonStyled onClick={add} width="unset" marginLeft={'1.5rem'}>
          <AddOutline fontSize="inherit" sx={{ color: '#FFF' }} />
        </ButtonStyled>
      </Box>
      {error && (
        <Box marginBottom={'10px'}>
          <CharactersMessage>
            <span>{error}</span>
          </CharactersMessage>
        </Box>
      )}
      <TableContainer component={Paper} style={{ marginBottom: '60px' }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Domain</TableCell>
              <TableCell style={{ width: '15%' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {whitelist.map((item: string, index) => (
              <TableRow
                key={item + '_' + index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <TextWrapper>{item}</TextWrapper>
                </TableCell>
                <TableCell valign="top">
                  <Tooltip placement="top" title="Remove this item">
                    <IconButton
                      size="small"
                      onClick={() => {
                        removeItem(item)
                      }}
                    >
                      <DeleteOutlineIcon
                        fontSize="inherit"
                        sx={{ color: '#000000' }}
                      />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default DomainList
