import { useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { useAuth } from '@services/auth/auth'
import { loginPending, loginSuccess, loginFail } from '../../slices/loginSlice'
import {
  setUserId,
  setUserRole,
  setCompanyId,
  setDashboard,
  setUserMail,
  setCognitoId,
  setUserAdmin,
} from '../../slices/authUserSlice'
import {
  Container,
  LoginWrapper,
  Logo,
  LoginCard,
  InputBlock,
  Checkbox,
  Icon,
} from '@components'
import {
  Divider,
  Title,
  FormGroup,
  Button,
  Line,
  TextLink,
  TextWrapper,
  CheckWrapper,
} from './styled'
import { AxiosError } from 'axios'
import { useGetDashboard } from '@/services/suppliers/suppliers.service'
import { useEffect, useRef } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import MailIcon from '@assets/images/icons/MailIcon.png'
import PasswordIcon from '@assets/images/icons/PasswordIcon.png'
import storage from '../../utils/storage'
import { AuthUser } from '@/services/auth/types'

type LoginData = {
  username: string
  password: string
  remember: boolean
}

const schema = yup
  .object({
    username: yup.string().required('Email is required'),
    password: yup.string().required('Password is required'),
  })
  .required()

const Login = () => {
  const { login, isLoggingIn } = useAuth()
  const { userId, userRole } = useAppSelector((state) => state.authUser)
  const userEmail = useRef<string | undefined>()
  const dispatch = useAppDispatch()
  const { error } = useAppSelector((state) => state.login)
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<LoginData>({
    resolver: yupResolver(schema),
  })

  const workEmailRegister = { ...register('username') }
  const passwordRegister = { ...register('password') }

  const { isLoading: isDashboardLoading, data: dashboard } = useGetDashboard({
    userId: userId ?? '', // TODO: error handler
    enabled: userId && userId != '' && userRole === 'seller' ? true : false,
  })

  const onSubmit = handleSubmit(async (data: LoginData) => {
    dispatch(loginPending())
    try {
      const response = (await login(data)) as AuthUser
      storage.setUserMail(data.username)
      dispatch(setUserMail(data.username))

      if (response.data) {
        if (response.data.userId) {
          dispatch(setUserId(response.data.userId))
          storage.setUserId(response.data.userId)
        }
        if (response.data.userRole) {
          dispatch(setUserRole(response.data.userRole))
          storage.setUserRole(response.data.userRole)
        }
        if (response.data.companyId) {
          dispatch(setCompanyId(response.data.companyId))
          storage.setCompanyId(response.data.companyId)
        }
        if (response.data.cognitoId) {
          dispatch(setCognitoId(response.data.cognitoId))
          storage.setCognitoId(response.data.cognitoId)
        }
        if (response.data.isAdmin === true) {
          dispatch(setUserAdmin(response.data.isAdmin))
          storage.setUserAdmin(response.data.isAdmin)
        }
        if (response.data?.userRole === 'buyer') {
          navigate('/')
        }
        dispatch(loginSuccess())
      }
    } catch (e) {
      const err = e as AxiosError | Error
      const { response }: any = err
      const {
        data: { response: errorResponse },
      } = response

      const errorMessage = errorResponse || err.message
      dispatch(loginFail(errorMessage))
    }
  })

  useEffect(() => {
    if (dashboard && dashboard.ok && dashboard.data) {
      dispatch(setDashboard(dashboard.data))
      dispatch(loginSuccess())
      navigate('/suppliers-dashboard')
    }
  }, [dashboard])

  return (
    <Container minHeight={'100vh'}>
      <LoginWrapper>
        <Logo />
        <LoginCard maxWidth="528px">
          <Divider />
          <Title>Login</Title>
          <FormGroup onSubmit={onSubmit}>
            <InputBlock
              label="Work Email"
              isMail
              onChange={register('username').onChange}
              type="email"
              params={workEmailRegister}
              errorMessage={errors.username?.message}
              padding="16px 35px"
            >
              <Icon src={MailIcon} position="absolute" top="50px" left="6px" />
            </InputBlock>

            <InputBlock
              label="Password"
              type="password"
              isPassword
              onChange={register('password').onChange}
              params={passwordRegister}
              errorMessage={errors.password?.message}
              padding="16px 35px"
            >
              <Icon
                src={PasswordIcon}
                position="absolute"
                top="50px"
                left="6px"
              />
            </InputBlock>
            {error && <h5 style={{ color: 'red' }}>{error}</h5>}
            <CheckWrapper>
              <Checkbox label="Remember me" />
            </CheckWrapper>
            <Button type="submit">
              {isSubmitting || isDashboardLoading ? (
                <ClipLoader
                  color="#ffffff"
                  loading={isSubmitting || isDashboardLoading}
                  size={15}
                />
              ) : (
                `Log In`
              )}
            </Button>
          </FormGroup>
          <Line />
          <TextLink to="/forgot-password" rel="noopener noreferrer">
            Forgot your Password?
          </TextLink>
          <TextWrapper>
            Not a member yet?{' '}
            <TextLink to="/create-account" rel="noopener noreferrer">
              Create account
            </TextLink>
          </TextWrapper>
        </LoginCard>
      </LoginWrapper>
    </Container>
  )
}

export default Login
