import styled from 'styled-components'
import {
  IButtonProps,
  IPipelineColProps,
  ToggleButtonProps,
  ToggleIconProps,
} from './types'
import icon from '@assets/images/icons/pipeline-toggle-icon.png'
import { IconButton } from '@mui/material'

export const CompanyRow = styled.div`
  display: flex;
  column-gap: 12px;
`

export const CompanyCol = styled.div`
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #000000;
`

export const CompanyUser = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #474747;
  display: flex;
`

export const CompanyUserAvatar = styled.div`
  width: 21px;
  height: 21px;
  border-radius: 100%;
  font-weight: 800;
  font-size: 9px;
  line-height: 15px;
  color: #6a6a6a;
  border: 0.525px solid #6a6a6a;
  margin-right: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Stage = styled.div`
  padding: 12px 26px;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #5e5e5e;
  background-color: #d9d9d9;
  display: inline-block;
  text-transform: capitalize;
`

export const CTAWrapper = styled.div`
  display: flex;
  column-gap: 10px;
`

export const Button = styled.button.attrs(({ type }) => ({
  type: type || 'button',
}))<IButtonProps>`
  border: ${({ borderColor }) => '1px solid' + (borderColor || '#000000')};
  background-color: ${({ backgroundColor }) =>
    backgroundColor || 'transparent'};
  color: ${({ color }) => color || '#ffffff'};
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  padding: ${({ padding }) => padding || '6px 16px'};
  height: 33px;
  cursor: pointer;
  margin: 0;
`

export const PipelineTable = styled.div`
  padding-top: 33px;
  background-color: #ffffff;
  border: 1px solid #c4c4c4;
  display: flex;
  min-height: 500px;
`

export const PipelineCol = styled.div<IPipelineColProps>`
  width: 16.6%;
  padding: 0 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: ${({ noBorder }) => (noBorder ? 'none' : '1px solid #C4C4C4')};
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: #8b8b8b;
`

export const PipelineColTitle = styled.h3`
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  color: #000000;
`

export const PipelineCardWrapper = styled.div`
  width: 100%;
  margin-top: 28px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`

export const PipelineCard = styled.div`
  width: 100%;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  padding: 18px 15px;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: #8b8b8b;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  cursor: pointer;
`

export const PipelineCardRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const PipelineCardCompany = styled.div`
  width: 100%;
  display: flex;
  column-gap: 6px;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
`

export const ToggleViewContainer = styled.div`
  width: fit-content;
  display: flex;
  margin: 20px 0;
  margin-left: auto;
`

export const ToggleButton = styled.button<ToggleButtonProps>`
  height: 48px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  border: 1px solid #efefef;
  border-radius: unset;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
  color: ${({ active }) => (active ? '#000000' : '#757575')};
  background-color: ${({ active }) => (active ? '#efefef' : 'white')};
`

export const ToggleIcon = styled.img.attrs({
  src: icon,
  alt: 'close image',
})<ToggleIconProps>`
  cursor: pointer;
  margin-left: 10px;
  opacity: ${({ active }) => (active ? '1' : '0.5')};
`
