import styled from 'styled-components'
import { LabelProps, ItemProps } from './types'
import arrow from '@assets/images/arrow-right'
import arrowLeft from '@assets/images/arrow-left.png'

export const Wrapper = styled.div`
  width: 100%;
  padding: 50px 0 150px 0;
`

export const Stepper = styled.div`
  width: 100%;
  max-width: 701px;
  padding: 48px 107px;
  background-color: #f6f6f6;
  border: 1px solid #e9e9e9;
  margin: 0 auto;
`

export const Block = styled.div`
  margin-bottom: 48px;
`

export const Pagination = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 128%;
  letter-spacing: 0.05em;
  color: #646464;
  margin-bottom: 7px;
`

export const Title = styled.h2`
  font-weight: 700;
  font-size: 28px;
  line-height: 128.8%;
  letter-spacing: -0.015em;
  color: #000000;
`

export const Subtitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 128.8%;
  letter-spacing: -0.01em;
  color: #686868;
`

export const TextSmall = styled.p`
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.065em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e5e5e5;
  margin: 26px 0;
`

export const Label = styled.p<LabelProps>`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 144%;
  color: #000000b2;
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom : '18px'};
`

export const Item = styled.div<ItemProps>`
  width: 100%;
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom : '36px'};
`

export const Button = styled.button.attrs(({ type }) => ({
  type: type || 'button',
}))`
  background-color: #060606;
  border: 1px solid #060606;
  color: #ffffff;
  font-size: 14px;
  line-height: 22px;
  padding: 17px 34px;
  cursor: pointer;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-top: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Arrow = styled.img.attrs({
  src: arrow,
  alt: 'arrow',
})`
  margin-left: 16px;
`

export const StepperBarWrapper = styled.div`
  position: fixed;
  top: 172px;
  margin-left: -258px;
`

export const FormWrapper = styled.div`
  max-width: 700px;
  margin: 0 auto;
  position: relative;
`

export const ArrowLeft = styled.img.attrs({
  src: arrowLeft,
  alt: 'arrow',
})`
  max-width: 48px;
`

export const ArrowLeftWrapper = styled.div`
  cursor: pointer;
  position: fixed;
`
