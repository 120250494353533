import { GradientSeparator, Icon, Title } from '@/components'
import { pricingPropsMock } from '@/mocks/product/pricing'
import { Grid } from '@mui/material'
import * as React from 'react'
import {
  CheckIcon,
  FreeSampleText,
  LabelStyled,
  RowBoldText,
  RowText,
} from './styled'
// @ts-ignore
import check from '@assets/images/icons/GreenCheckIcon'
import { ILicensePlans, IPricingProps } from './types'
import { licensePlans } from './plans'
import { Pricing as PricingType } from '@/services/listing/types/pricing'
import { Separator } from '@/components/layout/gradient-separator/styled'
import { KeyDiffText } from '../key-differentiatiors/styled'

const Pricing: React.FunctionComponent<IPricingProps> = ({
  pricings,
  isFreeSample,
  boldTitle,
  title,
  titleDescription,
}) => {
  const renderFreeSample = () => (
    <Grid
      container
      spacing={1}
      sx={{
        margin: '10px 0',
        display: 'flex',
        alignItems: 'baseline',
      }}
    >
      <Grid item xs={0} sx={{ display: 'flex', alignItems: 'flex-start' }}>
        <Icon src={check} height="16px" />
      </Grid>
      <Grid item xs={6}>
        <FreeSampleText>Free sample available</FreeSampleText>
      </Grid>
    </Grid>
  )

  const renderPricingStarts = (
    plan: ILicensePlans,
    pricings: PricingType[],
  ) => {
    const planPricing = pricings.find(({ type }) => type === plan.id)
    return (
      <>
        {planPricing ? (
          <>
            <RowBoldText>${planPricing.price}</RowBoldText>
            <RowText fontSize="18px"> / {plan.startsText} </RowText>
          </>
        ) : (
          <>
            <RowText fontSize="18px">Not Available </RowText>
          </>
        )}
      </>
    )
  }

  return (
    <>
      <Title margin="0 0 15px 0" boldText="Pricing" fontSize="30px" />
      <KeyDiffText>{titleDescription}</KeyDiffText>
      {isFreeSample && renderFreeSample()}
      {/* <Grid
        container
        spacing={0}
        sx={{ marginTop: titleDescription ? '35px' : '15px' }}
      >
        <Grid item xs={6}>
          <LabelStyled>LICENSE</LabelStyled>
        </Grid>
        <Grid item xs={4}>
          <LabelStyled>STARTS AT</LabelStyled>
        </Grid>
        {licensePlans.map((plan) => (
          <Grid container key={plan.id}>
            <Grid
              item
              xs={6}
              sx={{ borderBottom: '1px solid #c4c4c4', padding: '10px 0' }}
            >
              <RowText>{plan.license}</RowText>
            </Grid>
            <Grid
              item
              xs={4}
              sx={{ borderBottom: '1px solid #c4c4c4', padding: '10px 0' }}
            >
              {renderPricingStarts(plan, pricings)}
            </Grid>
          </Grid>
        ))}
      </Grid> */}
      <GradientSeparator margin="30px 0" />
    </>
  )
}

export default Pricing
