import { ProductVisibility } from '@/components/forms/create-product/types'

export interface Visibility {
  label: string
  value: ProductVisibility
}

export const visibilityMock: Visibility[] = [
  {
    label: 'Public',
    value: 'public',
  },
  {
    label: 'Private',
    value: 'private',
  },
  {
    label: 'Restricted',
    value: 'restricted',
  },
]
