import { ResultsTitle } from '@/components/grid-cards/styled'
import * as React from 'react'
import { EmptyStateWrapper } from '../posting/styled'
import Header from '../header/index'
import { Container } from '@mui/material'

interface IComplianceProps {}

const Compliance: React.FC<IComplianceProps> = (props) => {
  return (
    <Container
      maxWidth={'xl'}
      sx={{ padding: '30px' }}
      style={{ position: 'relative' }}
    >
      <div style={{ display: 'flex' }}>
        <Header title={'Legal & Compliance'}></Header>
      </div>
      <EmptyStateWrapper>
        <ResultsTitle>Coming Soon!</ResultsTitle>
      </EmptyStateWrapper>
    </Container>
  )
}

export default Compliance
