import {
  NewProductIcon,
  Nav,
  NavCol,
  NavWrapper,
  Logo,
  VerticalDivider,
  TextBlock,
  Button,
  Avatar,
} from './styled'
import { useNavigate } from 'react-router'
import storage from '@/utils/storage'

const userId = storage.getUserMail()

const createAvatar = () => {
  if (userId) {
    let avatar = userId.slice(0, 2)
    return avatar
  }
}

const Navigation = () => {
  const navigate = useNavigate()
  return (
    <Nav>
      <NavWrapper>
        <NavCol>
          <Logo onClick={() => navigate('/')} />
          {/*<VerticalDivider />*/}
          <TextBlock>Data Discovery</TextBlock>
        </NavCol>
        <NavCol>
          <Avatar>{createAvatar()}</Avatar>
        </NavCol>
      </NavWrapper>
    </Nav>
  )
}

export default Navigation
