import { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { Container } from '@components'
import AppBarHeader from '@/components/appbar-header'
import Flag from '@assets/images/flags/us'
import storage from '@/utils/storage'
import Select from '@/components/forms/create-product/select'
import Checkbox from '@/components/ui/checkbox'
import ClipLoader from 'react-spinners/ClipLoader'
import Arrow from '@assets/images/icons/arrow-left'
import {
  Header,
  HeaderColLeft,
  HeaderColRight,
  Button,
  Wrapper,
  CardsCols,
  CardsContainer,
  CardsColLeft,
  CardsColRight,
  CardsColLeftTitle,
  CompanyInfo,
  HeadquarterLabel,
  CardsColLeftText,
  ClientMessage,
  CardsColRightTitle,
  Input,
  Commission,
  Terms,
  TextLink,
  CTAWrapper,
  ErrorText,
} from './styled'
import { useAppSelector } from '@/app/hooks'
import { Client } from '@twilio/conversations'
import { axios } from '@/lib/axios'
import { getTwilioClient } from '@/utils/twilio'

const LeadBriefing = () => {
  const userId = useAppSelector((state) => state.authUser.userId)
  const userCompany = useAppSelector((state) => state.authUser.companyId)
  const userMail = useAppSelector((state) => state.authUser.userMail)
  const navigate = useNavigate()
  const [isDisabled, setIsDisabled] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessageShowing, setErrorMessageShowing] = useState(false)
  const [message, setMessage] = useState('')
  const [assignedWorker, setAssignedWorker] = useState({
    id: userId,
    value: 'Myself',
    label: 'Myself',
  })
  const client = useRef<Client | undefined>()
  const ccompanyWorkers = useRef<any>()
  const baseUrl = process.env.REACT_APP_URL_BASE
  const supportEmail = process.env.SUPPORT_EMAIL
  let { leadId } = useParams()
  const location = useLocation()
  const lead = location?.state?.lead?.row

  useEffect(() => {
    setLoading(true)
    getCCompanyWorkers().then(() => {
      setLoading(false)
    })
  }, [lead])

  const getCCompanyWorkers = () => {
    const res = axios
      .get(`${baseUrl}/api/v1/ccompanies/${userCompany}/users`)
      .then(function (response: any) {
        ccompanyWorkers.current = response.data
        return response
      })
      .catch(function (error: any) {
        console.log(error)
      })
    return res
  }

  enum newStatus {
    accepted = 'accepted',
    declined = 'declined',
  }

  const changeProposalStatus = (newStatus: newStatus) => {
    if (newStatus === 'accepted') {
      const res = axios
        .put(`${baseUrl}/api/v1/inquiry/${leadId}`, {
          status: newStatus,
          sellerId: assignedWorker.id,
        })
        .then(function (response: any) {
          return response
        })
        .catch(function (error: any) {
          console.log(error)
        })
      return res
    } else {
      const res = axios
        .put(`${baseUrl}/api/v1/inquiry/${leadId}`, {
          status: newStatus,
          sellerId: assignedWorker.id,
        })
        .then(function (response: any) {
          return response
        })
        .catch(function (error: any) {
          console.log(error)
        })
      return res
    }
  }

  const sendNewProposalStatus = async (status: newStatus) => {
    if (status === 'accepted') {
      if (assignedWorker && !message) {
        setButtonLoading(true)
        setIsDisabled(true)
        changeProposalStatus(status).then(() => {
          setButtonLoading(false)
          setIsDisabled(false)
          sendLeadReplyEmailToKamba()
          navigate(`/leads`)
        })
      } else if (assignedWorker && message) {
        setButtonLoading(true)
        setIsDisabled(true)
        const newClient = await getTwilioClient(userId)
        client.current = newClient
        changeProposalStatus(status).then((data) => {
          let sidConversation = client.current
            .getConversationBySid(data.data.twilioConversationId)
            .then((data: any) => {
              if (message && String(message).trim()) {
                data.sendMessage(message).then(() => {
                  setMessage('')
                  setButtonLoading(false)
                  setIsDisabled(false)
                  sendLeadReplyEmailToKamba()
                  navigate(`/leads`)
                })
              }
            })
        })
      } else {
        setErrorMessageShowing(true)
      }
    } else if (status === 'declined') {
      changeProposalStatus(status).then(() => {
        setButtonLoading(false)
        setIsDisabled(false)
        navigate(`/leads`)
      })
    }
  }

  const sendLeadReplyEmailToKamba = () => {
    const res = axios
      .post(`${baseUrl}/api/v1/send-email-no-template`, {
        from: supportEmail,
        to: supportEmail,
        subject: 'New Reply To Lead',
        content: `The user ${userMail} has answered the Lead ${lead?.twilioTitle} (${lead?.id})`,
      })
      .then(function (response: any) {
        return response
      })
      .catch(function (error: any) {
        console.log(error)
      })
    return res
  }

  const handleOnChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value
    setMessage(value)
  }

  let selectOptions = ccompanyWorkers?.current?.map((worker: any) => {
    if (worker.id !== userId)
      return {
        id: worker.id,
        value: worker.id,
        label: worker.username,
      }
    else {
      return {
        id: worker.id,
        value: worker.id,
        label: 'Myself',
      }
    }
  })

  const selectAccount = (e: Event) => {
    setErrorMessageShowing(false)
    setAssignedWorker(e)
  }

  return (
    <>
      {!loading && (
        <>
          <AppBarHeader />
          <Container backgroundColor="#f6f6f6">
            <Wrapper>
              <CardsContainer>
                <Header>
                  <img
                    src={Arrow}
                    alt="arrow"
                    style={{ width: '26px', height: '26px', cursor: 'pointer' }}
                    onClick={() => navigate('/leads')}
                  />
                  <HeaderColLeft>Lead Briefing</HeaderColLeft>
                  <HeaderColRight></HeaderColRight>
                </Header>
                <CardsCols>
                  <CardsColLeft>
                    {!!lead?.CCompanyAvatar && (
                      <>
                        <CardsColLeftTitle>Company</CardsColLeftTitle>
                        <CompanyInfo>
                          <img
                            src={lead.CCompanyAvatar}
                            alt="Dom Tom Logo"
                            style={{ width: '26px', height: '26px' }}
                          />
                          {lead.CCompanyName}
                        </CompanyInfo>
                      </>
                    )}
                    {!!lead?.location && (
                      <>
                        <CardsColLeftTitle>Headquarter</CardsColLeftTitle>
                        <HeadquarterLabel>
                          <img
                            src={Flag}
                            alt="Flag"
                            style={{ width: '16px' }}
                          />
                          {lead.location}
                        </HeadquarterLabel>
                      </>
                    )}
                    {!!lead?.interest && (
                      <>
                        <CardsColLeftTitle>Interested in</CardsColLeftTitle>
                        <CardsColLeftText>{lead.interest}</CardsColLeftText>
                      </>
                    )}
                    {!!lead?.industry && (
                      <>
                        <CardsColLeftTitle>Industry</CardsColLeftTitle>
                        <CardsColLeftText>
                          Commercial &amp; Professional Services
                        </CardsColLeftText>
                      </>
                    )}
                    {!!lead?.industry && (
                      <>
                        <CardsColLeftTitle>Employee Count</CardsColLeftTitle>
                        <CardsColLeftText>51-250</CardsColLeftText>
                      </>
                    )}
                    {!!lead?.message && (
                      <>
                        <CardsColLeftTitle>Client Message</CardsColLeftTitle>
                        <ClientMessage>{lead.message}</ClientMessage>
                      </>
                    )}
                  </CardsColLeft>
                  <CardsColRight>
                    <CardsColRightTitle>Account manager</CardsColRightTitle>
                    <div style={{ marginBottom: '30px' }}>
                      <Select
                        placeholder="Pick one of your team members as account manager"
                        options={selectOptions}
                        onChange={(e: any) => selectAccount(e)}
                        defaultValue={assignedWorker.label}
                      />
                    </div>
                    <CardsColRightTitle>Your Message</CardsColRightTitle>
                    <Input type="text" onChange={handleOnChangeInput} />
                    {/*<Checkbox label="I will reply to this message later" />
                    <Commission>
                      Commission Fee Percentage:<span> 15.00%</span>
                    </Commission>
                    <Terms>
                      By accepting this referral, you agree to pay Kamba all
                      referral fees upon successfully closing a deal with this
                      referral. Referral fees are applicable to all referral
                      revenues generated in the first 24 months.
                    </Terms>*/}
                    {/*<Terms>
                      You can revisit the applicable terms{' '}
                      <TextLink
                        to="/terms-and-conditions"
                        rel="noopener noreferrer"
                      >
                        Here
                      </TextLink>
                  </Terms>*/}
                    <CTAWrapper>
                      <Button onClick={() => sendNewProposalStatus('declined')}>
                        {buttonLoading ? (
                          <ClipLoader
                            color="#ffffff"
                            loading={buttonLoading}
                            size={15}
                          />
                        ) : (
                          'DECLINE'
                        )}
                      </Button>
                      <Button
                        color="#ffffff"
                        backgroundColor="#24BC94"
                        check
                        onClick={() => sendNewProposalStatus('accepted')}
                        disabled={isDisabled}
                      >
                        {buttonLoading ? (
                          <ClipLoader
                            color="#ffffff"
                            loading={buttonLoading}
                            size={15}
                          />
                        ) : (
                          "I'M INTERESTED"
                        )}
                      </Button>
                    </CTAWrapper>
                    {errorMessageShowing && (
                      <ErrorText> You must assign an Account Manager</ErrorText>
                    )}
                  </CardsColRight>
                </CardsCols>
              </CardsContainer>
            </Wrapper>
          </Container>
        </>
      )}
    </>
  )
}

export default LeadBriefing
