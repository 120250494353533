import { useState } from 'react'
import { formatNumberWithCommas } from 'utils/format'
import {
  Stepper,
  Pagination,
  Title,
  Divider,
  Label,
  Block,
  Item,
  Button,
  Arrow,
} from '../styled'
import { StepTwoParams } from './types'
import { deliveryMethods } from './delivery'
import RadioButtons from '../radio-buttons'
import { Alert, Slider } from '@mui/material'
import { Checkbox } from '@/components'

const mark_values = [1000, 5000, 10000, 25000, 50000, 100000]

const marks = mark_values.map((value, index) => {
  let label: string = `\$${formatNumberWithCommas(value)}`
  if (index == 0) {
    label = ''.concat('<', label)
  } else if (index == mark_values.length - 1) {
    label = ''.concat('>', label)
  }
  return { value, label }
})

const SecondStep = ({ onClick, setSecondStepState }: StepTwoParams) => {
  const [delivery, setDelivery] = useState('')
  const [anonymousBudget, setAnonymousBudget] = useState(false)
  const [budget, setBudget] = useState<number[]>([
    mark_values[0],
    mark_values.slice(-1)[0],
  ])

  const secondStepValues = {
    delivery: delivery,
    anonimousBudget: anonymousBudget,
    budgetLow: budget[0],
    budgetHigh: budget[1],
  }

  const stepCount = marks.length - 1
  const stepSize = 1

  const valueToStep = (value: number) => {
    return marks.findIndex((mark) => mark.value === value)
  }

  const handleChange = (event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      setBudget(newValue.map((i) => marks[i].value))
    }
  }

  return (
    <Stepper>
      <Block>
        <Pagination>Step 2/3</Pagination>
        <Title>Delivery</Title>
        <Divider />
        <Item>
          <Label>How should the data be delivered?</Label>
          <RadioButtons
            values={deliveryMethods}
            setValue={setDelivery}
            value={delivery}
          ></RadioButtons>
        </Item>
        <Item>
          <Label>Budget</Label>
          <div style={{ marginBottom: '40px' }}>
            <Checkbox
              label="Should we show your budget?"
              handleOnChange={() => setAnonymousBudget(!anonymousBudget)}
              isChecked={anonymousBudget === true}
            />
          </div>
          <Slider
            sx={{ color: '#000' }}
            getAriaLabel={() => 'Custom marks'}
            valueLabelFormat={(i) => formatNumberWithCommas(marks[i].value)}
            value={budget.map((val) => valueToStep(val))}
            step={stepSize}
            marks={marks.map((mark) => ({
              ...mark,
              value: valueToStep(mark.value),
            }))}
            max={stepCount}
            valueLabelDisplay="on"
            onChange={handleChange}
            disableSwap
          />
        </Item>
        <Alert severity="info">
          It’s important to be as precise and realistic as possible with your
          budget indication to ensure you find the right data provider. Note
          that some providers only work above a certain budget - indicate your
          maximum budget to receive more offers.
        </Alert>
      </Block>
      <Button
        onClick={(e) => {
          e.preventDefault
          onClick(e)
          setSecondStepState(secondStepValues)
        }}
      >
        Continue to next step
        <Arrow />
      </Button>
    </Stepper>
  )
}

export default SecondStep
