import { useEffect, useRef, useState } from 'react'
import {
  ColLeft,
  ColCenter,
  ColRight,
  Columns,
  Container,
  ColRightTitle,
  ColRightSubTitle,
  ColRightText,
  HorizontalDivider,
} from './styled'
import ContactCard from '../../../messages/contact-card'
import EmptyState from '../../../messages/empty-state'
import Chat from '../chat'
import { useAppSelector } from '@/app/hooks'
import { Client, Conversation, Paginator } from '@twilio/conversations'
import { useParams } from 'react-router-dom'
import storage from '@/utils/storage'
import { wrap } from 'module'
import { getTwilioClient } from '@/utils/twilio'
import { axios } from '@/lib/axios'

const Tab = ({ id }) => {
  const [activeCard, setActiveCard] = useState('')
  const [renderFlag, setRenderFlag] = useState(false)
  const [loading, setLoading] = useState(true)
  const [tabId, setTabId] = useState('')
  const client = useRef<Client | undefined>()
  const proposals = useRef<any[] | undefined>()
  const posting = useRef<any | undefined>()
  const conversations = useRef<Conversation[] | undefined>()
  const userId = useAppSelector((state) => state.authUser.userId)
  const { proposalId } = useParams()
  const baseUrl = process.env.REACT_APP_URL_BASE
  const token = storage.getToken()

  useEffect(() => {
    const effect = async () => {
      setLoading(true)
      const response = await getProposalsByPostingId()

      if (!!token && !!userId && response.data.proposals.length) {
        const newClient = await getTwilioClient(userId)
        client.current = newClient
        const convos = response.data.proposals.map((p: any) =>
          newClient.getConversationBySid(p.twilioConversationId),
        )
        // conversations.current = await newClient.getSubscribedConversations()
        // conversations.current = await conversations.current.nextPage()
        const convosPromises = (await Promise.all(convos)) as Conversation[]
        // Filter by participants > 1
        conversations.current = await convosPromises.filter(async (r) => {
          const participants = await r.getParticipantsCount()
          return participants > 1
        })
        setLoading(false)
        setRenderFlag(false)
        switch (id) {
          case '1':
            setTabId('proposed')
            break
          case '2':
            setTabId('accepted')
            break
          default:
            setTabId('declined')
            break
        }
      }
    }
    effect()
  }, [renderFlag, id])

  const getProposalsByPostingId = () => {
    const res = axios
      .get(`/postings/${proposalId}/proposals`)
      .then(function (response: any) {
        proposals.current = response.data.proposals
        posting.current = response.data.posting
        return response
      })
      .catch(function (error: any) {
        console.log(error)
      })
    return res
  }
  // proposals.current?.map((a) => console.log('p', a.twilioConversationId))

  const sortedConvo = conversations.current?.sort((a, b) => {
    const bCreatedAt = b.lastMessage?.dateCreated?.getTime() || 0
    const aCreatedAt = a.lastMessage?.dateCreated?.getTime() || 0
    return bCreatedAt - aCreatedAt
  })

  return (
    <Container>
      {!loading && (
        <Columns>
          <ColLeft>
            {sortedConvo?.map((conv) => {
              if (
                proposals.current?.find(
                  (proposal) =>
                    proposal.twilioConversationId === conv.sid &&
                    proposal.status === tabId,
                )
              ) {
                return (
                  <ContactCard
                    key={conv.sid}
                    id={conv.sid}
                    conversation={conv}
                    onClick={() => setActiveCard(conv.sid)}
                    isActive={activeCard === conv.sid}
                    title={
                      proposals.current?.find(
                        (proposal) =>
                          proposal.twilioConversationId === conv.sid,
                      ).listingTitle
                    }
                  />
                )
              }
            })}
          </ColLeft>
          <ColCenter>
            {!activeCard ? (
              <EmptyState />
            ) : (
              sortedConvo?.map((conv) => {
                return (
                  <Chat
                    key={conv.sid}
                    id={id}
                    conversation={conv}
                    isActive={activeCard === conv.sid}
                    client={client.current}
                    renderFlag={setRenderFlag}
                    proposal={proposals.current?.find(
                      (proposal) => proposal.twilioConversationId === conv.sid,
                    )}
                    setActiveCard={setActiveCard}
                  />
                )
              })
            )}
          </ColCenter>
          <ColRight>
            <ColRightTitle>Your Requirements</ColRightTitle>
            <ColRightSubTitle>Geography</ColRightSubTitle>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {posting.current.geo.map((country: string, i: number) => {
                if (i === posting.current.geo.length - 1) {
                  return <ColRightText>and {country}</ColRightText>
                } else {
                  return (
                    <ColRightText>
                      {country},<a style={{ marginLeft: '5px' }} />
                    </ColRightText>
                  )
                }
              })}
            </div>
            <HorizontalDivider />
            <ColRightSubTitle>Delivery</ColRightSubTitle>
            <ColRightText>{posting.current.delivery}</ColRightText>
            <HorizontalDivider />
            <ColRightSubTitle>Budget</ColRightSubTitle>
            <ColRightText>{posting.current.budgetHigh}</ColRightText>
            <HorizontalDivider />
            <ColRightSubTitle>Use Case</ColRightSubTitle>
            <ColRightText>{posting.current.useCase}</ColRightText>
          </ColRight>
        </Columns>
      )}
    </Container>
  )
}

export default Tab
