import { useState, useEffect, useReducer, useRef, useMemo } from 'react'
import { Container, Navigation } from '../../components'
import {
  ArrowLeft,
  ArrowLeftWrapper,
  FormWrapper,
  StepperBarWrapper,
  Wrapper,
} from './styled'
import { useLocation, useNavigate, useParams } from 'react-router'
import StepperBar from '@/components/forms/create-product/stepper-bar'
import FirstStep from '@/components/forms/create-product/first-step'
import SecondStep from '@/components/forms/create-product/second-step'
import ThirdStep from '@/components/forms/create-product/third-step'
import { globalStateType } from '@/components/forms/create-product/types'
import { reducerType } from '@/utils/types'
import { axios } from '@/lib/axios'
import { ClipLoader } from 'react-spinners'
import { Listing } from '@/services/listing/types'
import { samplesTypes } from '@/assets/data/samples'
import { legalTypes } from '@/assets/data/legal'
import { SampleDto } from '@/components/forms/create-product/second-step/types'
import { downloadS3URL } from '@/utils/downloadS3Url'
import { uploadSamplesOrLegals } from '@/components/forms/create-product/common/uploadLegalSamples'
import getUuid from '@/utils/getUuid'
import { useAppSelector } from '@/app/hooks'
import { useGetDashboard } from '@/services/suppliers/suppliers.service'

const baseUrl = process.env.REACT_APP_URL_BASE

const ProductEditListing = () => {
  const navigate = useNavigate()
  const [isSubmit, setIsSubmit] = useState(false)
  const [error, setError] = useState(false)
  const initialState: globalStateType = {
    typeOfData: undefined,
    title: '',
    shortDescription: '',
    longDescription: '',
    dataCategories: [],
    optimalCompanySize: [],
    useCases: [],
    attributes: [],
    buyingFormat: [],
    keyDifferentiators: '',
    samples: samplesTypes.map((val) => {
      return { type: val }
    }),
    legalCompliance: legalTypes.map((val) => {
      return { type: val }
    }),
    geo: [],
    currency: '',
    dataFormat: [],
    freeSample: false,
    dataFrequency: [],
    historicalCov: 0,
    dataMethod: [],
    qualityData: [],
    revenueShare: false,
    volumeData: [],
    deleted: false,
    visibility: 'public',
    whitelist: [],
  }
  const reducer: reducerType<globalStateType> = (state, action) => {
    return { ...state, ...action }
  }
  const [globalState, dispatch] = useReducer(reducer, initialState)
  const [step, setStep] = useState(1)
  const [isLoading, setIsLoading] = useState(true)
  const [firstStepState, setFirstStepState] = useState({})
  const [secondStepState, setSecondStepState] = useState({})
  const [stepState, setStepState] = useState({})
  const newLegals = useRef<SampleDto[]>()
  const oldLegals = useRef<SampleDto[]>()
  const newSamples = useRef<SampleDto[]>()
  const oldSamples = useRef<SampleDto[]>()
  const dataAnalyticsBucket = process.env.BUCKET_DATA_ANALYTICS
  const { id } = useParams()
  const { state } = useLocation()
  const { url }: { url?: string } = state ? state : {}
  const unAuthorizedAxios = require('axios')
  const [presignedUrl, setPresignedUrl] = useState<string | undefined>(
    undefined,
  )
  const { userId } = useAppSelector((state) => state.authUser)
  const { isLoading: isFetchingLoading, data: fetchedDashboard } =
    useGetDashboard({
      userId: userId ?? '',
      enabled: true,
    })

  const getProduct = async (id: string) => {
    const res = await axios.get(`${baseUrl}/api/v1/listings/${id}`)
    return res
  }

  useEffect(() => {
    setStepState(Object.assign(stepState, firstStepState, secondStepState))
  }, [firstStepState, secondStepState])

  const injectFileIntoArrays = async (array: SampleDto[]) => {
    return Promise.all(
      array.map(async (val) => {
        // ADD VERIFICATION FOR EMPTY s3LINKs
        if (val.name) {
          if (val.type === 'Data Analytics') {
            val.s3Link = await getUrl(val.s3Link!)
          }
          const metaFile = await downloadS3URL(val.s3Link!)
          return {
            ...val,
            file: new File([metaFile.data], val.name, {
              type: metaFile.headers['content-type'],
            }),
          }
        } else {
          return val
        }
      }),
    )
  }

  // Merge current saved template and base structure from fixed list.
  // This will fix bad formed or missing saved samples structure.
  const sanitizeSamples = (array: SampleDto[]) => {
    return samplesTypes.map((s) => {
      const existingSample = array.find((a) => a.type === s)
      if (existingSample) {
        return existingSample
      } else {
        return {
          type: s,
        }
      }
    })
  }

  useEffect(() => {
    const effect = async () => {
      const productResponse = await getProduct(id)
      const data = productResponse.data
      if (!data.samples || data.samples.length === 0) {
        data.samples = samplesTypes.map((val) => {
          return { type: val }
        })
      } else {
        const sanitizedSamples = sanitizeSamples(data.samples)
        data.samples = await injectFileIntoArrays(sanitizedSamples)
      }
      if (!data.legalCompliance || data.legalCompliance.length === 0) {
        data.legalCompliance = legalTypes.map((val) => {
          return { type: val }
        })
      } else {
        data.legalCompliance = await injectFileIntoArrays(data.legalCompliance)
      }
      dispatch(productResponse.data)
      setIsLoading(false)
    }
    effect()
  }, [])

  const firstStepSubmit = () => {
    setStep(2)
    window.scrollTo(0, 0)
  }

  const secondStepSubmit = () => {
    setStep(3)
    window.scrollTo(0, 0)
  }

  const getUrl = async (url: string) => {
    let uuid: string = ''
    if (!url.startsWith('http')) {
      uuid = url
    } else {
      uuid = getUuid(url)
    }

    // const res = await unAuthorizedAxios.get(
    //   `https://${dataAnalyticsBucket}.s3.amazonaws.com/${uuid}`,
    // )
    // setPresignedUrl(res.data)
    // return res.data
    return `https://${dataAnalyticsBucket}.s3.amazonaws.com/${uuid}`
  }

  const submit = async (listing: Listing) => {
    setIsSubmit(true)
    try {
      oldSamples.current = listing.samples
        .filter((sample) => {
          return !!sample.s3Link && getUuid(sample.s3Link!) !== ''
        })
        .map((val) => {
          return { ...val, file: undefined }
        })
      newSamples.current = listing.samples.filter((sample) => {
        return !sample.s3Link || getUuid(sample.s3Link) === ''
      })
      oldLegals.current = listing.legalCompliance
        .filter((legal) => {
          return !!legal.s3Link && getUuid(legal.s3Link!) !== ''
        })
        .map((val) => {
          return { ...val, file: undefined }
        })
      newLegals.current = listing.legalCompliance.filter((legal) => {
        return !legal.s3Link || getUuid(legal.s3Link) === ''
      })
      //console.log(newLegals.current)
      const uploadedSamples = await uploadSamplesOrLegals(newSamples.current)
      const uploadedLegals = await uploadSamplesOrLegals(newLegals.current)

      const uploadListing = listing
      uploadListing.samples = uploadedSamples.concat(oldSamples.current)
      uploadListing.legalCompliance = uploadedLegals.concat(oldLegals.current)

      const res = await axios.put(`${baseUrl}/api/v1/listings/${id}`, {
        ...uploadListing,
      })
      setIsSubmit(false)
      if (!!res.data.ok) {
        setError(true)
      } else {
        setError(false)
        navigate(`/product/${id}`)
      }
    } catch (e) {
      console.log(e)
      setIsSubmit(false)
      setError(true)
    }
  }

  const hasStripeConfigured = useMemo(() => {
    return fetchedDashboard?.data?.linkAccountWithStripe
  }, [fetchedDashboard])

  return (
    <>
      <Navigation />
      {isLoading || isFetchingLoading ? (
        <ClipLoader color="#ffffff" loading={isLoading} size={15} />
      ) : (
        <Container backgroundColor="#000000" border={'unset'}>
          {step > 1 && (
            <ArrowLeftWrapper
              onClick={() => {
                window.scrollTo(0, 0)
                setStep(step - 1)
              }}
            >
              <ArrowLeft />
            </ArrowLeftWrapper>
          )}
          <Wrapper>
            <FormWrapper>
              <StepperBarWrapper>
                <StepperBar
                  stepNumber={step}
                  details={{ breadCrumb: 'Edit', title: 'Edit Product' }}
                />
              </StepperBarWrapper>
              {step === 1 && (
                <FirstStep
                  state={globalState}
                  dispatch={dispatch}
                  onClick={firstStepSubmit}
                />
              )}
              {step === 2 && (
                <SecondStep
                  state={globalState}
                  dispatch={dispatch}
                  onClick={secondStepSubmit}
                />
              )}
              {step === 3 && (
                <ThirdStep
                  state={globalState}
                  dispatch={dispatch}
                  url={url}
                  submit={submit}
                  error={error}
                  isSubmit={isSubmit}
                  buttonMessage={`Finalize & update product`}
                  hasStripeConfigured={hasStripeConfigured}
                />
              )}
            </FormWrapper>
          </Wrapper>
        </Container>
      )}
    </>
  )
}

export default ProductEditListing
