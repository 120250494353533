import * as React from 'react'
import { pricingPropsMock } from '@/mocks/product/pricing'
import { keyDiffMock } from '@/mocks/product/keyDifferentiators'
import { Grid } from '@mui/material'
import Delivery from './delivery'
import KeyDifferentiors from './key-differentiatiors'
import Pricing from './pricing'
import { GradientSeparator } from '@/components'
import WithScrollToElement from '@/hocs/withScrollToElement'
import WithVisibility from '@/hocs/withVisibility'

interface IPricingDeliveryProps {
  product: any
  keyDifferentiators: string
}

const PricingDelivery: React.FC<IPricingDeliveryProps> = ({
  product,
  keyDifferentiators,
}) => {
  const { isFreeSample, pricings } = pricingPropsMock
  const { title, text } = keyDiffMock
  let columns = [
    {
      text: 'METHODS',
      fontSize: '15px',
      color: '#858585',
      filters: product.dataMethod,
    },
    {
      text: 'FREQUENCY',
      fontSize: '15px',
      color: '#858585',
      filters: product.dataFrequency,
    },
    {
      text: 'FORMAT',
      fontSize: '15px',
      color: '#858585',
      filters: product.dataFormat,
    },
  ]
  const methods: {
    text: string
    fontSize: string
    color: string
    filters: any[]
  }[] = []
  columns.map((column) => {
    if (column.filters && column.filters.length > 0) {
      methods.push(column)
    }
  })
  methods.push()
  // console.log(product)
  return (
    <Grid container spacing={1}>
      {methods.length > 0 && (
        <Grid item xs={12}>
          <Delivery methods={methods} />
        </Grid>
      )}
      {!!keyDifferentiators && (
        <Grid item xs={12}>
          <KeyDifferentiors text={keyDifferentiators} />
        </Grid>
      )}
      {!!product.buyingFormat[0] ||
        (!!product.freeSample && (
          <Grid item xs={12}>
            <Pricing
              pricings={product.buyingFormat}
              isFreeSample={product.freeSample}
            />
          </Grid>
        ))}
      {/* <Grid item xs={12} sx={{ marginTop: '25px' }}>
        <GradientSeparator />
      </Grid> */}
    </Grid>
  )
}

export default WithVisibility(WithScrollToElement(PricingDelivery, 'pricing'))
