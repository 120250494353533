import React, { useState } from 'react'
import {
  LeftContainer,
  ProgressBar,
  TaskContainer,
  TaskCTA,
  TaskDesc,
  TasksContainer,
  TaskTitle,
  Texts,
} from './styled'
import Task from './task'

export interface TaskType {
  id: string
  title: string
  description: string
  size: number
  total: number
  CTAText: string
  CTALink?: any
  CTAIcon?: any
  disabled?: boolean
  onCTAClick: () => void
}

interface TasksProps {
  tasks: TaskType[]
}

function Tasks({ tasks }: TasksProps) {
  return (
    <TasksContainer>
      {tasks.map((task: TaskType, index: number) => (
        <Task
          key={index}
          task={task}
          first={index === 0}
          last={tasks.length - 1 === index}
          onCTAClick={() => task.onCTAClick()}
        />
      ))}
    </TasksContainer>
  )
}

export default Tasks
