import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { ContactIconSelected } from './types'

export const ContactTitle = styled.h2`
  margin-bottom: 15px;
  font-size: 22px;
  line-height: 30px;
  color: #000000;
`

export const ContactLabel = styled.label`
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #000000;
`

export const ContactIconsWrapper = styled.div<ContactIconSelected>`
  display: flex;
  flex-direction: column-reverse;
  padding: 5px 5px 15px;
  height: 100%;
  border: 1px solid ${({ isSelected }) => (isSelected ? '#000000' : '#d9d9d9')};
`
export const StyledSVGIcon = styled(SVG)`
  align-self: center;
`

export const ContactIconTitle = styled.span<ContactIconSelected>`
  margin-top: 13px;
  font-weight: 700;
  font-size: 12px;
  line-height: 19px;
  text-transform: uppercase;
  align-self: center;
  color: ${({ isSelected }) => (isSelected ? '#000000' : '#8c8c8c')};
`

export const ContactOneCallTitle = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #000000;
`

export const ContactCallText = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #757575;
`
export const ContactPhoneNumber = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin-left: 10px;
`

export const SuccessMessage = styled.div`
  display: flex;
  padding: 0 24px;
  margin-top: 25px;
  width: 100%;
  color: green;
  justify-content: center;
  text-align: center;
`

export const PoweredBy = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 5px;

  & > img {
    width: 100px;
    margin-left: 10px;
  }
`
