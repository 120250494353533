import styled from 'styled-components'
import { ContainerProps } from './types'

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  height: 100vh;
  width: 100%;
  overflow: hidden;
`
export const Iframe = styled.iframe`
  width: 80%;
  height: 100vh;
  border: none;
  margin: 0 auto;
  margin-left: 15%;
`
