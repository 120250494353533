import { Container, Tab, Tabs } from '@mui/material'
import { Link, scroller } from 'react-scroll'
import {
  TabsStyled,
  Wrapper,
  FullWidthBackground,
  TabContentContainer,
  TabPanelContainer,
  PageHeaderWrapper,
} from './styled'
import * as React from 'react'
import { useEffect } from 'react'
import { ITabsNavigationProps, TabPanelProps } from './types'
import { useNavigate } from 'react-router-dom'

const TabsNavigation: React.FC<ITabsNavigationProps> = ({
  tabs,
  backgroundMarginOffset,
  children,
  isScrollable,
  data,
  isLoading,
  name,
  id,
  isPostingsPage,
  hasPostings,
  isExplorePage,
  btn,
}) => {
  const [value, setValue] = React.useState(0)
  const [currentTabId, setCurrentTabId] = React.useState<string>('')
  const navigate = useNavigate()

  useEffect(() => {
    if (isScrollable) {
      scroller.scrollTo(currentTabId, {
        duration: 500,
        delay: 0,
        smooth: 'easeInOutQuart',
        containerId: 'scroll-container',
        offset: -20,
      })
    }
  }, [value, currentTabId])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (isScrollable) setCurrentTabId(event.currentTarget.id)
    setValue(newValue)
  }

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props

    return (
      <TabPanelContainer
        key={index}
        className="tabPanel"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        {...other}
      >
        {value === index && <>{children}</>}
      </TabPanelContainer>
    )
  }

  const childrenWithProps = (id?: any) =>
    React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { id, ...child.props })
      }
      return child
    })

  return (
    <>
      <PageHeaderWrapper>
        <TabsStyled value={value} onChange={handleChange}>
          {tabs.map((tab) => (
            <Tab key={tab.id} label={tab.label} id={tab.id} />
          ))}
        </TabsStyled>
        {((isPostingsPage && hasPostings) || isExplorePage) && btn}
      </PageHeaderWrapper>
      <Wrapper className="tabWrapper">
        <FullWidthBackground
          className="fullWidthBackground"
          backgroundColor="#F3F3F3"
          marginPercentOffset={backgroundMarginOffset}
        ></FullWidthBackground>
        <TabContentContainer
          className={id === 'subscriptions' ? 'subscriptions-tabs' : ''}
        >
          {tabs.map(({ label, id, Component, name }, index) => {
            return (
              <TabPanel key={label} value={value} index={index}>
                {Component ? (
                  <Component
                    data={data}
                    id={id}
                    isLoading={isLoading}
                    name={name}
                  />
                ) : (
                  childrenWithProps(id)
                )}
              </TabPanel>
            )
          })}
        </TabContentContainer>
      </Wrapper>
    </>
  )
}

export default TabsNavigation
