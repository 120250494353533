import Collapsible from '../ui/collapsible'
import { Checkbox } from '..'
import { CtaWrapper, Button, CheckboxWrapper } from './styled'
import { CollapsibleCheckGroupProps } from './types'

const CollapsibleCheckGroup = ({
  label,
  data,
  defaultData,
  isCheckedFunction,
  handleOnChange,
  value,
  origin,
}: CollapsibleCheckGroupProps) => {
  // const handleDefaultCheckData = (defaultData, option) => {
  //   return defaultData?.some(({ value }) => value === option.value)
  // }

  return (
    <Collapsible label={label}>
      <CheckboxWrapper>
        {data &&
          data.map((option: any, index) =>
            origin === 'product' ? (
              <Checkbox
                key={index}
                label={option.label}
                image={option.image}
                isChecked={isCheckedFunction && isCheckedFunction(option.value)}
                value={option.value}
                name={option.label}
                listing
                //isChecked={handleDefaultCheckData(defaultData, option)}
                handleOnChange={handleOnChange}
                isNotCheckbox={true}
              />
            ) : (
              <Checkbox
                key={index}
                label={option.label}
                image={option.image}
                isChecked={isCheckedFunction && isCheckedFunction(option.value)}
                value={option.value}
                name={option.label}
                listing
                //isChecked={handleDefaultCheckData(defaultData, option)}
                handleOnChange={handleOnChange}
              />
            ),
          )}
      </CheckboxWrapper>
    </Collapsible>
  )
}

export default CollapsibleCheckGroup
