import { useState, useEffect, useReducer, useMemo } from 'react'
import { Container, Navigation } from '../../components'
import {
  ArrowLeft,
  ArrowLeftWrapper,
  FormWrapper,
  StepperBarWrapper,
  Wrapper,
} from './styled'
import { useLocation, useNavigate } from 'react-router'
import StepperBar from '@/components/forms/create-product/stepper-bar'
import FirstStep from '@/components/forms/create-product/first-step'
import SecondStep from '@/components/forms/create-product/second-step'
import ThirdStep from '@/components/forms/create-product/third-step'
import { globalStateType } from '@/components/forms/create-product/types'
import { reducerType } from '@/utils/types'
import { useCreateListing } from '@/services/listing/listing.service'
import { FullListing, Listing } from '@/services/listing/types'
import { samplesTypes } from '@/assets/data/samples'
import { legalTypes } from '@/assets/data/legal'
import { uploadSamplesOrLegals } from '@/components/forms/create-product/common/uploadLegalSamples'
import { axios } from '@lib/axios'
import { useAppSelector } from '@/app/hooks'
import { useGetDashboard } from '@/services/suppliers/suppliers.service'

const ProductCreateListing = () => {
  const navigate = useNavigate()
  const [isSubmit, setIsSubmit] = useState(false)
  const [error, setError] = useState(false)
  const initialState: globalStateType = {
    typeOfData: { id: '1' },
    title: '',
    shortDescription: '',
    longDescription: '',
    dataCategories: [],
    optimalCompanySize: [],
    useCases: [],
    attributes: [],
    buyingFormat: [],
    geo: [],
    currency: '',
    dataFormat: [],
    freeSample: false,
    samples: samplesTypes.map((val) => {
      return { type: val }
    }),
    legalCompliance: legalTypes.map((val) => {
      return { type: val }
    }),
    dataFrequency: [],
    historicalCov: 0,
    dataMethod: [],
    qualityData: [],
    revenueShare: false,
    volumeData: [],
    visibility: 'public',
    whitelist: [],
  }
  const reducer: reducerType<globalStateType> = (state, action) => {
    return { ...state, ...action }
  }
  const [globalState, dispatch] = useReducer(reducer, initialState)
  const [step, setStep] = useState(1)
  const [firstStepState, setFirstStepState] = useState({})
  const [secondStepState, setSecondStepState] = useState({})
  const [stepState, setStepState] = useState({})
  const baseUrl = process.env.REACT_APP_URL_BASE
  const supportEmail = process.env.SUPPORT_EMAIL
  const { userMail, userId } = useAppSelector((state) => state.authUser)
  const { state } = useLocation()
  const { url }: { url?: string } = state ? state : {}
  const { isLoading, data: fetchedDashboard } = useGetDashboard({
    userId: userId ?? '',
    enabled: true,
  })

  useEffect(() => {
    setStepState(Object.assign(stepState, firstStepState, secondStepState))
  }, [firstStepState, secondStepState])

  const firstStepSubmit = () => {
    setStep(2)
    window.scrollTo(0, 0)
  }

  const secondStepSubmit = () => {
    setStep(3)
    window.scrollTo(0, 0)
  }

  const onSuccessFunc = function (response: any) {
    setIsSubmit(false)
    setError(false)
    //navigate(url ? url : '/listings')
    sendProductCreationEmailToKamba(response.data.title)
    navigate(`/product/${response.data.id}`)
  }
  const onErrorFunc = function (error: any) {
    setIsSubmit(false)
    setError(true)
  }

  const { mutate: createListing } = useCreateListing({
    config: { onSuccess: onSuccessFunc, onError: onErrorFunc },
  })

  const submit = async (listing: Listing) => {
    setIsSubmit(true)
    try {
      listing.samples = await uploadSamplesOrLegals(listing.samples)
      listing.legalCompliance = await uploadSamplesOrLegals(
        listing.legalCompliance,
      )
      createListing(listing)
    } catch (e) {
      setError(true)
    }
  }

  const sendProductCreationEmailToKamba = (productTitle: string) => {
    const res = axios
      .post(`${baseUrl}/api/v1/send-email-no-template`, {
        from: supportEmail,
        to: supportEmail,
        subject: 'New Product Created',
        content: `The product ${productTitle} has been created by the user: ${userMail}`,
      })
      .then(function (response: any) {
        return response
      })
      .catch(function (error: any) {
        console.log(error)
      })
    return res
  }

  const hasStripeConfigured = useMemo(() => {
    return fetchedDashboard?.data?.linkAccountWithStripe
  }, [fetchedDashboard])

  return (
    <>
      <Navigation />
      <Container backgroundColor="#000000" border={'unset'}>
        {step > 1 && (
          <ArrowLeftWrapper
            onClick={() => {
              window.scrollTo(0, 0)
              setStep(step - 1)
            }}
          >
            <ArrowLeft />
          </ArrowLeftWrapper>
        )}
        <Wrapper>
          <FormWrapper>
            <StepperBarWrapper>
              <StepperBar
                stepNumber={step}
                details={{ breadCrumb: 'New', title: 'New Product' }}
              />
            </StepperBarWrapper>
            {step === 1 && (
              <FirstStep
                state={globalState}
                dispatch={dispatch}
                onClick={firstStepSubmit}
              />
            )}
            {step === 2 && (
              <SecondStep
                state={globalState}
                dispatch={dispatch}
                onClick={secondStepSubmit}
              />
            )}
            {step === 3 && (
              <ThirdStep
                state={globalState}
                dispatch={dispatch}
                url={url}
                isSubmit={isSubmit}
                submit={submit}
                buttonMessage={`Finalize & create product`}
                error={error}
                hasStripeConfigured={hasStripeConfigured}
              />
            )}
          </FormWrapper>
        </Wrapper>
      </Container>
    </>
  )
}

export default ProductCreateListing
