import styled from 'styled-components'

export const CheckGrid = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 0;
  grid-row-gap: 0;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
`

export const CheckWrapper = styled.div`
  padding-left: 24px;
`

export const SelectWrapper = styled.div`
  max-width: 162px;
`

export const ErrorWrapper = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  margin: 20px auto 0;
  color: #ff0000;
`
