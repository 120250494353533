import Axios, { AxiosError, AxiosRequestConfig } from 'axios'
import storage from '../utils/storage'

const BASE_URL = process.env.API_BASE_URL
const API_VERSION = process.env.API_VERSION || '/api/v1/'

async function authRequestInterceptor(config: AxiosRequestConfig) {
  // console.log('---- TOKEN INTERCEPTOR')
  const token = storage.getToken()
  const impersonate = storage.getImpersonation()
  if (token) {
    config.headers!.Authorization = `${token}`
    // console.log('---- SET TOKEN', token)
  }
  if (impersonate) {
    config.headers!.impersonation = `${storage.getUserMail()}`
    // console.log('---- SET IMPERSONATE', impersonate)
  }
  return config
}

export const axios = Axios.create({
  baseURL: BASE_URL + API_VERSION,
})

axios.interceptors.request.use(authRequestInterceptor)
axios.interceptors.response.use(
  (response) => {
    return response.data
  },
  (error) => {
    const message = error.response?.data?.message || error.message
    //TODO:Global error notification
    const originalRequest = error.config
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      const refreshToken = storage.getRefreshToken()
      const username = storage.getUserMail(true)
      originalRequest._retry = true
      return axios({
        url: '/refresh-token',
        method: 'POST',
        data: { refreshToken, username },
      })
        .then((res) => {
          // console.log('======= TOKEN VENCIDO')
          if (res.ok) {
            // console.log('======= TOKEN ACTUALIZADO - Y ALMACENADO')
            // stopRefresh = false
            storage.clearToken()
            storage.setToken(res.data.AccessToken)
            originalRequest.headers = {
              ...originalRequest.headers,
              Authorization: `${res.data.AccessToken}`,
            }
            // axios.defaults.headers.common['Authorization'] =
            return axios(originalRequest)
          } else {
            // console.log('======== ELSE ', res)
          }
        })
        .catch((err) => {
          // stopRefresh = false
          storage.clearData()
          window.location.href = '/login'
        })
    } else if (
      (error.response &&
        error.response.status === 400 &&
        error.response.data.response === 'Bad token' &&
        error.code === AxiosError.ERR_BAD_REQUEST) ||
      (error.response.status === 403 &&
        error.response.data.response !== 'not authorized')
    ) {
      storage.clearData()
      setTimeout(() => (window.location.href = '/login'), 500)
    }

    return Promise.reject(error)
  },
)
