import * as React from 'react'
import { RadioButtonStyled, RadioLabel } from './styled'
import { IRadioButtonProps } from './types'

const RadioButton: React.FC<IRadioButtonProps> = ({ label, ...props }) => {
  return (
    <>
      <RadioButtonStyled {...props} />
      {label && <RadioLabel>{label}</RadioLabel>}
    </>
  )
}

export default RadioButton
