import * as React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useGetSubscriptions } from '@/services/subscriptions/subscriptions.service'
import {
  ListWrapper,
  Actions,
  RightSide,
  RightSideRow,
  RightSideColumn,
  RightSideItemLabel,
  RightSideItemValue,
  LoaderContainer,
  EmptyStateWrapper,
  Title,
  Info,
} from './styled'
import { Subscription } from '@/services/subscriptions/types'
import { ClipLoader } from 'react-spinners'
import Button from '@/components/ui/button'
import LongCard from '../../../../components/long-card'
import moment from 'moment'
import { useAppSelector } from '@/app/hooks'
import storage from '@/utils/storage'
import { axios } from '@/lib/axios'
import { Detail, DetailTitle, DetailWrapper } from '../subscription-page/styled'
import ConfirmDialog from '@/components/ui/confirm-dialog'

interface ISubscriptionsListProps {
  id: string
  isLoading: boolean
  data: any
  name: string
}

const SubscriptionList: React.FunctionComponent<ISubscriptionsListProps> = ({
  id,
}) => {
  const navigate = useNavigate()
  const userId = useAppSelector((state: any) => state.authUser.userId)
  const [isLoading, setIsLoading] = useState(true)
  const baseUrl = process.env.REACT_APP_URL_BASE
  const token = storage.getToken()
  const userRole = useAppSelector((state) => state.authUser.userRole)
  const sellerCompanyId = useAppSelector((state) => state.authUser.companyId)
  const [data, setData] = useState([])
  const [confirmDialog, setConfirmDialog] = useState(false)
  const [subscriptionInfo, setSubscriptionInfo] = useState({
    subscriptionId: '',
    listingId: '',
  })

  useEffect(() => {
    if (userRole == 'buyer') {
      getBuyerSubscriptions()
    } else if (userRole == 'seller') {
      getSellerSubscriptions()
    }
  }, [userRole, userId])

  const getBuyerSubscriptions = () => {
    const res = axios
      .get(`${baseUrl}/api/v1/subscriptions/${userId}`)
      .then(function (response: any) {
        setData(response.data)
        setIsLoading(!isLoading)
      })
      .catch(function (error: any) {
        console.log(error)
      })
    return res
  }

  const getSellerSubscriptions = () => {
    const res = axios
      .get(`${baseUrl}/api/v1/ccompanies/${sellerCompanyId}/subscriptions`)
      .then(function (response: any) {
        setData(response.data)
        setIsLoading(!isLoading)
      })
      .catch(function (error: any) {
        console.log(error)
      })
    return res
  }

  const capitalize = (word: string) => {
    return word[0].toUpperCase() + word.slice(1).toLowerCase()
  }

  const closeConfirmDialog = (b: boolean) => {
    setConfirmDialog(b)
    setSubscriptionInfo({
      subscriptionId: '',
      listingId: '',
    })
  }

  const setProductAsDelivered = () => {
    const { subscriptionId, listingId } = subscriptionInfo
    if (subscriptionId && listingId) {
      const res = axios
        .put(`/subscription/${subscriptionId}`, {
          status: 'delivered',
        })
        .then(function (response: any) {
          // console.info(response.data)
          navigate(`/messages?listings=${listingId}&source=delivered`)
          return response.data
        })
        .catch(function (error: any) {
          console.log(error)
        })
    }
  }

  return (
    <ListWrapper>
      {confirmDialog && (
        <ConfirmDialog
          title="Are you sure you want to change this subscription status to Delivered?"
          onConfirm={setProductAsDelivered}
          setOpen={closeConfirmDialog}
          open={true}
        />
      )}
      {isLoading ? (
        <LoaderContainer>
          <ClipLoader />
        </LoaderContainer>
      ) : data.length > 0 ? (
        data
          .filter((item: Subscription) => {
            if (id === 'delivered') {
              return !item.expired && item.status === 'delivered'
            } else if (id === 'pending') {
              return (
                !item.expired &&
                (item.status === 'pending' || item.status === 'paid')
              )
            }
          })
          .sort((a: Subscription, b: Subscription) => {
            const dateA = new Date(a.createdAt!)
            const dateB = new Date(b.createdAt!)
            return dateB.getTime() - dateA.getTime()
          })
          .map((subscription: Subscription, idx) => {
            return (
              <LongCard
                key={idx}
                title={subscription.listing?.title ?? ''}
                provider={subscription.listing?.company?.name}
                countries={subscription.listing?.geo}
                description={subscription.listing?.longDescription ?? ''}
                showActions={
                  // TODO: status its coming as "test" from BE. Will need to change this harcoded button when field coming correct.
                  <Actions>
                    {userRole === 'buyer' ? (
                      <>
                        <Button
                          variant="primary"
                          onClick={
                            () =>
                              navigate(`/subscriptions/${subscription.id}`, {
                                state: {
                                  listingTitle: subscription.listing?.title,
                                  legalFiles:
                                    subscription.listing?.legalCompliance,
                                },
                              }) // TODO: replace with correct path
                          }
                        >
                          Legal & Delivery
                        </Button>
                        <p style={{ marginTop: '10px', color: '#757575' }}>
                          Legal & Compliance - Delivery Instructions
                        </p>
                      </>
                    ) : (
                      <Button
                        variant="primary"
                        onClick={() => {
                          // navigate(`/messages`, {
                          //   state: {
                          //     id: 4, //Delivery
                          //   },
                          // })
                          setConfirmDialog(true)
                          setSubscriptionInfo({
                            subscriptionId: subscription.id,
                            listingId: subscription.listingId,
                          })
                        }}
                      >
                        Delivery
                      </Button>
                    )}
                  </Actions>
                }
                rightSide={
                  <RightSide>
                    {subscription.createdAt && (
                      <DetailWrapper>
                        <DetailTitle>Subscribed:</DetailTitle>
                        <Detail>
                          {moment(
                            new Date(subscription.createdAt!),
                            'DD-MM-YYYY',
                          ).format('MMM DD, YYYY')}
                        </Detail>
                      </DetailWrapper>
                    )}
                    {subscription.end && (
                      <DetailWrapper>
                        <DetailTitle>End:</DetailTitle>
                        <Detail>
                          {moment.unix(subscription.end).format('MMM DD, YYYY')}
                        </Detail>
                      </DetailWrapper>
                    )}
                    {/* {subscription.listing?.buyingFormat &&
                      subscription.listing?.buyingFormat.length > 0 && (
                        <DetailWrapper>
                          <DetailTitle>Payment schedule:</DetailTitle>
                          {subscription.listing?.buyingFormat?.map(
                            (buyingFormatItem: {
                              price: number
                              type: string
                            }) => {
                              if (buyingFormatItem.type === 'one') {
                                return (
                                  <Detail>
                                    Single purchase of ${buyingFormatItem.price}
                                  </Detail>
                                )
                              } else {
                                return (
                                  <Detail>
                                    ${buyingFormatItem.price} per{' '}
                                    {buyingFormatItem.type}
                                  </Detail>
                                )
                              }
                            },
                          )}
                        </DetailWrapper>
                      )} */}
                    {/* {subscription.payment && (
                      <DetailWrapper>
                        <DetailTitle>Payment</DetailTitle>
                        <Detail>{subscription.payment}</Detail>
                      </DetailWrapper>
                    )} */}
                    {subscription.status && (
                      <DetailWrapper>
                        <DetailTitle>Status:</DetailTitle>
                        <Detail>{capitalize(subscription.status)}</Detail>
                      </DetailWrapper>
                    )}
                    {subscription.visibility && (
                      <DetailWrapper>
                        <DetailTitle>Visibility:</DetailTitle>
                        <Detail>{subscription.visibility}</Detail>
                      </DetailWrapper>
                    )}
                    {/* TODO: at the moment, there is no field coming from BE to know the auto-renews data
                    <RightSideItemValue>
                      Auto-renews on{' '}
                      {moment(subscription.end, 'DD-MM-YYYY').format(
                        'MMM DD, YYYY',
                      )}
                    </RightSideItemValue>
                    */}
                  </RightSide>
                }
              />
            )
          })
      ) : (
        <EmptyStateWrapper>
          <Title>There are no subscriptions</Title>
          <Info>Your Subscriptions will appear here.</Info>
        </EmptyStateWrapper>
      )}
    </ListWrapper>
  )
}

export default SubscriptionList
