import styled from 'styled-components'
import { LogoProps } from './types'
import kambaLogoWhite from '@/assets/images/KambalogoWhite.png'

export const LogoWrapper = styled.img.attrs<LogoProps>(({ src }) => ({
  src: src ?? kambaLogoWhite,
  alt: 'logo',
}))<LogoProps>`
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : '80px')};
  margin: ${({ margin }) => (margin ? margin : '0 auto 32px auto')};
  display: block;
  margin-right: 28px;
`
