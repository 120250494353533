import * as React from 'react'
import Contact from '@/components/contact'
import Detail from './detail'
import { Sidebar, AppBarHeader, TabsNavigation } from '@components'
import { Container } from './styled'
import { tabs } from './tabStorefront'
import { Grid, Container as Wrapper } from '@mui/material'
import ClipLoader from 'react-spinners/ClipLoader'
import {
  useGetCompany,
  useGetCompanyByDomain,
} from '@/services/suppliers/suppliers.service'
import { useGetCompanyProfile } from '@/services/storefront/storefront.service'
import { IStorefrontProps } from './types'
import { useParams } from 'react-router'
import { LoaderWrapper } from '../product/styled'
import { useAppSelector } from '@/app/hooks'
import MainLayout from '@/components/layout'

const Storefront: React.FC<IStorefrontProps> = (props) => {
  const userRole = useAppSelector((state) => state.authUser.userRole)
  const userId = useAppSelector((state) => state.authUser.userId)
  const { companyId } = useParams()
  /*const { data: companyData, isLoading: isCompanyLoading } =
    useGetCompanyByDomain(domain!, !!domain)*/

  const { data: companyData, isLoading: isCompanyLoading } = useGetCompany(
    companyId!,
    !!companyId,
  )

  const { data: companyProfileData, isLoading: isCompanyProfileLoading } =
    useGetCompanyProfile(
      companyData?.data.profile!,
      !!companyData?.data.profile,
    )

  const capitalize = (word: string) => {
    return word[0].toUpperCase() + word.slice(1).toLowerCase()
  }

  const showRightPanel = userId && userRole === 'buyer'
  return (
    <MainLayout>
      {isCompanyLoading || isCompanyProfileLoading ? (
        <LoaderWrapper>
          <ClipLoader
            color="#000000"
            loading={isCompanyLoading || isCompanyProfileLoading}
            size={30}
          />
        </LoaderWrapper>
      ) : (
        <>
          <>
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              overflow="auto"
              justifyContent={showRightPanel ? 'flex-start' : 'center'}
              sx={{
                paddingX: 5,
                paddingTop: 3,
              }}
              id="scroll-container"
            >
              {/* Left Side */}
              <Grid
                // style={{
                //   background: 'red',
                // }}
                xs={7}
              >
                <Detail
                  company={companyProfileData?.data}
                  companyNative={companyData?.data}
                />
              </Grid>
              {/* Right Side */}
              {showRightPanel && (
                <Grid
                  xs={4}
                  xl={3}
                  ml={3}
                  sx={{
                    position: 'sticky',
                    top: '0',
                    overflow: 'hidden',
                  }}
                  id="rightBox"
                >
                  <Contact
                    companyName={capitalize(companyProfileData?.data?.name!)}
                    phoneNumber={companyProfileData?.data?.phoneNumber}
                    showIconsInfo={true}
                    companyId={companyData?.data.id}
                    origin="storefront"
                  />
                </Grid>
              )}
            </Grid>
          </>
        </>
      )}
    </MainLayout>
  )
}

export default Storefront
