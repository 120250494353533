import { Checkbox } from '@/components'
import { useDashboardContext } from '@/providers/dashboard/hooks/useDashboardContext'
import { Grid } from '@mui/material'
import * as React from 'react'
import { useState } from 'react'
import { FilterSection } from './filter-section'
import { IFilterGridProps, ISelectedValues } from './types'

const FilterGrid: React.FunctionComponent<IFilterGridProps> = ({
  filterTitle,
  injectFilter,
  filterAttributes,
}) => {
  const baseUrl = process.env.REACT_APP_URL_BASE

  const [selectedValues, setSelectedValues] = useState<ISelectedValues>({})
  const [seeMore, setSeeMore] = useState<string | null>(null)
  const { params, setParams } = useDashboardContext()

  const handleCheck = (event: any, id: string) => {
    const isChecked = event?.target.checked
    const newSelectedValues = { ...selectedValues }
    if (!newSelectedValues[id] && isChecked) {
      return setSelectedValues({
        ...newSelectedValues,
        [id]: [event.target.value],
      })
    }

    if (newSelectedValues[id] && isChecked) {
      const values = [...newSelectedValues[id], event.target.value]
      return setSelectedValues({ ...selectedValues, [id]: values })
    }

    if (newSelectedValues[id] && !isChecked) {
      const index = newSelectedValues[id].findIndex(
        (value) => value === event.target.value,
      )
      newSelectedValues[id].splice(index, 1)
      if (newSelectedValues[id].length === 0) {
        delete newSelectedValues[id]
      }
      return setSelectedValues(newSelectedValues)
    }
  }

  const makeCheckbox = (key: string, value: string) => {
    return (
      <Checkbox
        key={`${key} ${value}`}
        name={value}
        label={value}
        value={value}
        isChecked={selectedValues[key] && selectedValues[key].includes(value)}
        handleOnChange={(ev) => handleCheck(ev, key)}
      />
    )
  }
  const makeCheckboxObject = (key: string, value: string) => {
    const filterObj = injectFilter![key].options.find(
      (val) => val.label === value,
    )!
    return (
      <Checkbox
        key={`${key} ${value}`}
        name={value}
        label={value}
        value={filterObj.value}
        isChecked={selectedValues[key] && selectedValues[key].includes(value)}
        handleOnChange={(ev) => handleCheck(ev, injectFilter![key].value)}
      />
    )
  }
  React.useEffect(() => {
    setParams({ ...params, ...selectedValues })
  }, [selectedValues])
  return (
    <>
      {/* <Title>{filterTitle}</Title> */}
      {/* {!!injectFilter &&
        Object.keys(injectFilter).map((key, keyIndex) => {
          const filter = injectFilter[key]
          return (
            <FilterSection
              keyName={key}
              key={keyIndex}
              filters={filter.options.map((val) => val.label)}
              selectedValues={selectedValues[filter.value]}
              seeMore={seeMore === key}
              setSeeMore={setSeeMore}
              makeCheckbox={makeCheckboxObject}
            />
          )
        })} */}
      <p style={{ color: 'red', fontSize: '14px' }}>BETA FILTERS</p>
      <Grid container direction="column" sx={{ marginTop: '25px' }}>
        {Object.keys(filterAttributes).map((key, keyIndex) => (
          <FilterSection
            keyName={key}
            key={keyIndex}
            filters={filterAttributes[key]}
            selectedValues={selectedValues[key]}
            seeMore={seeMore === key}
            setSeeMore={setSeeMore}
            makeCheckbox={makeCheckbox}
          />
        ))}
      </Grid>
    </>
  )
}

export default FilterGrid
