import styled from 'styled-components'
import { IStatusProps } from './types'

export const CircleStatus = styled.div<IStatusProps>`
  width: 15px;
  height: 15px;
  background-color: ${({ active }) => (active ? '#2fa14f4d' : '#D9D9D9')};
  border-radius: 100%;
  padding: 3px;
  margin-right: 10px;
  display: inline-flex;
`

export const CircleStatusInternal = styled.div<IStatusProps>`
  width: 9px;
  height: 9px;
  background-color: ${({ active }) => (active ? '#2fa14f' : '#B5B5B5')};
  border-radius: 100%;
`
