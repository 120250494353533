const textAreaResize = (
  e: any,
  options: { min?: number; max?: number } = {},
) => {
  const { min, max } = options
  e.target.style.height = !!min ? `${min}px` : 'inherit'
  const scrollHeight = e.target.scrollHeight
  e.target.style.height =
    !!max && max < scrollHeight ? `${max}px` : `${scrollHeight}px`
  if (!!max) {
    if (scrollHeight > max) {
      e.target.style.overflowY = 'scroll'
    } else {
      e.target.style.overflowY = 'hidden'
    }
  }
}

export default textAreaResize
