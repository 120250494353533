import { ILegalProps } from '@/pages/product/detail/legal-compliance/types'

export const legalMock: ILegalProps = {
  legalText:
    'View our privacy policy for a detailed description of our policies.',
  legalCTAs: [
    { name: 'CCPA compliant', url: '/' },
    { name: 'GDPR compliant', url: '/' },
    { name: 'DDQ', url: '/' },
  ],
}
