import { CountryDto, geographyList } from '@/data/geographicData'

export const findCountriesByString = (countries: string[]): CountryDto[] => {
  const countriesData: CountryDto[] = []
  countries?.forEach((country) => {
    const foundCountry = geographyList.find((val) => val.country === country)
    if (!!foundCountry) {
      countriesData.push(foundCountry)
    }
  })
  return countriesData
}

export const findCountriesByCode = (countries: string[]): CountryDto[] => {
  const countriesData: CountryDto[] = []
  countries?.forEach((country) => {
    const foundCountry = geographyList.find((val) => val.code === country)
    if (!!foundCountry) {
      countriesData.push(foundCountry)
    }
  })
  return countriesData
}
