import styled from 'styled-components'
import { SpanProps, AbsoluteWrapperProps } from './types'

export const Wrapper = styled.div`
  margin-bottom: 32px;
`

export const Label = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 144%;
  color: #000000b2;
  margin-bottom: 18px;
`

export const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 8px;
`

export const Input = styled.input`
  width: 100%;
  height: 54px;
  padding: 14px 18px;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  color: #000000b2;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  &:focus {
    border: 1px solid #000000;
  }
  &:focus-visible {
    border: 1px solid #000000;
    outline: 1px solid #000000;
  }
`

export const TextArea = styled.textarea`
  width: 100%;
  height: 116px;
  padding: 14px 18px;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  color: #000000b2;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  &:focus {
    border: 1px solid #000000;
  }
  &:focus-visible {
    border: 1px solid #000000;
    outline: 1px solid #000000;
  }
`

export const AbsoluteWrapper = styled.div<AbsoluteWrapperProps>`
  position: absolute;
  top: ${({ top }) => (top ? top : '-237px')};
  right: ${({ right }) => (right ? right : '-425px')}; ;
`

export const CharactersMessage = styled.p<SpanProps>`
  font-style: italic;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.015em;
  color: #5c5c5c;
  span {
    color: ${({ charactersOk }) => (charactersOk ? '#2ea14f' : '#f00000')};
  }
`
