import { FileInfoDiv, ViewFileDiv } from './styled'
import ExplorerFile from '@assets/images/icons/PostingIcon.svg'
import { ViewFilesProps } from './types'

const ViewFile = ({ name }: ViewFilesProps) => {
  const pieces = name.split('.')
  const onlyName = pieces.slice(0, -1).join('.')
  const extension = pieces[pieces.length - 1]
  return (
    <ViewFileDiv>
      <FileInfoDiv>
        <ExplorerFile />
        <p>{onlyName}</p>
        <span>.{extension}</span>
      </FileInfoDiv>
    </ViewFileDiv>
  )
}

export default ViewFile
