import { Listing } from '@/services/listing/types'
import { Client } from '@twilio/conversations'
import React, { createContext, useState, useEffect } from 'react'
import { TwilioContextProps, TwilioProviderProps } from './types'

const TwilioContext = createContext<TwilioContextProps | null>(null)

const TwilioProvider = ({ children }: TwilioProviderProps) => {
  const [client, setClient] = useState<Client | undefined>()

  return (
    <TwilioContext.Provider value={{ client, setClient }}>
      {children}
    </TwilioContext.Provider>
  )
}

export { TwilioContext, TwilioProvider }
