import { axios } from '@/lib/axios'
import moment from 'moment'

const copyListing = async (
  id: string,
  authInfo: { userId: string; companyId: string },
): Promise<boolean> => {
  const baseUrl = process.env.REACT_APP_URL_BASE
  try {
    const listingRes = await axios.get(`${baseUrl}/api/v1/listings/${id}`)
    // console.log('listingRes', listingRes)
    if (!listingRes.ok) {
      return false
    }
    const newListing = {
      ...listingRes.data,
      title: `Copy at ${moment().format('YYYY-MM-DDTHH:mm:ss')} on ${
        listingRes.data.title
      }`, // Add timestamp to avoid duplicates.
      company: authInfo.companyId,
    }
    const createdListingResponse = await axios.post(`/listings`, newListing)
    // console.log('createdListingResponse', createdListingResponse)

    if (!createdListingResponse.ok) {
      return false
    }
    return true
  } catch (e) {
    return false
  }
}

export default copyListing
