import * as React from 'react'
import { Button, Container, FilterRedirect } from '@components'
import InfoQuality from './info-quality'
import PreviewAnalytics from './preview-analytics'
import GeoCoverage from './geo-coverage'
import PricingDelivery from './pricing-delivery'
import LegalAndCompliance from './legal-compliance'
import Faq from './faq'
// import Reviews from './reviews'
// import SimilarDatasets from './similar-datasets'
import { Listing } from '@/services/listing/types'
import storage from '@/utils/storage'
import { useAppSelector } from '@/app/hooks'
import { useNavigate } from 'react-router'

interface IDetailProps {
  product: Listing
  companyProfile: any
}

const Detail: React.FC<IDetailProps> = (props) => {
  const isLoggedIn = storage.getUserId() ? true : false
  const userRole = useAppSelector((state) => state.authUser.userRole)
  const userCompany = useAppSelector((state) => state.authUser.companyId)
  const companyProfile = props.companyProfile ? props.companyProfile : {}
  const navigate = useNavigate()
  const handleCreateAccount = () => navigate(`/create-account`)

  const renderDialogFooter = () => (
    <Button variant="secondary" onClick={handleCreateAccount}>
      CREATE AN ACCOUNT
    </Button>
  )

  return (
    <Container
      backgroundColor="#FFFF"
      height="calc(100vh - 250px)"
      padding={'25px 30px'}
      overflow="auto"
      // hideScroll
      id="scroll-container"
    >
      <InfoQuality product={props.product} id="infoQuality" />
      {props.product.useCases && !!props.product.useCases[0] && (
        <FilterRedirect
          boldText="Use Cases"
          filters={props.product.useCases}
          fontSize="30px"
        />
      )}
      {props.product.optimalCompanySize &&
        !!props.product.optimalCompanySize[0] && (
          <FilterRedirect
            // text="Target"
            boldText="Company Size Target"
            filters={props.product.optimalCompanySize}
            isSeparatorEnabled
            fontSize="30px"
          />
        )}
      <PreviewAnalytics
        product={props.product}
        id="previewAnalytics"
        isVisible={
          !!isLoggedIn &&
          (userRole === 'buyer' || userCompany === props.product?.company?.id)
        }
        dialogDescription="Create an account to preview the Analytics section"
        dialogContentFooter={renderDialogFooter()}
      />
      {!!props.product.geo && props.product.geo.length > 0 && (
        <GeoCoverage
          id="coverage"
          countries={props.product.geo}
          isVisible={
            !!isLoggedIn &&
            (userRole === 'buyer' || userCompany === props.product?.company?.id)
          }
          dialogDescription="Create an account to preview the Geo Coverage section"
          dialogContentFooter={renderDialogFooter()}
        />
      )}
      <PricingDelivery
        product={props.product}
        keyDifferentiators={companyProfile.keyDifferentiators}
        id="delivery"
        isVisible={
          !!isLoggedIn &&
          (userRole === 'buyer' || userCompany === props.product?.company?.id)
        }
        dialogDescription="Create an account to preview the Pricing section"
        dialogContentFooter={renderDialogFooter()}
      />
      {!!props.product.legalCompliance &&
        props.product.legalCompliance.find((val) => !!val.name) && (
          <LegalAndCompliance
            id="legal"
            legalDocs={props.product.legalCompliance
              .filter((val) => !!val.name)
              .map((val) => ({ name: val.type, url: val.s3Link! }))}
            isVisible={
              !!isLoggedIn &&
              (userRole === 'buyer' ||
                userCompany === props.product?.company?.id)
            }
            dialogDescription="Create an account to preview the Legal & Compliance section"
            dialogContentFooter={renderDialogFooter()}
          />
        )}
      {!!companyProfile.faq &&
        companyProfile?.faq.length > 0 &&
        !!companyProfile.faq[0].answer && (
          <Faq
            id="faq"
            faqs={companyProfile.faq}
            isVisible={
              !!isLoggedIn &&
              (userRole === 'buyer' ||
                userCompany === props.product?.company?.id)
            }
            dialogDescription="Create an account to preview the FAQ section"
            dialogContentFooter={renderDialogFooter()}
          />
        )}
      {/*<Reviews id="reviews"/>*/}
      {/*<SimilarDatasets id="similar" /> */}
    </Container>
  )
}

export default Detail
