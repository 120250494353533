import { ExtractFnReturnType } from '@/lib/react-query'
import { getImageListItemBarUtilityClass } from '@mui/material'
import { useMutation, useQuery } from 'react-query'
import {
  getCompanyProfile,
  getFile,
  getImage,
  getIndustries,
  updateCompanyProfile,
} from './api/storefront'
import { CompanyProfile } from './types'

type GetCompanyProfileQueryFnType = typeof getCompanyProfile
type GetFileQueryFnType = typeof getFile

export const useGetCompanyProfile = (id: string, enabled: boolean) => {
  return useQuery<ExtractFnReturnType<GetCompanyProfileQueryFnType>>({
    queryKey: ['company-profile'],
    queryFn: () => getCompanyProfile(id),
    enabled: enabled,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })
}

export const useUpdateCompanyProfile = (onSuccess: () => void) => {
  return useMutation({
    mutationKey: ['company-profile'],
    mutationFn: ({
      id,
      companyProfile,
    }: {
      id: string
      companyProfile: CompanyProfile
    }) => updateCompanyProfile({ id: id, companyProfile: companyProfile }),
    onSuccess: () => onSuccess(),
  })
}

export const useGetFile = (
  uuid: string,
  keys: string[],
  enabled: boolean,
  onSuccess?: () => void,
) => {
  return useQuery<ExtractFnReturnType<GetFileQueryFnType>>({
    queryKey: ['file', ...keys],
    queryFn: () => getFile(uuid),
    onSuccess: onSuccess ? () => onSuccess() : undefined,
    enabled: enabled,
  })
}

export const useGetImage = (
  url: string,
  enabled: boolean,
  onSuccess: () => void,
) => {
  return useQuery({
    queryKey: ['file', 'img'],
    enabled: enabled,
    queryFn: () => getImage(url),
    onSuccess: () => onSuccess(),
  })
}

export const useGetIndustries = () => {
  return useQuery({
    queryKey: ['industries'],
    queryFn: () => getIndustries(),
  })
}
