import styled from 'styled-components'

export const SelectStyled = styled.select`
  width: 100%;
  color: rgb(51, 51, 51);
  background-color: white;
  border-color: rgb(204, 204, 204);
  font-family: 'Proxima Nove', sans-serif;
  font-size: 16px;
  padding-left: 10px;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  -ms-appearance: none !important;
  -o-appearance: none !important;
  appearance: none !important;
`

export const OptionStyled = styled.option`
  :selected {
    color: blue;
  }
`
