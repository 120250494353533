import { ApiResponse } from '@/services/types'
import { axios } from '@lib/axios'

export const getBacktestingUrl = (userId: string) => {
  return axios.post(
    // 'https://3a2mgp1gu9.execute-api.us-east-1.amazonaws.com/staging/',
    // 'https://k2wqcfgv20.execute-api.us-east-1.amazonaws.com/prod/',
    'https://vupitxqlrg.execute-api.us-east-1.amazonaws.com/dev/',
    // process.env.BACKTESTING_API!,
    // { kamba_user_id: '226316e9-880e-419e-821c-1c13c19e7295' },
    { kamba_user_id: '2f86fcea-24b6-49e8-a816-d493f4656e98' },
    // { kamba_user_id: userId },
  )
}
