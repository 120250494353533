import { useState, useEffect } from 'react'
import storage from '@/utils/storage'
import { useNavigate } from 'react-router-dom'
import {
  Stepper,
  Pagination,
  Title,
  Divider,
  Block,
  Item,
  Button,
} from '../styled'
import { ErrorWrapper } from './styled'
import { StepThreeParams } from './types'
import ClipLoader from 'react-spinners/ClipLoader'
import InputComponent from '../input'
import { TitleTooltip } from '../tooltip-content'
import { axios } from '@/lib/axios'
import { useAppSelector } from '@/app/hooks'

const ThirdStep = ({ prevState }: StepThreeParams) => {
  const navigate = useNavigate()
  const [state, setState] = useState({})
  const [isSubmit, setIsSubmit] = useState(false)
  const [error, setError] = useState(false)
  const [requirements, setRequirements] = useState('')
  const baseUrl = process.env.REACT_APP_URL_BASE
  const supportEmail = process.env.SUPPORT_EMAIL
  const userMail = useAppSelector((state) => state.authUser.userMail)
  const token = storage.getToken()

  const thirdStepValues = {
    useCase: requirements,
  }

  useEffect(() => {
    setState(
      Object.assign(state, prevState, thirdStepValues, {
        typeOfData: 'Dataset',
        active: true,
      }),
    )
  }, [prevState, thirdStepValues])

  const onClick = () => {
    setIsSubmit(true)
    axios
      .post(`${baseUrl}/api/v1/postings`, state)
      .then(function (response: any) {
        setIsSubmit(false)
        setError(false)
        sendPostCreationEmailToKamba(state.title)
        navigate('/posting')
      })
      .catch(function (error: any) {
        setIsSubmit(false)
        setError(true)
      })
  }

  const sendPostCreationEmailToKamba = (postTitle: string) => {
    const res = axios
      .post(`${baseUrl}/api/v1/send-email-no-template`, {
        from: supportEmail,
        to: supportEmail,
        subject: 'New Posting Created',
        content: `The posting ${postTitle} has been created by the user: ${userMail}`,
      })
      .then(function (response: any) {
        return response
      })
      .catch(function (error: any) {
        console.log(error)
      })
    return res
  }

  return (
    <Stepper>
      <Block>
        <Pagination>Step 3/3</Pagination>
        <Title>Requirements</Title>
        <Divider />
        <Item>
          <InputComponent
            title="Your requirements in detail"
            minCharacters={140}
            isTextArea
            getValue={(e: any) => setRequirements(e.target.value)}
          >
            <TitleTooltip />
          </InputComponent>
        </Item>
      </Block>
      <Button onClick={onClick}>
        {!isSubmit ? (
          `Post your Request`
        ) : (
          <ClipLoader color="#ffffff" size={15} />
        )}
      </Button>
      {error && <ErrorWrapper>An error has occurred</ErrorWrapper>}
    </Stepper>
  )
}

export default ThirdStep
