import * as React from 'react'
import { useNavigate } from 'react-router'
import LongCard from '../../../../components/long-card'
import { ListWrapper, RightSideWrapper } from './styled'
import AvailableProposal from './availableProposal'

interface IPostingProps {
  id?: string
  postings?: any
}

const PostingList: React.FunctionComponent<IPostingProps> = ({
  id,
  postings,
}) => {
  const navigate = useNavigate()

  return (
    <ListWrapper>
      {postings &&
        postings.map((post: any) => {
          return (
            <LongCard
              key={post.id}
              title={post.title}
              price={post.budgetHigh}
              priceLow={post.budgetLow}
              paymentOption={post.delivery}
              type={post.typeOfData}
              countries={post.geo}
              description={post.useCase}
              anonimousBudget={post.anonimousBudget}
              rightSide={
                <RightSideWrapper>
                  <AvailableProposal postingId={post.id} />
                </RightSideWrapper>
              }
            />
          )
        })}
    </ListWrapper>
  )
}

export default PostingList
