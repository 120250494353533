import { DialogTitle, Grid } from '@mui/material'
import * as React from 'react'
import { DialogContainer, DialogDescription } from './styled'
import { IDialogProps } from './types'

const Dialog: React.FunctionComponent<IDialogProps> = ({
  title,
  description,
  contentFooter,
  padding,
}) => {
  return (
    <DialogContainer padding={padding} container item xs={6} spacing={1}>
      {title && (
        <Grid item xs={12}>
          <Grid item xs={6}>
            <DialogTitle>{title}</DialogTitle>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <DialogDescription>{description}</DialogDescription>
      </Grid>
      <Grid item xs={12}>
        {contentFooter}
      </Grid>
    </DialogContainer>
  )
}

export default Dialog
