export const useCasesData = [
  {
    id: 1,
    value: 'Finance',
    label: 'Finance',
  },
  {
    id: 2,
    value: 'Financial Data Enrichment',
    label: 'Financial Data Enrichment',
  },
  {
    id: 3,
    value: 'Financial Services',
    label: 'Financial Services',
  },
  {
    id: 4,
    value: 'Islamic Finance',
    label: 'Islamic Finance',
  },
]
