import * as React from 'react'
import { IGridPreviewProps } from './types'
import DownloadIcon from '@assets/images/icons/DownloadIcon'
import RightArrowIcon from '@assets/images/icons/RightArrowIcon'
// import { DataGrid } from '@mui/x-data-grid'
import { Grid, Tooltip } from '@mui/material'
import Preview from '@/utils/preview'
import { Button, Icon, Title } from '@/components'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '@/app/hooks'
import storage from '@/utils/storage'
import { useParams } from 'react-router'
import { axios } from '@lib/axios'
import TooltipButton from '@/components/ui/tooltip-button'

const GridPreview: React.FunctionComponent<IGridPreviewProps> = ({
  file,
  url,
  backtesting,
  backtestingFile,
}) => {
  const navigate = useNavigate()
  const handleCreateAccount = () => navigate(`/create-account`)
  const isLoggedIn = storage.getUserId() ? true : false
  const baseUrl = process.env.REACT_APP_URL_BASE
  const userMail = useAppSelector((state) => state.authUser.userMail)
  const userRole = useAppSelector((state) => state.authUser.userRole)
  const supportEmail = process.env.SUPPORT_EMAIL
  const fileAvailable = !file ? false : true
  const backtestingAvailable = !backtesting ? false : true
  const [showTooltipSample, setShowTooltipSample] = React.useState(false)
  const [showTooltipSample2, setShowTooltipSample2] = React.useState(false)

  const renderDialogFooter = () => (
    <Button variant="secondary" onClick={handleCreateAccount}>
      CREATE AN ACCOUNT
    </Button>
  )
  const { id } = useParams()

  const sendBacktestingRequestEmailToKamba = () => {
    const res = axios
      .post(`${baseUrl}/api/v1/send-email-no-template`, {
        from: supportEmail,
        to: supportEmail,
        subject: 'New Backtesting Request',
        content: `The user ${userMail} has requested Backtesting Data from product with the ID ${id}`,
      })
      .then(function (response: any) {
        return response
      })
      .catch(function (error: any) {
        console.log(error)
      })
    return res
  }

  const requestBackDataTesting = () => {
    sendBacktestingRequestEmailToKamba()
    navigate(`/environment/${id}`, {
      state: { backtestingFile: backtestingFile },
    })
  }

  const showToolTip = () => {
    if (!fileAvailable) {
      setShowTooltipSample(true)
    }
  }

  const hideToolTip = () => {
    setShowTooltipSample(false)
  }

  const showToolTip2 = () => {
    if (!backtestingAvailable) {
      setShowTooltipSample2(true)
    }
  }

  const hideToolTip2 = () => {
    setShowTooltipSample2(false)
  }

  return (
    <>
      <Title boldText="Dataset Preview" margin="0 0 20px 0" fontSize="30px" />
      {file && <Preview data={file} />}
      <Grid container spacing={2} marginTop="20px">
        <Tooltip
          title="Please, contact this supplier to request a sample data."
          disableHoverListener={true}
          open={showTooltipSample}
        >
          <Grid item xs={4} lg={3}>
            {isLoggedIn && userRole === 'buyer' && (
              <a
                style={{ textDecoration: 'none' }}
                href={url}
                download
                onPointerOver={showToolTip}
                onPointerOut={hideToolTip}
              >
                <Button
                  variant={'secondary'}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  disabled={!fileAvailable}
                  style={
                    !fileAvailable
                      ? { backgroundColor: 'rgba(0,0,0,.5)', color: '#FFF' }
                      : {}
                  }
                >
                  DOWNLOAD SAMPLE
                  <Icon src={DownloadIcon}></Icon>
                </Button>
              </a>
            )}
          </Grid>
        </Tooltip>
        <Grid item xs={5} lg={4}>
          {isLoggedIn && userRole === 'buyer' && (
            <Tooltip
              title="Please, contact this supplier to request a sample data."
              disableHoverListener={true}
              open={showTooltipSample2}
            >
              <div>
                <Button
                  variant="primary"
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  onClick={() => requestBackDataTesting()}
                  disabled={!backtestingAvailable}
                  onPointerOver={showToolTip2}
                  onPointerOut={hideToolTip2}
                >
                  REQUEST DATA BACKTESTING
                  <Icon src={RightArrowIcon}></Icon>
                </Button>
              </div>
            </Tooltip>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default GridPreview
