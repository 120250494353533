import { ApiResponse } from '@/services/types'
import { axios } from '@lib/axios'
import qs from 'qs'
import { GetCompanyListingsProps, Listing, SearchListings } from '../types'

type ApiListingResponse = ApiResponse<SearchListings>
type ApiCompanyListingResponse = ApiResponse<Listing[]>
export const getCompanyListings = ({
  sellerCompanyId,
}: GetCompanyListingsProps): Promise<ApiCompanyListingResponse> => {
  return axios.get(`/ccompanies/${sellerCompanyId}/listings`)
}

export const getListings = ({
  ...params
}: Listing): Promise<ApiListingResponse> => {
  return axios.get(`/listings/search`, {
    params: { ...params },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'comma' })
    },
  })
}

//Post Listing type response ????
export const createListing = ({
  ...params
}: Listing): Promise<ApiListingResponse> => {
  return axios.post(`/listings`, {
    ...params,
  })
}

export const deleteListing = (id: string): Promise<ApiListingResponse> => {
  return axios.delete(`/listings/${id}`)
}
