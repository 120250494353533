import { axios } from '@lib/axios'
import { ApiResponse } from '../types'

interface RegisterClick {
  url: string
}

type ApiRegisterClickResponse = ApiResponse<RegisterClick>

export const RegisterClick = ({
  clickType,
}: {
  clickType: string
}): Promise<ApiRegisterClickResponse> => {
  return axios.post(`/register-click`, {
    clickType: clickType,
  })
}
