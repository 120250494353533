import styled from 'styled-components'
import { IChatBox } from './types'

export const Wrapper = styled.div<IChatBox>`
  width: 75%;
  background-color: #ffffff;
  border: 2px solid #e1e1e1;
  padding: 24px;
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 139.8%;
  color: #000000;
  column-gap: 24px;
  margin: 10px 0;
  margin-left: ${({ alignLeft }) => (alignLeft ? 'auto' : '0')};
`

export const Message = styled.div`
  display: block;
  width: 100%;
`

export const MessageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #8a8a8a;
  margin-bottom: 8px;
  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin-right: 5px;
  }
`
export const MessageAttachment = styled.a`
  display: flex;
  align-items: center;
  color: #333;
  span {
    font-weight: bold;
  }
  :hover {
    text-decoration: none;
  }
`
