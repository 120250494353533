import styled from 'styled-components'
import { ContainerProps } from '../storefront/types'
import backArrow from '@assets/images/icons/LeftArrowIcon'
import exportIcon from '@assets/images/icons/RedirectIcon'

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background-color: #e5e5e5;
`
export const PageContainer = styled(Container)`
  width: 80% !important;
  margin: 2rem auto;
  display: flex;
`
export const CompleteSubscription = styled.div`
  width: 30%;
  display: flex;
  background-color: white;
  padding: 30px 30px 36px 30px;
  height: fit-content;
  flex-direction: column;
  border: 1px solid #e9e9e9;
`
export const CardTitle = styled.span`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 24px;
`
export const BulletTitle = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`
export const BulletDescription = styled.span`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(117, 117, 117, 0.8);\
  vertical-align: middle;
  display: inline-block;
`
export const BulletSeparator = styled.div`
  height: 24px;
  border-left: solid 2px #f0f0f0;
  margin: 4px 0;
`
export const FinalStep = styled.div`
  padding: 18px;
  display: flex;
  flex-direction: column;
  background: #fafafa;
`
export const SubscriptionDetails = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  padding: 0;
  padding-left: 25px;
  height: fit-content;
`
export const DetailHeader = styled.span`
  display: flex;
  align-items: center;
  gap: 18px;
  margin-bottom: 22px;
`
export const ArrowButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
`
export const ArrowIcon = styled.img.attrs({
  src: backArrow,
  alt: 'Return icon',
})``
export const HeaderTitle = styled.span`
  font-size: 24px;
  line-height: 32px;
  color: #111111;
`
export const DetailsContainer = styled.div`
  width: 100%;
  height: 72vh;
  display: flex;
  background-color: white;
  padding: 0;
  flex-direction: column;
  border: 1px solid #e9e9e9;
`
export const DetailTabs = styled.div`
  width: 100%;
  display: flex;
  background-color: white;
  padding: 0 24px;
  height: 65px;
  border-bottom: 1px solid #e9e9e9;
`
export const Tab = styled.div`
  width: 86px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: transparent;
  height: 100%;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #757575;
`
export const SubscriptionDetailInfo = styled.div`
  width: 100%;
  height: 100%;
  padding: 66px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`
export const Step = styled.section`
  width: 100%;
  height: fit-content;
  padding: 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
`
export const StepNumber = styled.p`
  font-size: 16px;
  line-height: 128.8%;
  letter-spacing: 0.05em;
  color: #646464;
  margin-bottom: 7px;
`
export const StepTitle = styled.span`
  width: 100%;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -0.015em;
  color: #000000;
  padding-bottom: 26px;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 26px;
`
export const StepSubtitle = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
`
export const StepOffer = styled.div`
  width: 100%;
  background-color: transparent;
  padding: 18px 24px;
  height: fit-content;
  border: 1px solid #d9d9d9;
  display: flex;
  gap: 19px;
  align-items: flex-start;
  margin-top: 18px;
`
export const StepOfferTitle = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
`
export const StepParagraph = styled.span`
  font-size: 16px;
  line-height: 24px;
  color: #303030;
  margin-bottom: 0;
`
export const DocumentsContainer = styled.div`
  width: 100%;
  background-color: transparent;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: 6px;
`
export const DocumentCard = styled.div`
  width: fit-content;
  background-color: transparent;
  padding: 11px 22px;
  height: 48px;
  border: 1px solid #000;
  display: flex;
  align-items: center;
  margin-top: 12px;
  margin-right: 12px;
  gap: 8px;
  cursor: pointer;
`
export const DocumentURLButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
`
export const DocumentURLIcon = styled.img.attrs({
  src: exportIcon,
  alt: 'Export icon',
})``
export const CardImgContainer = styled.div`
  min-width: 65px;
  height: 45px;
  background-color: transparent;
  padding: 0;
  border: 1px solid #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const AutomaticRenewal = styled.div`
  width: 100%;
  height: fit-content;
  background-color: transparent;
  padding: 26px 24px 0;
  border: none;
  display: flex;
  flex-direction: column;
  gap: 17px;
`
export const TermsParagraph = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #757575;
`
export const SealOfApproval = styled.div`
  width: 100%;
  max-width: fit-content;
  height: 60px;
  background: rgba(47, 162, 79, 0.1);
  border: 2px solid #2fa14f;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #2fa24f;
  padding: 19px 25px;
  margin-top: 36px;
  display: flex;
  &.inactive {
    display: none;
  }
`

export const SealOfApprovalButton = styled.div`
  width: 100%;
  max-width: 327px;
  height: 60px;
  background: transparent;
  border: 1px solid black;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: block;
  padding: 19px 0;
  margin-top: 36px;
  display: flex;
  cursor: pointer;
  &.inactive {
    display: none;
  }
`
