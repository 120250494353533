import styled from 'styled-components'

export const Container = styled.div`
  width: 80vw;
  background-color: #ffffff;
`

export const Columns = styled.div`
  display: flex;
  height: 70vh;
`

export const ColLeft = styled.div`
  width: 27%;
  max-width: 450px;
  border-right: 1px solid #f0f0f0;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`

export const ColCenter = styled.div`
  width: 61%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

export const ColRight = styled.div`
  width: 12%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  border: 1px solid #f0f0f0;
  padding: 30px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`

export const ColRightTitle = styled.h2`
  font-weight: 700;
  font-size: 16.954px;
  line-height: 25px;
  color: #000000;
  margin-bottom: 30px;
`

export const ColRightSubTitle = styled.h4`
  font-weight: 600;
  font-size: 15.1915px;
  line-height: 26px;
  color: #b8b8b8;
  margin-bottom: 5px;
`

export const ColRightText = styled.p`
  font-weight: 600;
  font-size: 15.6347px;
  line-height: 23px;
  color: #5b5b5b;
`

export const HorizontalDivider = styled.hr`
  width: 100%;
  border-top: #c4c4c4;
  margin: 18px 0;
`
