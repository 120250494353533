import styled from 'styled-components'
import { ContainerProps } from './types'

export const BlockContainer = styled.div<ContainerProps>`
  max-width: 100%;
  height: ${({ height }) => height && `${height}`};
  padding: ${({ padding }) => padding || '0 16px'};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : '#000000'};
  border: ${({ border }) => border || '1px solid #D9D9D9'};
  overflow: ${({ overflow }) => overflow && `${overflow}`};
  min-height: ${({ minHeight }) => minHeight && `${minHeight}`};

  ${({ hideScroll }) =>
    hideScroll &&
    `&::-webkit-scrollbar {
      display: none; }
  `}
`
