import styled from 'styled-components'
import logo from '../../assets/images/KambalogoWhite.png'
import addProductIcon from '@assets/images/icons/newProductIcon'

export const Nav = styled.div`
  width: 100%;
  height: 67px;
  background-color: #000000;
  display: flex;
  align-items: center;
`

export const NavWrapper = styled.div`
  width: 100%;
  max-width: 1356px;
  padding: 0 16px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
`

export const NavCol = styled.div`
  display: flex;
  align-items: center;
`

export const Logo = styled.img.attrs({
  src: logo,
  alt: 'logo',
})`
  max-width: 80px;
  cursor: pointer;
  margin-right: 28px;
`

export const VerticalDivider = styled.div`
  width: 1px;
  height: 24px;
  background-color: #444444;
  margin: 0 18px;
`

export const TextBlock = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #b8b8b8;
`

export const Button = styled.button`
  background-color: #000000;
  border: 2px solid #ffffff;
  color: #ffffff;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  padding: 12px 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 43px;
`

export const NewProductIcon = styled.img.attrs({
  src: addProductIcon,
  alt: 'New product icon',
})`
  margin-right: 10px;
`

export const Avatar = styled.div`
  width: 43px;
  height: 43px;
  display: flex;
  background-color: #ffffff;
  color: #000000;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  font-size: 17px;
  line-height: 21px;
  margin-right: 16px;
  border: none;
  text-transform: uppercase;
`

/*background-color: #04AA6D;
  color: white;
  padding: 16px;
  font-size: 16px;
  */
