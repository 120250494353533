import { TooltipTitle, FontSemiBold, TooltipList } from './styled'

export const TitleTooltip = () => {
  return (
    <>
      <TooltipTitle>Examples of strong titles:</TooltipTitle>
      Give your Data Product a title that is{' '}
      <TooltipList>
        <li>
          Mobile location & POI visit data for consumers in Japan (6-month
          historical lookback)
        </li>
        <li>
          Verified dataset of all retail CEOs in APAC + emails and phone numbers
        </li>
        <li>
          {' '}
          US companies’ Sustainable Development Goal performance (min. weekly
          updates)
        </li>
      </TooltipList>
    </>
  )
}

export const DescriptionTooltip = () => {
  return (
    <>
      <TooltipTitle>What does a good description look like?</TooltipTitle>
      Is your chance to
      <FontSemiBold> engage with data buyers</FontSemiBold> who want to fully
      understand a Data Product before requesting it.
      <br />
      <br />
      By adding
      <FontSemiBold>search-optimized keywords</FontSemiBold> in the text you can
      <FontSemiBold>increase the visibility</FontSemiBold> of your Data Products
      as well.
      <br />
      <br /> Try to <FontSemiBold>answer the following questions:</FontSemiBold>
      <TooltipList>
        <li>What makes your data unique?</li>
        <li>How is the data generally sourced?</li>
        <li>
          What are the primary use-cases or verticals of this Data Product?
        </li>
        <li>How does this Data Product fit into your broader data offering?</li>
        <li>
          {' '}
          Great in-depth descriptions comprise several hundred words. Most top
          performing products on Kamba have a description length between 3000
          and 6000 characters.
        </li>
      </TooltipList>
    </>
  )
}

export const AttributesTooltip = () => {
  return (
    <>
      <TooltipTitle>Why are attributes important?</TooltipTitle>
      They are the<FontSemiBold> essence of your data</FontSemiBold> and have a{' '}
      <FontSemiBold>
        tremendous impact in convincing potential buyers
      </FontSemiBold>{' '}
      to send a contact request.
      <br />
      <br />
      When data buyers see the available attributes they
      <FontSemiBold> get an instant idea of what is included.</FontSemiBold>
      Data attributes contain of the following elements:
      <TooltipList>
        <li>Name - often the column header of your data file</li>
        <li>Example - a sample input field</li>
        <li>
          Description - an extended description of the column header so that
          it&apos;s easier understood
        </li>
        <li>Number of Records - the scale of your available attribute</li>
      </TooltipList>
    </>
  )
}
