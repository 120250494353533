export const deliveryMethods = [
  {
    label: 'One-Time',
    value: 'one-time',
    info: 'Just want to purchase the data in a simple one-off transaction',
  },
  {
    label: 'Ongoing',
    value: 'ongoing',
    info: 'Receive the data on a regular basis like monthly or weekly',
  },
  {
    label: 'Usage based',
    value: 'usage-based',
    info: 'Get the data by yourself and pay for what you use',
  },
]
