import { TabsSubscriptions } from './types'
import SubscriptionList from './subscriptions-list'
import ExpiredList from './subscriptions-list/expired'

export const tabs: TabsSubscriptions[] = [
  {
    id: 'pending',
    label: 'Pending Delivery',
    Component: SubscriptionList,
    name: 'pending',
  },
  {
    id: 'delivered',
    label: 'Active',
    Component: SubscriptionList,
    name: 'pending',
  },
  {
    id: 'expired',
    label: 'Expired',
    Component: ExpiredList,
    name: 'expired',
  },
]
