import { Button, GradientSeparator, Title } from '@/components'
import { Grid } from '@mui/material'
import * as React from 'react'
import { useNavigate } from 'react-router'
import { FilterContainer } from './styled'
//import { IFilterRedirectProps } from './types'

interface IFilterRedirectProps {
  text?: string
  boldText?: string
  filters?: string[]
  columnSize?: any
  isSeparatorEnabled?: boolean
  marginContainer?: any
  fontSize?: string
}

const FilterRedirect: React.FC<IFilterRedirectProps> = ({
  filters,
  text,
  boldText,
  columnSize,
  isSeparatorEnabled,
  marginContainer,
  fontSize,
  //...titleProps
}) => {
  const navigate = useNavigate()
  const handleOnClick =
    (urlPath: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
      navigate(`/${urlPath}`)
    }

  return (
    <>
      <FilterContainer marginContainer={marginContainer}>
        <Title
          text={text}
          boldText={boldText}
          fontSize={fontSize}
          margin="30px 0"
        />
        <Grid container item xs={10} spacing={1}>
          {filters &&
            filters.map((name) => (
              <Grid
                item
                xs={columnSize ? columnSize : 'auto'}
                sx={{ marginTop: '10px' }}
                key={name}
              >
                <Button
                  variant="secondary" /*onClick={handleOnClick(urlPath)}*/
                  style={{ cursor: 'text' }}
                >
                  {name}
                </Button>
              </Grid>
            ))}
        </Grid>
      </FilterContainer>
      {isSeparatorEnabled && <GradientSeparator margin="30px 0" />}
    </>
  )
}

export default FilterRedirect
