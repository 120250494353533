import AddFileLogo from '@assets/images/icons/AddFileIcon.svg'
import FilePlus from '@assets/images/icons/file-plus.svg'

import { DragDropZone, LabelChooseFile } from './styled'
import { DragAndDropProps } from './types'

const DragAndDrop = ({ dispatch }: DragAndDropProps) => {
  const handleDragEnter = (event: any) => {
    event.preventDefault()
  }

  const handleDragOver = (event: any) => {
    event.preventDefault()
  }

  const handleDrop = (event: any) => {
    event.preventDefault()
    let files = [...event.dataTransfer.files]

    files.map((file) => {
      file['preview'] = URL.createObjectURL(file)
    })

    if (files) {
      dispatch(files[0])
      event.target.value = ''
    }
  }

  const uploadFile = (event: any) => {
    event.preventDefault()
    const files = [...event.target.files]

    files.map((file) => {
      file['preview'] = URL.createObjectURL(file)
    })

    if (files) {
      dispatch(files[0])
      event.target.value = ''
    }
  }
  return (
    <>
      <DragDropZone
        id="container"
        onDrop={(event) => handleDrop(event)}
        onDragOver={(event) => handleDragOver(event)}
        onDragEnter={(event) => handleDragEnter(event)}
      >
        <FilePlus stroke="black" />

        <p>Drag your files here</p>
        <p>or</p>
        <LabelChooseFile htmlFor="file-upload">
          <AddFileLogo />
          Choose a File
          <input
            type="file"
            id="file-upload"
            style={{ display: 'none' }}
            onChange={uploadFile}
          />
        </LabelChooseFile>
      </DragDropZone>
    </>
  )
}

export default DragAndDrop
