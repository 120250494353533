import * as React from 'react'
import { QueryClientProvider } from 'react-query'
import { queryClient } from '../lib/react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { AuthProvider } from '../services/auth/auth'

type AppProviderProps = {
  children: React.ReactNode
}

const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      {process.env.NODE_ENV !== 'prod' && <ReactQueryDevtools />}
      <AuthProvider>{children}</AuthProvider>
    </QueryClientProvider>
  )
}

export default AppProvider
