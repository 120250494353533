import * as React from 'react'
import { ThemeProvider } from 'styled-components'
import { ButtonStyled, theme as defaultTheme } from './styled'
import { IButtonProps } from './types'

const Button: React.FC<IButtonProps> = ({
  children,
  theme,
  variant,
  ...props
}) => {
  return (
    <ThemeProvider theme={(theme = defaultTheme)}>
      <ButtonStyled variant={variant} {...props}>
        {children}
      </ButtonStyled>
    </ThemeProvider>
  )
}

export default Button
