import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import LongCard from '../../../../components/long-card'
import {
  ButtonContentWrapper,
  ButtonText,
  CtaWrapper,
  EmptyStateWrapper,
  Info,
  ListWrapper,
  RightSideWrapper,
  StatusText,
  TextLine,
  Title,
} from './styled'
import StatusDot from '@/components/ui/status-dot'
import Button from '@/components/ui/button'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import storage from '@/utils/storage'
import { useAppSelector } from '@/app/hooks'
import { axios } from '@/lib/axios'
import { ClipLoader } from 'react-spinners'
import { LoaderWrapper } from '@/pages/product/styled'

interface IPostingProps {
  id?: string
  postings?: any
}

const LeadList: React.FunctionComponent<IPostingProps> = ({ id }) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const postingsArray = useRef<any | undefined>()
  const proposalsArray = useRef<any | undefined>()
  const baseUrl = process.env.REACT_APP_URL_BASE
  const token = storage.getToken()
  const userId = useAppSelector((state) => state.authUser.userId)
  const supportEmail = process.env.SUPPORT_EMAIL

  useEffect(() => {
    // console.info('GET PROPOSAL - Lead List ===', id)
    setLoading(true)
    getPostings().then(() => {
      getProposals().then(() => {
        setLoading(false)
      })
    })
  }, [])

  const getAllProposals = () => {
    if (token && userId) {
      const res = axios
        .get(`${baseUrl}/api/v1/users/${userId}/proposals`)
        .then(function (response: any) {
          return response
        })
        .catch(function (error: any) {
          console.log(error)
        })
      return res
    }
  }

  const getProposals = async () => {
    try {
      const allProposals = await getAllProposals()
      proposalsArray.current = allProposals.data
    } catch {
      console.log('error')
    }
  }

  const getAllPostings = () => {
    if (token && userId) {
      const res = axios
        .get(`${baseUrl}/api/v1/postings`)
        .then(function (response: any) {
          return response
        })
        .catch(function (error: any) {
          console.log(error)
        })
      return res
    }
  }

  const getPostings = async () => {
    try {
      const allPostings = await getAllPostings()
      postingsArray.current = allPostings.data
    } catch {
      console.log('error')
    }
  }

  return (
    <ListWrapper>
      {!!loading ? (
        <LoaderWrapper>
          <ClipLoader size={36} />
        </LoaderWrapper>
      ) : !postingsArray.current ? (
        <EmptyStateWrapper>
          <Title>Something happened</Title>
          <Info>
            <span>Please contact</span>{' '}
            <a href={`"mailto:${supportEmail}`}>Kamba support</a>
          </Info>
        </EmptyStateWrapper>
      ) : !!postingsArray.current && !proposalsArray.current ? (
        postingsArray.current
          .sort((a: any, b: any) => {
            const dateA = new Date(a.createdAt!)
            const dateB = new Date(b.createdAt!)
            return dateB.getTime() - dateA.getTime()
          })
          .map((post: any) => {
            return (
              <LongCard
                key={post.id}
                title={post.title}
                price={post.budgetHigh}
                paymentOption={post.delivery}
                type={post.typeOfData}
                countries={post.geo}
                description={post.useCase}
                onClick={() => navigate(`/posting/${post.id}`, { state: post })}
                rightSide={
                  <RightSideWrapper>
                    <TextLine>
                      <StatusDot active />
                      <StatusText active>20 days left</StatusText>
                    </TextLine>
                    <TextLine>
                      <StatusDot active />
                      <StatusText active>8 provider responses left</StatusText>
                    </TextLine>
                    <CtaWrapper>
                      <Button>
                        <ButtonContentWrapper>
                          <ButtonText>PROPOSE PRODUCT</ButtonText>
                          <ArrowForwardRoundedIcon fontSize="small" />
                        </ButtonContentWrapper>
                      </Button>
                    </CtaWrapper>
                  </RightSideWrapper>
                }
              />
            )
          })
      ) : !!postingsArray.current && !!proposalsArray.current ? (
        postingsArray.current
          .sort((a: any, b: any) => {
            const dateA = new Date(a.createdAt!)
            const dateB = new Date(b.createdAt!)
            return dateB.getTime() - dateA.getTime()
          })
          .map((post: any) => {
            if (
              proposalsArray?.current.find(
                (proposal: any) => proposal.postingId === post.id,
              )
            ) {
              return null
            } else {
              return (
                <LongCard
                  key={post.id}
                  title={post.title}
                  price={post.budgetHigh}
                  paymentOption={post.delivery}
                  type={post.typeOfData}
                  countries={post.geo}
                  description={post.useCase}
                  onClick={() =>
                    navigate(`/posting/${post.id}`, { state: post })
                  }
                  rightSide={
                    <RightSideWrapper>
                      {/*<TextLine>
                        <StatusDot active />
                        <StatusText active>20 days left</StatusText>
                      </TextLine>
                      <TextLine>
                        <StatusDot active />
                        <StatusText active>
                          8 provider responses left
                        </StatusText>
                  </TextLine>*/}
                      <CtaWrapper>
                        <Button>
                          <ButtonContentWrapper>
                            <ButtonText>PROPOSE PRODUCT</ButtonText>
                            <ArrowForwardRoundedIcon fontSize="small" />
                          </ButtonContentWrapper>
                        </Button>
                      </CtaWrapper>
                    </RightSideWrapper>
                  }
                />
              )
            }
          })
      ) : null}
    </ListWrapper>
  )
}

export default LeadList

/*<EmptyStateWrapper>
          <Title>You have made no proposals yet</Title>
          <Info>
            Post your request and reach out to our +3000 providers at once.
          </Info>
          <Button onClick={() => navigate(`/proposals`)} />
        </EmptyStateWrapper> */
