import { useGetFilters } from '@/services/filters/filters.service'
import { Box } from '@mui/system'
import { useMemo } from 'react'
import Loader from 'react-spinners/ClipLoader'
import Filter from './filter'
import { Options } from 'react-select'
import { capitalize } from '@mui/material'
import { Option } from './types'
import { useSearchParams } from 'react-router-dom'

interface FiltersProps {}

const Filters: React.FC<FiltersProps> = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { isLoading, data } = useGetFilters()

  const selectFilter = (value: Option[], type: string) => {
    searchParams.delete(type)
    value.forEach((v) => searchParams.append(type, v.value as string))
    setSearchParams(searchParams)
  }

  const { users, source, listings } = useMemo(() => {
    let users = [] as Options<{ label: string; value: string }>
    let source = [] as Options<{ label: string; value: string }>
    let listings = [] as Options<{ label: string; value: string }>
    let tags = [] as Options<{ label: string; value: string }>

    if (data?.data) {
      users = data.data.users.map((u) => ({
        label: u.userName,
        value: u.id,
      }))

      source = data.data.source.map((s) => ({
        label: capitalize(s),
        value: s,
      }))

      listings = data.data.listings.map((l) => ({
        label: l.company + ' - ' + l.title,
        value: l.id,
      }))
    }

    return {
      users,
      source,
      listings,
      tags,
    }
  }, [data])

  const { selectedListings, selectedSources, selectedUsers } = useMemo(() => {
    // Listings
    const sListings = searchParams.getAll('listings')
    const selectedListings = listings.filter((l) => sListings.includes(l.value))

    // Source
    const sSource = searchParams.getAll('source')
    const selectedSources = source.filter((s) => sSource.includes(s.value))

    // Users
    const sUsers = searchParams.getAll('users')
    const selectedUsers = users.filter((u) => sUsers.includes(u.value))

    return {
      selectedListings,
      selectedSources,
      selectedUsers,
    }
  }, [searchParams, listings, source, users])

  if (isLoading) return <Loader size={20} />

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="row"
      justifyContent={'flex-start'}
    >
      <Filter
        data={listings}
        onChange={(a) => selectFilter(a, 'listings')}
        defaultValue={selectedListings}
        isMulti={true}
        placeholder="Filter By Product"
        width="400px"
      />
      <Filter
        data={source}
        onChange={(a) => selectFilter(a, 'source')}
        defaultValue={selectedSources}
        isMulti={true}
        placeholder="Filter By Type"
        width="400px"
      />
      <Filter
        data={users}
        onChange={(a) => selectFilter(a, 'users')}
        defaultValue={selectedUsers}
        isMulti={true}
        placeholder="Filter By user"
        width="400px"
      />
    </Box>
  )
}

export default Filters
