import { useState } from 'react'
import ReactInputVerificationCode from 'react-input-verification-code'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate, useSearchParams } from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader'
import * as yup from 'yup'
import {
  Container,
  LoginWrapper,
  Logo,
  LoginCard,
  InputBlock,
} from '../../components'
import {
  Divider,
  Title,
  FormGroup,
  Button,
  TextLink,
  TextWrapper,
  Error,
  Line,
  Text,
  CodeVerificationWrapper,
  Paragraph,
} from './styled'

type ForgotPasswordData = {
  userName: string
}

const schema = yup
  .object({
    userName: yup.string().required('Email is required'),
  })
  .required()

const ForgotPassword = () => {
  const baseUrl = process.env.REACT_APP_URL_BASE
  const axios = require('axios').default
  const [error, setError] = useState(false)
  const [code, setCode] = useState('')
  const navigate = useNavigate()
  let [searchParams] = useSearchParams()
  const fromEmail = searchParams.get('success') === 'true' ? true : false
  const username = searchParams.get('username')
  const [success, setSuccess] = useState(fromEmail)
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<ForgotPasswordData>({
    resolver: yupResolver(schema),
  })

  const workEmailRegister = { ...register('userName') }
  const emailWatcher = watch('userName')
  const fetchForgotPassword = ({ userName }: ForgotPasswordData) => {
    const res = axios
      .post(`${baseUrl}/api/v1/forgot-password`, {
        username: userName,
      })
      .then(function (response: any) {
        return response
      })
      .catch(function (error: any) {
        return error
      })

    return res
  }

  const onSubmit = handleSubmit(async (data: ForgotPasswordData) => {
    setError(false)
    try {
      const isSignUp = await fetchForgotPassword(data)
      if (isSignUp.message) {
        return setError(true)
      }
      setSuccess(true)
    } catch (error) {
      return setError(true)
    }
  })
  const onResendCode = () => {
    fetchForgotPassword({ userName: username || emailWatcher })
  }

  return (
    <Container height="100vh">
      <LoginWrapper>
        <Logo />
        <LoginCard>
          {!success && (
            <>
              <Divider />
              <Title>Reset Your Password</Title>
              <Text>
                We&apos;ll email you instructions to reset your password. If you
                don&apos;t have access to your email anymore, please{' '}
                <TextLink to="/" rel="noopener noreferrer">
                  contact us
                </TextLink>{' '}
                to recover your account
              </Text>
              <FormGroup onSubmit={onSubmit}>
                <InputBlock
                  label="Work Email"
                  isMail
                  type="email"
                  params={workEmailRegister}
                  errorMessage={errors.userName?.message}
                  marginBottom="32px"
                  onChange={register('userName').onChange}
                />
                <Button type="submit">
                  {isSubmitting ? (
                    <ClipLoader
                      color="#ffffff"
                      loading={isSubmitting}
                      size={15}
                    />
                  ) : (
                    `Resend RESET instructions`
                  )}
                </Button>
                {error && <Error>An error ocurred. Please try again.</Error>}
              </FormGroup>
            </>
          )}
          {success && (
            <>
              <Divider />
              <Title>Enter verification code</Title>
              <Paragraph>
                Please open your email inbox and enter the 6-digit verification
                code we&apos;ve just sent you here.
              </Paragraph>
              <CodeVerificationWrapper>
                <div className="custom-styles">
                  <ReactInputVerificationCode
                    length={6}
                    placeholder=""
                    onChange={setCode}
                  />
                </div>
              </CodeVerificationWrapper>
              <Button
                type="submit"
                disabled={code.length < 6}
                onClick={() =>
                  navigate(
                    `/change-password/?username=${
                      getValues('userName') || username
                    }&code=${code}`,
                  )
                }
              >
                {code.length < 6
                  ? `${6 - code.length} digits left`
                  : `Send Code`}
              </Button>
              <Button onClick={onResendCode} isSecondary>
                Resend Code
              </Button>
              <Line />
              {/* <TextWrapper>
                Haven't received your verification email?{' '}
                <TextLink
                  to="/email-verification-code"
                  rel="noopener noreferrer"
                >
                  Contact Us
                </TextLink>
              </TextWrapper> */}
            </>
          )}
        </LoginCard>
      </LoginWrapper>
    </Container>
  )
}

export default ForgotPassword
