import { TabsProduct } from '@/pages/product/types'
import { Tab, Tabs } from '@mui/material'
import { red } from '@mui/material/colors'
import React, { useState } from 'react'

interface TabsComponentProps {
  tabs: TabsProduct[]
  currentIndex: number
  callback?: (idx: string) => void
}

function a11yProps(id: string) {
  return {
    id: `${id}`,
    'aria-controls': `simple-tabpanel-${id}`,
  }
}
/**
 * > TabsComponent loops through tabs array.
 * >> Sets track internal state and use an external callback
 * >> Allows default index tab (initially 0)
 */
const TabsComponent: React.FC<TabsComponentProps> = ({
  tabs,
  currentIndex = 0,
  callback,
}) => {
  const [value, setValue] = useState(currentIndex)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
    callback && callback(event.currentTarget.id)
  }

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      aria-label="basic tabs example"
      TabIndicatorProps={{
        sx: {
          borderBottom: '5px solid #000000',
          background: 'white',
        },
      }}
      sx={{
        '& .MuiTab-textColorPrimary ': {
          color: '#757575',
        },
        paddingX: 5,
        background: 'white',
        minHeight: '70px',
      }}
    >
      {tabs.map((t, idx) => (
        <Tab
          key={idx}
          label={t.label}
          {...a11yProps(t.id)}
          sx={{
            textTransform: 'Capitalize',
            padding: '25px',
            fontSize: '16px',
            '&.Mui-selected': {
              color: '#000000',
              fontWeight: 600,
            },
          }}
        ></Tab>
      ))}
    </Tabs>
  )
}

export default TabsComponent
