import { ApiResponse } from '@/services/types'
import { axios } from '@lib/axios'
import { Posting } from '../types'

type ApiPostingsResponse = ApiResponse<Posting[]>

export const getPostings = ({
  ...params
}: Posting): Promise<ApiPostingsResponse> => {
  return axios.get(`/postings`, {
    params: { ...params },
  })
}

export const getPostingsById = ({
  userId,
  ...params
}: Posting): Promise<ApiPostingsResponse> => {
  return axios.get(`${userId}/postings`, {
    params: { ...params },
  })
}
