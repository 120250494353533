import { Grid } from '@mui/material'
import { GradientSeparator, GridItemBorder } from '@components'
import * as React from 'react'
import Header from './header'
import Metric from './metric'
import { metricsData } from './metric/metricMocks'
import { MetricList, HeaderDescription } from './styled'
import { HeaderMock } from './header/headerMock'
import Score from './score'
import Share from './share'
import WithScrollToElement from '@/hocs/withScrollToElement'
import { createMarkup } from '@/utils/purify-html'

interface IInfoQualityProps {
  product: any
  metrics?: any[]
}

const InfoQuality: React.FC<IInfoQualityProps> = (props) => {
  const { description, rating, scores } = HeaderMock
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Header product={props.product} />
        </Grid>
        <Grid item container xs={12} sx={{ marginTop: '45px' }}>
          {props.metrics && props.metrics.length > 0 && (
            <MetricList>
              {props.metrics.map((metric) => (
                <li key={metric.value}>
                  <GridItemBorder xs item className="gridItemBorder">
                    <Metric
                      label={metric.label}
                      value={metric.value}
                      description={metric.description}
                    />
                  </GridItemBorder>
                </li>
              ))}
            </MetricList>
          )}
        </Grid>
        {props.product.longDescription && (
          <Grid xs={12} item>
            <HeaderDescription
              dangerouslySetInnerHTML={createMarkup(
                props.product.longDescription,
              )}
            />
          </Grid>
        )}
        <GradientSeparator margin="30px 0" />
        {/* {props.product.qualityData[0] && (
          <Grid xs={11} item sx={{ margin: '60px 0' }}>
            <Score
              scores={scores}
              totalValue={props.product.qualityData[0].volume}
            />
          </Grid>
        )}
        <GradientSeparator /> */}
        <Grid xs={12} item>
          <Share />
        </Grid>
        <GradientSeparator margin="30px 0" />
      </Grid>
    </>
  )
}
/* @ts-ignore */
export default WithScrollToElement(InfoQuality, 'infoQuality')
