import { AccountData } from '@/services/signup-validation/api/api/signup-validation'
import { useSignupValidation } from '@/services/signup-validation/api/signup-validation.service'
import ReactInputVerificationCode from 'react-input-verification-code'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { Container, LoginWrapper, Logo, LoginCard } from '../../components'
import {
  Divider,
  Title,
  Line,
  TextLink,
  TextWrapper,
  CodeVerificationWrapper,
  Paragraph,
  Button,
} from './styled'
import { useState } from 'react'
import { ClipLoader } from 'react-spinners'
import { Error } from '../forgot-password/styled'
import { axios } from '@lib/axios'

const EmailVerificationCode = () => {
  const [code, setCode] = useState('')
  let [searchParams] = useSearchParams()
  let username = searchParams.get('username')
  const baseUrl = process.env.REACT_APP_URL_BASE
  const supportEmail = process.env.SUPPORT_EMAIL
  const navigate = useNavigate()

  const {
    mutate: signupValidation,
    isError,
    isLoading,
  } = useSignupValidation(() => {
    sendAccountCreationEmailToKamba()
    navigate('/login')
  })

  const submitCode = async ({ username, code }: AccountData) => {
    signupValidation({ username: username, code: code })
  }

  const sendAccountCreationEmailToKamba = () => {
    const res = axios
      .post(`${baseUrl}/api/v1/send-email-no-template`, {
        from: supportEmail,
        to: supportEmail,
        subject: 'New Kamba Account Created',
        content: `A new account has been created with the username: ${username}`,
      })
      .then(function (response: any) {
        return response
      })
      .catch(function (error: any) {
        console.log(error)
      })
    return res
  }

  return (
    <Container>
      <LoginWrapper>
        <Logo />
        <LoginCard maxWidth="539px">
          <Divider />
          <Title>Enter verification code</Title>
          <Paragraph>
            Please open your email inbox and enter the 6-digit verification code
            we&apos;ve just sent you here.
          </Paragraph>
          <CodeVerificationWrapper>
            <div className="custom-styles">
              <ReactInputVerificationCode
                onChange={setCode}
                length={6}
                placeholder=""
              />
            </div>
          </CodeVerificationWrapper>
          <Button
            onClick={() => {
              if (username && code) {
                submitCode({ username: username, code: code })
              }
            }}
            type="submit"
          >
            {isLoading ? (
              <ClipLoader color="#ffffff" loading={isLoading} size={15} />
            ) : (
              `Send Code`
            )}
          </Button>
          {isError && <Error>An error ocurred. Please try again.</Error>}
          <Line />
          {/* <TextWrapper>
            Haven't received your verification email?{' '}
            <TextLink to="/" rel="noopener noreferrer">
              Contact Us
            </TextLink>
          </TextWrapper> */}
        </LoginCard>
      </LoginWrapper>
    </Container>
  )
}

export default EmailVerificationCode
