import styled from 'styled-components'

export const Banner = styled.img`
  width: 100%;
  height: auto;
`
export const CompanyDescriptionTitle = styled.span`
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
`

export const CompanyDescription = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  margin-top: 20px;
`
