import styled from 'styled-components'

export const Title = styled.h2`
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
  color: #000000;
`

export const FilterTitle = styled.span`
  color: '#404040';
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
`

export const FilterList = styled.ul`
  padding: 0;
`

export const NoResults = styled.p`
  margin-left: 30px;
  font-size: 30px;
  font-weight: 700;
`

export const ResultsTitle = styled.div`
  font-family: 'Proxima Nova';
  font-weight: 700;
  font-size: 23px;
  text-align: center;
  color: #000000;
`

export const ResultsWrapper = styled.div`
  /* display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; */
  text-align: center;
`
