import styled from 'styled-components'
import { AbsoluteWrapperProps, InputProps, ColumnProps } from './types'
import CurrencyInputComponet, {
  CurrencyInputProps,
} from 'react-currency-input-field'

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`

export const Column = styled.div<ColumnProps>`
  display: block;
  width: ${({ width }) => (width ? width : 'auto')};
  position: relative;
  margin-right: 22px;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : '100%')};
`

export const AbsoluteWrapper = styled.div<AbsoluteWrapperProps>`
  position: absolute;
  top: ${({ top }) => (top ? top : '0')};
`

export const Input = styled.input<InputProps>`
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : '120px')};
  height: 54px;
  padding: 14px 18px;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  color: #000000b2;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  &:focus {
    border: 1px solid #000000;
  }
  &:focus-visible {
    border: 1px solid #00000;
    outline: 1px solid #000000;
  }
`

//// max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : '120px')};
export const CurrencyInput = styled(CurrencyInputComponet)<CurrencyInputProps>`
  width: 100%;
  height: 54px;
  padding: 14px 18px;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  color: #000000b2;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  &:focus {
    border: 1px solid #000000;
  }
  &:focus-visible {
    border: 1px solid #00000;
    outline: 1px solid #000000;
  }
`

export const UnitText = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #303030;
  opacity: 0.5;
  margin-right: 10px;
  margin-left: -10px;
`
