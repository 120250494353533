import styled from 'styled-components'
import { DividerProps, IMenuProps } from './types'

export const StorefrontWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding-top: 50px;
`

export const MenuPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: sticky;
  top: 0;
`

export const Breadcrum = styled.span`
  font-weight: 500;
  color: #5a5a5a;
`

export const Active = styled.span`
  font-weight: 700;
  color: #000000;
`

export const CompanyName = styled.h1`
  font-size: 36px;
  font-weight: 700;
  text-transform: capitalize;
`

export const CompanyProfileTitle = styled.h1`
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 50px;
`

export const MenuWrapper = styled.div`
  display: flex;
  align-items: left;
  flex-direction: column;
  width: 100%;
`

export const MenuItem = styled.div<IMenuProps>`
  padding: 18px 24px;
  cursor: pointer;
  position: relative;
  border-bottom: 1px solid #fafafa;

  &:before {
    content: '';
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #000000;
    width: 3px;
    height: 100%;
    transition: all 0.2s;
  }

  &:hover {
    background: #fafafa;
    transition: all 0.5s;

    &:before {
      width: 5px;
      opacity: 1;
    }
  }

  background: ${({ isSelected }) => isSelected && '#fafafa'};

  &:before {
    width: ${({ isSelected }) => isSelected && '5px'};
    opacity: ${({ isSelected }) => isSelected && 1};
  }

  &:last-child {
    border-bottom: none;
  }
`

export const ProfileStrength = styled.div`
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  margin-top: 180px;
`

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #f6f6f6;
  max-width: 675px;
  gap: 1.5rem;
  padding: 40px 60px;
`

export const FormTitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #646464;
`

export const InputTitle = styled.p`
  font-weight: 700;
  font-size: 28px;
  letter-spacing: -0.015em;
  color: #000000;
`

export const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const SavedChanges = styled.p`
  text-align: center;
  color: green;
  font-style: italic;
  font-size: 14px;
`

export const KeyBenefits = styled.div`
  background: #ffffff;
  width: 100%;
  display: flex;
  border: 1px solid #d9d9d9;
  justify-content: space-around;
`

export const Preview = styled.div`
  display: flex;
  align-items: center;

  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.02em;
    margin-right: 10px;
  }
`

export const Benefit = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 30px;
`

export const CustomDivider = styled.div<DividerProps>`
  height: ${({ height }) => height || '50px'};
  margin: 10px 0;
  border: 0.5px solid #d9d9d9;
`

export const Label = styled.p`
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.01em;
  word-wrap: break-word;
`

export const Value = styled.p`
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.03em;
  color: #aaaaaa;
  white-space: pre;
`

export const Modifier = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`

export const ValueWrapper = styled.div`
  width: 35%;
`

export const BillingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  &:first-child {
    margin-right: 10px;
  }
`

export const CountryInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  & > p {
    margin-bottom: 22px;
  }

  & > div > div {
    height: 54px;
  }
`

/* The snackbar - position it at the bottom and in the middle of the screen */
export const Snackbar = styled.div`
  visibility: hidden; /* Hidden by default. Visible on click */
  min-width: 250px; /* Set a default minimum width */
  margin-left: -125px; /* Divide value of min-width by 2 */
  background-color: #333; /* Black background color */
  color: #fff; /* White text color */
  text-align: center; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 1; /* Add a z-index if needed */
  left: 50%; /* Center the snackbar */
  bottom: 30px; /* 30px from the bottom */

  &.show {
    visibility: visible; /* Show the snackbar */
    /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
    However, delay the fade out process for 2.5 seconds */
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }
  /* Animations to fade the snackbar in and out */
  @keyframes fadein {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 30px;
      opacity: 1;
    }
  }
  @keyframes fadein {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 30px;
      opacity: 1;
    }
  }

  @keyframes fadeout {
    from {
      bottom: 30px;
      opacity: 1;
    }
    to {
      bottom: 0;
      opacity: 0;
    }
  }
`
