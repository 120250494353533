import { ICategories } from '../types'

export const categories: ICategories[] = [
  { id: 'branding', name: 'Branding' },
  { id: 'company-info', name: 'Company Information' },
  { id: 'offering', name: 'Offering' },
  { id: 'benefits', name: 'Key Benefits & Differentiators' },
  { id: 'pricing', name: 'Pricing & Use Cases' },
  { id: 'privacy', name: 'Privacy & Compliance' },
  // { id: 'billing', name: 'Billing' },
]
