import * as React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useGetSubscriptions } from '@/services/subscriptions/subscriptions.service'
import {
  ListWrapper,
  Actions,
  RightSide,
  RightSideRow,
  RightSideColumn,
  RightSideItemLabel,
  RightSideItemValue,
  LoaderContainer,
} from './styled'
import { Subscription } from '@/services/subscriptions/types'
import { ClipLoader } from 'react-spinners'
import Button from '@/components/ui/button'
import LongCard from '../../../../components/long-card'
import moment from 'moment'
import { useAppSelector } from '@/app/hooks'
import storage from '@/utils/storage'
import { axios } from '@/lib/axios'

interface ISubscriptionsListProps {
  isLoading: boolean
  data: any
  name: string
}

const ExpiredList: React.FunctionComponent<ISubscriptionsListProps> = () => {
  const navigate = useNavigate()
  const userId = useAppSelector((state: any) => state.authUser.userId)
  const baseUrl = process.env.REACT_APP_URL_BASE
  const token = storage.getToken()
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    axios
      .get(`${baseUrl}/api/v1/subscriptions/${userId}`)
      .then(function (response: any) {
        setData(response.data)
        setIsLoading(!isLoading)
      })
      .catch(function (error: any) {
        console.log(error)
      })
  }, [])

  return (
    <ListWrapper>
      {isLoading ? (
        <LoaderContainer>
          <ClipLoader />
        </LoaderContainer>
      ) : data.length > 0 ? (
        data
          .filter((item: any) => {
            return item.expired
          })
          .map((subscription: any, i) => {
            return (
              <LongCard
                key={i}
                title={subscription.listing?.title ?? ''}
                provider={subscription.listing?.company?.name}
                countries={subscription.listing?.geo}
                description={subscription.listing?.longDescription ?? ''}
                showActions={
                  // TODO: status its coming as "test" from BE. Will need to change this harcoded button when field coming correct.
                  <Actions>
                    <Button
                      variant="primary"
                      onClick={
                        () => navigate(`/subscriptions/${subscription.id}`) // TODO: replace with correct path
                      }
                    >
                      Connect this subscription
                    </Button>
                  </Actions>
                }
                rightSide={
                  <RightSide>
                    <RightSideRow>
                      <RightSideColumn>
                        <RightSideItemLabel>Subscribed:</RightSideItemLabel>
                        <RightSideItemLabel>Ends:</RightSideItemLabel>
                        {subscription.listing?.buyingFormat?.map(
                          (buyingFormatItem, index) => {
                            return index === 0 ? (
                              <RightSideItemLabel key={index}>
                                Price:
                              </RightSideItemLabel>
                            ) : (
                              <RightSideItemLabel
                                key={index}
                              ></RightSideItemLabel>
                            )
                          },
                        )}
                        <RightSideItemLabel>
                          Payment schedule:
                        </RightSideItemLabel>
                        <RightSideItemLabel>Product status:</RightSideItemLabel>
                        <RightSideItemLabel>
                          Product visibility:
                        </RightSideItemLabel>
                        <RightSideItemLabel>Data sets:</RightSideItemLabel>
                      </RightSideColumn>
                      <RightSideColumn>
                        <RightSideItemValue>
                          {moment(subscription.suscribed, 'DD-MM-YYYY').format(
                            'MMM DD, YYYY',
                          )}
                        </RightSideItemValue>
                        <RightSideItemValue>
                          {moment(subscription.end, 'DD-MM-YYYY').format(
                            'MMM DD, YYYY',
                          )}
                        </RightSideItemValue>
                        {subscription.listing?.buyingFormat?.map(
                          (buyingFormatItem, i) => {
                            return (
                              <RightSideItemValue key={i}>
                                {buyingFormatItem.price} {buyingFormatItem.type}
                              </RightSideItemValue>
                            )
                          },
                        )}
                        <RightSideItemValue>
                          {subscription.payment}
                        </RightSideItemValue>
                        <RightSideItemValue>
                          {subscription.status}
                        </RightSideItemValue>
                        <RightSideItemValue>
                          {subscription.visibility}
                        </RightSideItemValue>
                        <RightSideItemValue>
                          {subscription.datasets}
                        </RightSideItemValue>
                      </RightSideColumn>
                    </RightSideRow>
                    {/* TODO: by the moment, there is no field coming from BE to know the auto-renews data */}
                    <RightSideItemValue>
                      Auto-renews on May 30, 2023
                    </RightSideItemValue>
                  </RightSide>
                }
              />
            )
          })
      ) : (
        <LoaderContainer>
          <p>There are no subscriptions</p>
        </LoaderContainer>
      )}
    </ListWrapper>
  )
}

export default ExpiredList
