import { findCountriesByString } from '@/utils/countryFind'
import {
  LongCardWrapper,
  Title,
  Details,
  CustomDivider,
  Info,
  Description,
  DescriptionText,
  Price,
  Provider,
  Type,
  CountryList,
  PaymentOption,
  LongCardRight,
  FlagImg,
} from './styled'
import { ILongCardProps } from './types'

const LongCard: React.FunctionComponent<ILongCardProps> = ({
  title,
  price,
  priceLow,
  provider,
  paymentOption,
  type,
  countries,
  description,
  showActions,
  onClick,
  rightSide,
  anonimousBudget,
}) => {
  const renderDetails = () => {
    const countriesData = countries ? findCountriesByString(countries) : []
    return (
      <Details>
        {price && anonimousBudget && (
          <>
            <Price className="details-item">
              ${priceLow.toLocaleString('en')}
            </Price>
            <CustomDivider />
            <Price className="details-item">
              ${price.toLocaleString('en')}
            </Price>
          </>
        )}
        {provider && (
          <>
            <Provider className="details-item">
              Provided By: {provider}
            </Provider>
            <CustomDivider />
          </>
        )}
        {paymentOption && (
          <>
            <PaymentOption className="details-item base-stye">
              {paymentOption}
            </PaymentOption>
            <CustomDivider />
          </>
        )}
        {type && (
          <>
            <Type className="details-item base-style">{type}</Type>
            <CustomDivider />
          </>
        )}
        {countriesData &&
          countriesData.map((country) => (
            <div key={country.country} className="country-list">
              <CountryList className="details-item base-style">
                {<FlagImg src={country.image} />}
                {country.country}
              </CountryList>
              <CustomDivider />
            </div>
          ))}
      </Details>
    )
  }

  return (
    <LongCardWrapper onClick={onClick}>
      <Info>
        <Title>{title}</Title>
        {renderDetails()}
        <Description>
          <DescriptionText>{description}</DescriptionText>
        </Description>
        <>{showActions}</>
      </Info>
      <CustomDivider className="divider" height={'160px'}></CustomDivider>
      <LongCardRight>{rightSide}</LongCardRight>
    </LongCardWrapper>
  )
}

export default LongCard
