import { useQuery } from 'react-query'
import { getSubsById, getSubscriptions } from './api/subscriptions'
import { SubscriptionQuery, SubscriptionsQuery } from './types'
import { ExtractFnReturnType, QueryConfig } from '@lib/react-query'

type SubcriptionsQueryFnType = typeof getSubscriptions
type SubcriptionsConfigFn = QueryConfig<SubcriptionsQueryFnType>
type SubcriptionByIdQueryFnType = typeof getSubsById
type SubscriptionByIdConfigFn = QueryConfig<SubcriptionsQueryFnType>

export const useGetSubscriptions = ({
  userId,
}: SubscriptionsQuery<SubcriptionsConfigFn>) => {
  return useQuery<ExtractFnReturnType<SubcriptionsQueryFnType>>({
    queryKey: ['all-subscriptions'],
    queryFn: () => getSubscriptions(userId),
  })
}
export const useGetSubsById = ({
  subId,
  enabled,
  onSuccess,
}: SubscriptionQuery<SubscriptionByIdConfigFn>) =>
  // onSuccess?: () => void,
  {
    return useQuery<ExtractFnReturnType<SubcriptionByIdQueryFnType>>({
      queryKey: ['subscription'],
      enabled: enabled,
      queryFn: () => getSubsById(subId),
      onSuccess: () => onSuccess && onSuccess(),
      refetchOnWindowFocus: false,
      // refetchOnMount: false,
    })
  }
