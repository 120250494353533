import { useState } from 'react'
import { Collapse } from 'react-collapse'
import { Wrapper, Header, Arrow } from './styled'
import { CollapsibleProps } from './types'

const Collapsible = ({ label, children, ...props }: CollapsibleProps) => {
  const [open, setOpen] = useState(false)
  return (
    <Wrapper>
      <Header onClick={() => setOpen(!open)} isOpened={open} {...props}>
        <Arrow isOpened={open} />
        {label}
      </Header>
      <Collapse isOpened={open}>{children}</Collapse>
    </Wrapper>
  )
}

export default Collapsible
