import * as React from 'react'
import Contact from '@/components/contact'
import Detail from './detail/index-old'
import Subscription from './subscription'
import { Sidebar, AppBarHeader, TabsNavigation } from '@components'
import { Container, ErrorMessage, LoaderWrapper } from './styled'
import { tabs } from './tabsProduct'
import { Grid, Container as Wrapper } from '@mui/material'
import { SubscriptionMock } from './subscription/subscriptionMock'
import { ContactMock } from '@/components/contact/contactMock'
import { useParams } from 'react-router'
import ClipLoader from 'react-spinners/ClipLoader'
import storage from '@/utils/storage'
import { useAppSelector } from '@/app/hooks'
import { axios } from '@/lib/axios'
import { string } from 'yup'
import { off } from 'process'
import { CompanyProfile } from '@/services/storefront/types'
import { TabsProduct } from '@/pages/product/types'

interface IProductProps {}

const Product: React.FC<IProductProps> = (props) => {
  const [product, setProduct] = React.useState<any>()
  const [companyProfile, setCompanyProfile] = React.useState<
    CompanyProfile | undefined
  >()
  const [loading, setLoading] = React.useState(true)
  const { price } = SubscriptionMock
  const { companyName, phoneNumber } = ContactMock
  const { id } = useParams()
  const userRole = useAppSelector((state) => state.authUser.userRole)
  const userId = useAppSelector((state) => state.authUser.userId)
  const baseUrl = process.env.REACT_APP_URL_BASE
  const token = storage.getToken()

  const getProduct = (id: string) => {
    const res = axios
      .get(`${baseUrl}/api/v1/listings/${id}`)
      .then(function (response: any) {
        return response
      })
      .catch(function (error: any) {
        return error
      })
    return res
  }

  const getCompanyProfile = async (id: string) => {
    const company = await axios.get(`${baseUrl}/api/v1/ccompanyById/${id}`)
    const companyProfileId = company.data?.profile
    return await axios.get(
      `${baseUrl}/api/v1/company-profiles/${companyProfileId}`,
    )
  }

  const asyncFetcher = async () => {
    const productData = await getProduct(id!)
    const companyProfileData = await getCompanyProfile(
      productData?.data?.company?.id,
    )
    setProduct(productData.data)
    setCompanyProfile(companyProfileData.data)
  }

  React.useEffect(() => {
    asyncFetcher().then(() => {
      setLoading(false)
    })
  }, [id])

  const capitalize = (word: string) => {
    return word[0].toUpperCase() + word.slice(1).toLowerCase()
  }

  const renderFullDetail = () => {
    return (
      // <Grid container spacing={3} sx={{ width: '100vw', marginTop: '20px' }}>
      <Grid container spacing={3} sx={{ marginTop: '20px' }}>
        {userRole != 'buyer' && <Grid container item xs={2} />}
        <Grid item xs={7}>
          <Detail product={product!} companyProfile={companyProfile} />
        </Grid>
        {userId && userRole === 'buyer' && (
          <Grid
            container
            item
            xs={4}
            spacing={3}
            direction="column"
            justifyContent="flex-start"
            sx={{
              paddingRight: '30px',
              paddingTop: '0',
              marginTop: '-25px',
            }}
          >
            {product?.buyingFormat && (
              <Grid item xs={2}>
                <Subscription
                  buyingFormats={product?.buyingFormat}
                  listingId={id!}
                  companyId={product?.company?.id}
                />
              </Grid>
            )}
            {userId && userRole === 'buyer' && (
              <Grid item xs={4}>
                <Contact
                  companyName={capitalize(product?.company?.name || 'Company')}
                  listingId={id}
                  authorId={product?.author}
                  companyId={product?.company?.id}
                  phoneNumber={phoneNumber}
                  origin="listing"
                  showIconsInfo={true}
                />
              </Grid>
            )}
            {!userId && (
              <ErrorMessage>
                Create an account or login to send messages to this supplier.
              </ErrorMessage>
            )}
          </Grid>
        )}
      </Grid>
    )
  }

  const getTabs = () => {
    const dic: any = {
      previewAnalytics: false,
      coverage: false,
      delivery: false,
      legal: false,
      faq: false,
    }

    // console.log(!!product.dataFrequency[0])

    dic['previewAnalytics'] =
      !!product.samples &&
      product.samples.filter((sample: any) => !!sample.name).length > 0
    dic['coverage'] = !!product.geo && product.geo.length > 0
    dic['delivery'] =
      (product.dataFormat && !!product.dataFormat[0]) ||
      (product.dataMethod && !!product.dataMethod[0]) ||
      (product.dataFrequency && !!product.dataFrequency[0]) ||
      // !!product.buyingFormat[0] ||
      product.freeSample
    dic['legal'] =
      !!product.legalCompliance &&
      product.legalCompliance.find((val: any) => !!val.name)
    dic['faq'] =
      !!companyProfile?.faq &&
      !!companyProfile.faq.find((item) => !!item.answer)

    let newTabs = tabs

    newTabs = tabs.filter((tab: TabsProduct) =>
      dic.hasOwnProperty(tab.id) ? dic[tab.id] : true,
    )

    return newTabs
  }

  return (
    <Container>
      <Sidebar />
      <Container flexDirection="column">
        <AppBarHeader />
        <Wrapper maxWidth={'xl'} sx={{ margin: '45px' }}>
          {loading ? (
            <LoaderWrapper>
              <ClipLoader color="#000000" size={36} />
            </LoaderWrapper>
          ) : (
            <TabsNavigation
              tabs={getTabs()}
              backgroundMarginOffset={'45%'}
              isScrollable
            >
              {renderFullDetail()}
            </TabsNavigation>
          )}
        </Wrapper>
      </Container>
    </Container>
  )
}

export default Product
