import Filter from '@/pages/dashboard/messages/filters/filter'
import { Option } from '@/pages/dashboard/messages/filters/types'
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import React, { useMemo, useState } from 'react'
import Button from '../ui/button'
import { useGetUsers } from '@/services/users/filters.service'
import { useAppSelector } from '@/app/hooks'
import storage from '@/utils/storage'

interface ImpersonateModalProps {
  onClose: () => void
}

const ImpersonateModal: React.FC<ImpersonateModalProps> = ({ onClose }) => {
  const [saving, setSaving] = useState(false)
  const [selectedUser, setSelectedUser] = useState<any>()
  const currentUser = useAppSelector((state) => state.authUser)
  const { data, isLoading } = useGetUsers()
  const { users, usersOption } = useMemo(() => {
    let users: any[] = []
    let usersOption: Option[] = []
    if (data?.data) {
      usersOption = data.data.map((u: any) => ({
        label: `${u.username} - (${u.role})`,
        value: u.id,
      }))
      users = data.data
    }
    return {
      users,
      usersOption,
    }
  }, [data?.data])

  const impersonateSelection = (u: Option | undefined) => {
    if (u) {
      const user = users.find((a) => a.id === u.value)
      setSelectedUser(user)
    } else {
      setSelectedUser(undefined)
    }
  }

  const save = () => {
    setSaving(true)
    if (selectedUser) {
      storage.setImpersonation(currentUser)
      storage.setCognitoId(selectedUser.AwsCognito)
      storage.setCompanyId(selectedUser.ConsumerCompany)
      storage.setUserId(selectedUser.id)
      storage.setUserMail(selectedUser.username)
      storage.setUserRole(selectedUser.role)
      window.location.href = '/'
    } else {
      onClose()
    }
  }

  const selected = usersOption.find((op) => op.value === selectedUser?.id)

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="visivility-dialog">
      <DialogTitle id="visivility-dialog">Impersonate User</DialogTitle>
      <DialogContent sx={{ minWidth: '600px', minHeight: '300px' }}>
        <Box>
          <Filter
            data={usersOption}
            onChange={(a) => impersonateSelection(a as Option)}
            defaultValue={selected}
            isClearable={true}
            placeholder={isLoading ? 'Loading Users' : 'Select User'}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="secondary"
          onClick={onClose}
          style={{ width: '150px' }}
        >
          Cancel
        </Button>
        <Button style={{ maxWidth: '150px' }} variant="primary" onClick={save}>
          {saving && (
            <CircularProgress
              color="inherit"
              size={20}
              style={{ marginBottom: '-5px', marginRight: '25px' }}
            />
          )}
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ImpersonateModal
