import checkShieldIcon from '@assets/images/icons/IconShield'
import styled from 'styled-components'

export const ProfileImage = styled.img`
  width: 100%;
  height: auto;
  cursor: pointer;
  align-self: center;
`
export const VerifiedLabel = styled.span`
  font-size: 12px;
  font-weight: 700;
  white-space: pre;
  color: #2fa24f;
  line-height: 34px;
`
export const TotalScore = styled.span`
  color: #000000;
  font-weight: 600;
  font-size: 22px;
  line-height: 34px;
`

export const CheckShieldIcon = styled.div`
  background-image: url(${checkShieldIcon});
  background-repeat: no-repeat;
  color: #2fa24f;
  width: 22px;
  height: 30px;
`

export const BorderBlock = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
  border-right: 1px solid #d9d9d9;
`

export const CompanyName = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  white-space: pre;
  line-height: 18px;
  cursor: pointer;
`

export const CompanyTitle = styled.h1`
  color: #404040;
  line-height: 41px;
  font-size: 30px;
`

export const MarginSpace = styled.div`
  margin-right: 10px;
`
