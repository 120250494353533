enum Tabs {
  datasets = 'datasets',
  dataplatforms = 'dataplatforms',
  dataservices = 'dataservices',
  datasuppliers = 'datasuppliers',
}

export type TabsTypeExplore = {
  [tab in Tabs]: number
}

export interface IExploreProps {
  id: number
}

export const TabsExplore: TabsTypeExplore = {
  datasets: 1,
  dataplatforms: 2,
  dataservices: 3,
  datasuppliers: 4,
}
