import { Grid } from '@mui/material'
import { GridItemBorder, ProgressBar } from '@components'
import * as React from 'react'
import { HeaderMock } from '@/mocks/storefront/headerMock'
import { metricsData } from '@/mocks/storefront/metricMocks'
import {
  CheckShieldIcon,
  CompanyName,
  MetricList,
  ProfileImage,
  TotalScore,
  VerifiedLabel,
} from './styled'
import Metric from './metric'

interface IHeaderProps {
  company: any
  companyNative: any
}

const Header: React.FunctionComponent<IHeaderProps> = ({
  company,
  companyNative,
}) => {
  const { companyImg, companyName, isVerifiedProvider, rating } = HeaderMock

  const capitalize = (word: string) => {
    return word[0].toUpperCase() + word.slice(1).toLowerCase()
  }

  const renderVerifiedText = () => (
    <>
      <Grid item xs={6} lg={10} sx={{ display: 'flex', alignItems: 'center' }}>
        <VerifiedLabel>VERIFIED DATA PROVIDER</VerifiedLabel>
      </Grid>
      <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
        <CheckShieldIcon />
      </Grid>
    </>
  )

  const areVariables =
    company.variables.filter(
      (item: { unit: string; value: number }) => item.unit && item.unit != '',
    ).length > 0
  const renderSubTitle = () => {
    return (
      <>
        {/* <Grid
          container
          item
          xs={12}
          lg={10}
          spacing={0}
          direction="column"
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        > */}
        {/* <Grid
            item
            xs={6}
            lg={5}
            sx={{ justifyContent: 'flex-start', width: '100%' }}
          > */}
        <CompanyName>{capitalize(company.name)}</CompanyName>
        {/* </Grid> */}

        {companyNative.verified && (
          <Grid item container xs={3} lg={8}>
            {renderVerifiedText()}
          </Grid>
        )}

        {/* <Grid item xs={3} sx={{ width: '100%' }}>
            <ProgressBar value={rating} />
          </Grid> */}
        {/* </Grid> */}
      </>
    )
  }

  //console.log(company.variables)

  return (
    <>
      <Grid container columnSpacing={{ xs: 1, lg: 3 }} alignItems={'center'}>
        <Grid item xs={2} lg={2} alignItems={'center'} height={'fit-content'}>
          {company.avatar ? (
            <ProfileImage src={company.avatar}></ProfileImage>
          ) : (
            <ProfileImage src={companyImg}></ProfileImage>
          )}
        </Grid>
        <Grid
          item
          xs={areVariables ? 5 : 10}
          lg={areVariables ? 5 : 10}
          xl={areVariables ? 5 : 10}
        >
          {renderSubTitle()}
        </Grid>
        {areVariables && (
          <Grid
            item
            xs={5}
            lg={5}
            xl={5}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            height={'100%'}
          >
            <MetricList>
              {company.variables
                .filter(
                  (item: { unit: string; value: number }) =>
                    item.unit && item.unit != '',
                )
                .map(
                  (metric: { unit: string; value: number }, index: number) => (
                    <li key={index}>
                      <GridItemBorder xs item className="gridItemBorder">
                        <Metric
                          // label={metric.label}
                          value={metric.value}
                          description={metric.unit}
                        />
                      </GridItemBorder>
                    </li>
                  ),
                )}
            </MetricList>
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default Header
