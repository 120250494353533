import styled from 'styled-components'

export const SelectSection = styled.div`
  p {
    color: #757575;
    margin-bottom: 7px;
  }
  width: 50%;
  min-width: 300px;
  margin-bottom: 20px;
`
