import styled from 'styled-components'
import { ContainerProps } from './types'
import backArrow from '@assets/images/icons/LeftArrowIcon'

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`

export const DetailHeader = styled.span`
  display: flex;
  align-items: center;
  gap: 18px;
  margin: 45px;
  margin-bottom: -8px;
  padding: 0 24px;
`

export const ArrowButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
`

export const ArrowIcon = styled.img.attrs({
  src: backArrow,
  alt: 'Return icon',
})``

export const HeaderTitle = styled.span`
  font-size: 24px;
  line-height: 32px;
  color: #111111;
`

export const BodyContainer = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin: 0 45px;
  padding: 0 24px;
`
