import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import {
  ButtonContentWrapper,
  ButtonText,
  CtaWrapper,
  EmptyStateWrapper,
  Info,
  ListWrapper,
  Title,
} from '../lead-list/styled'
import storage from '@/utils/storage'
import { useAppSelector } from '@/app/hooks'
import {
  CardRequirements,
  CardSubtitle,
  CardTitle,
  Country,
  CustomDivider,
  FlagImg,
  HalfContent,
  LongCardWrapper,
  Price,
  ProposalInfo,
  ProposalMessage,
  ProposedProduct,
  ProposeProductTitle,
  Subtitletext,
} from '../styled'
import Button from '@/components/ui/button'
import { axios } from '@/lib/axios'
import { findCountriesByString } from '@/utils/countryFind'
import { ClipLoader } from 'react-spinners'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
interface IproposalingProps {
  id?: string
}

const PendingProposalList: React.FunctionComponent<IproposalingProps> = ({
  id,
}) => {
  const [loading, setLoading] = useState(false)
  const proposalsArray = useRef<any | undefined>()
  const baseUrl = process.env.REACT_APP_URL_BASE
  const appEnv = process.env.APP_ENVIRONMENT
  const token = storage.getToken()
  const userId = useAppSelector((state) => state.authUser.userId)

  useEffect(() => {
    // console.info('GET PROPOSAL - Pending proposal', id)
    setLoading(true)
    getProposals().then(() => {
      setLoading(false)
    })
  }, [])

  const getAllProposals = () => {
    if (token && userId) {
      const res = axios
        .get(`${baseUrl}/api/v1/users/${userId}/proposals`)
        .then(function (response: any) {
          return response
        })
        .catch(function (error: any) {
          console.log(error)
        })
      return res
    }
  }

  const getProposals = async () => {
    try {
      const allProposals = await getAllProposals()
      proposalsArray.current = allProposals.data
    } catch {
      console.log('error')
    }
  }

  const navigate = useNavigate()

  return (
    <ListWrapper>
      {!!loading ? (
        <ClipLoader size={20} />
      ) : !!proposalsArray.current ? (
        proposalsArray.current
          .filter((proposal: any) => proposal.status == 'proposed')
          .sort((a: any, b: any) => {
            const dateA = new Date(a.createdAt!)
            const dateB = new Date(b.createdAt!)
            return dateB.getTime() - dateA.getTime()
          })
          .map((proposal: any) => {
            const countriesData = proposal?.posting?.geo
              ? findCountriesByString(proposal.posting.geo)
              : []
            return (
              <LongCardWrapper key={proposal.id}>
                <HalfContent>
                  <CardTitle>{proposal.posting.title}</CardTitle>
                  <CardSubtitle>
                    <Price>{proposal.posting.budgetHigh}</Price>
                    <CustomDivider />
                    <Subtitletext>{proposal.posting.delivery}</Subtitletext>
                    <CustomDivider />
                    <Subtitletext>{proposal.posting.typeOfData}</Subtitletext>
                    <CustomDivider />
                    {countriesData.length < 2 && (
                      <div>
                        {countriesData.map((country, i: number) => (
                          <Country key={i}>
                            <Subtitletext>
                              <FlagImg src={country.image} />
                              {country.country}
                            </Subtitletext>
                          </Country>
                        ))}
                      </div>
                    )}
                    {countriesData.length > 2 && (
                      <div style={{ display: 'flex' }}>
                        {countriesData.slice(0, 2).map((country, i: number) => (
                          <Country key={i}>
                            <Subtitletext>
                              <FlagImg src={country.image} />
                              {country.country}
                            </Subtitletext>
                          </Country>
                        ))}{' '}
                        <Subtitletext>+ more</Subtitletext>
                      </div>
                    )}
                    <CustomDivider />
                  </CardSubtitle>
                  <CardRequirements>
                    {proposal.posting.useCase}
                  </CardRequirements>
                </HalfContent>
                <HalfContent>
                  <ProposalInfo>
                    <ProposedProduct>Proposed Product</ProposedProduct>
                    <ProposeProductTitle
                      href={`${appEnv}/product/${proposal.listingId}`}
                    >
                      {proposal.listingTitle}
                    </ProposeProductTitle>
                    <ProposalMessage>{proposal.message}</ProposalMessage>
                    <CtaWrapper style={{ maxWidth: '200px' }}>
                      <Button
                        backgroundColor="#000"
                        onClick={() => {
                          navigate(
                            `/messages?source=proposal&listings=${proposal.listingId}`,
                          )
                        }}
                      >
                        <ButtonContentWrapper>
                          <ButtonText>See messages</ButtonText>
                          <ArrowForwardRoundedIcon fontSize="small" />
                        </ButtonContentWrapper>
                      </Button>
                    </CtaWrapper>
                  </ProposalInfo>
                </HalfContent>
              </LongCardWrapper>
            )
          })
      ) : (
        <EmptyStateWrapper>
          <Title>You have made no proposals yet</Title>
          <Info>
            Search all postings and reach out to our +3000 buyers at once.
          </Info>
          <Button onClick={() => navigate(`/proposals`)}>
            Go to proposals
          </Button>
        </EmptyStateWrapper>
      )}
    </ListWrapper>
  )
}

export default PendingProposalList
