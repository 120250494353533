import { DeleteFileDiv, FileInfoDiv, ViewFileDiv } from './styled'
import ExplorerFile from '@assets/images/icons/PostingIcon.svg'
import { Divider } from '../../styled'
import RedCloseIcon from '@assets/images/icons/red-close-icon.svg'
import { ViewFilesProps } from './types'
import { useState } from 'react'

const ViewFile = ({ data, deleteFile }: ViewFilesProps) => {
  const sizeKb = data.size / 1000
  const sizeMB = sizeKb / 1000
  return (
    <ViewFileDiv>
      <FileInfoDiv>
        <div>
          <ExplorerFile />
          <span>{data.name}</span>
          <p>
            {sizeMB >= 1 ? `${sizeMB.toFixed(2)}MB` : `${sizeKb.toFixed(2)}KB`}
          </p>
        </div>
        <DeleteFileDiv onClick={() => deleteFile()}>
          <RedCloseIcon style={{ transform: 'scale(1.5)' }} />
        </DeleteFileDiv>
      </FileInfoDiv>
      <Divider />
    </ViewFileDiv>
  )
}

export default ViewFile
