import { axios } from '@/lib/axios'
import { Client } from '@twilio/conversations'
const baseUrl = process.env.REACT_APP_URL_BASE

export const getTwilioInfo = async (userId: string) => {
  const res = await axios.post(`${baseUrl}/api/v1/twilio-token`, {
    userId: userId,
  })
  return res
}

export const getTwilioClient = async (userId: string): Promise<Client> => {
  const twilioInfo = await getTwilioInfo(userId)
  const twilioClient = new Client(twilioInfo.data.jwt)
  const refreshToken = async () => {
    const newTwilioInfo = await getTwilioInfo(userId)
    twilioClient.updateToken(newTwilioInfo.data.jwt)
  }

  twilioClient.on('tokenAboutToExpire', refreshToken)
  twilioClient.on('tokenExpired', refreshToken)

  return twilioClient
}
