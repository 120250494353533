import * as React from 'react'
import { useEffect } from 'react'
import * as am5 from '@amcharts/amcharts5/index'
import * as am5map from '@amcharts/amcharts5/map'
import am5geodata_worldLow from '@amcharts/amcharts5-geodata/worldLow'
import am5geodata_data_countries2 from '@amcharts/amcharts5-geodata/data/countries2'
import { ChartContainer } from './styled'
import { IGeoMapProps } from './types'

const GeoMap: React.FunctionComponent<IGeoMapProps> = ({
  height,
  selectedCountriesId,
  hexDefaultColor = '#D3D3D3',
  hexSelectedColor = '#737373',
}) => {
  let root: any
  let chart
  let worldSeries: any
  const defaultColor = am5.color(hexDefaultColor)
  const selectedColor = am5.color(hexSelectedColor)

  useEffect(() => {
    root = createRoot()
    chart = createMapChart(root)
    worldSeries = createWorld(root, chart)
    loadCountriesData(worldSeries, selectedCountriesId)
    return () => root.dispose()
  }, [selectedCountriesId])

  const createRoot = () => {
    return am5.Root.new('chartContainer')
  }

  const createMapChart = (root: any) => {
    return root.container.children.push(
      am5map.MapChart.new(root, {
        panX: 'none',
        projection: am5map.geoMercator(),
        wheelX: 'none',
        wheelY: 'none',
      }),
    )
  }

  const createWorld = (root: any, chart: any) => {
    const worldSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: am5geodata_worldLow,
        fill: am5.color('#D3D3D3'),
        exclude: ['AQ'],
      }),
    )

    worldSeries.mapPolygons.template.setAll({
      tooltipText: '{name}',
      templateField: 'polygonSettings',
    })

    return worldSeries
  }

  const loadCountriesData = (
    worldSeries: any,
    selectedCountriesId?: string[],
  ) => {
    // Set up data for countries
    const data = []
    for (const id in am5geodata_data_countries2) {
      if (am5geodata_data_countries2.hasOwnProperty(id)) {
        const country = am5geodata_data_countries2[id]
        if (country.maps.length) {
          data.push({
            id: id,
            map: country.maps[0],
            polygonSettings: {
              fill: selectedCountriesId?.includes(id)
                ? selectedColor
                : defaultColor,
            },
          })
        }
      }
    }
    worldSeries.data.setAll(data)
  }

  return <ChartContainer height={height} id="chartContainer"></ChartContainer>
}

export default GeoMap
