import * as React from 'react'
import { useState, useEffect } from 'react'
import { Container } from '@mui/material'
import { TabsNavigation } from '@components'
import { tabs } from './tabsSubscriptions'
import { useGetSubscriptions } from '@/services/subscriptions/subscriptions.service'
import { SelectAllOutlined } from '@mui/icons-material'
import { Subscription } from '@/services/subscriptions/types'
import { useAppSelector } from '../../../app/hooks'
import Header from '../header'
import storage from '@/utils/storage'

interface ISubscriptionsProps {}

const Subscriptions: React.FC<ISubscriptionsProps> = (props) => {
  const userRole = useAppSelector((state) => state.authUser.userRole)
  const [subscriptions, setSubscriptions] = useState([])

  return (
    <Container maxWidth={'xl'} sx={{ padding: '30px' }}>
      <Header
        title={'Subscriptions'}
        description={
          userRole === 'seller'
            ? 'Manage your products subscriptions across Buyers.'
            : 'Manage your products subscriptions and share entitled products with your team.'
        }
      ></Header>
      <TabsNavigation tabs={tabs} backgroundMarginOffset={'42%'} />
    </Container>
  )
}

export default Subscriptions
