import styled from 'styled-components'

export const ViewFileWrapper = styled.div`
  width: 100%;
  padding: 1rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  min-width: 500px;
`

export const FileWrapper = styled.div`
  span {
    font-weight: bold;
  }
  p {
    color: #5c5c5c;
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & > div > img {
    object-fit: cover;
    width: 100px;
    height: 100px;
  }
`

export const FileInfo = styled.div`
  display: flex;
  & > div {
    margin-left: 20px;
  }
`

export const DeleteFile = styled.div`
  cursor: pointer;
`
