import { useState, useEffect } from 'react'
import storage from '@/utils/storage'
import { useNavigate } from 'react-router-dom'
import {
  methodData,
  formatData,
  frequencyData,
  currencyData,
  priceFormatData,
} from '@/mocks'
import {
  Stepper,
  Pagination,
  Title,
  Divider,
  Label,
  Block,
  Item,
  TextSmall,
  Button,
} from '../styled'
import { CheckGrid, CheckWrapper, SelectWrapper } from './styled'
import { ErrorWrapper } from '../common/styled'
import SelectComponent from '../select/index'
import CheckInput from './check-input'
import * as yup from 'yup'
import { BuyingFormatCheckDto, BuyingFormatDto, StepThreeParams } from './types'
import { Checkbox } from '@components'
import ClipLoader from 'react-spinners/ClipLoader'
import { useCreateListing } from '@/services/listing/listing.service'
import { useAppSelector } from '@/app/hooks'
import { arrayCheckboxesHandler } from '@/utils/array-checkboxes'
import { deleteError, findError, validateFunction } from '@/utils/form-errors'
import { CheckBox } from '@mui/icons-material'
import { AllLegal } from './legal'
import { SampleDto } from '../second-step/types'
import { Alert } from '@mui/material'

const setValidBuyingFormats = (
  buyingFormat: BuyingFormatCheckDto[],
  format: 'pricing' | 'negotiable' | 'free',
): BuyingFormatDto[] => {
  const validBuyingFormats: BuyingFormatDto[] = []
  switch (format) {
    case 'pricing': {
      buyingFormat.forEach((buyingElement) => {
        if (buyingElement.isActive) {
          const newElem: BuyingFormatDto & Partial<BuyingFormatCheckDto> = {
            ...buyingElement,
          }
          delete newElem.id
          delete newElem.isActive
          validBuyingFormats.push(newElem)
        }
      })
      break
    }
    case 'free': {
      validBuyingFormats.push({
        price: 0,
        type: 'one',
      })
      break
    }
  }
  return validBuyingFormats
}

const checkPricing = (
  buyingFormat: BuyingFormatDto[],
): 'pricing' | 'negotiable' | 'free' => {
  switch (true) {
    case buyingFormat.length === 0: {
      return 'negotiable'
    }
    case !!buyingFormat[0] && buyingFormat[0].price === 0: {
      return 'free'
    }
    default: {
      return 'pricing'
    }
  }
}

const ThirdStep = ({
  url,
  state,
  dispatch,
  error,
  isSubmit,
  submit,
  buttonMessage,
  hasStripeConfigured,
}: StepThreeParams) => {
  const navigate = useNavigate()
  const [methods, setMethods] = [
    state.dataMethod,
    (data: string[]) => {
      dispatch({ dataMethod: data })
    },
  ]
  const [formats, setFormats] = [
    state.dataFormat,
    (data: string[]) => {
      dispatch({ dataFormat: data })
    },
  ]
  const [frequencies, setFrequencies] = [
    state.dataFrequency,
    (data: string[]) => {
      dispatch({ dataFrequency: data })
    },
  ]

  const [legalCompliance, setLegalCompliance] = [
    state.legalCompliance,
    (data: SampleDto[]) => {
      dispatch({ legalCompliance: data })
    },
  ]
  /*
  const [freeSample, setFreeSample] = [
    state.freeSample,
    (data: boolean) => {
      dispatch({ freeSample: data })
    },
  ]
  const [revenueShare, setRevenueShare] = [
    state.revenueShare,
    (data: boolean) => {
      dispatch({ revenueShare: data })
    },
  ]
  */
  const [currency, setCurrency] = [
    state.currency,
    (data: string) => {
      dispatch({ currency: data })
    },
  ]
  const [buyingFormat, setBuyingFormat] = useState<BuyingFormatCheckDto[]>(
    priceFormatData.map((priceFormat) => {
      const foundBuyingFormat = state.buyingFormat.find(
        (buyingFormat) => priceFormat.value === buyingFormat.type,
      )
      if (!!foundBuyingFormat) {
        return {
          id: priceFormat.id,
          isActive: true,
          price: foundBuyingFormat.price,
          unit: foundBuyingFormat.unit,
          type: priceFormat.value,
        }
      } else {
        return {
          id: priceFormat.id,
          isActive: false,
          price: 0,
          type: priceFormat.value,
        }
      }
    }),
  )
  const [typeOfBuying, setTypeOfBuying] = useState<
    'pricing' | 'negotiable' | 'free'
  >(checkPricing(state.buyingFormat))
  const [validationErrors, setValidationErrors] = useState<
    yup.ValidationError[]
  >([])
  const [optionalErrors, setOptionalErrors] = useState<yup.ValidationError[]>(
    [],
  )

  const token = storage.getToken()

  type stateType = {
    currency: string
    buyingFormat: BuyingFormatDto[]
  }
  const validateSchema = {
    currency: yup.string().required('Currency is required.'),
    buyingFormat: yup
      .array(
        yup.object({
          price: yup.number(),
          type: yup.string(),
          unit: yup.string().optional(),
        }),
      )
      .test(
        'noLengthZeroIfNotNegotiable',
        'You must specify a Price if your product is not negotiable/free',
        (untypedData: any) => {
          const data: BuyingFormatDto[] = untypedData
          return (data.length === 0) === (typeOfBuying === 'negotiable')
        },
      )
      .test(
        'noFreeIfnotFreeCheckbox',
        "Price can't be invalid or 0. If you wish to make your Product free click the Free checkbox",
        (untypedData: any) => {
          const data: BuyingFormatDto[] = untypedData
          const freeBuyingFormat = data.find(
            (buyingFormat) => !!buyingFormat.price,
          )
          return !!freeBuyingFormat === (typeOfBuying === 'pricing')
        },
      ),
  }

  const internalValidateFunction = async (
    value: stateType | stateType[keyof stateType],
    lookup?: keyof stateType,
  ): Promise<boolean> => {
    const newErrors = await validateFunction<stateType>(
      value,
      validateSchema,
      lookup,
    )
    setValidationErrors(newErrors)
    return newErrors.length === 0
    //return true to dismiss errors
  }

  const internalDeleteError = (identifier: string) => {
    deleteError(identifier, validationErrors, setValidationErrors)
  }

  const internalFindError = (
    identifier: string,
    alternativeState?: yup.ValidationError[],
  ) => {
    const error = findError(
      identifier,
      alternativeState ? alternativeState : validationErrors,
    )
    return !!error && <ErrorWrapper>{error.message}</ErrorWrapper>
  }

  const authInfo = useAppSelector((state) => state.authUser)!
  const validBuyingFormats = setValidBuyingFormats(buyingFormat, typeOfBuying)

  useEffect(() => {
    dispatch({
      buyingFormat: setValidBuyingFormats(buyingFormat, typeOfBuying),
    })
  }, [buyingFormat, typeOfBuying])

  const onClick = async () => {
    const isValid = await internalValidateFunction({
      currency,
      buyingFormat: validBuyingFormats,
    })
    if (isValid) {
      submit({
        ...state,
        author: authInfo.userId!,
        company: authInfo.companyId!,
      })
    }
  }

  return (
    <Stepper>
      <Block>
        <Pagination>Step 3/3</Pagination>
        <Title>How can you deliver the data?</Title>
        <Divider />
        <Item>
          <Label>Method</Label>
          <CheckGrid>
            <TextSmall>(batch/bulk transfer)</TextSmall>
            <TextSmall>(on-demand transfer)</TextSmall>
            {methodData &&
              methodData.map((item: any, index: number) => (
                <CheckWrapper key={index}>
                  <Checkbox
                    label={item.value}
                    value={item.value}
                    fontWeight="600"
                    isChecked={methods.includes(item.value)}
                    handleOnChange={(e: any) =>
                      arrayCheckboxesHandler(
                        e.target.checked,
                        methods,
                        setMethods,
                        (stateValue) => stateValue === e.target.value,
                        e.target.value,
                      )
                    }
                  />
                </CheckWrapper>
              ))}
          </CheckGrid>
        </Item>
        <Item>
          <Label>Format</Label>
          <CheckGrid>
            {formatData &&
              formatData.map((item: any, index: number) => (
                <CheckWrapper key={index}>
                  <Checkbox
                    label={item.value}
                    value={item.value}
                    fontWeight="600"
                    isChecked={formats.includes(item.value)}
                    handleOnChange={(e: any) =>
                      arrayCheckboxesHandler(
                        e.target.checked,
                        formats,
                        setFormats,
                        (stateValue) => stateValue === e.target.value,
                        e.target.value,
                      )
                    }
                  />
                </CheckWrapper>
              ))}
          </CheckGrid>
        </Item>
        <Item>
          <Label>Frequency</Label>
          <CheckGrid>
            {frequencyData &&
              frequencyData.map((item: any, index: number) => (
                <CheckWrapper key={index}>
                  <Checkbox
                    label={item.value}
                    fontWeight="600"
                    isChecked={frequencies.includes(item.value)}
                    value={item.value}
                    handleOnChange={(e) =>
                      arrayCheckboxesHandler(
                        e.target.checked,
                        frequencies,
                        setFrequencies,
                        (stateValue) => stateValue === e.target.value,
                        e.target.value,
                      )
                    }
                  />
                </CheckWrapper>
              ))}
          </CheckGrid>
        </Item>
      </Block>
      <Block>
        <Title>How do you price your data?</Title>
        <Divider />
        {/*
        <Item>
          <Label>Free sample</Label>
          <Checkbox
            label="We offer free data samples for qualified buyers of this product"
            fontWeight="600"
            isChecked={freeSample}
            value={freeSample}
            handleOnChange={() => setFreeSample(!freeSample)}
          />
        </Item>
        <Item>
          <Label>Revenue-share</Label>
          <Checkbox
            label="We offer a revenue share model for the monetization of our data"
            fontWeight="600"
            isChecked={revenueShare}
            value={revenueShare}
            handleOnChange={() => setRevenueShare(!revenueShare)}
          />
        </Item>
        */}
        <Item
          marginBottom="54px"
          onBlur={(e: any) => internalValidateFunction(currency, 'currency')}
          onFocus={() => {
            internalDeleteError('currency')
          }}
        >
          <Label>Currency</Label>
          <SelectWrapper>
            <SelectComponent
              value={currencyData.find((option) => currency === option.value)}
              options={currencyData}
              placeholder="Select"
              onChange={(e: any) => setCurrency(e.value)}
            />
          </SelectWrapper>
          {internalFindError('currency')}
        </Item>
        <Item
          onBlur={(e: any) =>
            internalValidateFunction(validBuyingFormats, 'buyingFormat')
          }
          onFocus={() => {
            internalDeleteError('buyingFormat')
          }}
        >
          <Label>Pricing</Label>
          <Item>
            <Checkbox
              label="Negotiable"
              fontWeight="600"
              isChecked={typeOfBuying === 'negotiable'}
              handleOnChange={() => setTypeOfBuying('negotiable')}
            />
            <Checkbox
              label="Free Product"
              fontWeight="600"
              isChecked={typeOfBuying === 'free'}
              handleOnChange={() => setTypeOfBuying('free')}
            />
          </Item>

          {!hasStripeConfigured && (
            <Item>
              <Alert variant="filled" severity="warning">
                You must setup your stripe account in order to be able to fill
                pricing section of any product.
              </Alert>
            </Item>
          )}
          {priceFormatData &&
            priceFormatData.map((item: any, index: number) => (
              <CheckInput
                key={index}
                index={index}
                checkboxLabel={item.check}
                inputLabel={item.input}
                value={item.value}
                forceFalse={typeOfBuying !== 'pricing'}
                state={buyingFormat.find((element) => element.id === item.id)!}
                onChange={(value: BuyingFormatCheckDto) => {
                  setTypeOfBuying('pricing')
                  const findIndex = buyingFormat.findIndex(
                    (element) => element.id === value.id,
                  )
                  const newList = [...buyingFormat]
                  newList.splice(findIndex, 1, value)
                  setBuyingFormat(newList)
                }}
                disabled={!hasStripeConfigured}
              />
            ))}
          {internalFindError('buyingFormat')}
        </Item>
        <Title>Legal Documentation</Title>
        <Divider />

        <Label>
          Upload all privacy and legal documents related to this product
        </Label>
        <Item
          onFocus={() => {
            deleteError('file', optionalErrors, setOptionalErrors)
          }}
        >
          {
            <AllLegal
              state={legalCompliance}
              dispatch={setLegalCompliance}
              setValidationError={setOptionalErrors}
            />
          }
          {internalFindError('file', optionalErrors)}
        </Item>
      </Block>
      <Button onClick={onClick}>
        {!isSubmit ? (
          buttonMessage ? (
            buttonMessage
          ) : (
            'Finish'
          )
        ) : (
          <ClipLoader color="#ffffff" size={15} />
        )}
      </Button>
      {validationErrors.length > 0 && (
        <ErrorWrapper>You must resolve the errors to continue</ErrorWrapper>
      )}
      {error && <ErrorWrapper>An error has occurred</ErrorWrapper>}
    </Stepper>
  )
}

export default ThirdStep
