import styled from 'styled-components'

export const ViewSelectFileDiv = styled.div`
  display: flex;
  max-height: 53px;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;

  & > * {
    flex-grow: 1;
    max-width: 45%;
    max-height: 53px !important;
  }

  & > div:last-child {
    flex-grow: 0;
    max-width: 50px;
  }
`
