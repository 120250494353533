import { AppBarHeader } from '@components'
import {
  Container,
  ContractContainer,
  ArrowButton,
  ArrowIcon,
  Button,
} from './styled'
import { Container as Wrapper } from '@mui/material'
import { useNavigate, useParams } from 'react-router'
import storage from '@/utils/storage'
import {
  useAcceptTermsAndConditions,
  useGetCompany,
} from '@/services/suppliers/suppliers.service'
import { ClipLoader } from 'react-spinners'
import { queryClient } from '@/lib/react-query'

interface ITAndCprops {
  content?: string
  isSupplier?: boolean
  isChecked?: boolean
  setIsChecked?: any
}

const PrivacyPolicy: React.FC<ITAndCprops> = () => {
  const navigate = useNavigate()
  // const { refetch } = useGetCompany(companyId, false)
  // const { mutate: acceptTerms, isLoading: loadingAcceptance } =
  //   useAcceptTermsAndConditions(() => {
  //     queryClient.invalidateQueries({ queryKey: ['dashboard'] })
  //     navigate(-1)
  //   })

  // const handleClick = async () => {
  //   const { data: companyData } = await refetch()
  //   acceptTerms(companyData?.data.profile!)
  // }

  return (
    <Container>
      <Container flexDirection="column">
        <AppBarHeader />
        <Wrapper maxWidth={'xl'} sx={{ margin: '45px' }}>
          <ContractContainer>
            <ArrowButton onClick={() => navigate(-1)}>
              <ArrowIcon />
            </ArrowButton>
            <h2>Privacy Policy</h2>
            <p className="c3">
              <span className="c2 c1">
                &nbsp;
                <br />
              </span>
              <span className="c14">KAMBA PRIVACY POLICY</span>
            </p>
            <p className="c6">
              <span className="c2 c1"></span>
            </p>
            <p className="c6">
              <span className="c0"></span>
            </p>
            <p className="c8">
              <span className="c1 c7">
                Effective Date: Last Updated on November 1, 2022
              </span>
            </p>
            <p className="c6">
              <span className="c2 c1"></span>
            </p>
            <p className="c9">
              <span className="c2 c1">
                In this Privacy Policy, we, Kamba Group LLC (&ldquo;Kamba&rdquo;
                or &ldquo;we&rdquo; and sometimes &ldquo;us&rdquo;), describe
                the types of Personally Identifiable Information (defined below)
                we collect; how we use, process, or share that information; and
                what rights you have while we hold that information.
              </span>
            </p>
            <p className="c8">
              <span className="c2 c1">
                We take the private nature of your Personally Identifiable
                Information (defined below) very seriously and are committed to
                protecting it. To do that, we&#39;ve set up procedures to ensure
                that such information is handled responsibly and in accordance
                with applicable data protection and privacy laws. Your trust is
                paramount to us, and we act accordingly.
              </span>
            </p>
            <p className="c6">
              <span className="c2 c1"></span>
            </p>
            <p className="c8">
              <span className="c2 c1">
                This Privacy Policy describes what information we collect when
                you use our Sites (defined below), how we use that information,
                and what choices we offer you to access, update, and control it.
              </span>
            </p>
            <p className="c9">
              <span className="c2 c5">
                What Type of Information is Covered by this Policy
              </span>
            </p>
            <p className="c8">
              <span className="c2 c5">
                &quot;Personally Identifiable information&quot;
              </span>
              <span className="c2 c1">
                &nbsp;(&ldquo;PII&rdquo;) is any information that we could use
                to identify an individual, including, for example, their name,
                address, geographic location of their computer or mobile device,
                telephone number, credit card number, email address and bank
                account information. It does not include Personally Identifiable
                Information that is encoded or anonymized (such as through
                encryption or hashing), or publicly available information that
                has not been combined with non-public information.
              </span>
            </p>
            <p className="c8">
              <span className="c2 c1">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </p>
            <p className="c8">
              <span className="c2 c5">
                &quot;Sensitive Personally Identifiable Information&quot;
              </span>
              <span className="c2 c1">
                &nbsp;is information that meets the &quot;Personally
                Identifiable Information&quot; criteria outlined above and also
                (i) reveals race, ethnic origin, political opinions, religious
                or philosophical beliefs, trade union membership, or (ii)
                concerns health or sex life, information about Social Security
                benefits, or information on criminal or administrative
                proceedings other than in the context of pending legal
                proceedings.&nbsp;We will never ask you to share sensitive
                Personally Identifiable Information with us, but if it is shared
                with us, this Privacy Policy applies.
              </span>
            </p>
            <p className="c6">
              <span className="c2 c5"></span>
            </p>
            <p className="c8">
              <span className="c2 c5">What does this Policy Cover?</span>
            </p>
            <p className="c9">
              <span className="c2 c1">
                This Privacy Policy covers your use of Kamba websites, including
                sites located on Kambagroup.com and any other pages that link to
                this Privacy Policy (the &ldquo;Sites&rdquo;), as well as
                Account Information and Usage Data (each defined below). This
                Privacy Policy does not cover any other data submitted through
                or otherwise made available to Kamba or other written agreement
                in place between Kamba and our platform customers
                (&ldquo;Customers&rdquo;), or any other data collection and
                processing, including, without limitation, data that we collect
                offline.
              </span>
            </p>
            <p className="c9">
              <span className="c2 c1">
                Customers are solely responsible for establishing policies for
                and ensuring compliance with all applicable laws and
                regulations, as well as any and all privacy policies, agreements
                or other obligations relating to such Customers&rsquo; use or
                collection of personal data in connection with the use of our
                platform services (the &ldquo;Platform Services&rdquo;) by
                individuals with whom our Customers interact. If you are an
                individual who interacts with a Customer using our Platform
                Services or you otherwise believe that a Customer uses our
                Platform Services to process your personal data, and you contact
                us regarding this data, you will be directed to contact the
                applicable Customer for assistance with any requests or
                questions relating to your personal data, including without
                limitation any requests to access, amend or erase your personal
                data.
              </span>
            </p>
            <p className="c9">
              <span className="c2 c5">
                What information do we collect and how?
              </span>
            </p>
            <p className="c9">
              <span className="c2 c1">
                We collect information and data from you in two ways. First, we
                collect information you manually provide to us, including
                registration information like your name and email address.
                Second, we collect information automatically from your use of
                our Sites and Platform Services, including how and when our
                Sites and Platform Services are used.
              </span>
            </p>
            <p className="c9">
              <span className="c7 c1">
                Information you submit or make available.
              </span>
            </p>
            <p className="c9">
              <span className="c2 c1">
                If you create an account for our Platform Services or otherwise
                create an account through our Sites, we may ask, and in some
                cases require, you to enter certain personal data such as your
                name, email address, phone number, physical address, gender, job
                title, job role, company name, company size and other
                information relating to your proposed use of our products and
                services. You will know which data categories you provide to us,
                because you will affirmatively enter and submit the data. Any
                such information you provide us in connection with the
                registration for our Platform Services is deemed &ldquo;Account
                Information.&rdquo; Kamba may receive information from third
                parties regarding the companies and users who use our Sites and
                Platform Services and may combine such information with the
                information we receive or collect from you.
              </span>
            </p>
            <p className="c9">
              <span className="c7 c1">
                Information we collect about your use of our Sites.
              </span>
            </p>
            <p className="c9">
              <span className="c2 c1">
                When you use our Sites, your device automatically generates and
                provides data to us. We collect and use such data to help us to
                understand how you are using our Sites, and how to better
                provide those Sites to you.
              </span>
            </p>
            <p className="c9">
              <span className="c2 c1">
                When you use the Sites, we use standard automated data
                collection tools such as{' '}
              </span>
              <span className="c2 c1">cookies</span>
              <span className="c2 c1">
                , web beacons, tracking pixels and the like (&ldquo;Web
                Data&rdquo;). While the vast majority of Web Data is not
                personal data, some Web Data may include certain information
                which may be considered personal data depending on where you
                live, like a User&rsquo;s IP address. To the extent Web Data
                contains personal data, it will be treated as personal data
                under this Privacy Policy.
              </span>
            </p>
            <p className="c9">
              <span className="c7 c1">
                Information we collect about your use of our Platform Services.
              </span>
            </p>
            <p className="c9">
              <span className="c2 c1">
                When you use our Platform Services, information and data gets
                automatically generated and collected that can help us to
                understand how you are using our Platform Services, and how to
                better provide the Platform Services to you.
              </span>
            </p>
            <p className="c9">
              <span className="c2 c1">
                When you use the Platform Services, we automatically record
                information about how our users (both account holders and other
                users on an account) (&ldquo;Users&rdquo;) use the Platform
                Services (&ldquo;Usage Data&rdquo;). Most Usage Data is not
                personal data, and includes information like browser type,
                operating system, the pages or features of our Platform Services
                accessed or used by User and the time spent on those pages or
                features, search terms entered into our Platform Services to
                browse, commands executed when using our Platform Services,
                information about the types and size of files analyzed via the
                Platform Services, and other statistical information. Some Usage
                Data may include certain information which may be considered
                personal data depending on where you live, like a User&rsquo;s
                IP address. To the extent Usage Data contains personal data, it
                will be treated as personal data and is covered under this
                Privacy Policy.
              </span>
            </p>
            <p className="c9">
              <span className="c2 c5">
                How do we use the information we collect from you?
              </span>
            </p>
            <p className="c9">
              <span className="c2 c1">
                We may use your personal data for the following purposes:
              </span>
            </p>
            <ul className="c10 lst-kix_list_6-0 start">
              <li className="c16 li-bullet-0">
                <span className="c2 c1">
                  to provide, maintain, improve and update the Sites, the
                  Platform Services, and our services;
                </span>
              </li>
              <li className="c4 li-bullet-0">
                <span className="c2 c1">
                  to develop new products and services;
                </span>
              </li>
              <li className="c4 li-bullet-0">
                <span className="c2 c1">
                  for billing, payment, or account management; for example, to
                  identify your account and correctly identify your usage of our
                  products and services;
                </span>
              </li>
              <li className="c4 li-bullet-0">
                <span className="c2 c1">
                  to investigate security issues, prevent fraud, or combat the
                  illegal use of our products and services;
                </span>
              </li>
              <li className="c4 li-bullet-0">
                <span className="c2 c1">
                  to provide you with support and respond to your questions,
                  comments, and requests, including to keep in contact with you
                  regarding the products and services you use;
                </span>
              </li>
              <li className="c4 li-bullet-0">
                <span className="c2 c1">
                  to tailor and send you newsletters, emails and other content
                  to promote our products and services (you can always
                  unsubscribe from our marketing emails by emailing us at{' '}
                </span>
                <span className="c1">privacy@kamba.com</span>
                <span className="c1">&nbsp;</span>
                <span className="c2 c1">
                  and to allow third-party partners to send you marketing
                  communications about their services, in accordance with your
                  preferences;
                </span>
              </li>
              <li className="c4 li-bullet-0">
                <span className="c2 c1">
                  to generate and analyze statistical information about how our
                  Sites and Platform Services are used in the aggregate;
                </span>
              </li>
              <li className="c4 li-bullet-0">
                <span className="c2 c1">
                  where Kamba otherwise has a legitimate interest or lawful
                  business purposes;
                </span>
              </li>
              <li className="c4 li-bullet-0">
                <span className="c2 c1">
                  where required by applicable law, legal process, or government
                  regulation; or
                </span>
              </li>
              <li className="c4 li-bullet-0">
                <span className="c2 c1">where you have given consent.</span>
              </li>
            </ul>
            <p className="c9">
              <span className="c2 c5">How do we share your information?</span>
            </p>
            <p className="c9">
              <span className="c2 c1">
                We may share your personal data with third parties as follows:
              </span>
            </p>
            <ul className="c10 lst-kix_list_7-0 start">
              <li className="c16 li-bullet-0">
                <span className="c2 c1">
                  with businesses and service providers that help us conduct our
                  business, subject to confidentiality obligations and the
                  requirement that those businesses and service providers do not
                  sell your personal data;
                </span>
              </li>
              <li className="c4 li-bullet-0">
                <span className="c2 c1">
                  with our trusted partners such as with third-party partners
                  who are working with us to enable them to contact you about
                  their services but only where we have a lawful basis to do so
                  (such as your consent where required by applicable law);
                </span>
              </li>
              <li className="c4 li-bullet-0">
                <span className="c2 c1">
                  when necessary to deliver our products and services, such as
                  with a payment card provider to process your credit card
                  transaction;
                </span>
              </li>
              <li className="c4 li-bullet-0">
                <span className="c2 c1">
                  where you have instructed us to share your personal data, such
                  as to provide you with support;
                </span>
              </li>
              <li className="c4 li-bullet-0">
                <span className="c2 c1">
                  when authorized by law or necessary to comply with a legal
                  process;
                </span>
              </li>
              <li className="c4 li-bullet-0">
                <span className="c2 c1">
                  when required to protect and defend the rights or property of
                  Kamba, our Customers, vendors or other users of our Sites,
                  including the security of our Sites, products and services
                  (including the Platform Services);
                </span>
              </li>
              <li className="c4 li-bullet-0">
                <span className="c2 c1">
                  when necessary to protect the personal safety, property or
                  other rights of the public, Kamba or its customers or
                  employees;
                </span>
              </li>
              <li className="c4 li-bullet-0">
                <span className="c2 c1">
                  where you have consented to the sharing of your information
                  with third parties; or
                </span>
              </li>
              <li className="c4 li-bullet-0">
                <span className="c2 c1">
                  in connection with a sale or reorganization of all or part of
                  our business.
                </span>
              </li>
            </ul>
            <p className="c9">
              <span className="c2 c1">
                Kamba does not &lsquo;sell&rsquo; personal information of
                California consumers (as defined in the California Consumer
                Privacy Act (CCPA)) and we will not do so without offering you
                the right to opt out of any &lsquo;sale&rsquo;.
              </span>
            </p>
            <p className="c9">
              <span className="c2 c5">
                What rights do you have over your information?
              </span>
            </p>
            <p className="c9">
              <span className="c2 c1">
                We offer you choices regarding the collection, use and sharing
                of your personal data and we will respect the choices you make.
                Where you have consented to Kamba&rsquo; processing of your
                personal data, you may withdraw that consent at any time by
                contac
              </span>
              <span className="c1">ting </span>
              <span className="c1">privacy@kamba.com</span>
              <span className="c1">
                &nbsp;with the subject &ldquo;Withdraw Consent&rdquo;. Please
                note that if you decide not to provide us with
              </span>
              <span className="c2 c1">
                &nbsp;your personal data, you may not be able to access the
                Platform Services or certain features of the Sites.
              </span>
            </p>
            <p className="c9">
              <span className="c7 c1">Opt Out of Certain Communications.</span>
            </p>
            <p className="c9">
              <span className="c2 c1">
                We may periodically send you free communications that directly
                promote our products and services. When you receive such
                promotional or marketing communications from us, you will have
                the opportunity to opt out (either by following the unsubscribe
                instructions in the communication you receive or by emailing us
              </span>
              <span className="c1">&nbsp;at </span>
              <span className="c1">privacy@kamba.com</span>
              <span className="c1">. </span>
              <span className="c2 c1">
                We may also send you certain necessary communications regarding
                your use of our products or services and you may not be able to
                opt out of those communications; for example, we may need to
                send you communications regarding updates to our terms, this
                Privacy Policy, or relating to payment and billing.
              </span>
            </p>
            <p className="c9">
              <span className="c7 c1">
                Rights of Access, Modification, Deletion and Restriction of Your
                Information.
              </span>
            </p>
            <p className="c9">
              <span className="c2 c1">
                If you are located in certain parts of the world, including the
                European Economic Area, the United Kingdom, and certain other
                legal jurisdictions (for example California), you may have
                certain rights in relation to certain of your personal data.
                Those rights may include asking us to provide information
                (including categories of sources), access, export, modification,
                deletion, or to restrict processing of certain of your personal
                data. If you wish to exercise these rights, please emai
              </span>
              <span className="c1">l us at </span>
              <span className="c1">legal@kamba.com.</span>
            </p>
            <p className="c9">
              <span className="c2 c1">
                Kamba may take certain steps to verify requests submitted using
                information available to us, including your email address and
                any information associated with your account. Where allowed, we
                may ask you to provide your government identification to verify
                your account. Personal data you provide to us for verification
                will only be used to verify and maintain records regarding your
                request.
              </span>
            </p>
            <p className="c9">
              <span className="c2 c1">
                We will endeavor to promptly respond to your request. Although
                Kamba makes good faith efforts to provide users of our Sites and
                Platform Services with access to their personal data, there may
                be circumstances in which Kamba is unable to provide access
                including but not limited to: where the information is legally
                privileged, would compromise the privacy or other legitimate
                rights of others, where the burden or expense of providing the
                information would be disproportionate to the risks to the
                User&rsquo;s privacy in the case in question, where the request
                is manifestly unfounded or excessive or where the information is
                proprietary. If you request deletion of your personal data,
                please note that we may continue to retain certain of your
                personal data in archived/backup copies for our records or as
                otherwise required or allowed by law.
              </span>
            </p>
            <p className="c9">
              <span className="c2 c5">
                How long do we store your information?
              </span>
            </p>
            <p className="c9">
              <span className="c2 c1">
                Kamba may retain the personal data we collect from you as
                described in this Privacy Policy for as long as you use our
                services or as necessary to fulfill the purpose(s) for which it
                was collected, provide our services, resolve disputes, establish
                legal defenses, conduct audits, pursue our legitimate interests,
                for our business or commercial purposes, enforce our agreements,
                and comply with applicable laws.
              </span>
            </p>
            <p className="c9">
              <span className="c2 c5">
                What steps do we take to secure your Information?
              </span>
            </p>
            <p className="c9">
              <span className="c1">
                Kamba considers protecting the security of your data to be its
                number one responsibility.{' '}
              </span>
              <span className="c1">
                Kamba aligns its security practices to the internationally
                recognized industry standard code of practice for protecting
                personal data in the cloud. Kamba encrypts communications you
                make with our Sites and the Platform Services, for example,
                entering your username and password or information into forms,
                using TLS (transport layer security) or other industry standard{' '}
              </span>
              <span className="c1">technologies</span>
              <span className="c1">.</span>
              <span className="c1">&nbsp;Plea</span>
              <span className="c2 c1">
                se be aware, however, that no method of transmitting information
                over the Internet or storing information is completely secure or
                safe. Accordingly, we cannot guarantee the absolute security of
                any information.
              </span>
            </p>
            <p className="c9">
              <span className="c2 c5">
                What about international data transfer?
              </span>
            </p>
            <p className="c9">
              <span className="c2 c1">
                Kamba may transfer your personal data to countries other than
                the one from which you provide it to us. Specifically, if you
                are located outside the United States and provide your personal
                data to us, we may transfer your personal data to the United
                States and process it there, subject to the following
                safeguards:
              </span>
            </p>
            <ul className="c10 lst-kix_list_9-0 start">
              <li className="c16 li-bullet-0">
                <span className="c2 c5">
                  E.U.-U.S. Privacy Shield and Swiss-U.S. Privacy Shield
                </span>
                <span className="c2 c1">
                  . To comply with European Union and Swiss data protection
                  laws, Kamba adheres to and h
                </span>
                <span className="c1">as </span>
                <span className="c1">
                  self-certified to the EU&ndash;U.S. and Swiss-US Privacy
                  Shield{' '}
                </span>
                <span className="c1">Frameworks</span>
                <span className="c1">
                  &nbsp;set forth by the U.S. Department of Commerce regarding
                  the collection, use, and retention of personal data tr
                </span>
                <span className="c2 c1">
                  ansferred from the European Economic Area and Switzerland to
                  the United States. Kamba complies with the E.U.-U.S. Privacy
                  Shield in respect of the collection, use and retention of
                  personal data transferred from the United Kingdom to the
                  United States in reliance on the E.U.-U.S. Privacy Shield
                  until such time as a successor framework or other transfer
                  mechanism exists between the U.S. and the U.K.
                </span>
              </li>
            </ul>
            <ul className="c10 lst-kix_list_10-0 start">
              <li className="c16 li-bullet-0">
                <span className="c2 c1">
                  If you are a resident of the European Economic Area or
                  Switzerland and feel that Kamba is not abiding by the ter
                </span>
                <span className="c1">
                  ms of the Privacy Policy, or is not in compliance with the
                  Privacy Shield Principles, please contact{' '}
                </span>
                <span className="c1">legal@kamba.com</span>
                <span className="c1">
                  &nbsp;with the subject &ldquo;Privacy Shield&rdquo;. Kamba
                  will respond to any such requests within 45 days. If Kamba
                  does not resolve your complaint, you may have the possibility,
                  under certain condi
                </span>
                <span className="c2 c1">
                  tions, to invoke binding arbitration through the Privacy
                  Shield Panel. If we receive personal data subject to our
                  certification under the Privacy Shield and then transfer it to
                  a third-party service provider acting as an agent on our
                  behalf, we may have certain liability under the Privacy Shield
                  Principles if both (i) the agent processes the personal data
                  in a manner inconsistent with the Privacy Shield Principles
                  and (ii) we are responsible for the event giving rise to the
                  damage.
                </span>
              </li>
            </ul>
            <ul className="c10 lst-kix_list_11-0 start">
              <li className="c16 li-bullet-0">
                <span className="c2 c1">
                  Kamba&rsquo; commitments under the Privacy Shield are subject
                  to the investigatory and enforcement powers of the United
                  States Federal Trade Commission. European Union Model Clauses.
                  Kamba offers our Customers the ability to enter into a data
                  processing addendum (DPA) that contains the European Economic
                  Area Model Clauses, also known as Standard Contractual
                  Clauses, to meet the adequacy and security requirements for
                  our Customers with users located in the European Economic Area
                  or are otherwise using Kamba to process any data originating
                  from the European Economic Area. If you represent a Customer
                  that you have determined is subject to the GDPR or other
                  applicable data protection law and you do not yet have in
                  place an updated data processing addendum (DPA) with us,
                  please review and complete the instructions on our&nbsp;
                </span>
                <span className="c5 c13">
                  <a
                    className="c11"
                    href="https://www.google.com/url?q=https://www.databricks.com/dpa&amp;sa=D&amp;source=editors&amp;ust=1668807870972464&amp;usg=AOvVaw0r5s4quLdyx2WAUIWYSSFr"
                  >
                    DPA
                  </a>
                </span>
                <span className="c2 c1">.</span>
              </li>
            </ul>
            <p className="c9">
              <span className="c2 c5">What about third party services?</span>
            </p>
            <p className="c9">
              <span className="c2 c1">
                We may make available certain features and functionality that
                allow you to sign into our Sites using third party login
                credentials (such as LinkedIn, Facebook, Twitter and Google+ and
                others) or access certain third-party services from our Platform
                Services (each such third party services, a &ldquo;Third Party
                Service&rdquo;). Any data you submit to any Third Party Services
                will be subject to the terms of service and privacy policy of
                such Third Party Service.
              </span>
            </p>
            <p className="c9">
              <span className="c2 c1">
                We may also link to co-branded websites or products that are
                maintained by Kamba and one or more of our business partners.
                Please note that these co-branded websites and products may have
                their own privacy policy, which we encourage you to read and
                understand. When you click a link to a third-party site, you
                will leave our site and we don&rsquo;t control or endorse
                anything on third-party sites.
              </span>
            </p>
            <p className="c9">
              <span className="c2 c5">
                Do we collect children&rsquo;s data?
              </span>
            </p>
            <p className="c9">
              <span className="c1 c2">
                Kamba products and services are not directed to children under
                18 years of age and Kamba does not knowingly collect personal
                data from children under 18 years of age. If we learn that we
                have collected any personal data from children under 18 years
                old, we will promptly take steps to delete suc
              </span>
              <span className="c1">
                h information. If you are aware that a child has submitted us
                such information, please contact us at{' '}
              </span>
              <span className="c1">privacy@kamba.com</span>
              <span className="c1">&nbsp;w</span>
              <span className="c2 c1">
                ith the subject &ldquo;Child Data&rdquo;.
              </span>
            </p>
            <p className="c9">
              <span className="c2 c5">
                How can we make changes to this Privacy Policy?
              </span>
            </p>
            <p className="c9">
              <span className="c2 c1">
                Kamba may change this Privacy Policy from time to time by
                updating this site. If there are material changes to Privacy
                Policy that may impact your rights, Kamba will attempt to notify
                you by email or as otherwise required by applicable law. You
                understand and agree that you will be deemed to have accepted
                the updated Privacy Policy if you visit our Sites or use any of
                our products or services after such changes have been made.{' '}
              </span>
              <span className="c2 c5">
                If you do not agree with this policy, do not access or use our
                Services or interact with any other aspect of our business
              </span>
              <span className="c2 c1">.&nbsp;&nbsp;</span>
            </p>
            <p className="c9">
              <span className="c2 c5">How can you contact us?</span>
            </p>
            <p className="c9">
              <span className="c2 c1">Please co</span>
              <span className="c1">ntact us at </span>
              <span className="c1">privacy@kamba.com</span>
              <span className="c1">&nbsp;if </span>
              <span className="c2 c1">
                you have any questions about our Privacy Policy.
              </span>
            </p>
            <p className="c6">
              <span className="c2 c1"></span>
            </p>
            <p className="c6">
              <span className="c2 c1"></span>
            </p>
            <p className="c6">
              <span className="c2 c12"></span>
            </p>{' '}
          </ContractContainer>
        </Wrapper>
      </Container>
    </Container>
  )
}

export default PrivacyPolicy
