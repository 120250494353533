import { useEffect, useRef, useState } from 'react'
import {
  Wrapper,
  Header,
  Title,
  Column,
  ColumnWrapper,
  ChatWrapper,
  InputWrapper,
  Input,
  MessagesWrapper,
  TitleURL,
  DeclineButton,
  RegularButton,
} from './styled'
import logo from '@/assets/images/safe.png'
import ChatBox from '../../../messages/chat-box'
import { IChat } from './types'
import { Message, Paginator } from '@twilio/conversations'
import { useAppSelector } from '@/app/hooks'
import { ClipLoader } from 'react-spinners'
import storage from '@/utils/storage'
import { axios } from '@/lib/axios'
const Chat = ({
  isActive,
  conversation,
  client,
  renderFlag,
  proposal,
  id,
  setActiveCard,
}: IChat) => {
  const [newMessage, setNewMessage] = useState('')
  const [loading, setLoading] = useState(true)
  const [messageWasSent, setMessageWasSent] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [messages, setMessages] = useState<Paginator<Message> | undefined>()
  const userMail = useAppSelector((state) => state.authUser.userMail)
  const appEnv = process.env.APP_ENVIRONMENT
  const baseUrl = process.env.REACT_APP_URL_BASE
  const token = storage.getToken()

  useEffect(() => {
    if (conversation && isActive) {
      getMessages().then(() => {
        setLoading(false)
        setMessageWasSent(false)
      })
      conversation.on('messageAdded', (msg) => {
        getMessages()
      })
    }
  }, [conversation, isActive, messageWasSent])

  const truncate = (str: string, n: number) => {
    return str.length > n ? str.slice(0, n - 1) + '...' : str
  }

  const getMessages = async () => {
    try {
      setMessages(await conversation.getMessages())
    } catch {
      console.log('error')
    }
  }

  const sendMessage = async () => {
    if (newMessage && client) {
      setButtonLoading(true)
      client.getConversationBySid(conversation.sid).then((data: any) => {
        if (newMessage && String(newMessage).trim()) {
          data.sendMessage(newMessage).then(() => {
            setNewMessage('')
            setButtonLoading(false)
            setMessageWasSent(true)
          })
        } else {
          console.log('error')
        }
      })
    }
  }

  const changeProposalStatus = (newStatus: string) => {
    const res = axios
      .put(`${baseUrl}/api/v1/proposals/${proposal.id}/status`, {
        status: newStatus,
      })
      .then(function (response: any) {
        return response
      })
      .catch(function (error: any) {
        console.log(error)
      })
    return res
  }

  const sendNewProposalStatus = async (status: string) => {
    setButtonLoading(true)
    changeProposalStatus(status).then((data) => {
      setButtonLoading(false)
      renderFlag(true)
      setActiveCard('')
    })
  }

  return (
    <>
      {isActive && (
        <Wrapper>
          <Header>
            <img src={logo} alt="Company logo" style={{ maxWidth: '52px' }} />
            <ColumnWrapper>
              <Column>
                <Title>Interested in</Title>
                Info
              </Column>
              <Column>
                <Title>Proposed product</Title>
                <TitleURL href={`${appEnv}/product/${proposal.listingId}`}>
                  {truncate(proposal.listingTitle, 100)}
                </TitleURL>
              </Column>
            </ColumnWrapper>
          </Header>
          <ChatWrapper>
            <MessagesWrapper>
              {messages &&
                messages.items.map((message: Message, index: number) =>
                  message.author == userMail ? (
                    <ChatBox
                      key={index}
                      author={message.author}
                      message={message.body}
                      date={message.dateCreated}
                      isAlignLeft={true}
                    />
                  ) : (
                    <ChatBox
                      key={index}
                      author={message.author}
                      message={message.body}
                      date={message.dateCreated}
                      isAlignLeft={false}
                    />
                  ),
                )}
            </MessagesWrapper>
          </ChatWrapper>
          {id !== '3' && (
            <InputWrapper>
              {id === '1' ? (
                <>
                  <DeclineButton
                    onClick={() => sendNewProposalStatus('declined')}
                    disabled={buttonLoading}
                  >
                    {buttonLoading ? (
                      <ClipLoader
                        color="#ffffff"
                        loading={buttonLoading}
                        size={15}
                      />
                    ) : (
                      'DECLINE'
                    )}
                  </DeclineButton>
                  <RegularButton
                    onClick={() => sendNewProposalStatus('accepted')}
                    disabled={buttonLoading}
                  >
                    {buttonLoading ? (
                      <ClipLoader
                        color="#ffffff"
                        loading={buttonLoading}
                        size={15}
                      />
                    ) : (
                      'REPLY TO SALES'
                    )}
                  </RegularButton>
                </>
              ) : (
                <>
                  <Input
                    onChange={(e) => setNewMessage(e.target.value)}
                    id="input"
                    value={newMessage}
                  />
                  <RegularButton onClick={sendMessage} disabled={buttonLoading}>
                    {buttonLoading ? (
                      <ClipLoader
                        color="#ffffff"
                        loading={buttonLoading}
                        size={15}
                      />
                    ) : (
                      'Send message'
                    )}
                  </RegularButton>
                </>
              )}
            </InputWrapper>
          )}
        </Wrapper>
      )}
    </>
  )
}

export default Chat
