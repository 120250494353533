import styled from 'styled-components'

export const ViewFileDiv = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  padding: 1rem;
  border: 1px solid black;
  box-sizing: border-box;
  background-color: white;
`
export const FileInfoDiv = styled.div`
  span {
    color: #1c1c1c;
  }
  p {
    color: #1c1c1c;
    white-space: nowrap;
    overflow: hidden;
    max-width: 60%;
    text-overflow: ellipsis;
  }
  svg {
    margin-right: 10px;
  }
  display: flex;
  max-width: auto;

  flex-direction: row;
  align-items: center;
  justify-content: left;
`

export const DeleteFileDiv = styled.div``
