import { axios as axiosCustom } from '@/lib/axios'
import axios from 'axios'

interface UploadFileConfig {
  customBucket?: string
  returnUUID?: boolean
}

const uploadFile = async (
  data: File,
  { customBucket, returnUUID }: UploadFileConfig,
): Promise<string> => {
  const responseUrl = await axiosCustom.put(`/uploadS3`, {
    fileType: data.type,
    awsIdentificator: customBucket,
  })
  if (!(responseUrl as any).ok) {
    // console.log(responseUrl)
    throw {}
  }

  const url: string = responseUrl.data
  const incompleteUuid = url.split('.com/')
  const uuid = incompleteUuid[1].split('?')[0]
  // const newFile = new File([data], data.name + uuid, { type: data.type })
  const newFile = new File([data], uuid, { type: data.type })

  const responseAws = await axios.put(url, newFile, {
    headers: {
      'Content-Type': data.type,
      // 'Content-Disposition': `attachment;fileName="${data.name}"`,
    },
  })

  if (responseAws.status !== 200) {
    console.log('ERROR', responseAws)
    throw {}
  }

  // const nameWithoutExtension =
  //   data.name.substring(0, data.name.lastIndexOf('.')) || data.name

  // const resultUrl = nameWithoutExtension.trim().replace(/\s/g, '') + '_' + uuid

  // return resultUrl
  if (returnUUID) {
    return uuid
  } else {
    return url.split('?')[0]
  }
}

export default uploadFile
