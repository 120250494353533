import styled, { keyframes } from 'styled-components'
import { DividerProps, InfoProps } from './types'

export const LongCardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
  margin: 6px 0;
  min-height: 222px;
  padding: 30px 40px;
  border: 1px solid #e7ebef;

  &:last-child {
    margin-bottom: 356px;
  }

  &:before {
    content: '';
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    background: #000;
    width: 3px;
    height: 100%;
    transition: all 0.4s;
  }

  &:hover {
    cursor: pointer;
    background: #fafafa;
    transition: all 1s;
    min-height: 222px;

    .divider {
      transition: all 0.6s;
      height: 100px;
    }

    &:before {
      width: 5px;
      opacity: 1;
    }
  }
`

export const Info = styled.div<InfoProps>`
  display: flex;
  flex-direction: column;
  width: 65%;
`

export const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 1.3;
  letter-spacing: 0em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 80px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

export const Details = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 5px;

  .details-item {
    padding: 5px 15px;
  }

  .base-style {
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    color: #828282;
  }

  .country-list {
    display: flex;
    align-items: center;
  }
`

export const Price = styled.div`
  margin-left: -15px;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 38px;
  color: #505050;
`

export const Provider = styled.span`
  display: block;
  margin-left: -15px;
  font-size: 15px;
  font-weight: 700;
  color: #828282;
`

export const PaymentOption = styled.div`
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  color: #828282;
`
export const Type = styled.div`
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  color: #828282;
`

export const CountryList = styled.div`
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  color: #828282;
`

export const Description = styled.div`
  margin-top: 12px;
  padding: 18px;
  background: #fafafa;
`

export const DescriptionText = styled.p`
  max-height: 48px;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #838383;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`

export const CustomDivider = styled.div<DividerProps>`
  height: ${({ height }) => height || '15px'};
  border: 1px solid #e7ebef;
  transition: all 0.4s;
`

export const FlagImg = styled.img`
  height: 15px;
  position: relative;
  top: 2px;
  width: 15px;
  margin-right: 6px;
`

export const LongCardRight = styled.div``
