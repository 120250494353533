import styled from 'styled-components'
import { ISeparatorProps } from '.'

export const Separator = styled.span<ISeparatorProps>`
  display: flex;
  background: linear-gradient(90deg, #d9d9d9 0%, rgba(217, 217, 217, 0) 100%);
  width: 100%;
  height: 3px;
  margin: ${({ margin }) => margin || '0'};
`
