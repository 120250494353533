import { Grid } from '@mui/material'
import * as React from 'react'
import { IMetricProps } from './types'
import { LabelMetric, ValueDesc, ValueMetric } from './styled'

const Metric: React.FC<IMetricProps> = ({ label, value, description }) => {
  return (
    <>
      <Grid container spacing={0}>
        <Grid xs={8} item>
          <LabelMetric>{label}</LabelMetric>
        </Grid>
        <Grid xs={11} item>
          <ValueMetric>{value}</ValueMetric>
        </Grid>
        <Grid xs={9} item>
          <ValueDesc>{description}</ValueDesc>
        </Grid>
      </Grid>
    </>
  )
}

export default Metric
