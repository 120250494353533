export const methodData = [
  { id: 1, value: 'S3 Bucket' },
  { id: 2, value: 'REST API' },
  { id: 3, value: 'SFTP' },
  { id: 4, value: 'SOAP API' },
  { id: 5, value: 'Email' },
  { id: 6, value: 'Streaming API' },
  { id: 7, value: 'UI Export' },
  { id: 8, value: 'Feed API' },
]
