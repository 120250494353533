import styled from 'styled-components'
import check from '@assets/images/icons/GreenCheckIcon'
import { RowTextProps } from './types'

export const CheckIcon = styled.div`
  background-image: url(${check});
  color: #2fa24f;
  width: 24px;
  height: 24px;
`

export const FreeSampleText = styled.span`
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  color: #000000;
`

export const LabelStyled = styled.label`
  font-weight: 800;
  font-size: 17px;
  line-height: 27px;
  color: #858585;
`

const BorderRow = styled.div`
  border-bottom: 1px solid #c4c4c4;
`

export const RowText = styled.span<RowTextProps>`
  font-weight: 500;
  font-size: ${({ fontSize }) => fontSize || '22px'};
  line-height: 32px;
  color: #545454;
`

export const RowBoldText = styled.span`
  font-size: 23px;
  font-weight: 700;
  color: #000000;
`
