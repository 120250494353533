import * as React from 'react'
import { Description, Title, Row } from './styled'
import { IHeaderProps } from './types'

const Header: React.FC<IHeaderProps> = ({ title, description, children }) => {
  return (
    <>
      <Row>
        <div>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </div>
        {children}
      </Row>
    </>
  )
}

export default Header
