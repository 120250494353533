import { Container } from '@mui/material'
import * as React from 'react'
import Header from '../header'
import { tabs } from './tabsExplorer'
import { Button, Icon, TabsNavigation } from '@components'
import supportIcon from '@/assets/images/icons/support.png'
import { FlexButtonWrapper, KambaSupport } from './styled'

interface IExplorerProps {}

const Explorer: React.FC<IExplorerProps> = () => {
  const supportEmail = process.env.SUPPORT_EMAIL
  const handleClick = (e: any) => {
    window.location.href = `mailto:${supportEmail}`
    e.preventDefault()
  }
  const button = (
    <FlexButtonWrapper>
      <p>Need help?</p>
      <Button
        marginLeft={'10px'}
        variant="secondary"
        onClick={handleClick}
        display="flex"
        alignItems={'center'}
        padding={'12px 24px'}
      >
        <KambaSupport>Contact Kamba Support</KambaSupport>
        <Icon src={supportIcon} />
      </Button>
    </FlexButtonWrapper>
  )
  return (
    <div style={{ background: 'white' }}>
      <Container maxWidth={'xl'} sx={{ padding: '30px' }}>
        <div style={{ marginTop: '-30px' }}>
          <TabsNavigation
            tabs={tabs}
            backgroundMarginOffset={'46%'}
            isExplorePage
            btn={button}
          />
        </div>
      </Container>
    </div>
  )
}

export default Explorer
