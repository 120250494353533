import styled from 'styled-components'
import { ContainerProps } from './types'
import backArrow from '@assets/images/icons/LeftArrowIcon'

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  height: 100vh;
  width: 100%;
  overflow: auto;
`
export const ContractContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 80%;
  margin-left: 10%;
`
export const ArrowButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
`
export const ArrowIcon = styled.img.attrs({
  src: backArrow,
  alt: 'Return icon',
})``

export const Button = styled.button.attrs(({ type }) => ({
  type: type || 'button',
}))`
  background-color: #060606;
  border: 1px solid #060606;
  color: #ffffff;
  font-size: 14px;
  line-height: 22px;
  padding: 12px 20px;
  cursor: pointer;
  letter-spacing: 2px;
  text-transform: uppercase;
  min-width: 350px;
  margin-top: 24px;
`
