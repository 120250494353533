import Select, { Options } from 'react-select'
import { Option } from './types'

interface FilterProps {
  data: Options<{ label: string; value: string }>
  onChange: (option: Option[] | Option) => void
  defaultValue: unknown
  isMulti?: boolean
  placeholder?: string
  width?: string
  isClearable?: boolean
}

const Filter: React.FC<FilterProps> = ({
  data,
  defaultValue,
  onChange,
  width,
  placeholder = '',
  isMulti = false,
  isClearable = true,
}) => {
  const onChangeFn = (option: Option[] | Option) => {
    onChange(option)
  }

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      minHeight: '54px',
      minWidth: width,
      maxWidth: '500px',
      marginRight: '2rem',
      display: 'flex',
      flex: 1,
    }),
    dropdownIndicator: (provided: any, state: any) => ({
      ...provided,
      transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
      transition: '250ms',
      color: '#000000',
      '&:hover': {
        color: '#000000',
      },
    }),
  }

  return (
    <Select
      isMulti={isMulti}
      value={defaultValue}
      styles={customStyles}
      placeholder={placeholder}
      options={data}
      theme={(theme: any) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary: 'black',
        },
      })}
      noOptionsMessage={() => 'Not found...'}
      components={{ IndicatorSeparator: () => null }}
      onChange={(a) => {
        if (isMulti) {
          onChangeFn(a as Option[])
        } else {
          onChangeFn(a as Option)
        }
      }}
      isClearable={isClearable}
    />
  )
}

export default Filter
