import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import {
  StyledAttributeName,
  StyledTableCell,
  StyledTableRow,
  StyledTitleColumn,
} from './styled'
import { IDataAttributesProps } from './types'
import { Grid } from '@mui/material'
import { GradientSeparator, Title } from '@/components'
import { PreviewContainer } from '../styled'

const DataAttributes: React.FC<IDataAttributesProps> = ({ rows }) => {
  return (
    <PreviewContainer>
      <Title boldText="Data Dictionary" fontSize="30px"></Title>
      {/* <Table sx={{ minWidth: 700 }}> */}
      <Grid container spacing={1} sx={{ padding: '35px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ backgroundColor: '#FFFF' }}>
                <StyledTitleColumn>ATTRIBUTES & DESCRIPTION</StyledTitleColumn>
              </StyledTableCell>
              <StyledTableCell
                sx={{
                  backgroundColor: '#FFFF',
                  display: 'flex',
                  justifyContent: 'end',
                }}
              >
                <StyledTitleColumn>EXAMPLE</StyledTitleColumn>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <StyledTableRow key={i}>
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{ width: '50%' }}
                >
                  <Grid container spacing={1} direction="column">
                    <Grid item xs={4}>
                      <StyledAttributeName>{row.name}</StyledAttributeName>
                    </Grid>
                    <Grid item xs={4}>
                      <div
                        sx={{
                          paddingLeft: '0',
                          textAlign: 'left',
                          border: 'unset',
                        }}
                      >
                        {row.description}
                      </div>
                    </Grid>
                  </Grid>
                </StyledTableCell>
                <StyledTableCell align="right">{row.example}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
      <GradientSeparator margin="0 0 30px 0" />
    </PreviewContainer>
  )
}

export default DataAttributes
