import styled from 'styled-components'

export const MetricList = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;

  li:last-child {
    .gridItemBorder {
      border: none;
    }
  }
`

export const HeaderDescription = styled.div`
  margin-top: 25px;
  margin-bottom: 40px;
  width: 100%;
`
