import styled, { css } from 'styled-components'
import arrow from '@assets/images/icons/left-arrow'

export const Title = styled.h2`
  font-size: 36px;
  font-weight: 700;
  color: #000000;
`

export const FilterTitle = styled.span`
  color: '#404040';
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
`

export const FilterList = styled.ul`
  padding: 0;
  margin-right: 10px;
  margin-bottom: 4px;
  ${({ scrollable }: { scrollable?: boolean }) => {
    if (!scrollable) {
      return
    } else {
      return css`
        overflow-y: scroll;
        max-height: 300px;
      `
    }
  }}
`

export const SeeMore = styled.div`
  cursor: pointer;
  display: flex;
  color: black;
  margin-bottom: 10px;
  img {
    position: relative;
    content: url(${arrow});
    top: 4px;
    transform: ${({ isLess }: { isLess: boolean }) =>
      isLess ? 'rotate(270deg)' : 'rotate(90deg)'};
    height: 14px;
    width: 7px;
    margin-right: 10px;
    margin-left: 10px;
  }
`

export const SearchBar = styled.input`
  font: inherit;
  width: 100%;
  border: 1px #d9d9d9 solid;
  padding: 5px;
  margin-top: 7px;

  &::placeholder {
    color: #c9c9c9;
  }

  &:focus {
    outline: none;
  }
`
