import * as React from 'react'
const { useState, useMemo } = React

const useHover = (isHovered: boolean): [boolean, object] => {
  const [hovered, setHovered] = useState(isHovered)

  const eventHandlers = useMemo<object>(
    () => ({
      onMouseOver() {
        setHovered(true)
      },
      onMouseOut() {
        setHovered(false)
      },
    }),
    [],
  )

  return [hovered, eventHandlers]
}

export default useHover
