import { FilterRedirect, GradientSeparator, Title } from '@/components'
import { Grid } from '@mui/material'
import * as React from 'react'
import { DescriptionStyled } from './styled'
import { IDataOfferingProps } from './types'
import { createMarkup } from '@/utils/purify-html'

const DataOffering: React.FunctionComponent<IDataOfferingProps> = ({
  description,
  methods,
}) => {
  return (
    // <Grid container sx={{ margin: '25px 0' }}>
    // <Grid container>
    <>
      <Title margin="0 0 15px 0" boldText="Data Offering" fontSize="30px" />
      <DescriptionStyled dangerouslySetInnerHTML={createMarkup(description)} />
      <FilterRedirect
        filters={methods}
        isSeparatorEnabled={false}
        // marginContainer="30px 0"
      />
      <GradientSeparator margin="30px 0" />
    </>
    // </Grid>
  )
}

export default DataOffering
