import { useEffect, useRef, useState } from 'react'
import {
  Wrapper,
  ChatWrapper,
  InputWrapper,
  Button,
  Input,
  MessagesWrapper,
} from './styled'
import ChatBox from '../../messages/chat-box'
import { IChat } from './types'
import { Message, Paginator } from '@twilio/conversations'
import { useAppSelector } from '@/app/hooks'
import { ClipLoader } from 'react-spinners'

const SubscriptionChat = ({ isActive, conversation, client }: IChat) => {
  const [newMessage, setNewMessage] = useState('')
  const [loading, setLoading] = useState(true)
  const [messageWasSent, setMessageWasSent] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)
  const userMail = useAppSelector((state) => state.authUser.userMail)
  const [messages, setMessages] = useState<Paginator<Message> | undefined>()

  useEffect(() => {
    if (conversation && isActive) {
      getMessages().then(() => {
        setLoading(false)
        setMessageWasSent(false)
      })
      conversation.on('messageAdded', (msg) => {
        getMessages()
      })
    }
  }, [conversation, isActive, messageWasSent])

  const getMessages = async () => {
    try {
      setMessages(await conversation.getMessages())
    } catch {
      console.log('error')
    }
  }

  const sendMessage = async () => {
    if (newMessage && client) {
      setButtonLoading(true)
      client.getConversationBySid(conversation?.sid).then((data: any) => {
        if (newMessage && String(newMessage).trim()) {
          data.sendMessage(newMessage).then(() => {
            setNewMessage('')
            setButtonLoading(false)
            setMessageWasSent(true)
            //renderFlag(true)
          })
        } else {
          console.log('error')
        }
      })
    }
  }

  return (
    <>
      {isActive && (
        <Wrapper>
          <ChatWrapper>
            <MessagesWrapper>
              {messages &&
                messages.items.map((message: Message, index: number) =>
                  message.author == userMail ? (
                    <ChatBox
                      key={index}
                      author={message.author}
                      message={message.body}
                      date={message.dateCreated}
                      isAlignLeft={true}
                    />
                  ) : (
                    <ChatBox
                      key={index}
                      author={message.author}
                      message={message.body}
                      date={message.dateCreated}
                      isAlignLeft={false}
                    />
                  ),
                )}
            </MessagesWrapper>
          </ChatWrapper>
          <InputWrapper>
            <Input
              onChange={(e) => setNewMessage(e.target.value)}
              id="input"
              value={newMessage}
            />
            <Button onClick={sendMessage}>
              {buttonLoading ? (
                <ClipLoader color="#ffffff" loading={buttonLoading} size={15} />
              ) : (
                'Send message'
              )}
            </Button>
          </InputWrapper>
        </Wrapper>
      )}
    </>
  )
}

export default SubscriptionChat
