import styled from 'styled-components'
import { IProgress, IProgressProps } from './types'

export const BarLabel = styled.label`
  font-weight: 500;
  font-size: 21px;
  line-height: 28px;
  color: #202020;
`

export const BarValue = styled.span`
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
`

export const BarContainerStyles = styled.div`
  height: 100%;
  width: 100%;
  background-color: #cfcfcf;
`

type ProgressBar = Omit<IProgress, 'value'>

export const BarFillerStyles = styled.div<ProgressBar>`
  height: 100%;
  width: ${({ completed }) => `${completed || 0}%`};
  background-color: ${({ bgcolor }) => bgcolor || '#000000'};
`
