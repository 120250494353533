import { useState, useEffect } from 'react'
import {
  Stepper,
  Pagination,
  Title,
  Divider,
  Label,
  Block,
  Item,
  Subtitle,
  Button,
  Arrow,
} from '../styled'
import SelectComponent from '../select'
import { CollapsibleCheckGroup } from '@/components'
import { Input, InputWrapper } from './styled'
import VolumeDynamicFields from './volume-dynamic-fields'
import QualityDynamicFields from './quality-dynamic-fields'
import AttributesDynamicFields from './attributes-dynamic-fields'
import { AttributeDto, DataDto, SampleDto, StepTwoParams } from './types'
import { geographyData } from '@/data/geographicData'
import { arrayCheckboxesHandler } from '@/utils/array-checkboxes'
import { AllSamples } from './samples'
import * as yup from 'yup'
import { deleteError, findError } from '@/utils/form-errors'
import { ErrorWrapper } from '../common/styled'

const SecondStep = ({ onClick, state, dispatch }: StepTwoParams) => {
  const [errors, setErrors] = useState<yup.ValidationError[]>([])
  const [countries, setCountries] = [
    state.geo,
    (countries: string[]) => {
      dispatch({ geo: countries })
    },
  ]
  const [qualityData, setQualityData] = [
    state.qualityData,
    (data: DataDto[]) => {
      dispatch({ qualityData: data })
    },
  ]
  const [volumeData, setVolumeData] = [
    state.volumeData,
    (data: DataDto[]) => {
      dispatch({ volumeData: data })
    },
  ]
  const [attributeData, setAttributeData] = [
    state.attributes,
    (data: AttributeDto[]) => {
      dispatch({ attributes: data })
    },
  ]

  const [sampleData, setSampleData] = [
    state.samples,
    (data: SampleDto[]) => {
      dispatch({ samples: data })
    },
  ]

  const internalDeleteError = (identifier: string) => {
    deleteError(identifier, errors, setErrors)
  }

  const internalFindError = (identifier: string) => {
    const error = findError(identifier, errors)
    return !!error && <ErrorWrapper>{error.message}</ErrorWrapper>
  }

  const [historicalCoverageNumber, setHistoricalCoverageNumber] = useState(
    `${state.historicalCov}`,
  )
  const [historicalCoverageRange, setHistoricalCoverageRange] =
    useState<number>(1)

  const historicalCoverageOptions = [
    { id: 1, value: 1, label: 'Days' },
    { id: 2, value: 7, label: 'Weeks' },
    { id: 3, value: 30, label: 'Months' },
    { id: 4, value: 365, label: 'Years' },
  ]

  useEffect(() => {
    dispatch({
      historicalCov:
        parseInt(historicalCoverageNumber, 10) * historicalCoverageRange || 0,
    })
  }, [historicalCoverageRange, historicalCoverageNumber])

  return (
    <Stepper>
      <Block>
        <Pagination>Step 2/3</Pagination>
        <Title>
          Help your buyers preview the value and quality of your data
        </Title>
        <Divider />
        <Item
          onFocus={() => {
            internalDeleteError('file')
          }}
        >
          <AllSamples
            state={sampleData}
            dispatch={setSampleData}
            setValidationError={setErrors}
          />
          {internalFindError('file')}
        </Item>
        <Title>What&apos;s the spatial &amp; temporal coverage?</Title>
        <Divider />
        <Item>
          <Label>Geographical Coverage</Label>
          {geographyData &&
            Object.keys(geographyData).map((key, index) => (
              <CollapsibleCheckGroup
                key={index}
                label={key}
                data={geographyData[key]}
                isCheckedFunction={(val: string) => {
                  return countries.includes(val)
                }}
                handleOnChange={(e: any) =>
                  arrayCheckboxesHandler(
                    e.target.checked,
                    countries,
                    setCountries,
                    (stateValue) => stateValue === e.target.value,
                    e.target.value,
                  )
                }
              />
            ))}
        </Item>
        <Item>
          <Label>Historical Coverage</Label>
          <InputWrapper>
            <Input
              type="number"
              value={historicalCoverageNumber}
              onChange={(e) => {
                setHistoricalCoverageNumber(
                  parseInt(e.target.value) > 0 ? e.target.value : '0',
                )
              }}
            />
            <SelectComponent
              value={historicalCoverageOptions.find(
                (option) => historicalCoverageRange === option.value,
              )}
              options={historicalCoverageOptions}
              onChange={(e: any) => {
                setHistoricalCoverageRange(e.value)
              }}
            />
          </InputWrapper>
        </Item>
      </Block>
      <Block>
        <Title>What volume and quality of data do you provide?</Title>
        <Divider />
        <Item marginBottom="54px">
          <VolumeDynamicFields state={volumeData} setValue={setVolumeData} />
        </Item>
        <Item>
          <QualityDynamicFields state={qualityData} setValue={setQualityData} />
        </Item>
      </Block>
      <Block>
        <Title>Which attributes does your product include?</Title>
        <Subtitle>
          Please be privacy compliant with the examples, do not expose sensitive
          data here!
        </Subtitle>
        <Divider />
        <Item>
          <AttributesDynamicFields
            state={attributeData}
            setValue={setAttributeData}
          />
        </Item>
      </Block>
      <Button
        onClick={(e) => {
          e.preventDefault
          onClick(e)
        }}
      >
        Continue to next step
        <Arrow />
      </Button>
    </Stepper>
  )
}

export default SecondStep
