import { Container, Navigation } from '@/components'
import { useReducer, useState } from 'react'
import StepperBar from './stepper-bar'
import {
  ArrowLeft,
  ArrowLeftWrapper,
  Block,
  Divider,
  FormWrapper,
  Item,
  Pagination,
  Stepper,
  StepperBarWrapper,
  Title,
  Wrapper,
} from './styled'
import StepOne from './step-one'
import StepTwo from './step-two'
import { globalStateType, reducerType } from './types'
import uploadFile from './uploadFile'

const DatasetCreateListing = () => {
  const initialState: globalStateType = {
    name: '',
    description: '',
    listing: null,
    file: null,
  }
  const reducer: reducerType = (state, action) => {
    return { ...state, ...action }
  }
  const [data, dispatch] = useReducer(reducer, initialState)
  const [step, setStep] = useState(1)
  const [error, setError] = useState(false)

  const showStep = () => {
    switch (step) {
      /*
      case 1:
        return (
          <StepOne
            globalState={data}
            dispatch={dispatch}
            onClick={buttonChangeStep(2)}
          />
        )
        */
      case 1:
        return (
          <StepTwo globalState={data} dispatch={dispatch} onClick={upload} />
        )
    }
  }
  const upload = (data: File) => {
    uploadFile(data, setError)
  }
  const buttonChangeStep = (step: number) => {
    return () => {
      setStep(step)
      window.scrollTo(0, 0)
    }
  }

  return (
    <>
      <Navigation />
      <Container backgroundColor="#000000" border={'unset'}>
        {step > 1 && (
          <ArrowLeftWrapper
            onClick={() => {
              window.scrollTo(0, 0)
              setStep(step - 1)
            }}
          >
            <ArrowLeft />
          </ArrowLeftWrapper>
        )}
        <Wrapper>
          <FormWrapper>
            <StepperBarWrapper>
              <StepperBar stepNumber={step} />
            </StepperBarWrapper>
            {showStep()}
          </FormWrapper>
        </Wrapper>
      </Container>
    </>
  )
}
export default DatasetCreateListing
