export const currencyData = [
  {
    id: 1,
    value: 'ARS',
    label: 'ARS - $',
  },
  {
    id: 2,
    value: 'AUD',
    label: 'AUD - $ ',
  },
  {
    id: 3,
    value: 'BOB',
    label: 'BOB - $b',
  },
  {
    id: 4,
    value: 'BRL',
    label: 'BRL - R$',
  },
  { id: 9, value: 'EUR', label: 'EUR - €' },
  {
    id: 5,
    value: 'GBP',
    label: 'GBP - £',
  },
  {
    id: 6,
    value: 'INR',
    label: 'INR - ₹',
  },
  {
    id: 7,
    value: 'JPY',
    label: 'JPY - ¥',
  },
  {
    id: 8,
    value: 'USD',
    label: 'USD - $',
  },
]
