import styled from 'styled-components'
import alertIcon from '@assets/images/icons/alert-triangle'
import { ITextWrapperProps } from './types'

export const TextWrapper = styled.div<ITextWrapperProps>`
  font-weight: ${({ weight }) => weight || '700'};
  font-size: ${({ fontSize }) => fontSize || '16px'};
  line-height: 21px;
  color: ${({ color }) => color || '#000000'};
  width: ${({ width }) => width || '18.5vw'};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

export const TextUnderline = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  text-decoration-line: underline;
  color: #000000;
`

export const Warning = styled.div`
  height: 36px;
  background: #fff1e1;
  padding-left: 20px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #825b00;
  background-image: url(${alertIcon});
  background-repeat: no-repeat;
  background-position: 10px 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`
