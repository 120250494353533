import styled from 'styled-components'

export const CheckboxWrapper = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 16px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto auto auto auto auto auto;
`

export const Button = styled.button.attrs(({ type }) => ({
  type: type || 'button',
}))`
  justify-content: center;
  align-items: center;
  width: 134px;
  height: 31px;
  border: 1px solid #000000;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #000000;
  cursor: pointer;
  background-color: #ffffff;
`

export const CtaWrapper = styled.div`
  display: flex;
  margin-bottom: 28px;
  gap: 10px;
`
