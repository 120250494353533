import { useGetConversations } from '@/services/conversations/convesations.service'
import { Collapse, List, ListItemButton, capitalize } from '@mui/material'
import { useMemo } from 'react'
import Loader from 'react-spinners/ClipLoader'

interface ChatListProps {
  listingId: string
}

const ChatList: React.FC<ChatListProps> = ({ listingId }) => {
  const { data, isLoading } = useGetConversations({
    listingId: [listingId],
  })

  const conversations = useMemo(() => {
    if (data?.data) {
      return data.data.conversations
    }
    return []
  }, [data?.data])

  const renderConverations = () => {
    if (isLoading) {
      return <Loader size={16} />
    } else {
      if (!conversations.length) {
        return <span>There are no conversations yet.</span>
      } else {
        return conversations.map((c) => {
          const goToChat = () => {
            window.open('/messages?listings=' + c.listing.id, '_blank')
          }
          return (
            <ListItemButton key={c.id} sx={{ pl: 4 }} onClick={goToChat}>
              <span style={{ fontWeight: 'bold ' }}>
                {capitalize(c.source)}
              </span>{' '}
              <span> - About {c.listing.title} with </span>
              {c.anonymous ? 'Anonymous' : c.userName}
            </ListItemButton>
          )
        })
      }
    }
  }

  return (
    <Collapse in={true} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {renderConverations()}
      </List>
    </Collapse>
  )
}

export default ChatList
