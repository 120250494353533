import { Button } from '@/components'
import { useProposalsByPostingId } from '@/services/proposals/proposals.service'
import { Proposal } from '@/services/proposals/types'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router'
import { ButtonContentWrapper, ButtonText, CtaWrapper } from './styled'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import Loader from 'react-spinners/ClipLoader'

interface AvailableProposalProps {
  postingId: string
}

const AvailableProposal: React.FC<AvailableProposalProps> = ({ postingId }) => {
  const { data, isLoading } = useProposalsByPostingId({
    postingId,
  })
  const navigate = useNavigate()

  const proposals = useMemo(() => {
    return data?.data?.proposals || []
  }, [data])

  const buildButton = (proposals: Proposal[]) => {
    const listings = proposals.map((p) => `listings=${p.listingId}`).join('&')
    const url = `/messages?source=proposal&${listings}`

    return (
      <CtaWrapper>
        <Button
          backgroundColor="#000"
          onClick={() => {
            navigate(url)
          }}
        >
          <ButtonContentWrapper>
            <ButtonText>See messages</ButtonText>
            <ArrowForwardRoundedIcon fontSize="small" />
          </ButtonContentWrapper>
        </Button>
      </CtaWrapper>
    )
  }

  if (isLoading) {
    return <Loader size={12} />
  }

  return (
    <>
      {proposals.length > 0 ? (
        buildButton(proposals)
      ) : (
        <div>There is not proposal yet</div>
      )}
    </>
  )
}

export default AvailableProposal

/**
 *
 * {/ *<TextLine>
                    <StatusDot active />
                    <StatusText active>20 days left</StatusText>
                  </TextLine>
                  <TextLine>
                    <StatusDot active />
                    <StatusText active>8 provider responses left</StatusText>
              </TextLine>* /}
                  <CtaWrapper>
                    <Button
                      backgroundColor="#000"
                      onClick={() => {
                        navigate(`/posting-proposals/${post.id}`)
                      }}
                    >
                      <ButtonContentWrapper>
                        <ButtonText>
                          NEW RESPONSES = ES ESTE COMPONENTE
                        </ButtonText>
                        <ArrowForwardRoundedIcon fontSize="small" />
                      </ButtonContentWrapper>
                    </Button>
                  </CtaWrapper>
 */
