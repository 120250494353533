import { ChangeEvent, useState } from 'react'
import { Label } from '../../styled'
import {
  Input,
  Row,
  CloseButton,
  AddButton,
  PlusIcon,
  InputWrapper,
} from '../volume-dynamic-fields/styled'
import { AbsoluteWrapper } from '../../input/styled'
import { Tooltip } from '@/components'
import { AttributesTooltip } from '../../tooltip-content'
import { AttributeDto } from '../types'

const AttributesDynamicFields = ({
  state,
  setValue,
}: {
  state: AttributeDto[]
  setValue: (arg0: AttributeDto[]) => any
}) => {
  const [isFocused, setIsFocused] = useState(false)
  const [characters, setCharacters] = useState(0)
  const [inputList, setInputList] = useState(
    state.length > 0
      ? state
      : [
          {
            name: '',
            example: '',
            description: '',
            records: 0,
          },
        ],
  )

  const transformToInt = (val: any) => {
    const intVal = parseInt(val)
    if (!intVal) {
      return 0
    }
    return intVal
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    setCharacters(e.target.value.length)
    const { name, value } = e.target
    const list = [...inputList]
    switch (name) {
      case 'name':
        list[index].name = value
        break
      case 'example':
        list[index].example = value
        break
      case 'description':
        list[index].description = value
        break
      case 'records':
        list[index].records = transformToInt(value)
        break
      default:
        break
    }
    setInputList(list)
    setValue(list)
  }

  const handleAddInput = () => {
    setInputList([
      ...inputList,
      { name: '', example: '', description: '', records: 0 },
    ])
  }

  const handleRemoveInput = (index: any) => {
    const list = [...inputList]
    list.splice(index, 1)
    setInputList(list)
    setValue(list)
  }

  return (
    <>
      {inputList &&
        inputList.map((item: any, index: number) => (
          <Row key={index}>
            <InputWrapper maxWidth="127px">
              {index === 0 && <Label marginBottom="8px">Name</Label>}
              <Input
                type="text"
                name="name"
                placeholder="e.g.: “lat/long”"
                value={item.name}
                onChange={(e) => handleChange(e, index)}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                autoComplete="off"
              />
              {isFocused && characters < 1 && (
                <AbsoluteWrapper right="auto" top="108px">
                  <Tooltip verticalPointer>
                    <AttributesTooltip />
                  </Tooltip>
                </AbsoluteWrapper>
              )}
            </InputWrapper>
            <InputWrapper>
              {index === 0 && <Label marginBottom="8px">Example</Label>}
              <Input
                type="text"
                name="example"
                placeholder="e.g.: “40.35/20.54”"
                value={item.example}
                onChange={(e) => handleChange(e, index)}
              />
            </InputWrapper>
            <InputWrapper>
              {index === 0 && <Label marginBottom="8px">Description</Label>}
              <Input
                type="text"
                name="description"
                placeholder="e.g.: “the coordinates...”"
                value={item.description}
                onChange={(e) => handleChange(e, index)}
              />
            </InputWrapper>
            <InputWrapper>
              {index === 0 && <Label marginBottom="8px"># of Records</Label>}
              <Input
                type="text"
                name="records"
                placeholder="e.g.: “5000”"
                value={item.records}
                onChange={(e) => handleChange(e, index)}
              />
            </InputWrapper>
            <CloseButton onClick={() => handleRemoveInput(index)} />
          </Row>
        ))}
      <AddButton onClick={handleAddInput}>
        <PlusIcon />
        Add Attribute
      </AddButton>
    </>
  )
}

export default AttributesDynamicFields
