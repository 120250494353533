import { CircleStatus, CircleStatusInternal } from './styled'
import { IStatusProps } from './types'

const StatusDot = ({ active }: IStatusProps) => {
  return (
    <CircleStatus active={active}>
      <CircleStatusInternal active={active} />
    </CircleStatus>
  )
}

export default StatusDot
