import { HeaderMock } from '@/mocks/storefront/headerMock'
import { useGetFile } from '@/services/storefront/storefront.service'
import useHover from '@hooks/useHover'
import { Grid } from '@mui/material'
import { geoAlbers } from 'd3-geo'
import * as React from 'react'
import { Link } from 'react-router-dom'
import {
  BannerImg,
  CardContainer,
  CardDescription,
  CardImg,
  CardSubtitle,
  CardTitle,
  CardUl,
  CheckIcon,
  VerifiedLabel,
} from './styled'
import { ICompanyCardProps } from './types'

const CompanyCard: React.FC<ICompanyCardProps> = ({
  avatar,
  banner,
  domain,
  name,
  geo,
  country,
  verified,
  description,
  id,
  companyId,
  slogan,
}) => {
  const [isHovered, hoversHandlers] = useHover(false)

  const renderVerified = () => (
    <>
      <Grid item xs={9} md={9} xl={8}>
        <VerifiedLabel>VERIFIED</VerifiedLabel>
      </Grid>
      <Grid
        item
        xs={3}
        md={3}
        xl={4}
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <CheckIcon />
      </Grid>
    </>
  )

  const renderProperties = () => {
    const items: JSX.Element[] = []
    if (geo && geo.length > 0) {
      items.push(<li key={geo[0]}>{`Data for ${geo.length} countries`}</li>)
    }
    // https://kubikware.atlassian.net/browse/KMB-492
    // if (country) {
    //   items.push(<li key={country}>{`Based in ${country}`}</li>)
    // }
    return items
  }

  return (
    <Link
      to={`/storefront/${companyId}`}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: 'none' }}
    >
      <CardContainer isHovered={isHovered} {...hoversHandlers}>
        <Grid
          container
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          spacing={2}
        >
          <Grid item xs={9} md={6} xl={9}>
            {avatar && <CardImg src={avatar} alt="avatar" />}
            {/*renderSubtitle()*/}
          </Grid>
          {verified && (
            <Grid container item xs={4} spacing={1}>
              {renderVerified()}
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <CardTitle>{name}</CardTitle>
        </Grid>
        <Grid item xs={12}>
          <CardDescription maxRows={5}>{description}</CardDescription>
        </Grid>
        <Grid item xs={12}>
          {/* <CardTitle>{slogan}</CardTitle> */}
        </Grid>
        <Grid item xs={12}>
          <CardUl>{renderProperties()}</CardUl>
        </Grid>
        {banner && (
          <Grid item xs={12}>
            <BannerImg src={banner} />
          </Grid>
        )}
      </CardContainer>
    </Link>
  )
}

export default CompanyCard
