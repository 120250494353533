import styled from 'styled-components'
interface BadgeProps {
  variant: 'success' | 'warning'
}
export const BadgeWrapper = styled.div<BadgeProps>`
  padding: 8.5px 11px;
  background-color: ${({ variant }) =>
    variant === 'success' ? '#f0fff1' : '#FFF1E1'};
  width: 110px;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
`

export const BadgeText = styled.span<BadgeProps>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({ variant }) => (variant === 'success' ? '#2FA14F' : '#825B00')};
`
