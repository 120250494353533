import { useEffect, useState } from 'react'
import {
  Button,
  CTAWrapper,
  DarkButton,
  TextWrapper,
  TitleWrapper,
  Wrapper,
  LinkWrapper,
  Error,
  Container,
} from './styled'
import download from '@assets/images/icons/download-white'
import { useNavigate } from 'react-router-dom'
import { useLocation, useParams } from 'react-router'
import { AppBarHeader, Sidebar } from '@/components'
import storage from '@/utils/storage'
import { axios } from '@/lib/axios'
import { useAppSelector } from '@/app/hooks'
import { useGetBacktestingUrl } from '@/services/backtesting/backtesting.service'

const Environment = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { state } = useLocation()
  const [dataId, setDataId] = useState('')
  const [dataUrl, setDataUrl] = useState('')
  const [error, setError] = useState(false)
  const baseUrl = process.env.REACT_APP_URL_BASE
  const token = storage.getToken()
  // const userId = useAppSelector((state) => state.authUser.userId)
  const userId = storage.getUserId()
  const userMail = useAppSelector((state) => state.authUser.userMail)
  const supportEmail = process.env.SUPPORT_EMAIL
  const [btBadResponse, setBtBadResponse] = useState('')

  const {
    data: btData,
    mutate,
    isLoading,
  } = useGetBacktestingUrl(() => console.log('finished', btData))
  useEffect(() => {
    mutate(userId!)
    if (!isLoading) console.log(btData)
    axios
      .get(`${baseUrl}/api/v1/listings/${id}/datasets`)
      .then(function (response: any) {
        setDataId(response.data[0].s3Id)
        setError(false)
      })
      .catch(function (error: any) {
        setError(true)
      })
  }, [])

  useEffect(() => {
    if (dataId) {
      axios
        .get(`${baseUrl}/api/v1/getFile/${dataId}`)
        .then(function (response: any) {
          setDataUrl(response.data)
          setError(false)
        })
        .catch(function (error: any) {
          setError(true)
        })
    }
  }, [dataId])

  const sendSampleDownloadEmailToKamba = () => {
    const res = axios
      .post(`${baseUrl}/api/v1/send-email-no-template`, {
        from: supportEmail,
        to: supportEmail,
        subject: 'New Sample Download',
        content: `The user ${userMail} has downloaded the data sample from product with the ID ${id}`,
      })
      .then(function (response: any) {
        return response
      })
      .catch(function (error: any) {
        console.log(error)
      })
    return res
  }

  const handleBackTestingClick = () => {
    if (!isLoading) {
      if (btData?.statusCode === 200) {
        window.open(btData?.body.slice(1, -1), '_blank')
      } else {
        setBtBadResponse(btData?.body.slice(1, -1))
      }
    }
    // window.open('https://jupyter.org/try-jupyter/lab/', '_blank')
  }
  //console.log(state.backtestingFile.file.name)
  return (
    <Container>
      <Sidebar />
      <Container flexDirection="column">
        <AppBarHeader />
        <Wrapper>
          <TitleWrapper>
            <h1>
              Kamba <span>Environment</span>
            </h1>
          </TitleWrapper>
          <TextWrapper>
            {/* This dataset has a free sample available, you can use your{' '}
            <span>Kamba Environment</span> to perform your backtesting */}
            Use <span>Kamba Environment</span> to perform your backtesting.
          </TextWrapper>
          <CTAWrapper>
            <Button onClick={handleBackTestingClick}>
              Open Environment
              <span>Powered by AWS</span>
            </Button>
            <a
              style={{ textDecoration: 'none' }}
              href={state.backtestingFile.url}
              download
            >
              <DarkButton
                onClick={() => {
                  if (dataUrl) {
                    window.open(dataUrl, '_blank')
                    sendSampleDownloadEmailToKamba()
                    setTimeout(() => {
                      window.location.href =
                        'https://jupyter.org/try-jupyter/lab/'
                    }, 100)
                  }
                }}
              >
                <img src={download} />
                Download Sample
              </DarkButton>
            </a>
          </CTAWrapper>
          {/*error && <Error>No data found</Error>*/}
          <LinkWrapper onClick={() => navigate(-1)}>
            Back to <span>Product Detail</span>
          </LinkWrapper>
          {btBadResponse.length > 0 && (
            <span style={{ color: 'red' }}>{btBadResponse}</span>
          )}
        </Wrapper>
      </Container>
    </Container>
  )
}

export default Environment
