export const geographicalData = [
  {
    image: 'ac',
    label: 'Ascension Island',
    value: 'AC',
  },
  {
    image: 'ad',
    label: 'Andorra',
    value: 'AD',
  },
  {
    image: 'ae',
    label: 'United Arab Emirates',
    value: 'AE',
  },
  {
    image: 'af',
    label: 'Afghanistan',
    value: 'AF',
  },
  {
    image: 'ag',
    label: 'Antigua and Barbuda',
    value: 'AG',
  },
  {
    image: 'ai',
    label: 'Anguilla',
    value: 'AI',
  },
  {
    image: 'al',
    label: 'Albania',
    value: 'AL',
  },
  {
    image: 'am',
    label: 'Armenia',
    value: 'AM',
  },
  {
    image: 'ao',
    label: 'Angola',
    value: 'AO',
  },
  {
    image: 'aq',
    label: 'Antarctica',
    value: 'AQ',
  },
  {
    image: 'ar',
    label: 'Argentina',
    value: 'AR',
  },
  {
    image: 'as',
    label: 'American Samoa',
    value: 'AS',
  },
  {
    image: 'at',
    label: 'Austria',
    value: 'AT',
  },
  {
    image: 'au',
    label: 'Australia',
    value: 'AU',
  },
  {
    image: 'aw',
    label: 'Aruba',
    value: 'AW',
  },
  {
    image: 'ax',
    label: 'Aland Islands',
    value: 'AX',
  },
  {
    image: 'az',
    label: 'Azerbaijan',
    value: 'AZ',
  },
  {
    image: 'ba',
    label: 'Bosnia and Herzegovina',
    value: 'BA',
  },
  {
    image: 'bb',
    label: 'Barbados',
    value: 'BB',
  },
  {
    image: 'bd',
    label: 'Bangladesh',
    value: 'BD',
  },
  {
    image: 'be',
    label: 'Belgium',
    value: 'BE',
  },
  {
    image: 'bf',
    label: 'Burkina Faso',
    value: 'BF',
  },
  {
    image: 'bg',
    label: 'Bulgaria',
    value: 'BG',
  },
  {
    image: 'bh',
    label: 'Bahrain',
    value: 'BH',
  },
  {
    image: 'bi',
    label: 'Burundi',
    value: 'BI',
  },
  {
    image: 'bj',
    label: 'Benin',
    value: 'BJ',
  },
  {
    image: 'bl',
    label: 'Saint Barthélemy',
    value: 'BL',
  },
  {
    image: 'bm',
    label: 'Bermuda',
    value: 'BM',
  },
  {
    image: 'bn',
    label: 'Brunei',
    value: 'BN',
  },
  {
    image: 'bo',
    label: 'Bolivia',
    value: 'BO',
  },
  {
    image: 'bq',
    label: 'Caribbean Netherlands',
    value: 'BQ',
  },
  {
    image: 'br',
    label: 'Brazil',
    value: 'BR',
  },
  {
    image: 'bs',
    label: 'Bahamas',
    value: 'BS',
  },
  {
    image: 'bt',
    label: 'Bhutan',
    value: 'BT',
  },
  {
    image: 'bv',
    label: 'Bouvet Island',
    value: 'BV',
  },
  {
    image: 'bw',
    label: 'Botswana',
    value: 'BW',
  },
  {
    image: 'by',
    label: 'Belarus',
    value: 'BY',
  },
  {
    image: 'bz',
    label: 'Belize',
    value: 'BZ',
  },
  {
    image: 'ca',
    label: 'Canada',
    value: 'CA',
  },
  {
    image: 'cc',
    label: 'Cocos (Keeling) Islands',
    value: 'CC',
  },
  {
    image: 'cd',
    label: 'Democratic Republic of the Congo',
    value: 'CD',
  },
  {
    image: 'cefta',
    label: 'Central European Free Trade Agreement',
    value: 'CEFTA',
  },
  {
    image: 'cf',
    label: 'Central African Republic',
    value: 'CF',
  },
  {
    image: 'cg',
    label: 'Republic of the Congo',
    value: 'CG',
  },
  {
    image: 'ch',
    label: 'Switzerland',
    value: 'CH',
  },
  {
    image: 'ci',
    label: "Côte d'Ivoire",
    value: 'CI',
  },
  {
    image: 'ck',
    label: 'Cook Islands',
    value: 'CK',
  },
  {
    image: 'cl',
    label: 'Chile',
    value: 'CL',
  },
  {
    image: 'cm',
    label: 'Cameroon',
    value: 'CM',
  },
  {
    image: 'cn',
    label: 'China',
    value: 'CN',
  },
  {
    image: 'co',
    label: 'Colombia',
    value: 'CO',
  },
  {
    image: 'cp',
    label: 'Clipperton Island',
    value: 'CP',
  },
  {
    image: 'cr',
    label: 'Costa Rica',
    value: 'CR',
  },
  {
    image: 'cu',
    label: 'Cuba',
    value: 'CU',
  },
  {
    image: 'cv',
    label: 'Cape Verde',
    value: 'CV',
  },
  {
    image: 'cw',
    label: 'Curaçao',
    value: 'CW',
  },
  {
    image: 'cx',
    label: 'Christmas Island',
    value: 'CX',
  },
  {
    image: 'cy',
    label: 'Cyprus',
    value: 'CY',
  },
  {
    image: 'cz',
    label: 'Czech Republic',
    value: 'CZ',
  },
  {
    image: 'de',
    label: 'Germany',
    value: 'DE',
  },
  {
    image: 'dg',
    label: 'Diego Garcia',
    value: 'DG',
  },
  {
    image: 'dj',
    label: 'Djibouti',
    value: 'DJ',
  },
  {
    image: 'dk',
    label: 'Denmark',
    value: 'DK',
  },
  {
    image: 'dm',
    label: 'Dominica',
    value: 'DM',
  },
  {
    image: 'do',
    label: 'Dominican Republic',
    value: 'DO',
  },
  {
    image: 'dz',
    label: 'Algeria',
    value: 'DZ',
  },
  {
    image: 'ea',
    label: 'Ceuta and Melilla',
    value: 'EA',
  },
  {
    image: 'ec',
    label: 'Ecuador',
    value: 'EC',
  },
  {
    image: 'ee',
    label: 'Estonia',
    value: 'EE',
  },
  {
    image: 'eg',
    label: 'Egypt',
    value: 'EG',
  },
  {
    image: 'eh',
    label: 'Western Sahara',
    value: 'EH',
  },
  {
    image: 'er',
    label: 'Eritrea',
    value: 'ER',
  },
  {
    image: 'es-ct',
    label: 'Catalonia',
    value: 'ES-CT',
  },
  {
    image: 'es-ga',
    label: 'Galicia',
    value: 'ES-GA',
  },
  {
    image: 'es',
    label: 'Spain',
    value: 'ES',
  },
  {
    image: 'et',
    label: 'Ethiopia',
    value: 'ET',
  },
  {
    image: 'eu',
    label: 'European Union',
    value: 'EU',
  },
  {
    image: 'fi',
    label: 'Finland',
    value: 'FI',
  },
  {
    image: 'fj',
    label: 'Fiji',
    value: 'FJ',
  },
  {
    image: 'fk',
    label: 'Falkland Islands',
    value: 'FK',
  },
  {
    image: 'fm',
    label: 'Micronesia',
    value: 'FM',
  },
  {
    image: 'fo',
    label: 'Faroe Islands',
    value: 'FO',
  },
  {
    image: 'fr',
    label: 'France',
    value: 'FR',
  },
  {
    image: 'ga',
    label: 'Gabon',
    value: 'GA',
  },
  {
    image: 'gb-eng',
    label: 'England',
    value: 'GB-ENG',
  },
  {
    image: 'gb-nir',
    label: 'Northern Ireland',
    value: 'GB-NIR',
  },
  {
    image: 'gb-sct',
    label: 'Scotland',
    value: 'GB-SCT',
  },
  {
    image: 'gb-wls',
    label: 'Wales',
    value: 'GB-WLS',
  },
  {
    image: 'gb',
    label: 'United Kingdom',
    value: 'GB',
  },
  {
    image: 'gd',
    label: 'Grenada',
    value: 'GD',
  },
  {
    image: 'ge',
    label: 'Georgia',
    value: 'GE',
  },
  {
    image: 'gf',
    label: 'French Guiana',
    value: 'GF',
  },
  {
    image: 'gg',
    label: 'Guernsey',
    value: 'GG',
  },
  {
    image: 'gh',
    label: 'Ghana',
    value: 'GH',
  },
  {
    image: 'gi',
    label: 'Gibraltar',
    value: 'GI',
  },
  {
    image: 'gl',
    label: 'Greenland',
    value: 'GL',
  },
  {
    image: 'gm',
    label: 'Gambia',
    value: 'GM',
  },
  {
    image: 'gn',
    label: 'Guinea',
    value: 'GN',
  },
  {
    image: 'gp',
    label: 'Guadeloupe',
    value: 'GP',
  },
  {
    image: 'gq',
    label: 'Equatorial Guinea',
    value: 'GQ',
  },
  {
    image: 'gr',
    label: 'Greece',
    value: 'GR',
  },
  {
    image: 'gs',
    label: 'South Georgia and the South Sandwich Islands',
    value: 'GS',
  },
  {
    image: 'gt',
    label: 'Guatemala',
    value: 'GT',
  },
  {
    image: 'gu',
    label: 'Guam',
    value: 'GU',
  },
  {
    image: 'gw',
    label: 'Guinea-Bissau',
    value: 'GW',
  },
  {
    image: 'gy',
    label: 'Guyana',
    value: 'GY',
  },
  {
    image: 'hk',
    label: 'Hong Kong',
    value: 'HK',
  },
  {
    image: 'hm',
    label: 'Heard Island and McDonald Islands',
    value: 'HM',
  },
  {
    image: 'hn',
    label: 'Honduras',
    value: 'HN',
  },
  {
    image: 'hr',
    label: 'Croatia',
    value: 'HR',
  },
  {
    image: 'ht',
    label: 'Haiti',
    value: 'HT',
  },
  {
    image: 'hu',
    label: 'Hungary',
    value: 'HU',
  },
  {
    image: 'ic',
    label: 'Canary Islands',
    value: 'IC',
  },
  {
    image: 'id',
    label: 'Indonesia',
    value: 'ID',
  },
  {
    image: 'ie',
    label: 'Ireland',
    value: 'IE',
  },
  {
    image: 'il',
    label: 'Israel',
    value: 'IL',
  },
  {
    image: 'im',
    label: 'Isle of Man',
    value: 'IM',
  },
  {
    image: 'in',
    label: 'India',
    value: 'IN',
  },
  {
    image: 'io',
    label: 'British Indian Ocean Territory',
    value: 'IO',
  },
  {
    image: 'iq',
    label: 'Iraq',
    value: 'IQ',
  },
  {
    image: 'ir',
    label: 'Iran',
    value: 'IR',
  },
  {
    image: 'is',
    label: 'Iceland',
    value: 'IS',
  },
  {
    image: 'it',
    label: 'Italy',
    value: 'IT',
  },
  {
    image: 'je',
    label: 'Jersey',
    value: 'JE',
  },
  {
    image: 'jm',
    label: 'Jamaica',
    value: 'JM',
  },
  {
    image: 'jo',
    label: 'Jordan',
    value: 'JO',
  },
  {
    image: 'jp',
    label: 'Japan',
    value: 'JP',
  },
  {
    image: 'ke',
    label: 'Kenya',
    value: 'KE',
  },
  {
    image: 'kg',
    label: 'Kyrgyzstan',
    value: 'KG',
  },
  {
    image: 'kh',
    label: 'Cambodia',
    value: 'KH',
  },
  {
    image: 'ki',
    label: 'Kiribati',
    value: 'KI',
  },
  {
    image: 'km',
    label: 'Comoros',
    value: 'KM',
  },
  {
    image: 'kn',
    label: 'Saint Kitts and Nevis',
    value: 'KN',
  },
  {
    image: 'kp',
    label: 'North Korea',
    value: 'KP',
  },
  {
    image: 'kr',
    label: 'South Korea',
    value: 'KR',
  },
  {
    image: 'kw',
    label: 'Kuwait',
    value: 'KW',
  },
  {
    image: 'ky',
    label: 'Cayman Islands',
    value: 'KY',
  },
  {
    image: 'kz',
    label: 'Kazakhstan',
    value: 'KZ',
  },
  {
    image: 'la',
    label: 'Laos',
    value: 'LA',
  },
  {
    image: 'lb',
    label: 'Lebanon',
    value: 'LB',
  },
  {
    image: 'lc',
    label: 'Saint Lucia',
    value: 'LC',
  },
  {
    image: 'li',
    label: 'Liechtenstein',
    value: 'LI',
  },
  {
    image: 'lk',
    label: 'Sri Lanka',
    value: 'LK',
  },
  {
    image: 'lr',
    label: 'Liberia',
    value: 'LR',
  },
  {
    image: 'ls',
    label: 'Lesotho',
    value: 'LS',
  },
  {
    image: 'lt',
    label: 'Lithuania',
    value: 'LT',
  },
  {
    image: 'lu',
    label: 'Luxembourg',
    value: 'LU',
  },
  {
    image: 'lv',
    label: 'Latvia',
    value: 'LV',
  },
  {
    image: 'ly',
    label: 'Libya',
    value: 'LY',
  },
  {
    image: 'ma',
    label: 'Morocco',
    value: 'MA',
  },
  {
    image: 'mc',
    label: 'Monaco',
    value: 'MC',
  },
  {
    image: 'md',
    label: 'Moldova',
    value: 'MD',
  },
  {
    image: 'me',
    label: 'Montenegro',
    value: 'ME',
  },
  {
    image: 'mf',
    label: 'Saint Martin (French Part)',
    value: 'MF',
  },
  {
    image: 'mg',
    label: 'Madagascar',
    value: 'MG',
  },
  {
    image: 'mh',
    label: 'Marshall Islands',
    value: 'MH',
  },
  {
    image: 'mk',
    label: 'North Macedonia',
    value: 'MK',
  },
  {
    image: 'ml',
    label: 'Mali',
    value: 'ML',
  },
  {
    image: 'mm',
    label: 'Myanmar',
    value: 'MM',
  },
  {
    image: 'mn',
    label: 'Mongolia',
    value: 'MN',
  },
  {
    image: 'mo',
    label: 'Macau',
    value: 'MO',
  },
  {
    image: 'mp',
    label: 'Northern Mariana Islands',
    value: 'MP',
  },
  {
    image: 'mq',
    label: 'Martinique',
    value: 'MQ',
  },
  {
    image: 'mr',
    label: 'Mauritania',
    value: 'MR',
  },
  {
    image: 'ms',
    label: 'Montserrat',
    value: 'MS',
  },
  {
    image: 'mt',
    label: 'Malta',
    value: 'MT',
  },
  {
    image: 'mu',
    label: 'Mauritius',
    value: 'MU',
  },
  {
    image: 'mv',
    label: 'Maldives',
    value: 'MV',
  },
  {
    image: 'mw',
    label: 'Malawi',
    value: 'MW',
  },
  {
    image: 'mx',
    label: 'Mexico',
    value: 'MX',
  },
  {
    image: 'my',
    label: 'Malaysia',
    value: 'MY',
  },
  {
    image: 'mz',
    label: 'Mozambique',
    value: 'MZ',
  },
  {
    image: 'na',
    label: 'Namibia',
    value: 'NA',
  },
  {
    image: 'nc',
    label: 'New Caledonia',
    value: 'NC',
  },
  {
    image: 'ne',
    label: 'Niger',
    value: 'NE',
  },
  {
    image: 'nf',
    label: 'Norfolk Island',
    value: 'NF',
  },
  {
    image: 'ng',
    label: 'Nigeria',
    value: 'NG',
  },
  {
    image: 'ni',
    label: 'Nicaragua',
    value: 'NI',
  },
  {
    image: 'nl',
    label: 'Netherlands',
    value: 'NL',
  },
  {
    image: 'no',
    label: 'Norway',
    value: 'NO',
  },
  {
    image: 'np',
    label: 'Nepal',
    value: 'NP',
  },
  {
    image: 'nr',
    label: 'Nauru',
    value: 'NR',
  },
  {
    image: 'nu',
    label: 'Niue',
    value: 'NU',
  },
  {
    image: 'nz',
    label: 'New Zealand',
    value: 'NZ',
  },
  {
    image: 'om',
    label: 'Oman',
    value: 'OM',
  },
  {
    image: 'pa',
    label: 'Panama',
    value: 'PA',
  },
  {
    image: 'pe',
    label: 'Peru',
    value: 'PE',
  },
  {
    image: 'pf',
    label: 'French Polynesia',
    value: 'PF',
  },
  {
    image: 'pg',
    label: 'Papua New Guinea',
    value: 'PG',
  },
  {
    image: 'ph',
    label: 'Philippines',
    value: 'PH',
  },
  {
    image: 'pk',
    label: 'Pakistan',
    value: 'PK',
  },
  {
    image: 'pl',
    label: 'Poland',
    value: 'PL',
  },
  {
    image: 'pm',
    label: 'Saint Pierre and Miquelon',
    value: 'PM',
  },
  {
    image: 'pn',
    label: 'Pitcairn Islands',
    value: 'PN',
  },
  {
    image: 'pr',
    label: 'Puerto Rico',
    value: 'PR',
  },
  {
    image: 'ps',
    label: 'Palestine',
    value: 'PS',
  },
  {
    image: 'pt',
    label: 'Portugal',
    value: 'PT',
  },
  {
    image: 'pw',
    label: 'Palau',
    value: 'PW',
  },
  {
    image: 'py',
    label: 'Paraguay',
    value: 'PY',
  },
  {
    image: 'qa',
    label: 'Qatar',
    value: 'QA',
  },
  {
    image: 're',
    label: 'Réunion',
    value: 'RE',
  },
  {
    image: 'ro',
    label: 'Romania',
    value: 'RO',
  },
  {
    image: 'rs',
    label: 'Serbia',
    value: 'RS',
  },
  {
    image: 'ru',
    label: 'Russia',
    value: 'RU',
  },
  {
    image: 'rw',
    label: 'Rwanda',
    value: 'RW',
  },
  {
    image: 'sa',
    label: 'Saudi Arabia',
    value: 'SA',
  },
  {
    image: 'sb',
    label: 'Solomon Islands',
    value: 'SB',
  },
  {
    image: 'sc',
    label: 'Seychelles',
    value: 'SC',
  },
  {
    image: 'sd',
    label: 'Sudan',
    value: 'SD',
  },
  {
    image: 'se',
    label: 'Sweden',
    value: 'SE',
  },
  {
    image: 'sg',
    label: 'Singapore',
    value: 'SG',
  },
  {
    image: 'sh',
    label: 'Saint Helena, Ascension and Tristan da Cunha',
    value: 'SH',
  },
  {
    image: 'si',
    label: 'Slovenia',
    value: 'SI',
  },
  {
    image: 'sj',
    label: 'Svalbard and Jan Mayen',
    value: 'SJ',
  },
  {
    image: 'sk',
    label: 'Slovakia',
    value: 'SK',
  },
  {
    image: 'sl',
    label: 'Sierra Leone',
    value: 'SL',
  },
  {
    image: 'sm',
    label: 'San Marino',
    value: 'SM',
  },
  {
    image: 'sn',
    label: 'Senegal',
    value: 'SN',
  },
  {
    image: 'so',
    label: 'Somalia',
    value: 'SO',
  },
  {
    image: 'sr',
    label: 'Suriname',
    value: 'SR',
  },
  {
    image: 'ss',
    label: 'South Sudan',
    value: 'SS',
  },
  {
    image: 'st',
    label: 'São Tomé and Príncipe',
    value: 'ST',
  },
  {
    image: 'sv',
    label: 'El Salvador',
    value: 'SV',
  },
  {
    image: 'sx',
    label: 'Sint Maarten (Dutch Part)',
    value: 'SX',
  },
  {
    image: 'sy',
    label: 'Syria',
    value: 'SY',
  },
  {
    image: 'sz',
    label: 'Eswatini',
    value: 'SZ',
  },
  {
    image: 'ta',
    label: 'Tristan da Cunha',
    value: 'TA',
  },
  {
    image: 'tc',
    label: 'Turks and Caicos Islands',
    value: 'TC',
  },
  {
    image: 'td',
    label: 'Chad',
    value: 'TD',
  },
  {
    image: 'tf',
    label: 'French Southern and Antarctic Lands',
    value: 'TF',
  },
  {
    image: 'tg',
    label: 'Togo',
    value: 'TG',
  },
  {
    image: 'th',
    label: 'Thailand',
    value: 'TH',
  },
  {
    image: 'tj',
    label: 'Tajikistan',
    value: 'TJ',
  },
  {
    image: 'tk',
    label: 'Tokelau',
    value: 'TK',
  },
  {
    image: 'tl',
    label: 'Timor-Leste',
    value: 'TL',
  },
  {
    image: 'tm',
    label: 'Turkmenistan',
    value: 'TM',
  },
  {
    image: 'tn',
    label: 'Tunisia',
    value: 'TN',
  },
  {
    image: 'to',
    label: 'Tonga',
    value: 'TO',
  },
  {
    image: 'tr',
    label: 'Turkey',
    value: 'TR',
  },
  {
    image: 'tt',
    label: 'Trinidad and Tobago',
    value: 'TT',
  },
  {
    image: 'tv',
    label: 'Tuvalu',
    value: 'TV',
  },
  {
    image: 'tw',
    label: 'Taiwan',
    value: 'TW',
  },
  {
    image: 'tz',
    label: 'Tanzania',
    value: 'TZ',
  },
  {
    image: 'ua',
    label: 'Ukraine',
    value: 'UA',
  },
  {
    image: 'ug',
    label: 'Uganda',
    value: 'UG',
  },
  {
    image: 'um',
    label: 'United States Minor Outlying Islands',
    value: 'UM',
  },
  {
    image: 'un',
    label: 'United Nations',
    value: 'UN',
  },
  {
    image: 'us',
    label: 'United States',
    value: 'US',
  },
  {
    image: 'uy',
    label: 'Uruguay',
    value: 'UY',
  },
  {
    image: 'uz',
    label: 'Uzbekistan',
    value: 'UZ',
  },
  {
    image: 'va',
    label: 'Vatican City',
    value: 'VA',
  },
  {
    image: 'vc',
    label: 'Saint Vincent and the Grenadines',
    value: 'VC',
  },
  {
    image: 've',
    label: 'Venezuela',
    value: 'VE',
  },
  {
    image: 'vg',
    label: 'British Virgin Islands',
    value: 'VG',
  },
  {
    image: 'vi',
    label: 'U.S. Virgin Islands',
    value: 'VI',
  },
  {
    image: 'vn',
    label: 'Vietnam',
    value: 'VN',
  },
  {
    image: 'vu',
    label: 'Vanuatu',
    value: 'VU',
  },
  {
    image: 'wf',
    label: 'Wallis and Futuna',
    value: 'WF',
  },
  {
    image: 'ws',
    label: 'Samoa',
    value: 'WS',
  },
  {
    image: 'xk',
    label: 'Kosovo',
    value: 'XK',
  },
  {
    image: 'xx',
    label: 'Unknown or Invalid Region',
    value: 'XX',
  },
  {
    image: 'ye',
    label: 'Yemen',
    value: 'YE',
  },
  {
    image: 'yt',
    label: 'Mayotte',
    value: 'YT',
  },
  {
    image: 'za',
    label: 'South Africa',
    value: 'ZA',
  },
  {
    image: 'zm',
    label: 'Zambia',
    value: 'ZM',
  },
  {
    image: 'zw',
    label: 'Zimbabwe',
    value: 'ZW',
  },
]
