export const companySizeData = [
  {
    id: 1,
    value: 'Small Business',
    label: 'Small Business',
  },
  {
    id: 2,
    value: 'Medium-sized Business',
    label: 'Medium-sized Business',
  },
  {
    id: 3,
    value: 'Enterprise',
    label: 'Enterprise',
  },
]
