import React, { useState } from 'react'
import { TextWrapper } from '@/pages/dashboard/listings/tab/styled'
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import AddOutline from '@mui/icons-material/AddOutlined'
import { ButtonStyled } from '@/components/ui/button/styled'
import { Input, Label } from '@/components/ui/input/styled'
import { validate } from 'email-validator'
import { CharactersMessage } from '@/components/forms/create-product/input/styled'

interface EmailListProps {
  placeholder?: string
  emails: string[]
  addItem: (item: string) => void
  removeItem: (item: string) => void
}

const EmailList: React.FC<EmailListProps> = ({
  emails,
  addItem,
  removeItem,
  placeholder = 'type an email. eg: info@guerrillamail.de',
}) => {
  // @TODO: Add validation
  const [newItem, setNewItem] = useState('')
  const [error, setError] = useState('')

  const add = () => {
    if (validate(newItem)) {
      addItem(newItem)
      setNewItem('')
    } else {
      setError('Invalid Email. eg: info@guerrillamail.de')
    }
  }

  return (
    <>
      <Label style={{ margin: 0 }}>Company Email</Label>
      <Box display={'flex'} flexDirection={'row'}>
        <Input
          value={newItem}
          onChange={(e) => setNewItem(e.target.value.trim().toLowerCase())}
          style={{ width: '75%' }}
          placeholder={placeholder}
        />
        <ButtonStyled
          type="button"
          onClick={add}
          width="unset"
          marginLeft={'1.5rem'}
        >
          <AddOutline fontSize="inherit" sx={{ color: '#FFF' }} />
        </ButtonStyled>
      </Box>
      {error && (
        <Box>
          <CharactersMessage>
            <span>{error}</span>
          </CharactersMessage>
        </Box>
      )}
      {emails.length > 0 && (
        <TableContainer component={Paper} style={{ marginBottom: '60px' }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell style={{ width: '15%' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {emails.map((item: string, index) => (
                <TableRow
                  key={item + '_' + index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <TextWrapper>{item}</TextWrapper>
                  </TableCell>
                  <TableCell valign="top">
                    <Tooltip placement="top" title="Remove this item">
                      <IconButton
                        size="small"
                        onClick={() => {
                          removeItem(item)
                        }}
                      >
                        <DeleteOutlineIcon
                          fontSize="inherit"
                          sx={{ color: '#000000' }}
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}

export default EmailList
