import styled from 'styled-components'
import { InputBlockProps, WrapperBlockProps } from './types'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'

export const Wrapper = styled.div<WrapperBlockProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom : '15px'};
  ${({ height }) => height && `min-height:${height};`}
`

export const Label = styled.label`
  font-size: 16px;
  line-height: 23px;
  font-weight: 400;
  margin-bottom: 7px;
  color: #000000b2;
`

export const EyeToggle = styled.span`
  position: absolute;
  top: 50px;
  right: 6px;
`

export const EyeIcon = styled(VisibilityOutlinedIcon)``
export const EyeIconOff = styled(VisibilityOffOutlinedIcon)``

export const Input = styled.input<InputBlockProps>`
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  color: #000000b2;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  padding: ${({ padding }) => (padding ? padding : '16px')};
  ${({ height }) => height && `height:${height};`}

  &:focus {
    border: 1px solid #000000;
  }
  &:focus-visible {
    border: 1px solid #000000;
    outline: 1px solid #000000;
  }
`
export const Error = styled.p`
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
  color: #ff0000;
  position: absolute;
  bottom: -18px;
`

export const InputTextArea = styled.textarea<InputBlockProps>`
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  color: #000000b2;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  width: 100% !important;
  padding: ${({ padding }) => (padding ? padding : '16px')};
  ${({ height }) => height && `height:${height};`}
  ${({ maxHeight }) => maxHeight && `max-height:${maxHeight};`}

  &:focus {
    border: 1px solid #000000;
  }
  &:focus-visible {
    border: 1px solid #000000;
    outline: 1px solid #000000;
  }
`
