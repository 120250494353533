import { useState } from 'react'
import PasswordStrengthBar from 'react-password-strength-bar'
import {
  Input,
  Label,
  Wrapper,
  Error,
  EyeIconOff,
  EyeIcon,
  EyeToggle,
  InputTextArea,
} from './styled'
import { InputBlockProps } from './types'

const InputBlock = ({
  children,
  padding,
  label,
  type,
  errorMessage,
  params,
  marginBottom,
  isMail,
  isPassword,
  onChange,
  showStrenghtBar,
  height,
  placeHolder,
  readOnly,
  clearValue,
  isTextArea,
  maxHeight,
}: InputBlockProps) => {
  const [value, setValue] = useState('')
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const togglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword)
  }

  return (
    <>
      {!isTextArea ? (
        <Wrapper marginBottom={marginBottom} height={height}>
          {label && <Label>{label}</Label>}
          <Input
            {...params}
            type={isPassword ? (showPassword ? 'text' : 'password') : type}
            isMail={isMail}
            isPassword={isPassword}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (onChange) {
                onChange(event)
              }
              setValue(event.target?.value)
            }}
            padding={padding}
            height={height}
            placeholder={placeHolder}
            readOnly={readOnly}
            value={clearValue}
          />
          {isPassword && (
            <EyeToggle className="password__show" onClick={togglePassword}>
              {showPassword ? <EyeIconOff /> : <EyeIcon />}
            </EyeToggle>
          )}
          {children}
          {!isMail && showStrenghtBar && (
            <PasswordStrengthBar password={value} />
          )}
          {errorMessage && <Error>{errorMessage}</Error>}
        </Wrapper>
      ) : (
        <Wrapper marginBottom={marginBottom} height={height}>
          {label && <Label>{label}</Label>}
          <InputTextArea
            {...params}
            type={isPassword ? (showPassword ? 'text' : 'password') : type}
            isMail={isMail}
            isPassword={isPassword}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (onChange) {
                onChange(event)
              }
              setValue(event.target?.value)
            }}
            padding={padding}
            height={height}
            placeholder={placeHolder}
            readOnly={readOnly}
            value={clearValue}
            maxHeight={maxHeight}
          />
          {isPassword && (
            <EyeToggle className="password__show" onClick={togglePassword}>
              {showPassword ? <EyeIconOff /> : <EyeIcon />}
            </EyeToggle>
          )}
          {children}
          {!isMail && showStrenghtBar && (
            <PasswordStrengthBar password={value} />
          )}
          {errorMessage && <Error>{errorMessage}</Error>}
        </Wrapper>
      )}
    </>
  )
}

export default InputBlock
