export const HeaderMock = {
  title:
    'SafeGraph | Alternative Data | Foot Traffic for Grocery Stores in the US',
  companyName: 'SafeGraph',
  companyImg: 'https://via.placeholder.com/135x135',
  isVerifiedProvider: true,
  rating: 4.9,
  scores: [
    { name: 'Uniqueness', value: 4.9 },
    { name: 'Recency', value: 4.9 },
    { name: 'Consistency', value: 4.5 },
    { name: 'Availability', value: 4.1 },
    { name: 'Completeness', value: 3 },
  ],
  description:
    'Diam et sit diam enim. Tortor proin velit et semper sagittis. Nullam turpis amet, aliquam vel a integer in bibendum malesuada. Ullamcorper posuere dignissim fermentum amet diam, dictum. Ut nisi donec a, velit. ullam turpis amet, aliquam vel a integer in bibendum malesuada. Ullamcorper posuere dignissim fermentum amet diam, dictum. Ut nisi donec a, velit. ',
}
