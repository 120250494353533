import styled from 'styled-components'

export const DragDropZone = styled.div`
  border: dashed black 2px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 2rem;
  background: white;
  gap: 5px;
`

export const LabelChooseFile = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  background-color: black;
  color: white;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.02em;
  padding: 0.5rem 1.4rem;
`
