import RadioButton from '@/components/ui/radio-button'
import { Grid } from '@mui/material'
import * as React from 'react'
import { ContactIconsWrapper, ContactIconTitle, StyledSVGIcon } from '../styled'
import { IIconCheckProps } from './types'

const IconCheck: React.FunctionComponent<IIconCheckProps> = ({
  selectedContact,
  contactName,
  icon,
  onClick: onClickProps,
}) => {
  const onClick = () => {
    onClickProps && onClickProps(contactName)
  }
  return (
    <Grid item xs={4} onClick={onClick}>
      <ContactIconsWrapper isSelected={selectedContact === contactName}>
        <ContactIconTitle isSelected={selectedContact === contactName}>
          {contactName}
        </ContactIconTitle>
        <StyledSVGIcon
          src={icon}
          preProcessor={(code) =>
            code.replace(/fill=".*?"/g, 'fill="currentColor"')
          }
          color={selectedContact === contactName ? '#000000' : '#8C8C8C'}
        />
        <RadioButton
          width="14px"
          heigth="14px"
          alignSelf="flex-end"
          type="radio"
          value={contactName}
          name="option"
          margin="0 0 auto"
          readOnly
          checked={selectedContact === contactName}
        />
      </ContactIconsWrapper>
    </Grid>
  )
}

export default IconCheck
