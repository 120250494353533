import { useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Container, AppBarHeader } from '@components'
import Flag from '@assets/images/flags/us'
import storage from '@/utils/storage'
import Select from '@/components/forms/create-product/select'
import Arrow from '@assets/images/icons/arrow-left'
import Plus from '@assets/images/icons/plus-square'
import { useAppSelector } from '@/app/hooks'
import {
  Header,
  HeaderColLeft,
  Button,
  Wrapper,
  CardsCols,
  CardsContainer,
  CardsColLeft,
  CardsColRight,
  CardsColLeftTitle,
  CompanyInfo,
  HeadquarterLabel,
  CardsColLeftText,
  ClientMessage,
  CardsColRightTitle,
  Input,
  Commission,
  Terms,
  TextLink,
  CTAWrapper,
  Subtitle,
  Divider,
  Title,
  Line,
  TransparentButton,
  DeliveryText,
} from './styled'
import { Client } from '@twilio/conversations'
import { getTwilioClient } from '@/utils/twilio'
import { axios } from '@/lib/axios'

const ProposeProduct = () => {
  const navigate = useNavigate()
  const [isDisabled, setIsDisabled] = useState(true)
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [selectedListingId, setSelectedListingId] = useState('')
  const [selectedListingTitle, setSelectedListingTitle] = useState('')
  const client = useRef<Client | undefined>()
  const postingAuthor = useRef<any | undefined>()
  const conversationSid = useRef<string | undefined>()
  const location = useLocation()
  const baseUrl = process.env.REACT_APP_URL_BASE
  const supportEmail = process.env.SUPPORT_EMAIL
  const token = storage.getToken()
  var url = new URL(window.location.href)
  const userId = useAppSelector((state) => state.authUser.userId)
  const userMail = useAppSelector((state) => state.authUser.userMail)
  const sellerCompanyId = useAppSelector((state) => state.authUser.companyId)

  useEffect(() => {
    const effect = async () => {
      setLoading(true)
      if (!!token && !!userId) {
        getListingsByCompany()

        const newClient = await getTwilioClient(userId)
        client.current = newClient

        const userData = await getUserById(location.state.userId)
        postingAuthor.current = userData.data.username

        setLoading(false)
      }
    }
    effect()
  }, [selectedListingId])

  const getListingsByCompany = () => {
    const res = axios
      .get(`${baseUrl}/api/v1/ccompanies/${sellerCompanyId}/listings`)
      .then(function (response: any) {
        setOptions(response.data)
      })
      .catch(function (error: any) {
        console.log(error)
      })
    return res
  }

  const getUserById = (id) => {
    const res = axios
      .get(`${baseUrl}/api/v1/users/${id}`)
      .then(function (response: any) {
        return response
      })
      .catch(function (error: any) {
        console.log(error)
      })
    return res
  }

  const createTwilioConversation = () => {
    if (token && userId) {
      const res = axios
        .post(`${baseUrl}/api/v1/proposals`, {
          postingId: location.state.id,
          userId: userId,
          authorId: location.state.userId,
          listingId: selectedListingId,
          message: message,
        })
        .then(function (response: any) {
          return response
        })
        .catch(function (error: any) {
          console.log(error)
        })
      return res
    }
  }

  const sendMessage = async () => {
    if (selectedListingId && message) {
      createTwilioConversation()
        .then((data) => {
          conversationSid.current = data.data.proposal.twilioConversationId
        })
        .then(() => {
          let sidConversation = client.current
            .getConversationBySid(conversationSid.current)
            .then((data: any) => {
              if (message && String(message).trim()) {
                setIsDisabled(true)
                data.sendMessage(message).then(() => {
                  setMessage('')
                  setIsDisabled(false)
                  sendPostProposalEmailToKamba()
                  navigate(`/lead-pool`)
                })
              } else {
                setMessage('')
                console.log('error')
              }
            })
        })
    }
  }

  const setSelectedListing = (listing: any) => {
    setSelectedListingId(listing.id)
    setSelectedListingTitle(listing.label)
  }

  const sendPostProposalEmailToKamba = () => {
    const res = axios
      .post(`${baseUrl}/api/v1/send-email-no-template`, {
        from: supportEmail,
        to: supportEmail,
        subject: 'New Proposed Product',
        content: `The user ${userMail} has answered the ${location.state.title} and proposed the product ${selectedListingTitle} `,
      })
      .then(function (response: any) {
        return response
      })
      .catch(function (error: any) {
        console.log(error)
      })
    return res
  }

  const handleOnChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value
    setMessage(value)
    setIsDisabled(!(value?.length > 0))
  }

  let selectOptions = options.map((value: any) => ({
    id: value.id,
    value: value.title,
    label: value.title,
  }))

  return (
    <>
      <AppBarHeader />
      <Container backgroundColor="#f6f6f6">
        <Wrapper>
          <CardsContainer>
            <CardsCols>
              <div>
                <Header>
                  <img
                    src={Arrow}
                    alt="arrow"
                    style={{ width: '26px', height: '26px', cursor: 'pointer' }}
                    onClick={() => navigate('/lead-pool')}
                  />
                  <HeaderColLeft>Back to Lead Pool</HeaderColLeft>
                </Header>
                <CardsColLeft>
                  <Subtitle>{location.state.title}</Subtitle>
                  {/*<CardsColLeftTitle>Company</CardsColLeftTitle>
                  <CompanyInfo>{postingAuthor.current}</CompanyInfo>*/}
                  <CardsColLeftTitle>Countries</CardsColLeftTitle>
                  {location &&
                    location.state.geo.map((country: any) => (
                      <HeadquarterLabel>
                        {/*<img src={Flag} alt="Flag" style={{ width: '16px' }} />*/}
                        {country}
                      </HeadquarterLabel>
                    ))}
                  <CardsColLeftTitle>Budget</CardsColLeftTitle>
                  <CardsColLeftText>
                    ${location.state.budgetHigh}
                  </CardsColLeftText>
                  <CardsColLeftTitle>Delivery Type</CardsColLeftTitle>
                  <CardsColLeftText>
                    <DeliveryText>
                      {location.state.delivery.replaceAll('-', ' ')}
                    </DeliveryText>
                  </CardsColLeftText>
                  <CardsColLeftTitle>Client Message</CardsColLeftTitle>
                  <ClientMessage>{location.state.useCase}</ClientMessage>
                </CardsColLeft>
              </div>
              <CardsColRight>
                <Divider />
                <Title>Propose a Product</Title>
                <Line />
                <CardsColRightTitle>
                  Select Data Product or{' '}
                  <span
                    onClick={() =>
                      navigate('/product-create-listing', {
                        state: { url: `/lead-pool` },
                      })
                    }
                  >
                    Create a New Product{' '}
                  </span>
                  <img
                    src={Plus}
                    alt="plus"
                    style={{
                      cursor: 'pointer',
                      marginLeft: '8px',
                      marginTop: '-100px',
                    }}
                    onClick={() => navigate('/product-create-listing')}
                  />
                </CardsColRightTitle>
                <div style={{ marginBottom: '30px' }}>
                  <Select
                    placeholder="Select one of your data products"
                    options={selectOptions}
                    onChange={(e: any) => setSelectedListing(e)}
                  />
                </div>
                <CardsColRightTitle>Your Message</CardsColRightTitle>
                <Input type="text" onChange={handleOnChangeInput} />
                {/*<Commission>
                  Commission Fee Percentage:<span> 15.00%</span>
                </Commission>
                <Terms>
                  By submitting this proposal, you agree to pay Kamba all
                  commission fees upon successfully closing a deal with this
                  posting. Commission fees are applicable to all revenues
                  generated in the first 24 months.
                  </Terms>*/}
                {/*<Terms>
                  You can revisit the applicable terms{' '}
                  <TextLink
                    to="/terms-and-conditions"
                    rel="noopener noreferrer"
                  >
                    Here
                  </TextLink>
                </Terms>*/}
                <CTAWrapper>
                  <TransparentButton onClick={() => navigate('/')}>
                    Cancel
                  </TransparentButton>
                  <Button onClick={sendMessage} disabled={isDisabled}>
                    Submit Proposal
                  </Button>
                </CTAWrapper>
              </CardsColRight>
            </CardsCols>
          </CardsContainer>
        </Wrapper>
      </Container>
    </>
  )
}

export default ProposeProduct
