import MainLayout from '@/components/layout'
import { DashboardProvider } from '@/providers/dashboard'
import { TwilioProvider } from '@/providers/twilio'
import { Outlet } from 'react-router-dom'

const Dashboard = () => {
  // Make sure we initiliazite Twilio Client.
  return (
    <DashboardProvider>
      <MainLayout>
        <TwilioProvider>
          <Outlet />
        </TwilioProvider>
      </MainLayout>
    </DashboardProvider>
  )
}

export default Dashboard
