import * as React from 'react'
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid'
import { GridContainer } from './styled'
import { IGridContainer, IGridPreviewProps } from './types'

const DataGrid: React.FC<IGridPreviewProps & IGridContainer> = ({
  height,
  margin,
  rows,
  columns,
}) => {
  return (
    <GridContainer height={height} margin={margin}>
      <MuiDataGrid rows={rows} columns={columns} />
    </GridContainer>
  )
}

export default DataGrid
