import { useAppSelector } from '@/app/hooks'
import { getTwilioClient } from '@/utils/twilio'
import { Client } from '@twilio/conversations'
import { useCallback, useEffect } from 'react'
import { useTwilioContext } from './useDashboardContext'

const useTwilioClient = (): Client | undefined => {
  const userId = useAppSelector((state) => state.authUser.userId)
  const { client, setClient } = useTwilioContext()

  const init = useCallback(() => {
    const setClientFn = async (userId: string) => {
      const c = await getTwilioClient(userId)
      setClient(c)
    }
    if (userId && !client) setClientFn(userId)
  }, [userId, client])

  useEffect(() => {
    init()
  }, [])

  return client
}

export default useTwilioClient
