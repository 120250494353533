import { configureStore } from '@reduxjs/toolkit'
import loginReducer from '../slices/loginSlice'
import authUserReducer from '../slices/authUserSlice'

export const store = configureStore({
  reducer: {
    login: loginReducer,
    authUser: authUserReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
