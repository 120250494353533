import styled from 'styled-components'

export const KeyDiffTitle = styled.label`
  font-weight: 400;
  font-size: 22px;
  color: #000000;
`

export const KeyDiffText = styled.p`
  font-weight: 400;
  font-size: 16px;
  color: #000000;
`
