import styled from 'styled-components'
import {
  CheckboxLabelProps,
  CheckboxProps,
  CheckboxPositionProps,
} from './types'
import check from '@assets/images/checkbox'

export const CheckWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

export const CheckboxRow = styled.label<CheckboxPositionProps>`
  display: flex;
  cursor: pointer;
  padding: 7px 0;
  align-items: center;
  color: white;
  span {
    content: '';
    height: 22px;
    width: 22px;
    display: block;
    flex-shrink: 0;
    margin-right: 12px;
    border: 1px solid #d9d9d9;
    background-size: 50%;
    background: #ffffff;
    ${({ topPosition }) =>
      topPosition && `position:relative; top:${topPosition};`};
  }
`

export const CheckboxInput = styled.input<CheckboxProps>`
  &:not(:checked),
  &:checked {
    position: absolute;
    left: -9999px;
  }
  &:checked ~ span {
    background-image: url(${check});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 30px 30px;
    background-color: #ffffff;
    border: 1px solid #000000;
  }
`
export const CheckboxLabel = styled.label<CheckboxLabelProps>`
  font-size: 16px;
  line-height: 23px;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : '400')};
  color: ${({ fontColor }) => (fontColor ? fontColor : '#000000b2')};
  display: flex;
  width: 100%;
`

export const CheckboxImage = styled.img.attrs({
  alt: 'flag image',
})`
  margin-right: 12px;
  max-width: 24px;
`
