import { useState, useEffect, useRef, useMemo } from 'react'

import {
  Card,
  CardAvatar,
  CardColumn,
  CardTitle,
  CardSubTitle,
  CardSubText,
  CardRow,
  CardDate,
  // CardNumber,
  CardSelectedVerticalBar,
  CardNewMessages,
  CardBold,
} from './styled'
import { ICard } from './types'
import moment from 'moment'
import {
  Conversation,
  Message,
  Paginator,
  // Participant,
} from '@twilio/conversations'
import { useAppSelector } from '@/app/hooks'
import useTwilioClient from '@/providers/twilio/hooks/useTwilioClient'
// import { useDashboardContext } from '@/providers/dashboard/hooks/useDashboardContext'
// import { getTwilioClient } from '@/utils/twilio'
import { capitalize } from '@mui/material'

const ContactCard = ({
  onClick,
  isActive,
  conversation,
  firstConvo,
  selectedConvo = false,
}: ICard) => {
  const listener = useRef<boolean | null>(null)
  const [loading, setLoading] = useState(true)
  const { userMail, userRole } = useAppSelector((state) => state.authUser)
  const [tconvo, setTconvo] = useState<Conversation>()
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0)
  const [messages, setMessages] = useState<Paginator<Message>>()
  const client = useTwilioClient()

  const getConversation = async () => {
    const c = await client?.getConversationBySid(
      conversation.twilioConversationId,
    )
    setTconvo(c)
    return c
  }

  const getConversationData = async (convo: Conversation) => {
    const unreadMessages = await convo.getUnreadMessagesCount()
    const msgCount = await convo.getMessagesCount()
    const msgs = await convo.getMessages(1)

    // console.info(unreadMessages, msgCount)

    if (unreadMessages === null) {
      await convo.updateLastReadMessageIndex(null)
      // unreadMessages = 1
    }

    // sdasd
    setUnreadMessagesCount(unreadMessages ?? 1)
    setMessages(msgs)
  }

  useEffect(() => {
    const initialize = async () => {
      const c = await getConversation()
      if (c) {
        await getConversationData(c)
      }
      setLoading(false)
    }

    initialize()
  }, [])

  useEffect(() => {
    if (tconvo && !listener.current) {
      tconvo.on('updated', (x) => {
        getConversationData(tconvo)
      })
      listener.current = true
    }
  }, [tconvo, listener])
  // console.info(tconvo?.lastReadMessageIndex)

  const createAvatar = () => {
    let userName = conversation.userName || conversation.listing.company

    const avatar =
      userRole === 'seller' && conversation.anonymous
        ? 'AN'
        : userName.slice(0, 2)
    return avatar
  }

  const displayUsername = () => {
    const userName =
      userRole === 'seller' && conversation.anonymous
        ? 'Anonymous Buyer'
        : conversation.userName
    return userName
  }

  const truncate = (str: string, n: number) => {
    return str.length > n ? str.slice(0, n - 1) + '...' : str
  }

  const displayTitle = () => {
    // return conversation.listing.company + ' - ' + conversation.listing.title
    const about =
      conversation.listing?.title || conversation.listing?.company || ''

    const withSubject = conversation.userName ? displayUsername() : ''

    return (
      <>
        About <CardBold>{about}</CardBold>{' '}
        {withSubject && (
          <>
            with <CardBold>{withSubject}</CardBold>
          </>
        )}
      </>
    )
  }

  const displaySource = () => {
    return capitalize(conversation.source)
  }

  const click = () => {
    if (tconvo && onClick) {
      onClick(conversation.id, tconvo)
    }
  }

  const lastMessage = messages?.items[0]

  return (
    <>
      {!loading && (
        <Card onClick={click} active={isActive}>
          <CardSelectedVerticalBar active={isActive} />
          {unreadMessagesCount > 0 && (
            // <CardNewMessages>{unreadMessagesCount}</CardNewMessages>
            <CardNewMessages />
          )}
          <CardAvatar>{createAvatar()}</CardAvatar>
          <CardColumn>
            <CardRow>
              <CardTitle>{displaySource()}</CardTitle>
              <CardDate>
                {moment(
                  new Date(
                    tconvo?.lastMessage?.dateCreated || conversation.createdAt,
                  ),
                ).format('MMM Do | h:mm A')}
              </CardDate>
            </CardRow>
            <CardSubTitle>{displayTitle()}</CardSubTitle>
            <CardRow>
              {lastMessage && <CardSubText>{lastMessage.body}</CardSubText>}
              {!lastMessage && (
                <CardSubText>
                  {moment(conversation.createdAt).format('MMM Do')}
                </CardSubText>
              )}
              {/* {conversation?.lastReadMessageIndex && (
                <CardNumber>{conversation?.lastReadMessageIndex}</CardNumber>
              )} */}
            </CardRow>
          </CardColumn>
        </Card>
      )}
    </>
  )
}

export default ContactCard
