import * as React from 'react'
import { Button, GradientSeparator, Title } from '@/components'
import { Grid } from '@mui/material'
import { ILegalProps } from './types'
import { LegalText } from './styled'
import { legalMock } from '@/mocks/product/legal'
import withScrollToElement from '@/hocs/withScrollToElement'
import WithVisibility from '@/hocs/withVisibility'

const LegalAndCompliance: React.FC<ILegalProps> = ({ legalDocs }) => {
  const { legalText } = legalMock

  const handleOnClick = (url: string) => () => {
    window.open(url, '_blank')
  }

  return (
    <Grid container spacing={1}>
      <Title margin="15px 0" boldText="Legal And Compliance" fontSize="30px" />
      {legalText && (
        <Grid container spacing={2}>
          <Grid item xs width={'fit-content'} minWidth={'250px'}>
            <LegalText>{legalText}</LegalText>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2} sx={{ marginTop: '5px', paddingLeft: '0' }}>
        {legalDocs.map((cta) => (
          <Grid item xs key={cta.name} width={'fit-content'} minWidth={'250px'}>
            <Button variant="secondary" onClick={handleOnClick(cta.url)}>
              {cta.name}
            </Button>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12}>
        <GradientSeparator margin="30px 0" />
      </Grid>
    </Grid>
  )
}

export default WithVisibility(withScrollToElement(LegalAndCompliance, 'legal'))
