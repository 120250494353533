import styled from 'styled-components'
import { StepParams } from './types'

export const Wrapper = styled.div`
  width: 100%;
  max-width: 210px;
`

export const Breadcumb = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  color: #a7a7a7;
`

export const Title = styled.h2`
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.03em;
  color: #ffffff;
  margin-bottom: 65px;
`

export const Step = styled.div<StepParams>`
  width: 100%;
  display: flex;
  gap: 20px;
  position: relative;
  margin-bottom: 45px;
  opacity: ${({ active }) => (active ? '1' : '0.3')};
`

export const StepNumber = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 17px;
  gap: 10px;
  max-width: 53px;
  width: 100%;
  height: 53px;
  background: #ffffff;
  font-weight: 700;
  font-size: 28px;
  line-height: 128.8%;
  letter-spacing: -0.015em;
  color: #000000;
  z-index: 1;
`

export const StepTitle = styled.div`
  font-weight: 700;
  font-size: 17px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #ffffff;
  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.04em;
    color: rgba(255, 255, 255, 0.8);
  }
`

export const StepLine = styled.div<StepParams>`
  width: 2px;
  background-color: #ffffff;
  height: 48px;
  position: absolute;
  left: 26.5px;
  bottom: -45px;
  opacity: ${({ active }) => (active ? '1' : '0.3')};
`

export const Progress = styled.div`
  font-weight: 700;
  font-size: 21px;
  line-height: 26px;
  letter-spacing: -0.02em;
  color: #ffffff;
  margin-top: 100px;
`

export const ProgressBar = styled.div`
  margin-top: 13px;
  width: 100%;
  height: 12px;
  background-color: #4c4c4c;
`

export const ProgressBarFill = styled.div<StepParams>`
  width: ${({ width }) => width};
  height: 12px;
  background-color: #ffffff;
`
