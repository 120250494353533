import uploadFile from '../uploadFile'
const generalBucket = process.env.BUCKET_AWS

export const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
    // [{ size: ['small', 'normal', 'large', 'huge'] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image'],
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    ['clean'],
  ],
  fileUploader: {
    accept: [{ image: ['jpeg', 'png', 'gif'] }],
    upload: async (file: File) => {
      const setError = (b: boolean) => {
        if (b) {
          alert('Something went wrong.')
        }
      }
      const uuid = await uploadFile(file!, file?.type!, setError)
      const uuidClean = uuid.split('?')[0]

      return `https://${generalBucket}.s3.amazonaws.com/${uuidClean}`
    },
  },
}

export const modules2 = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['blockquote', 'code-block'],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
    [{ direction: 'rtl' }], // text direction
    [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],
    ['clean'], // remove formatting button
  ],
}

export const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'code-block',
  // 'size',
  'list',
  // 'bullet',
  // 'indent',
  'link',
  'image',
  'color',
  'background',
]

export const formats2 = [
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'code-block',
  'header',
  'list',
  'script',
  'indent',
  'direction',
  'size',
  'header',
  'color',
  'background',
  'font',
  'align',
]
