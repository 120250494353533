import styled from 'styled-components'

export const TooltipTitle = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 139.8%;
  color: #000000;
  margin-bottom: 22px;
`

export const FontSemiBold = styled.span`
  font-weight: 600;
`

export const TooltipList = styled.ul`
  padding-inline-start: 24px;
`
