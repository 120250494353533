import React from 'react'
import Dialog from './dialog'
import { ContainerVisibility, VisibilityBlur } from './styled'
import { IWithVisibilty } from './types'

const WithVisibility = <P extends object & IWithVisibilty>(
  Component: React.ComponentType<P>,
) => {
  return (props: P & IWithVisibilty) => {
    return (
      <>
        {props.isVisible ? (
          <Component {...(props as P)} />
        ) : (
          <ContainerVisibility>
            <Dialog
              description={props.dialogDescription}
              contentFooter={props.dialogContentFooter}
              padding={props.dialogPadding}
            />
            <VisibilityBlur>
              <Component {...(props as P)} />
            </VisibilityBlur>
          </ContainerVisibility>
        )}
      </>
    )
  }
}

export default WithVisibility
