import * as React from 'react'
import Contact from '@/components/contact'
import Detail from './detail'
import Subscription from './subscription'
import { Sidebar, AppBarHeader, TabsNavigation } from '@components'
import { Container, ErrorMessage, LoaderWrapper } from './styled'
import { tabs } from './tabsProduct'
import {
  Grid,
  Container as Wrapper,
  Paper,
  ListSubheader,
  Box,
} from '@mui/material'
import { SubscriptionMock } from './subscription/subscriptionMock'
import { ContactMock } from '@/components/contact/contactMock'
import { useParams } from 'react-router'
import ClipLoader from 'react-spinners/ClipLoader'
import storage from '@/utils/storage'
import { useAppSelector } from '@/app/hooks'
import { axios } from '@/lib/axios'
import { string } from 'yup'
import { off } from 'process'
import { CompanyProfile } from '@/services/storefront/types'
import { TabsProduct } from '@/pages/product/types'
import { Stack, styled } from '@mui/system'
import MainLayout from '@/components/layout'
import { debounce } from 'lodash'
import TabsComponent from '@/components/tabs'
import { scroller } from 'react-scroll'
import { EmptyStateWrapper, Info } from '../dashboard/posting/styled'
import { ResultsTitle } from '@/components/grid-cards/styled'

interface IProductProps {}

const Product: React.FC<IProductProps> = (props) => {
  const supportEmail = process.env.SUPPORT_EMAIL
  const [product, setProduct] = React.useState<any>()
  const [companyProfile, setCompanyProfile] = React.useState<
    CompanyProfile | undefined
  >()
  const [loading, setLoading] = React.useState(true)
  const [errorFetching, setErrorFetching] = React.useState(false)
  const { price } = SubscriptionMock
  const { companyName, phoneNumber } = ContactMock
  const { id } = useParams()
  const userRole = useAppSelector((state) => state.authUser.userRole)
  const userId = useAppSelector((state) => state.authUser.userId)
  const baseUrl = process.env.REACT_APP_URL_BASE
  const token = storage.getToken()

  const scrollToFn = (sectionName: string) => {
    scroller.scrollTo(sectionName, {
      duration: 500,
      delay: 0,
      smooth: 'easeInOutQuart',
      containerId: 'scroll-container',
      offset: -25,
    })
  }

  const getProduct = (id: string) => {
    const res = axios
      .get(`${baseUrl}/api/v1/listings/${id}`)
      .then(function (response: any) {
        return response
      })
      .catch(function (error: any) {
        return error
      })
    return res
  }

  const getCompanyProfile = async (id: string) => {
    const company = await axios.get(`${baseUrl}/api/v1/ccompanyById/${id}`)
    const companyProfileId = company.data?.profile
    return await axios.get(
      `${baseUrl}/api/v1/company-profiles/${companyProfileId}`,
    )
  }

  const asyncFetcher = async () => {
    try {
      const productData = await getProduct(id!)
      const companyProfileData = await getCompanyProfile(
        productData?.data?.company?.id,
      )
      setProduct(productData.data)
      setCompanyProfile(companyProfileData.data)
    } catch (error) {
      setErrorFetching(true)
    }
  }

  React.useEffect(() => {
    asyncFetcher().then(() => {
      setLoading(false)
    })
  }, [id])

  const capitalize = (word: string) => {
    return word[0].toUpperCase() + word.slice(1).toLowerCase()
  }

  const getTabs = () => {
    const dic: any = {
      previewAnalytics: false,
      coverage: false,
      delivery: false,
      legal: false,
      faq: false,
    }

    dic['previewAnalytics'] =
      !!product.samples &&
      product.samples.filter((sample: any) => !!sample.name).length > 0
    dic['coverage'] = !!product.geo && product.geo.length > 0
    dic['delivery'] =
      (product.dataFormat && !!product.dataFormat[0]) ||
      (product.dataMethod && !!product.dataMethod[0]) ||
      (product.dataFrequency && !!product.dataFrequency[0]) ||
      // !!product.buyingFormat[0] ||
      product.freeSample
    dic['legal'] =
      !!product.legalCompliance &&
      product.legalCompliance.find((val: any) => !!val.name)
    dic['faq'] =
      !!companyProfile?.faq &&
      !!companyProfile.faq.find((item) => !!item.answer)

    let newTabs = tabs

    newTabs = tabs.filter((tab) =>
      dic.hasOwnProperty(tab.id) ? dic[tab.id] : true,
    )

    return newTabs
  }

  const showRightPanel = userId && userRole === 'buyer'
  return (
    <MainLayout>
      {loading ? (
        <LoaderWrapper>
          <ClipLoader color="#000000" size={36} />
        </LoaderWrapper>
      ) : (
        <>
          {errorFetching && (
            <EmptyStateWrapper>
              <ResultsTitle>
                Haven't found what you're looking for?
              </ResultsTitle>
              <Info>
                {' '}
                <a href={`mailto:${supportEmail}`}>
                  Contact Kamba's Sourcing Team
                </a>{' '}
                <span>for Help</span>
              </Info>
            </EmptyStateWrapper>
          )}
          {!errorFetching && (
            <>
              <TabsComponent
                tabs={getTabs()}
                currentIndex={0}
                callback={scrollToFn}
              />
              <Grid
                container
                direction="row"
                alignItems="flex-start"
                overflow="auto"
                justifyContent={showRightPanel ? 'flex-start' : 'center'}
                sx={{
                  paddingX: 5,
                  paddingTop: 3,
                }}
                id="scroll-container"
              >
                {/* Left Side */}
                <Grid
                  // style={{
                  //   background: 'red',
                  // }}
                  xs={7}
                >
                  <Detail product={product!} companyProfile={companyProfile} />
                </Grid>
                {/* Right Side */}
                {showRightPanel && (
                  <Grid
                    xs={4}
                    xl={3}
                    ml={3}
                    sx={{
                      position: 'sticky',
                      top: '0',
                      overflow: 'hidden',
                    }}
                    id="rightBox"
                  >
                    {product?.buyingFormat && (
                      <Grid item xs={12}>
                        <Subscription
                          buyingFormats={product?.buyingFormat}
                          listingId={id!}
                          companyId={product?.company?.id}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} mt={2}>
                      <Contact
                        companyName={capitalize(
                          product?.company?.name || 'Company',
                        )}
                        listingId={id}
                        authorId={product?.author}
                        companyId={product?.company?.id}
                        phoneNumber={phoneNumber}
                        origin="listing"
                        showIconsInfo={true}
                      />
                    </Grid>
                    {!userId && (
                      <ErrorMessage>
                        Create an account or login to send messages to this
                        supplier.
                      </ErrorMessage>
                    )}
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </>
      )}
    </MainLayout>
  )
}

export default Product
