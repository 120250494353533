import * as yup from 'yup'

export const validateFunction = async <T extends { [key: string]: unknown }>(
  value: T | T[keyof T],
  schema: Record<keyof T, yup.AnySchema>,
  lookup?: keyof T,
): Promise<yup.ValidationError[]> => {
  const objValue: Record<string, unknown> = lookup
    ? { [lookup]: value }
    : (value as T)
  const objSchema = lookup ? { [lookup]: schema[lookup] } : schema
  const validationSchema = yup.object().shape(objSchema)
  try {
    await validationSchema.validate(objValue, { abortEarly: false })
  } catch (e: any) {
    const typedError: yup.ValidationError = e
    return typedError.inner
  }
  return []
}

export const deleteError = (
  identifier: string,
  array: yup.ValidationError[],
  setState: React.Dispatch<React.SetStateAction<yup.ValidationError[]>>,
) => {
  const newErrorState = array
  const deleteIndex = array.findIndex((val) => val.path?.includes(identifier))
  newErrorState.splice(deleteIndex, 1)
  setState(newErrorState)
}

export const findError = (identifier: string, array: yup.ValidationError[]) => {
  const error = array.find((val) => val.path?.includes(identifier))
  return error
}
