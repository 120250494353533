export const HeaderMock = {
  title:
    'SafeGraph | Alternative Data | Foot Traffic for Grocery Stores in the US',
  companyName: 'SafeGraph',
  companyImg: 'https://via.placeholder.com/120x120',
  companyBanner:
    'https://www.bu.edu/globalprograms/files/2015/05/banner-placeholder.png',
  companyDescriptionTitle: 'SafeGraph in a Nutshell',
  companyDescription:
    'SafeGraph is a data company. That’s it - that’s all we do. We predict the past. We want to understand how humans interact with the physical world. We are a market leader in accurate location data. SafeGraph builds truth sets for machine learning, deep learning, and AI. We access truth sets by working with companies to securely manage, enhance, and monetize data. SafeGraph is unlocking the world’s most powerful data so that machines and humans can answer society’s toughest questions.',
  isVerifiedProvider: true,
  rating: 4.9,
  description:
    'Diam et sit diam enim. Tortor proin velit et semper sagittis. Nullam turpis amet, aliquam vel a integer in bibendum malesuada. Ullamcorper posuere dignissim fermentum amet diam, dictum. Ut nisi donec a, velit. ullam turpis amet, aliquam vel a integer in bibendum malesuada. Ullamcorper posuere dignissim fermentum amet diam, dictum. Ut nisi donec a, velit. ',
}
