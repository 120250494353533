export const arrayCheckboxesHandler = <T>(
  isChecked: boolean,
  state: T[],
  setState: (arg0: T[]) => any,
  findPredicate: (value: T, index: number, obj: T[]) => boolean,
  valueToAdd?: T,
) => {
  const foundIndex = state.findIndex(findPredicate)
  if (isChecked) {
    if (valueToAdd === undefined) throw new Error('No new value to add')
    if (foundIndex === -1) {
      setState([...state, valueToAdd])
    }
  } else {
    if (foundIndex !== -1) {
      const newStateList = [...state]
      newStateList.splice(foundIndex, 1)
      setState(newStateList)
    }
  }
}
