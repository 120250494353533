import {
  BrowserRouter,
  Route,
  Routes as Switch,
  Navigate,
} from 'react-router-dom'
import { useAppSelector } from '../app/hooks'
import Login from '@/pages/login'
import CreateAccount from '@/pages/create-account'
import EmailVerification from '@/pages/email-verification'
import ForgotPassword from '@/pages/forgot-password'
import Dashboard from '@/pages/dashboard/index'
import EmailVerificationCode from '../pages/email-verification-code'
import ResendVerificationInstructions from '../pages/resend-verification-instructions'
import ProductCreateListing from '../pages/product-create-listing'
import Routes from './dashboard-routes/routes'
import ChangePassword from '@/pages/change-password'
import Product from '@/pages/product'
import ProductOld from '@/pages/product/index-old'
import Storefront from '@/pages/storefront'
import StorefrontOld from '@/pages/storefront/index-old'
import LeadBriefing from '../pages/dashboard/leads/lead-briefing'
import SubscriptionInfo from '@/pages/subscription-info'
import SuppliersDashboard from '@/pages/suppliers-dashboard'
import QualityReport from '@/pages/quality-report'
import TermsAndConditions from '@/pages/contractual-terms'
import storage from '@/utils/storage'
import Environment from '@/pages/environment'
import ProposeProduct from '@/pages/dashboard/lead-pool/propose-product'
import CreatePost from '@/pages/dashboard/posting/create-post'
import DealConversation from '@/pages/dashboard/deals/deal-conversation'
import PostingProposals from '@/pages/dashboard/posting/posting-proposals'
import DatasetCreateListing from '@/pages/dataset-create-dataset'
import StorefrontAdmin from '@/pages/storefront-admin'
import ProductEditListing from '@/pages/edit-product'
import PrivacyPolicy from '@/pages/privacy-policy'
import FeePolicy from '@/pages/fee-policy'
import Backtesting from '@/pages/backtesting'

const Routing = () => {
  // const { isLoggedIn } = useAppSelector((state) => state.login)
  const isLoggedIn = storage.getUserId() ? true : false
  const { dashboard } = useAppSelector((state) => state.authUser)

  const hasChecklistComplete = () => {
    if (
      isLoggedIn &&
      dashboard &&
      dashboard.listDataProducts &&
      dashboard.linkAccountWithStripe &&
      dashboard.acceptTermsAndConditions
    ) {
      if (
        dashboard.listDataProducts.size === dashboard.listDataProducts.total &&
        dashboard.linkAccountWithStripe &&
        dashboard.acceptTermsAndConditions
      ) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" element={<Dashboard />}>
          {Routes.map((route: any) => (
            <Route
              path={route.path}
              key={route.path}
              element={<route.component />}
            />
          ))}
        </Route>
        <Route path="/product/:id" element={<Product />} />
        <Route path="/product-old/:id" element={<ProductOld />} />
        <Route path="/product/:id/edit" element={<ProductEditListing />} />
        <Route
          path="/suppliers-dashboard"
          element={
            !isLoggedIn ? (
              <Navigate to="/login" />
            ) : (
              // : hasChecklistComplete() ? (
              //   <Navigate to="/" />
              // )
              <SuppliersDashboard />
            )
          }
        />
        <Route path="/storefront/:companyId" element={<Storefront />} />
        <Route path="/storefront-old/:companyId" element={<StorefrontOld />} />
        <Route path="/login" element={<Login />} />
        <Route path="/create-account" element={<CreateAccount />} />
        <Route
          path="/resend-instructions"
          element={<ResendVerificationInstructions />}
        />
        <Route path="/email-verification" element={<EmailVerification />} />
        <Route
          path="/email-verification-code"
          element={<EmailVerificationCode />}
        />
        <Route path="/environment/:id" element={<Environment />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/change-password" element={<ChangePassword />} />
        {/* <Route path="/dashboard" element={<Dashboard />} /> */}
        <Route path="/lead-briefing/:leadId" element={<LeadBriefing />} />
        <Route
          path="/product-create-listing"
          element={<ProductCreateListing />}
        />
        <Route
          path="/subscription-info/:listingId"
          element={<SubscriptionInfo />}
        />
        <Route path="/quality-report" element={<QualityReport />} />
        <Route path="/posting/:id" element={<ProposeProduct />} />
        <Route
          path="/terms-and-conditions/:title"
          element={<TermsAndConditions />}
        />
        <Route path="/create-post" element={<CreatePost />} />
        <Route
          path="/deal-conversation/:dealId"
          element={<DealConversation />}
        />
        <Route
          path="/posting-proposals/:proposalId"
          element={<PostingProposals />}
        />
        <Route path="/create-dataset" element={<DatasetCreateListing />} />
        <Route path="/storefront-admin" element={<StorefrontAdmin />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/fee-policy" element={<FeePolicy />} />
        <Route path="/backtesting" element={<Backtesting />} />
      </Switch>
    </BrowserRouter>
  )
}

export default Routing
