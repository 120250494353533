import { Pricing } from '@/services/listing/types/pricing'

const pricingMock: Pricing[] = [
  {
    price: 1500,
    id: '3694133b-afeb-41ac-a57e-d428a8a232322f',
    type: 'monthly',
  },
]

export const pricingPropsMock = {
  isFreeSample: true,
  pricings: pricingMock,
  title: 'Safegraph',
  titleDescription:
    "Learn about SafeGraph's prices, subscription cost, and API pricing.",
}
