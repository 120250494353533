import { axios as axiosCustom } from '@/lib/axios'
import axios from 'axios'

const uploadFile = async (
  data: File,
  setError: (arg0: boolean) => void,
): Promise<string> => {
  const responseUrl = await axiosCustom.put(`/uploadS3`, {
    fileType: 'text/csv',
  })
  if (!(responseUrl as any).ok) {
    setError(true)
    return ''
  }
  const url: string = responseUrl.data
  const incompleteUuid = url.split('.com/')
  const uuid = incompleteUuid[1].split('?')[0]
  const newFile = new File([data], uuid, { type: data.type })
  const responseAws = await axios.put(url, newFile, {
    headers: {
      'Content-Type': 'text/csv',
    },
  })

  if (responseAws.status !== 200) {
    setError(true)
    return ''
  }

  return uuid
}

export default uploadFile
