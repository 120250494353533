import { ChangeEvent, useState } from 'react'
import { Label } from '../../styled'
import { DataDto } from '../types'
import {
  Input,
  Row,
  CloseButton,
  AddButton,
  PlusIcon,
  InputWrapper,
} from './styled'

const VolumeDynamicFields = ({
  state,
  setValue,
}: {
  state: DataDto[]
  setValue: (arg0: DataDto[]) => any
}) => {
  const [inputList, setInputList] = useState(
    state.length > 0
      ? state
      : [
          {
            volume: 0,
            unit: '',
          },
        ],
  )

  const transformToInt = (val: any) => {
    const intVal = parseInt(val)
    if (!intVal) {
      return 0
    }
    return intVal
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value } = e.target
    const list = [...inputList]
    name === 'volume'
      ? (list[index].volume = transformToInt(value))
      : (list[index].unit = value)
    setInputList(list)
    setValue(list)
  }

  const handleAddInput = () => {
    setInputList([...inputList, { volume: 0, unit: '' }])
  }

  const handleRemoveInput = (index: any) => {
    const list = [...inputList]
    list.splice(index, 1)
    setInputList(list)
    setValue(list)
  }

  return (
    <>
      {inputList &&
        inputList.map((item: any, index: number) => (
          <Row key={index}>
            <InputWrapper maxWidth="127px">
              {index === 0 && <Label marginBottom="8px">Volume</Label>}
              <Input
                type="text"
                name="volume"
                placeholder="e.g.: “100”"
                value={item.volume}
                onChange={(e) => handleChange(e, index)}
              />
            </InputWrapper>
            <InputWrapper>
              {index === 0 && <Label marginBottom="8px">Unit</Label>}
              <Input
                type="text"
                name="unit"
                placeholder="e.g.: “records”"
                value={item.unit}
                onChange={(e) => handleChange(e, index)}
              />
            </InputWrapper>
            <CloseButton onClick={() => handleRemoveInput(index)} />
          </Row>
        ))}
      <AddButton onClick={handleAddInput}>
        <PlusIcon />
        Add Volume
      </AddButton>
    </>
  )
}

export default VolumeDynamicFields
