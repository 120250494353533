import { useEffect, useState } from 'react'
import Papa from 'papaparse'
import DataGrid from './data-grid'

const Preview = ({ data }: { data: File }) => {
  const [content, setContent] = useState<{ [id: string]: string }[]>([])

  useEffect(() => {
    createCsv(data, 5)
      .then((val) => {
        setContent(val)
      })
      .catch((e) => console.log(e))
  }, [data])
  return (
    <>
      {content.length > 0 && (
        <DataGrid
          height="360px"
          columns={Object.keys(content[0]).map((val) => ({ field: val }))}
          rows={content}
        />
      )}
    </>
  )
}

const createCsv = async (
  data: File,
  limitRows?: number,
): Promise<{ [id: string]: string }[]> => {
  const csvPromise = new Promise<{ [id: string]: string }[]>(
    (resolve, reject) => {
      Papa.parse<{ [id: string]: string }>(data, {
        complete: (results) => {
          if (results.errors.length > 0) {
            reject(results.errors)
          } else {
            resolve(results.data)
          }
        },
        preview: !limitRows ? 0 : limitRows,
        header: true,
      })
    },
  )
  /* MANUAL CSV Parsing
  const csvPromise = new Promise<{ [id: string]: string }[]>(
    (resolve, reject) => {
      const reader = new FileReader()
      reader.onload = async (e) => {
        try {
          const text = e.target?.result
          const stringText = `${text}`

          const fixNewLines = stringText.split('\r').join('')
          const stringArrays = fixNewLines.split('\n')

          const columns = stringArrays[0].split(',')

          const dictArrays = stringArrays
            .slice(1, limitRows ? limitRows + 1 : undefined)
            .map((stringRow) => {
              const valueColumn = stringRow.split(',')
              const newRow = valueColumn.reduce(
                (dictionary, currentStr, index): { [id: string]: string } => {
                  dictionary[columns[index]] = currentStr
                  return dictionary
                },
                {} as { [id: string]: string },
              )
              return newRow
            })
          resolve(dictArrays)
        } catch (e) {
          reject(e)
        }
      }
      reader.readAsText(data)
    },
  )
  */
  return await csvPromise
}

export default Preview
