import { ProductVisibility } from '@/components/forms/create-product/types'
import { axios } from '@/lib/axios'

export const setVisibility = async (
  id: string,
  visibility: ProductVisibility,
  whitelist?: string[],
): Promise<boolean> => {
  try {
    const listingRes = await axios.get(`/listings/${id}`)

    if (!listingRes.ok) {
      return false
    }

    const updatedListing = {
      ...listingRes.data,
      visibility,
      whitelist,
    }
    const updatedListingResponse = await axios.put(
      `/listings/${id}`,
      updatedListing,
    )

    if (!updatedListingResponse.ok) {
      return false
    }
    return true
  } catch (e) {
    return false
  }
}
