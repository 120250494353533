import React from 'react'
import { BadgeWrapper, BadgeText } from './styled'
import CheckIcon from '@mui/icons-material/Check'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
interface BadgeProps {
  variant: 'success' | 'warning'
  text: string
}

function BadgeComponent({ variant, text }: BadgeProps) {
  return (
    <BadgeWrapper variant={variant}>
      {' '}
      {variant === 'success' ? (
        <CheckIcon style={{ color: '#2FA14F' }} fontSize={'small'} />
      ) : (
        <WarningAmberOutlinedIcon
          style={{ color: '#825B00' }}
          fontSize={'small'}
        />
      )}
      <BadgeText variant={variant}>{text}</BadgeText>
    </BadgeWrapper>
  )
}

export default BadgeComponent
