import { ApiResponse } from '@/services/types'
import { Posting } from '../types'

export const getPostings = (response: ApiResponse<Posting[]>) => {
  const postings = response.data?.map((posting: Posting) => {
    return {
      id: posting.id,
      title: posting.title || '',
      dataCategories: posting.dataCategories || [],
      geo: posting.geo || [],
      useCase: posting.useCase || [],
      budgetLow: posting.budgetLow || 0,
      budgetHigh: posting.budgetHigh || 0,
      anonimousBudget: posting.anonimousBudget || false,
      typeOfData: posting.typeOfData || '',
      updatedAt: posting.updatedAt || '',
      createdAt: posting.createdAt || '',
      delivery: posting.delivery || '',
      userId: posting.userId || '',
    }
  })

  return postings
}
