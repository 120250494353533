import styled from 'styled-components'

export const ShareWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border: 0.67px solid #d3d3d3;
  padding: 30px 40px;
`

export const ShareTitleWrapper = styled.div`
  border-left: 4px solid #000000;
  padding-left: 20px;
`

export const ShareTitle = styled.span`
  font-size: 29px;
  line-height: 34px;
  color: #000000;
`

export const ShareTitleBold = styled(ShareTitle)`
  font-weight: 700;
`
