import * as React from 'react'
import { TabsNavigation } from '@/components'
import { Container } from '@mui/material'
import Header from '../header'
import { tabs } from './tabsPosting'

interface IPostingProps {}

const LeadPoolPage: React.FC<IPostingProps> = (props) => {
  return (
    <Container maxWidth={'xl'} sx={{ padding: '30px' }}>
      <Header title={'Lead Pool'} description={'Find new prospects.'}></Header>
      <TabsNavigation tabs={tabs} backgroundMarginOffset={'42%'} />
    </Container>
  )
}

export default LeadPoolPage
