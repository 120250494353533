import { ApiResponse } from '@/services/types'
import { axios } from '@lib/axios'
import { PostingProposal, Proposal } from '../types'

type ApiProposalsResponse = ApiResponse<Proposal[]>
type ApiPostingProposalResponse = ApiResponse<PostingProposal>

export const getAllProposals = ({
  userId,
  ...params
}: {
  userId: string
}): Promise<ApiProposalsResponse> => {
  return axios.get(`/users/${userId}/proposals`, {
    params: { ...params },
  })
}

export const getProposalByPostingId = ({
  postingId,
}: {
  postingId: string
}): Promise<ApiPostingProposalResponse> => {
  return axios.get(`/postings/${postingId}/proposals`)
}
