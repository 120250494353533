import {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  ChangeEventHandler,
} from 'react'
import { RadioButtonsArrayProps, RadioButtonsArrayStatessProps } from './types'
import {
  RadioWrapper,
  RadioRow,
  Radio,
  RadioButton,
  RadioLabel,
} from './styled'

const RadioButtons = ({ state, values, onChange }: RadioButtonsArrayProps) => {
  const [checkValue, setCheckValue] = useState(state)
  return (
    <RadioWrapper>
      {values.length > 0 &&
        values.map((option: any, index) => (
          <RadioRow
            isChecked={checkValue === option.value || checkValue === option.id}
            key={index}
          >
            <Radio>
              <RadioButton
                type="radio"
                value={option.value}
                checked={
                  checkValue === option.value || checkValue === option.id
                }
                name={option.label}
                onClick={(e: any) => {
                  setCheckValue(e.target.value)
                }}
                onChange={onChange}
              />
              <span />
            </Radio>
            <RadioLabel>{option.label}</RadioLabel>
          </RadioRow>
        ))}
    </RadioWrapper>
  )
}

export const RadioButtonsCompanySize = ({
  state,
  values,
  onChange,
}: RadioButtonsArrayStatessProps) => {
  return (
    <RadioWrapper>
      {values.length > 0 &&
        values.map((option: any, index) => {
          const [checked, setChecked] = useState(state.includes(option.value))
          return (
            <RadioRow isChecked={checked} key={index}>
              <Radio>
                <RadioButton
                  type="radio"
                  value={option.value}
                  name={option.label}
                  checked={checked}
                  onClick={(e) => {
                    onChange!({
                      target: { checked: !checked, value: option.value },
                    } as any)
                    setChecked(!checked)
                  }}
                  onChange={(e) => {
                    /*to avoid console errors, for some reason checked false isnt activating this*/
                  }}
                />
                <span />
              </Radio>
              <RadioLabel>{option.label}</RadioLabel>
            </RadioRow>
          )
        })}
    </RadioWrapper>
  )
}

export default RadioButtons
