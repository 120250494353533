import { initReactQueryAuth } from 'react-query-auth'

import {
  loginWithEmailAndPassword,
  // getUser,
} from './login.service'
import { registerWithEmailAndPassword } from './register.service'
import {
  AuthUser,
  LoginCredentialsDTO,
  LoginResponse,
  RegisterCredentialsDTO,
} from './types'
import storage from '../../utils/storage'
import { ApiBaseResponse, ApiResponse } from '@services/types'

async function handleLoginResponse(response: ApiResponse<LoginResponse>) {
  const {
    data: {
      AccessToken: accessToken,
      RefreshToken: refreshToken,
      userId: userId,
    } = {},
  } = response
  if (accessToken) storage.setToken(accessToken)
  if (refreshToken) storage.setRefreshToken(refreshToken)
  return response
}

async function loadUser() {
  if (storage.getToken()) {
    // const data = await getUser() //PROD
    const data = null
    return data
  }
  return null
}

async function loginFn(data: LoginCredentialsDTO) {
  const response = await loginWithEmailAndPassword(data)
  const user = await handleLoginResponse(response)
  return user
}

async function registerFn(data: RegisterCredentialsDTO) {
  const response = await registerWithEmailAndPassword(data)
  return response
}

async function logoutFn() {
  storage.clearData()
  window.location.assign(window.location.origin as unknown as string)
}

const authConfig = {
  loadUser,
  loginFn,
  registerFn,
  logoutFn,
  LoaderComponent() {
    return <div className="loader">Loading....</div>
  },
}

export const { AuthProvider, useAuth } = initReactQueryAuth<
  AuthUser | ApiBaseResponse | null,
  unknown,
  LoginCredentialsDTO,
  RegisterCredentialsDTO
>(authConfig)
