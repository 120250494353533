export const priceFormatData = [
  {
    id: 1,
    check: 'One-off purchase',
    input: '',
    value: 'one',
  },
  {
    id: 2,
    check: 'Monthly License',
    input: 'month',
    value: 'month',
  },
  {
    id: 3,
    check: 'Yearly License',
    input: 'year',
    value: 'year',
  },
  // {
  //   id: 4,
  //   check: 'Usage-based',
  //   input: 'usage based',
  //   value: 'usage-based',
  // },
]
