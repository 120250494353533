import { ApiResponse } from '@/services/types'
import { SearchListings } from '../types'

export const getCardsListings = (response: ApiResponse<SearchListings>) => {
  const cards = response.data?.listings.map((listing) => {
    return {
      id: listing.id,
      title: listing.title || '',
      companyName: listing.company?.name,
      verified: listing.verified || false,
      description: listing.shortDescription || '',
      isInstantPurchase: listing.isInstantPurchase || false, //TODO:BK?
    }
  })

  return { count: response.data?.count, cards }
}
