import { TaskType } from '@/pages/suppliers-dashboard/tasks'
import AddFileIcon from '@assets/images/icons/AddFileIcon'
import StorefrontIcon from '@assets/images/icons/StorefrontIcon'
// import DatasetIcon from '@assets/images/icons/DatasetIcon'
import RedirectIcon from '@assets/images/icons/RedirectIcon'
import TermsIcon from '@assets/images/icons/TermsIcon'

export const tasks = [
  {
    id: 'acceptFeePolicy',
    title: 'Kamba‘s fee policy',
    description:
      'By accepting our fee policy, you will be unlocking access to our marketplace’s functionality. ​',
    CTAText: 'Go to fee policy',
    CTAIcon: TermsIcon,
    CTALink: '/fee-policy',
  },
  {
    id: 'acceptTermsAndConditions',
    title: 'Kamba’s legal documents',
    description:
      'A set of legal policies and documents for users of Kamba‘s marketplace',
    CTAText: 'Go to documentation',
    CTAIcon: TermsIcon,
    CTALink: '/terms-and-conditions/all',
  },
  {
    id: 'linkAccountWithStripe',
    title: 'Link your account with <span style="color: #635bff;">Stripe</span>',
    description: 'Kamba uses Stripe for customer payments and payouts.',
    CTAText: 'STRIPE SETUP',
    CTAIcon: RedirectIcon,
  },
  {
    id: 'preparePerfectStoreFront',
    title: 'Prepare the perfect Storefront',
    description:
      'Setting up an effective and reliable storefront can increase sales by up to 30%.',
    CTAText: 'UPDATE PROFILE',
    CTAIcon: StorefrontIcon,
    CTALink: '/storefront-admin',
  },
  {
    id: 'listDataProducts',
    title: 'List 3 Data Products and Services',
    description:
      'Put your products on the map by reaching initial visibility on Kamba',
    CTAText: 'LIST NEW PRODUCT',
    CTAIcon: AddFileIcon,
    CTALink: '/product-create-listing',
  },
  // {
  //   id: 'uploadFirstDataset',
  //   title: 'Upload your first Dataset',
  //   description: 'Create a dataset that can be instantly purchased',
  //   CTAText: 'UPLOAD A DATASET',
  //   CTAIcon: DatasetIcon,
  // },
]
