import { Button, Checkbox } from '@/components'
import InputComponent from '@/components/ui/input'
import { queryClient } from '@/lib/react-query'
import { useUpdateCompanyProfile } from '@/services/storefront/storefront.service'
import { CompanyProfile } from '@/services/storefront/types'
import { useState } from 'react'
import { ClipLoader } from 'react-spinners'
import { FormWrapper, InputTitle, SavedChanges } from '../styled'
import { IFormProps } from '../types'
import { complianceLawsList } from './compliance'

const Privacy: React.FC<IFormProps> = ({ id, companyProfile }) => {
  const [checkedState, setCheckedState] = useState(
    complianceLawsList.map((item, index) => {
      return !!companyProfile.legalCompliance![index]
    }),
  )
  const [complianceList, setComplianceList] = useState<string[]>(
    companyProfile.legalCompliance!,
  )
  const [savedChanges, setSavedChanges] = useState(false)
  const [privacyPolicy, setPrivacyPolicy] = useState<
    { url: string; description: string } | undefined
  >(companyProfile.privacyPolicy)
  const [privacyDescription, setPrivacyDescription] = useState<
    string | undefined
  >(companyProfile.privacyDescription)
  const [termsUrl, setTermsUrl] = useState<string | undefined>(
    companyProfile.termsAndConditionsUrl,
  )

  let updatedCompanyProfile: CompanyProfile = { ...companyProfile }

  const { mutate, isLoading } = useUpdateCompanyProfile(() => {
    setSavedChanges(true)
    queryClient.invalidateQueries({ queryKey: ['company-profile'] })
  })

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    updatedCompanyProfile = {
      ...updatedCompanyProfile,
      privacyPolicy: privacyPolicy!,
      privacyDescription: privacyDescription,
      termsAndConditionsUrl: termsUrl,
      legalCompliance: complianceList ? complianceList : [],
    }

    mutate({ id: id, companyProfile: updatedCompanyProfile })
  }

  const handleOnChange = (position: number) => {
    const updatedCheckedState = checkedState.map((item, index) => {
      return index === position ? !item : item
    })

    setCheckedState(updatedCheckedState)

    const newComplianceList = complianceLawsList.map((item, index) => {
      return updatedCheckedState[index] ? item : ''
    })

    setComplianceList(newComplianceList)
  }

  const renderComplianceCheckboxes = () =>
    complianceLawsList.map((law, index) => {
      return (
        <Checkbox
          key={index}
          label={law}
          value={law}
          isChecked={checkedState[index]}
          fontWeight="600"
          fontColor="#303030"
          handleOnChange={() => handleOnChange(index)}
        />
      )
    })

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <InputTitle>Privacy</InputTitle>
      <InputComponent
        title="Privacy Policy URL (must start with https://)"
        placeholder="e.g. https://www.company.com/privacy-policy"
        value={privacyPolicy?.url}
        getValue={(e: any) =>
          setPrivacyPolicy({ url: e.target.value, description: '' })
        }
      ></InputComponent>
      <InputComponent
        title="Terms & Conditions URL (must start with https://)"
        placeholder="e.g. https://www.company.com/terms-and-conditions"
        value={termsUrl}
        getValue={(e: any) => setTermsUrl(e.target.value)}
      ></InputComponent>
      <InputComponent
        title="How do you deal with privacy issues? GDPR? CCPA? What special measures do you take?"
        maxCharacters={1000}
        isTextArea
        value={privacyDescription}
        placeholder="Show data buyers that you source your data responsibly"
        getValue={(e: any) => setPrivacyDescription(e.target.value)}
      ></InputComponent>
      <InputTitle>Compliance</InputTitle>
      <div>Select the privacy laws that your company adheres to.</div>
      {renderComplianceCheckboxes()}
      <Button variant="primary" width={'100%'} type="submit">
        {isLoading ? (
          <ClipLoader color="#ffffff" size={12} />
        ) : (
          <span>SAVE CHANGES</span>
        )}
      </Button>

      {savedChanges && <SavedChanges>Changes Saved!</SavedChanges>}
    </FormWrapper>
  )
}

export default Privacy
