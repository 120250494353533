import { useMemo, useState } from 'react'
import { ColLeft, ColRight, Columns, Container } from './styled'
import ContactCard from '../contact-card'
import EmptyState from '../empty-state'
import Chat from '../chat'
import { Conversation } from '@twilio/conversations'
import Loader from 'react-spinners/ClipLoader'
import { Box } from '@mui/system'
import { useInfiniteConversations } from '@/services/conversations/convesations.service'
import { Conversation as DBConversation } from '@/services/conversations/types'
import { useSearchParams } from 'react-router-dom'
import { InView } from 'react-intersection-observer'

const AllMessagesList = ({}) => {
  const [activeCard, setActiveCard] = useState('')
  const [from, setFrom] = useState<string | undefined>(undefined)
  const [activeConvo, setActiveConvo] = useState<Conversation>()
  const [activeConversation, setActiveConversation] = useState<DBConversation>()

  // Query server based on filters' selection
  const [searchParams, setSearchParams] = useSearchParams()
  const filters = useMemo(() => {
    const userId = searchParams.getAll('users')
    const source = searchParams.getAll('source')
    const listingId = searchParams.getAll('listings')
    setFrom(undefined)

    return {
      userId,
      source,
      listingId,
    }
  }, [searchParams])

  const {
    isLoading,
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteConversations({
    ...filters,
  })

  const { conversations } = useMemo(() => {
    const conv = data?.pages?.map((p) => p.data?.conversations || []) || []

    const sanitizedConvo = conv.flat()

    return {
      conversations: sanitizedConvo,
    }
  }, [data])

  const loadMore = () => {
    !isFetchingNextPage && hasNextPage && fetchNextPage()
  }

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <Container>
          {conversations?.length === 0 && (
            <Box sx={{ padding: '10rem 2rem' }}>
              <EmptyState msg="There are no conversations" />
            </Box>
          )}
          {conversations.length > 0 && (
            <Columns>
              <ColLeft style={{ minHeight: '50vh', maxHeight: '60vh' }}>
                <>
                  {!isLoading && isFetching && (
                    <div style={{ position: 'absolute' }}>
                      <Loader size={22} />
                    </div>
                  )}
                  {conversations.map((c, idx) => {
                    const onClick = (id: string, convo: Conversation) => {
                      setActiveCard(id)
                      setActiveConvo(convo)
                      setActiveConversation(c)
                    }

                    const isActive = c.id === activeCard

                    return (
                      <InView
                        threshold={0.5}
                        key={c.id}
                        trackVisibility={true}
                        delay={1500}
                        initialInView={false}
                      >
                        {({ inView, ref }) => {
                          if (idx === conversations.length - 1 && inView) {
                            loadMore()
                          }
                          return (
                            <div ref={ref}>
                              <ContactCard
                                firstConvo={idx === 0}
                                selectedConvo={!!activeConversation}
                                conversation={c}
                                isActive={isActive}
                                onClick={onClick}
                              />
                            </div>
                          )
                        }}
                      </InView>
                    )
                  })}
                </>
              </ColLeft>
              <ColRight>
                {activeCard && activeConvo && activeConversation ? (
                  <Chat
                    key={activeCard}
                    conversation={activeConvo}
                    id={activeCard}
                    anonymous={activeConversation?.anonymous || false}
                  />
                ) : (
                  <EmptyState />
                )}
              </ColRight>
            </Columns>
          )}
        </Container>
      )}
    </>
  )
}

export default AllMessagesList
