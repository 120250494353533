import { RegisterClick } from '@/services/register-click/index.api'
import * as React from 'react'

import { useLocation } from 'react-router'

interface IRegisterClickProps {}

const RegisterClickPage: React.FC<IRegisterClickProps> = (props) => {
  const location = useLocation()
  React.useEffect(() => {
    const clickType = location.pathname.includes('data-valuation')
      ? 'dataValuation'
      : 'dataBakedLending'
    RegisterClick({ clickType }).then((r) => {
      if (r.data?.url) {
        window.location.href = r.data?.url
      }
    })
  }, [])

  return null
}

export default RegisterClickPage
