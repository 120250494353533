import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { visuallyHidden } from '@mui/utils'
import { HeadCell } from './types'
import { HeaderCell } from '../styled'

export type Order = 'asc' | 'desc'
interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof any) => void
  order: Order
  orderBy: string
  rowCount: number
  headers: HeadCell[]
}

export function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props
  const createSortHandler =
    (property: keyof any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  return (
    <TableHead>
      <TableRow>
        {props.headers.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={
              headCell.download ? 'center' : headCell.numeric ? 'right' : 'left'
            }
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon={headCell.notSortable}
            >
              <HeaderCell active={orderBy === headCell.id}>
                {headCell.label.toUpperCase()}
              </HeaderCell>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
