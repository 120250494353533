import { useState } from 'react'
import { Tooltip } from '../../../'
import { InputProps, AbsoluteWrapperProps } from './types'
import {
  Label,
  InputWrapper,
  Input,
  AbsoluteWrapper,
  TextArea,
  Wrapper,
  CharactersMessage,
} from './styled'
import textAreaResize from '@/utils/auto-size'

type Props = InputProps & AbsoluteWrapperProps

const InputComponent = ({
  title,
  children,
  isTextArea,
  defaultValue,
  maxCharacters,
  minCharacters,
  top,
  getValue,
  maxCharMessage,
  noError,
}: Props) => {
  const [isFocused, setIsFocused] = useState(false)
  const [characters, setCharacters] = useState(
    defaultValue ? defaultValue.length : 0,
  )

  return (
    <Wrapper>
      <Label>{title}</Label>
      <InputWrapper>
        {isTextArea ? (
          <TextArea
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            defaultValue={defaultValue}
            onChange={(e: any) => {
              textAreaResize(e, { min: 116, max: 500 })
              getValue(e)
              setCharacters(e.target.value.trim().length)
            }}
          />
        ) : (
          <Input
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            defaultValue={defaultValue}
            onChange={(e: any) => {
              getValue(e)
              setCharacters(e.target.value.trim().length)
            }}
          />
        )}
        {isFocused && characters < 1 && (
          <AbsoluteWrapper top={top}>
            <Tooltip>{children}</Tooltip>
          </AbsoluteWrapper>
        )}
      </InputWrapper>
      <CharactersMessage
        charactersOk={
          minCharacters <= characters && characters <= maxCharacters
        }
        noError={!!noError}
      >
        {maxCharMessage
          ? `${maxCharMessage
              .replace('%d', String(minCharacters))
              .replace('%d', String(maxCharacters))}${' '}`
          : `Between ${minCharacters} and ${maxCharacters} characters${' '}`}
        <span>(currently {characters} characters)</span>
      </CharactersMessage>
    </Wrapper>
  )
}

export default InputComponent
