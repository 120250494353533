import { Grid } from '@mui/material'
import styled from 'styled-components'

export const GridContainer = styled(Grid)`
  padding: 30px 20px;
  height: fit-content;
  background-color: #000000;
`

export const LabelPrice = styled.label`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
`

export const ValuePrice = styled.span`
  font-weight: 700;
  font-size: 37px;
  line-height: 44px;
  color: #ffffff;
`

export const DescriptionPrice = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #9b9b9b;
`
export const ErrorMessage = styled.div`
  display: flex;
  padding: 0 24px;
  margin-top: 25px;
  width: 100%;
  color: white;
  justify-content: center;
  text-align: center;
`
