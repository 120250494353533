import { Pointer, Wrapper, CloseButton, VerticalPointer } from './styled'
import { TooltipProps } from './types'

const Tooltip = ({ children, verticalPointer }: TooltipProps) => {
  return (
    <Wrapper>
      {verticalPointer ? <VerticalPointer /> : <Pointer />}
      <CloseButton />
      {children}
    </Wrapper>
  )
}

export default Tooltip
