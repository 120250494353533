import styled from 'styled-components'

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  height: 100vh;
  width: 100%;
  overflow: hidden;
`

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const TitleWrapper = styled.div`
  h1 {
    font-weight: 700;
    font-size: 49px;
    line-height: 57px;
    letter-spacing: -0.01em;
    color: #000000;
  }
  margin-bottom: 40px;
  span {
    font-weight: 400;
  }
`

export const TextWrapper = styled.div`
  max-width: 626px;
  margin: 0 auto;
  text-align: center;
  font-weight: 400;
  font-size: 25px;
  line-height: 36px;
  text-align: center;
  color: #757575;
  margin-bottom: 42px;
  span {
    font-weight: 700;
  }
`

export const CTAWrapper = styled.div`
  display: flex;
  column-gap: 16px;
  margin-bottom: 32px;
`

export const Button = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 31px;
  letter-spacing: 0.22em;
  text-transform: uppercase;
  color: #454545;
  height: 67px;
  padding: 18px 23px;
  background: #ffffff;
  border: 1.2px solid #d6d6d6;
  box-shadow: 0px 1.28311px 2.56622px rgba(16, 24, 40, 0.05);
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    box-shadow: 0px 1.28311px 2.56622px rgba(16, 24, 40, 0.2);
  }

  & > span {
    margin-top: -5px;
    font-size: 0.75rem;
  }
`

export const DarkButton = styled.button`
  font-weight: 700;
  font-size: 14px;
  line-height: 31px;
  letter-spacing: 0.22em;
  text-transform: uppercase;
  color: #ffffff;
  height: 67px;
  padding: 18px 45px;
  background: #000000;
  border: 1.2px solid #000000;
  box-shadow: 0px 1.28311px 2.56622px rgba(16, 24, 40, 0.05);
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  column-gap: 10px;
  &:hover {
    box-shadow: 0px 1.28311px 2.56622px rgba(16, 24, 40, 0.2);
  }
`

export const LinkWrapper = styled.div`
  max-width: 626px;
  margin: 0 auto;
  text-align: center;
  font-weight: 400;
  font-size: 25px;
  line-height: 36px;
  text-align: center;
  color: #757575;
  margin-bottom: 42px;
  span {
    font-weight: 700;
  }
  cursor: pointer;
  &:hover {
    color: #000000;
  }
`

export const Error = styled.p`
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #ff0000;
  margin-bottom: 32px;
`
