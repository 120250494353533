import { useState } from 'react'
import * as React from 'react'
import {
  SubscriptionPageWrapper,
  HeaderWrapper,
  TabsContainer,
  BodyWrapper,
  SideBarContainer,
  SideBarInner,
  DetailTitle,
  Detail,
  DetailWrapper,
  BackIcon,
} from './styled'
import Header from '../../header/index'
import { TabsNavigation } from '@/components'
import { tabsSubs } from './tabsSubscriptions'
import { useLocation, useNavigate, useParams } from 'react-router'
import { useGetSubsById } from '@/services/subscriptions/subscriptions.service'
import moment from 'moment'
import { ClipLoader } from 'react-spinners'
import { useAppSelector } from '@/app/hooks'
import storage from '@/utils/storage'
interface ISubscriptionsProps {}

const SubscriptionPage: React.FC<ISubscriptionsProps> = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  let { id } = useParams()
  const baseUrl = process.env.REACT_APP_URL_BASE
  const token = storage.getToken()
  const [data, setData] = useState([])

  const { isLoading, data: subscription } = useGetSubsById({
    subId: id || '', // TODO: replace userId with the id saved at login in context when logic its done
  })

  const capitalize = (word: string) => {
    return word[0].toUpperCase() + word.slice(1).toLowerCase()
  }

  return (
    <SubscriptionPageWrapper>
      {isLoading ? (
        <ClipLoader color="#ffffff" loading={isLoading} size={15} />
      ) : (
        <>
          <HeaderWrapper>
            <BackIcon
              onClick={() => {
                navigate('/subscriptions')
              }}
              fontSize={'large'}
            ></BackIcon>
            <Header title={location.state.listingTitle}></Header>
          </HeaderWrapper>
          <BodyWrapper>
            <TabsContainer>
              <TabsNavigation
                data={subscription.data}
                tabs={tabsSubs}
                backgroundMarginOffset={'0'}
              />
            </TabsContainer>
            <SideBarContainer>
              <SideBarInner>
                {subscription.data?.updatedAt && (
                  <DetailWrapper>
                    <DetailTitle>Subscribed:</DetailTitle>
                    <Detail>
                      {moment
                        .unix(subscription.data?.updatedAt)
                        .format('MMM DD, YYYY')}
                    </Detail>
                  </DetailWrapper>
                )}
                {subscription.data?.end && (
                  <DetailWrapper>
                    <DetailTitle>End:</DetailTitle>
                    <Detail>
                      {moment
                        .unix(subscription.data.end)
                        .format('MMM DD, YYYY')}
                    </Detail>
                  </DetailWrapper>
                )}
                {/*
                <DetailWrapper>
                  <DetailTitle>Price:</DetailTitle>
                  <Detail>$0 for 12 months</Detail>
                </DetailWrapper>
                */}
                {/*subscription.data?.payment && (
                  <DetailWrapper>
                    <DetailTitle>Payment Schedule:</DetailTitle>
                    <Detail>{subscription.data.payment}</Detail>
                  </DetailWrapper>
                )*/}
                {subscription.data?.status && (
                  <DetailWrapper>
                    <DetailTitle>Product Status:</DetailTitle>
                    <Detail>{capitalize(subscription.data.status)}</Detail>
                  </DetailWrapper>
                )}
                {subscription.data?.visibility && (
                  <DetailWrapper>
                    <DetailTitle>Product Visibility:</DetailTitle>
                    <Detail>{subscription.data.visibility}</Detail>
                  </DetailWrapper>
                )}
                {/*<DetailWrapper>
                  <DetailTitle>Data sets:</DetailTitle>
                  <Detail>{subscription.data.datasets}</Detail>
                    </DetailWrapper>*/}
                {/*
                <DetailWrapper>
                  <Detail>
                    Auto-renews on{' '}
                    {moment(subscription.data.end, 'DD-MM-YYYY').format(
                      'MMM DD, YYYY',
                    )}
                  </Detail>
                </DetailWrapper>
                    */}
              </SideBarInner>
            </SideBarContainer>
          </BodyWrapper>
        </>
      )}
    </SubscriptionPageWrapper>
  )
}

export default SubscriptionPage
