import geographyJson from '../assets/data/geography.json'

export const crudeGeographyData: Record<string, CountryDataDto[]> =
  geographyJson

const geographyData: Record<string, CountryDto[]> = {}
const geographyList: CountryDto[] = []

Object.keys(crudeGeographyData).forEach((continent) => {
  if (!geographyData[continent]) {
    geographyData[continent] = []
  }
  geographyData[continent].push(
    ...crudeGeographyData[continent].map((country) => {
      let image
      try {
        image = require(`../assets/images/flags/${country.code}`).default
      } catch (e) {}
      const newCountry = {
        ...country,
        image,
        value: country.country,
        label: country.country,
      }
      geographyList.push(newCountry)
      return newCountry
    }),
  )
})

export { geographyData, geographyList }

export interface CountryDataDto {
  code: string
  handle: string
  continent: string
  iso: string
  country: string
}
export interface CountryDto extends CountryDataDto {
  image: any
  label: string
  value: string
}
