import { useEffect, useRef, useState } from 'react'
import { getTwilioClient } from '@/utils/twilio'
import { useAppSelector } from '@/app/hooks'
import storage from '@/utils/storage'
import { Client, Conversation } from '@twilio/conversations'
import SubscriptionChat from '../../subscription-chat'

const Delivery = (props: { twilioConversationId: string }) => {
  const [loading, setLoading] = useState(true)
  const userId = useAppSelector((state) => state.authUser.userId)
  const token = storage.getToken()
  const client = useRef<Client | undefined>()
  const [stateConver, setStateConver] = useState<Conversation | undefined>(
    undefined,
  )
  useEffect(() => {
    const effect = async () => {
      if (!!token && !!userId) {
        const newClient = await getTwilioClient(userId)
        client.current = newClient
        getConversationById()
        setLoading(false)
      }
    }
    effect()
  }, [props.data.twilioConversationId])

  const getConversationById = async () => {
    try {
      const conver = await client.current.getConversationBySid(
        props.data.twilioConversationId,
      )
      setStateConver(conver)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    !loading && (
      <SubscriptionChat
        key={stateConver?.sid}
        id={stateConver?.sid}
        conversation={stateConver}
        isActive={true}
        client={client.current}
      />
    )
  )
}

export default Delivery
