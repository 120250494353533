import * as React from 'react'
import { GradientSeparator } from '@components'
import Summary from './summary'
// import AvailableProducts from './available-products'
import PricingDelivery from './pricing-delivery'
import About from './about'
import GeoCoverage from '@/pages/product/detail/geo-coverage'
import Faq from '@/pages/product/detail/faq'
import { Faq as IFaq } from '@/pages/product/detail/faq/types'
import { Box } from '@mui/material'
// import Reviews from '@/pages/product/detail/reviews'
import UseCases from './use-cases'
import LegalCompliance from './legal-compliance'
import storage from '@/utils/storage'

interface IDetailProps {
  company: any
  companyNative: any
}

const Detail: React.FC<IDetailProps> = ({ company, companyNative }) => {
  const isLoggedIn = storage.getUserId() ? true : false

  const filteredFaqs = React.useMemo(() => {
    return company.faq.filter((f: IFaq) => f.answer) || []
  }, [company.faq])

  return (
    <Box
      sx={{
        border: '1px solid #D9D9D9',
        backgroundColor: '#FFFF',
        padding: '25px 30px',
        maxWidth: '1250px',
        flexGrow: 0,
      }}
    >
      <Summary id="summary" company={company} companyNative={companyNative} />
      {/*<AvailableProducts id="availableProducts" company={company} />*/}
      <PricingDelivery company={company} />
      <About id="about" company={company} />
      {!!company.geo && company.geo.length > 0 && (
        <>
          <GeoCoverage countries={company.geo} isVisible={isLoggedIn} />
          {/* <Grid container item xs={12} sx={{ margin: '15px 0' }}> */}
          {/* <Grid container item xs={12}> */}
          <GradientSeparator margin="30px 0" />
          {/* </Grid> */}
        </>
      )}
      {company.useCasesDescription && <UseCases company={company} />}

      {filteredFaqs.length > 0 && (
        <Faq faqs={filteredFaqs} isVisible={isLoggedIn} />
      )}

      {((company.privacyPolicy && company.privacyPolicy.url) ||
        company.termsAndConditionsUrl) && (
        <LegalCompliance
          privacy={company.privacyPolicy ? company.privacyPolicy.url : ''}
          terms={company.termsAndConditionsUrl}
        />
      )}

      {/* <Reviews company={company} /> */}
    </Box>
  )
}

export default Detail
