import InputComponent from '@/components/ui/input'
import UploadInput from '@/components/ui/upload-input'
import { FormTitle, FormWrapper, InputTitle, SavedChanges } from '../styled'
import ViewFile from '../view-file'
import { IFormProps } from '../types'
import { Button } from '@/components'
import uploadFile from '../uploadFile'
import { useEffect, useState } from 'react'
import { useUpdateCompanyProfile } from '@/services/storefront/storefront.service'
import { CompanyProfile } from '@/services/storefront/types'
import { ClipLoader } from 'react-spinners'
import { queryClient } from '@/lib/react-query'
import axios from 'axios'
import { axios as customAxios } from '@/lib/axios'
import {
  BannerFileTypes,
  BannerRestrictions,
  LogoFileTypes,
  LogoRestrictions,
} from './texts'
import getUuid from '@/utils/getUuid'

const generalBucket = process.env.BUCKET_AWS

const Branding: React.FC<IFormProps> = ({ id, companyProfile }) => {
  const [logoFile, setLogoFile] = useState<any>(null)
  const [bannerFile, setBannerFile] = useState<any>(null)
  const [error, setError] = useState(false)
  const [savedChanges, setSavedChanges] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [sloganInput, setSloganInput] = useState<string | undefined>(
    companyProfile?.slogan,
  )
  const [descriptionInput, setDescriptionInput] = useState<string | undefined>(
    companyProfile?.description,
  )
  const [isAvatarLoading, setIsAvatarLoading] = useState(false)
  const [isBannerLoading, setIsBannerLoading] = useState(false)
  const [preloadedLogo, setPreloadedLogo] = useState(false)
  const [preloadedBanner, setPreloadedBanner] = useState(false)
  const [logoUrl, setLogoUrl] = useState(companyProfile?.avatar)
  const [bannerUrl, setBannerUrl] = useState(companyProfile?.banner)

  const getAvatar = async (url: string) => {
    try {
      const res = await axios.get(url)
      const file = new File([res.data], 'avatar', {
        type: res.headers.type,
      })
      const updatedFile = {
        ...file,
        preview: companyProfile.avatar,
      }
      setLogoFile(updatedFile)
      setPreloadedLogo(true)
      setIsAvatarLoading(false)
    } catch (err) {
      // TODO: handle errors
      console.log(err)
      setIsAvatarLoading(false)
    }
  }

  const getBanner = async (url: string) => {
    try {
      const res = await axios.get(url)
      const file = new File([res.data], 'banner', {
        type: res.headers.type,
      })
      const updatedFile = {
        ...file,
        preview: companyProfile.banner,
      }
      setBannerFile(updatedFile)
      setPreloadedBanner(true)
      setIsBannerLoading(false)
    } catch (err) {
      // TODO: handle errors
      console.log(err)
      setIsBannerLoading(false)
    }
  }

  useEffect(() => {
    if (
      typeof companyProfile.avatar === 'string' &&
      companyProfile?.avatar?.length! > 0
    ) {
      setIsAvatarLoading(true)
      getAvatar(companyProfile.avatar!)
    } else {
      setIsAvatarLoading(false)
    }

    if (
      typeof companyProfile.banner === 'string' &&
      companyProfile?.banner?.length! > 0
    ) {
      setIsBannerLoading(true)
      getBanner(companyProfile.banner!)
    } else {
      setIsAvatarLoading(false)
    }
  }, [])

  const setFile = async (data: File, type: string) => {
    const isValid = true
    if (isValid) {
      if (type === 'logo') {
        setLogoFile(data)
        setPreloadedLogo(false)
      } else {
        setBannerFile(data)
        setPreloadedBanner(false)
      }
    }
  }

  const deleteLogo = async () => {
    if (companyProfile.avatar) {
      const uuid = getUuid(companyProfile?.avatar!)
      const response: { ok: true } = await customAxios.delete(
        `deleteS3/${uuid}`,
      )

      if (response.ok) {
        setPreloadedLogo(false)
      }
    }
    setLogoFile(null)
  }
  const deleteBanner = async () => {
    if (companyProfile.banner) {
      const uuid = getUuid(companyProfile?.banner!)
      const response: { ok: true } = await customAxios.delete(
        `deleteS3/${uuid}`,
      )

      if (response.ok) {
        setPreloadedBanner(false)
      }
    }
    setBannerFile(null)
  }

  let updatedCompanyProfile: CompanyProfile = { ...companyProfile }

  const { mutate: updateCompanyProfile } = useUpdateCompanyProfile(() => {
    setSavedChanges(true)
    setIsSaving(false)
    queryClient.invalidateQueries({ queryKey: ['company-profile'] })
  })

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setSavedChanges(false)
    setIsSaving(true)
    setError(false)

    let avatarUrl = companyProfile.avatar ?? ''
    let bannerUrl = companyProfile.banner ?? ''

    if (!preloadedLogo && logoFile) {
      const logoUuid = await uploadFile(logoFile!, logoFile?.type!, setError)
      avatarUrl = `https://${generalBucket}.s3.amazonaws.com/${logoUuid}`
    }

    if (!preloadedBanner && bannerFile) {
      const bannerUuid = await uploadFile(
        bannerFile!,
        bannerFile?.type!,
        setError,
      )
      bannerUrl = `https://${generalBucket}.s3.amazonaws.com/${bannerUuid}`
    }

    updatedCompanyProfile = {
      ...updatedCompanyProfile,
      slogan: sloganInput,
      description: descriptionInput,
      avatar: logoFile || preloadedLogo ? avatarUrl : '',
      banner: bannerFile || preloadedBanner ? bannerUrl : '',
      // faq: updatedCompanyProfile.faq?.splice(0, 1, {answer: descriptionInput!})
    }

    updateCompanyProfile({ id: id, companyProfile: updatedCompanyProfile })
    setPreloadedLogo(true)
    setPreloadedBanner(true)
  }

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <FormTitle>Branding</FormTitle>
      <InputTitle>Slogan</InputTitle>
      <InputComponent
        title="What makes your company unique?"
        minCharacters={30}
        value={sloganInput}
        getValue={(e: any) => setSloganInput(e.target.value)}
      />
      <InputTitle>Short Description</InputTitle>
      <InputComponent
        title="What makes your company and your data special?"
        minCharacters={30}
        isTextArea
        value={descriptionInput}
        getValue={(e: any) => setDescriptionInput(e.target.value)}
      ></InputComponent>
      <InputTitle>Logo</InputTitle>
      {logoFile ? (
        <ViewFile data={logoFile} deleteFile={deleteLogo} />
      ) : (
        <UploadInput
          fileTypes={LogoFileTypes}
          restrictionList={LogoRestrictions}
          dispatch={setFile}
          type="logo"
          isLoading={isAvatarLoading}
        />
      )}
      <InputTitle>Cover Image</InputTitle>
      {!bannerFile ? (
        <UploadInput
          fileTypes={BannerFileTypes}
          restrictionList={BannerRestrictions}
          dispatch={setFile}
          type="banner"
          isLoading={isBannerLoading}
        />
      ) : (
        <ViewFile data={bannerFile} deleteFile={deleteBanner} />
      )}
      <Button variant="primary" width={'100%'} type="submit">
        {isSaving ? (
          <ClipLoader color="#ffffff" size={12} />
        ) : (
          <span>SAVE CHANGES</span>
        )}
      </Button>

      {savedChanges && <SavedChanges>Changes Saved!</SavedChanges>}
      {error && <h3>There was an error while uploading files</h3>}
    </FormWrapper>
  )
}

export default Branding
