import { Tabs } from '@mui/material'
import styled from 'styled-components'
import { ContainerProps } from '../../../storefront/types'
import { StyledTabProps } from './types'

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background-color: #e5e5e5;
`
export const PageContainer = styled(Container)`
  width: 80% !important;
  margin: 2rem auto;
  display: flex;
`

export const Columns = styled.div`
  display: flex;
  height: 75vh;
  width: 80vw;
  max-width: 1527px;
  margin: 0 auto;
`

export const ColLeft = styled.div`
  width: 35%;
  max-width: 282px;
  border-right: 1px solid #f0f0f0;
  background: #fff;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`

export const ColRight = styled.div`
  width: 65%;
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`

export const Header = styled.div`
  width: 80vw;
  max-width: 1527px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  position: sticky;
  top: 0;
  margin: 0 auto;
`

export const HeaderColLeft = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  font-size: 27px;
  line-height: 44px;
  span {
    color: #8b8b8b;
    font-weight: 500;
  }
`

export const UserAvatar = styled.div`
  display: flex;
  align-items: center;
  padding: 0 24px;
  gap: 18px;
  width: 100%;
  height: 77px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-shadow: 0px -12px 34px rgba(0, 0, 0, 0.11);
  overflow: hidden;
`

export const AvatarIcon = styled.div`
  min-width: 37.45px;
  min-height: 37.45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #000000;
  font-weight: 800;
  font-size: 15px;
  line-height: 22px;
  text-transform: uppercase;
`
export const SectionWrapper = styled.p`
  overflow-y: scroll;
  min-height: calc(100% - 77px);
  height: fit-content;
`

export const SectionTitle = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.035em;
  color: #717171;
`

export const SectionInfo = styled.p`
  font-weight: 700;
  font-size: 13.9333px;
  line-height: 19px;
  color: #000000;
`

export const Section = styled.div`
  width: 100%;
  margin: 20px 0 0;
  padding: 0 24px;
`

export const StageBackground = styled.div`
  width: fit-content;
  min-height: 35px;
  height: fit-content;
  background: #d9d9d9;
  text-transform: capitalize;
  padding: 9px 14px;
  display: flex;
  align-items: center;
  margin-top: 4px;
`

export const MissingBackground = styled.div`
  gap: 8px;
  width: fit-content;
  min-height: 35px;
  height: fit-content;
  background: #fff1e1;
  padding: 9px 14px;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #825b00;
  margin-top: 4px;
`

export const SectionHR = styled.hr`
  width: calc(100% - 48px);
  margin: 30px 24px 12px;
  background: transparent;
  border: unset;
  border-top: 1px solid #f0f0f0;
`

export const Button = styled.button.attrs(({ type }) => ({
  type: type || 'button',
}))`
  border: 1px solid #000000;
  background-color: #000000;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  padding: '11px 50px';
  height: 55px;
  cursor: pointer;
  margin: 0;
  width: 20%;
  max-width: 230px;
  text-transform: uppercase;
`

export const TabsContainer = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 24px;
  gap: 18px;
  width: 100%;
  height: 86px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-shadow: 0px -12px 34px rgb(0 0 0 / 11%);
`

export const Tab = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 24px;
  gap: 8px;
  width: fit-content;
  height: 76px;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  color: #000000;
  cursor: pointer;
`

export const PageHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;
  height: 77px;
  background: #fff;
`

export const TabsStyled = styled(Tabs)((props: StyledTabProps) => ({
  marginTop: '30px',
  '& .MuiTab-textColorPrimary ': {
    color: '#757575',
    padding: '25px',
    fontSize: '16px',
    textTransform: 'capitalize',
    height: '77px',
  },
  '& .MuiTabs-indicator': {
    borderBottom: '5px solid #000000;',
  },
  '& .Mui-selected': {
    color: '#000000 !important',
    fontWeight: 600,
  },
  '& > div': {
    overflowX: 'auto !important',
  },
}))
