import styled from 'styled-components'

export const Container = styled.div`
  width: 80vw;
  background-color: #ffffff;
`

export const Columns = styled.div`
  display: flex;
  flex: 1;
`

export const ColLeft = styled.div`
  width: 35%;
  min-width: 495px;
  border-right: 1px solid #f0f0f0;
  overflow-y: scroll;
`

export const ColRight = styled.div`
  width: 65%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`
