import axios from 'axios'

const baseUrl = process.env.REACT_APP_URL_BASE

type UserData = {
  username: string
}

export const resendCode = ({ username }: UserData) =>
  axios.post(`${baseUrl}/api/v1/resend-code`, {
    username: username,
  })
