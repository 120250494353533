import { Link } from 'react-router-dom'
import styled from 'styled-components'

type ButtonProps = {
  isSecondary?: boolean
  disabled?: boolean
}

export const Divider = styled.div`
  width: 45px;
  height: 6px;
  background-color: #000000;
  margin-bottom: 16px;
`

export const Title = styled.h1`
  font-size: 36px;
  line-height: 44px;
  font-weight: 300;
  margin-bottom: 55px;
`

export const FormGroup = styled.form`
  display: flex;
  flex-direction: column;
`

export const Button = styled.button.attrs(({ type }) => ({
  type: type || 'button',
}))<ButtonProps>`
  width: 100%;
  background-color: ${({ isSecondary }) =>
    isSecondary ? '#ffffff' : '#060606'};
  border: 1px solid #060606;
  color: ${({ isSecondary }) => (isSecondary ? '#060606' : '#ffffff')};
  font-size: 14px;
  line-height: 22px;
  padding: 17px 34px;
  cursor: pointer;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-top: ${({ isSecondary }) => (isSecondary ? '20px' : '0')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`

export const TextLink = styled(Link)`
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
  color: #000000;
  text-decoration: underline;
`

export const TextWrapper = styled.div`
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  color: #000000;
  text-decoration: none;
`

export const PrivacyWrapper = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: #00000080;
  text-decoration: none;
  margin-top: 24px;
`

export const Error = styled.p`
  color: #f00000;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  margin: 12px auto;
`
export const Success = styled.div`
  text-align: center;
`

export const Text = styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  margin-bottom: 24px;
  max-width: 412px;
`

export const Line = styled.div`
  background-color: #d9d9d9;
  width: 100%;
  height: 1px;
  margin: 38px 0;
`

export const Paragraph = styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  margin-bottom: 34px;
`

export const CodeVerificationWrapper = styled.div`
  .custom-styles {
    --ReactInputVerificationCode-itemWidth: 59px;
    --ReactInputVerificationCode-itemHeight: 85px;
  }

  .custom-styles .ReactInputVerificationCode__container {
    width: 100%;
    margin-bottom: 46px;
  }

  .custom-styles .ReactInputVerificationCode__item {
    position: relative;
    color: #262626;
    font-weight: 500;
    border: 1px solid #d9d9d9;
    border-radius: 0;
  }

  .custom-styles .ReactInputVerificationCode__item,
  .custom-styles .ReactInputVerificationCode__item.is-active {
    box-shadow: none;
  }

  .custom-styles .ReactInputVerificationCode__item:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    background-color: transparent;
    transition: background-color 0.2s ease-out;
  }

  .custom-styles .ReactInputVerificationCode__item.is-active:after {
    border: 1.5px solid #000;
    box-shadow: 0px 4px 20px 0px #0000000d;
  }
`
