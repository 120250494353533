import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { EnhancedTableHead, Order } from './auxiliar/table-head'
import { HeadCell } from './auxiliar/types'
import { StyledAnchor, StyledTableCell } from './styled'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import BadgeComponent from '../badge'
import exportIcon from '@assets/images/icons/RedirectIcon'

interface TableComponentProps {
  headers: HeadCell[]
  rows: any[]
}

const TableComponent = ({ headers, rows }: TableComponentProps) => {
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<any>('name')
  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
  ) => number {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number,
  ) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) {
        return order
      }
      return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof any,
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              order={order}
              headers={headers}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy)).map(
                (row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {Object.keys(row).map((key, index) => (
                        <StyledTableCell
                          maxCharactersWidth={headers[index].maxCharactersWidth}
                          key={index}
                          align={headers[index].download ? 'center' : 'left'}
                          scope="row"
                        >
                          {headers[index].download ? (
                            <StyledAnchor href={row[key].toString()}>
                              <FileDownloadIcon />
                            </StyledAnchor>
                          ) : headers[index].badge ? (
                            <BadgeComponent
                              text={row[key].toString()}
                              variant={
                                row[key].toString() === 'Updated'
                                  ? 'success'
                                  : 'warning'
                              }
                            />
                          ) : headers[index].upload ? (
                            <StyledAnchor
                              style={{ cursor: 'pointer', marginLeft: '23px' }}
                              onClick={() => {
                                window.open(row[key].toString(), '_blank')
                              }}
                            >
                              <img src={exportIcon} />
                            </StyledAnchor>
                          ) : (
                            row[key]
                          )}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  )
                },
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  )
}

export default TableComponent
