import * as React from 'react'
import { TabsNavigation } from '@/components'
import { Container } from '@mui/material'
import Header from '../header'
import { tabs } from './tabsPosting'
import PostingList from './posting-list'
import { usePostingById } from '@/services/postings/posting.service'
import { getPostings } from '@/services/postings/selectors'
import { Posting } from '@/services/postings/types'
import { EmptyStateWrapper, Info, Title } from './styled'
import RequestButton from './request-button'
import { useNavigate } from 'react-router'
import { useAppSelector } from '@/app/hooks'
import { ClipLoader } from 'react-spinners'

interface IPostingProps {}

const PostingPage: React.FC<IPostingProps> = (props) => {
  const userId = useAppSelector((state) => state.authUser.userId)
  const { isLoading, data } = usePostingById({
    select: getPostings,
    userId: userId!,
  })

  const orderedByNewest = data?.sort((a: Posting, b: Posting) => {
    const dateA = new Date(a.createdAt!)
    const dateB = new Date(b.createdAt!)
    return dateB.getTime() - dateA.getTime()
  })

  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/create-post')
  }

  const renderResults = () =>
    data?.length === 0 ? (
      <EmptyStateWrapper>
        <Title>You have no postings yet</Title>
        <Info>
          Post your request and reach out to our +3000 providers at once.
        </Info>
        <RequestButton onClick={handleClick} />
      </EmptyStateWrapper>
    ) : (
      <PostingList postings={orderedByNewest} />
    )

  return (
    <div style={{ background: 'white' }}>
      <Container maxWidth={'xl'} sx={{ padding: '30px' }}>
        <Header
          title={'Post a data request'}
          description={
            'Reach 1200+ suppliers and receive tailored offers in hours.'
          }
        ></Header>
        <TabsNavigation
          tabs={tabs}
          backgroundMarginOffset={'42%'}
          isPostingsPage
          hasPostings={data?.length > 0}
          btn={
            <RequestButton
              onClick={handleClick}
              marginBottom={10}
              marginRight={50}
            />
          }
        >
          {isLoading ? <ClipLoader size={20} /> : renderResults()}
        </TabsNavigation>
      </Container>
    </div>
  )
}

export default PostingPage
