import { TooltipTitle, FontSemiBold, TooltipList } from './styled'

export const TitleTooltip = () => {
  return (
    <>
      <TooltipTitle>What makes a good product title?</TooltipTitle>
      Give your Data Product a title that is{' '}
      <FontSemiBold>keyword search-optimized.</FontSemiBold> Ideally{' '}
      <FontSemiBold>it should contain the following details:</FontSemiBold>
      <TooltipList>
        <li>Your Brand Name </li>
        <li>Data Type</li>
        <li>Countries / Geographies</li>
        <li>Volume (e.g. 10M Devices, 5M companies, 20M consumers)</li>
        <li>
          Key Benefits (e.g.GDPR-compliant, real-time sourced, 10 years historic
          coverage)
        </li>
      </TooltipList>
      <TooltipTitle>
        Examples of search optimized Data Product titles:
      </TooltipTitle>
      <TooltipList>
        <li>
          Kamba&apos;s USA C-level executives b2b contact data (e-mail, phone)
          w/ 300k records
        </li>
        <li>
          Kamba&apos;s Location Data for People Visits to large cities in the
          UK, 18 months API
        </li>
        <li>
          Kamba&apos;s ESG ratings of Middle East companies with 5 years of
          history - tickerized
        </li>
      </TooltipList>
    </>
  )
}

export const DescriptionTooltip = () => {
  return (
    <>
      <TooltipTitle>What does a good description look like?</TooltipTitle>
      Is your chance to
      <FontSemiBold> engage with data buyers</FontSemiBold> who want to fully
      understand a Data Product before requesting it.
      <br />
      <br />
      By adding
      <FontSemiBold>search-optimized keywords</FontSemiBold> in the text you can
      <FontSemiBold>increase the visibility</FontSemiBold> of your Data Products
      as well.
      <br />
      <br /> Try to <FontSemiBold>answer the following questions:</FontSemiBold>
      <TooltipList>
        <li>What makes your data unique?</li>
        <li>How is the data generally sourced?</li>
        <li>
          What are the primary use-cases or verticals of this Data Product?
        </li>
        <li>How does this Data Product fit into your broader data offering?</li>
        <li>
          {' '}
          Great in-depth descriptions comprise several hundred words. Most top
          performing products on Kamba have a description length between 3000
          and 6000 characters.
        </li>
      </TooltipList>
    </>
  )
}

export const AttributesTooltip = () => {
  return (
    <>
      <TooltipTitle>Why are attributes important?</TooltipTitle>
      They are the<FontSemiBold> essence of your data</FontSemiBold> and have a{' '}
      <FontSemiBold>
        tremendous impact in convincing potential buyers
      </FontSemiBold>{' '}
      to send a contact request.
      <br />
      <br />
      When data buyers see the available attributes they
      <FontSemiBold> get an instant idea of what is included.</FontSemiBold>
      Data attributes contain of the following elements:
      <TooltipList>
        <li>Name - often the column header of your data file</li>
        <li>Example - a sample input field</li>
        <li>
          Description - an extended description of the column header so that
          it&apos;s easier understood
        </li>
        <li>Number of Records - the scale of your available attribute</li>
      </TooltipList>
    </>
  )
}
