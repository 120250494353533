import { Grid } from '@mui/material'
import styled from 'styled-components'
import { IDialogProps } from './types'

export const DialogContainer = styled(Grid)<IDialogProps>`
  background-color: #000000;
  position: absolute;
  padding: ${({ padding }) => padding || '20px'};
  top: 25%;
  left: 25%;
  width: ${({ width }) => width || '50%'};
  height: 50%;
  z-index: 1300;
`

export const DialogTitle = styled.span`
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.9px;
  text-transform: uppercase;
  color: #ffffff;
`

export const DialogDescription = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 34px;
  text-align: center;
  color: #ffffff;
`
