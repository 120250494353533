import { useMutation } from 'react-query'
import { resendCode } from './api/resend-code-api'

export const useResendCode = (onSuccess: () => void) => {
  return useMutation(resendCode, {
    onSuccess: () => {
      onSuccess()
    },
  })
}
