import uploadFile from '@/utils/uploadFile'
import { SampleDto } from '../second-step/types'

const dataAnalyticsBucket = process.env.BUCKET_DATA_ANALYTICS

export const uploadSamplesOrLegals = async (
  array: SampleDto[],
): Promise<SampleDto[]> => {
  return Promise.all(
    array.map(async (data) => {
      if (data.file) {
        const uuid = await uploadFile(data.file, {
          customBucket:
            data.type === 'Data Analytics' ? dataAnalyticsBucket : undefined,
          returnUUID: true,
        })
        return { ...data, s3Link: uuid, file: undefined }
      } else {
        return { type: data.type, s3Link: '', name: '' }
      }
    }),
  )
}
