import styled from 'styled-components'
import { CardProps } from './types'

export const Card = styled.div<CardProps>`
  display: flex;
  width: 100%;
  padding: 25px 24px 18px 24px;
  border-bottom: 1px solid #f0f0f0;
  position: relative;
  cursor: pointer;
  background-color: ${({ active }) => (active ? '#fafafa' : 'transparent')};
`

export const CardNewMessages = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: red;
  position: absolute;
  top: 15px;
  left: 15px;
  color: white;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
`

export const CardAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  font-style: normal;
  font-weight: 800;
  font-size: 19px;
  line-height: 32px;
  color: #ffffff;
  text-transform: uppercase;
  padding: 0 12px;
  height: 50px;
  min-width: 50px;
`

export const CardColumn = styled.div`
  margin-left: 18px;
  width: 100%;
`

export const CardRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const CardTitle = styled.h3`
  font-weight: 600;
  font-size: 18px;
  line-height: 128%;
  color: #202020;
  margin-bottom: 3px;
`

export const CardSubTitle = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #000000;
  margin-bottom: 17px;
`

export const CardBold = styled.strong`
  font-weight: bold;
`

export const CardSubText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  color: rgba(0, 0, 0, 0.7);
`

export const CardDate = styled.p`
  font-weight: 600;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: -0.02em;
  color: #2fa24f;
`

export const CardNumber = styled.p`
  height: 27px;
  width: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2fa24f;
  border-radius: 100%;
  font-weight: 700;
  font-size: 15.219px;
  line-height: 19px;
  color: #ffffff;
  padding: 0 8.5px;
`

export const CardSelectedVerticalBar = styled.div<CardProps>`
  height: 100%;
  width: 4px;
  background-color: #000000;
  position: absolute;
  display: ${({ active }) => (active ? 'block' : 'none')};
  left: 0;
  top: 0;
`
