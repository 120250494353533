import styled from 'styled-components'
import close from '@assets/images/close'

export const ItemWrapper = styled.div`
  margin-top: 28px;
`

export const Item = styled.div`
  width: 100%;
  padding: 10.5px 18px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #303030;
  position: relative;
`

export const CloseButton = styled.img.attrs({
  src: close,
  alt: 'close image',
})`
  position: absolute;
  top: 8px;
  right: 6px;
  cursor: pointer;
`
