import { ViewFileSelectType } from '../../view-file-select-type'
import { SampleDto } from '../types'
import * as yup from 'yup'

import { AddSample } from './sample'
import { validateFunction } from '@/utils/form-errors'
import { createCsv } from '@/utils/preview'
import moment from 'moment'
import { dateFormat, dateFormatAvs, dateFormatRegex } from '@/utils/csvTime'

type AllSamplesType = {
  state: SampleDto[]
  setValidationError: (arg0: yup.ValidationError[]) => void
  dispatch: (arg0: SampleDto[]) => void
}

export const AllSamples = ({
  state,
  dispatch,
  setValidationError,
}: AllSamplesType) => {
  const fileExtensions: Record<string, any> = {
    default: { extension: 'csv', error: 'File must be .csv' },
    'Chart IQ': {
      extension: '(png)|(csv)|(jpeg)|(jpg)',
      error: 'File must be either .csv, .png, .jpeg, or .jpg',
      extra: [
        {
          func: async (data: File) => {
            const parsedCsv = await createCsv(data)
            const keys = Object.keys(parsedCsv[0])
            const wantedKeys = [
              keys.find((val) => val === 'Value'),
              keys.find((val) => val === 'Date'),
            ]
            if (!wantedKeys[0] || !wantedKeys[1]) {
              return false
            }

            const error = parsedCsv.reduce((errorFound, val) => {
              if (errorFound) {
                return errorFound
              }
              try {
                const isDateValid = val[wantedKeys[1]!].match(dateFormatRegex)
                const value = parseFloat(val[wantedKeys[0]!])
                return !isDateValid || (!value && value !== 0)
              } catch (e) {
                return true
              }
            }, false)
            return !error
          },
          error: `File must have Value (number or float) and Date (should have one of the following formats ${dateFormatAvs.reduce(
            (prevVal, val) => `${prevVal}, ${val}`,
          )}) as columns`,
          key: 'validation',
        },
      ],
    },
  }

  const validate = async (data: File, type: string) => {
    const dataSchema = {
      file: yup
        .mixed()
        .test('required', 'You need to provide a file', (data: File) => {
          return !!(data && data.size)
        })
        .test(
          'fileType',
          fileExtensions[type]
            ? fileExtensions[type].error
            : fileExtensions.default.error,
          (data: File) => {
            return (
              data &&
              !!data.type.match(
                fileExtensions[type]
                  ? fileExtensions[type].extension
                  : fileExtensions.default.extension,
              )
            )
          },
        ),
    }
    if (
      fileExtensions[type] &&
      fileExtensions[type].extra &&
      data.type.includes('csv')
    ) {
      fileExtensions[type].extra.map(
        (val: { func: Function; error: string; validation: string }) => {
          dataSchema.file = dataSchema.file.test(
            val.validation,
            val.error,
            val.func as any,
          )
        },
      )
    }
    const errors = await validateFunction(data, dataSchema, 'file')
    setValidationError(errors)
    return errors.length === 0
  }

  const viewFiles = state
    .filter((sample) => !!sample.file)
    .map((sample, idx) => (
      <ViewFileSelectType
        key={`${sample.file!}_${idx}`}
        name={sample.file!.name}
        type={sample.type}
        options={state}
        onChange={async (e) => {
          const valid = await validate(sample.file!, e.value)
          if (!valid) {
            return
          }
          const newState = state.map((val) => {
            if (val.type === sample.type) {
              return {
                ...val,
                file: undefined,
                s3Link: undefined,
                name: undefined,
              }
            }
            if (val.type === e.value) {
              return { ...val, file: sample.file, name: sample.file?.name }
            }
            return val
          })
          dispatch(newState)
        }}
        deleteFile={(e) => {
          const newState = state.map((val) => {
            if (val.type === sample.type) {
              return {
                ...val,
                file: undefined,
                s3Link: undefined,
                name: undefined,
              }
            }
            return val
          })
          dispatch(newState)
        }}
      />
    ))

  return (
    <>
      {viewFiles.length < state.length && (
        <AddSample
          options={state}
          dispatch={async (type: string, file?: File) => {
            if (!!file) {
              const valid = await validate(file, type)
              if (!valid) {
                return
              }
            }
            const foundOption = state.findIndex((val) => val.type === type)
            dispatch([
              ...state.slice(0, foundOption),
              { type, file, name: file?.name },
              ...state.slice(foundOption + 1),
            ])
          }}
        />
      )}
      <>{viewFiles}</>
    </>
  )
}
