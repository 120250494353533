import styled from 'styled-components'
import pointer from '@assets/images/triangle-tooltip'

export const Wrapper = styled.div`
  max-width: 391px;
  padding: 10px;
  border: 1px solid #b3b3b3;
  background-color: #ffffff;
  position: absolute;
  font-size: 14px;
  line-height: 20px;
  top: 110%;
  z-index: 3;
  color: #5c5c5c;
  font-weight: 500;
`

export const Pointer = styled.img.attrs({
  src: pointer,
  alt: 'pointer image',
})`
  position: absolute;
  top: 48%;
  bottom: auto;
`

export const VerticalPointer = styled.img.attrs({
  src: pointer,
  alt: 'pointer image',
})`
  position: absolute;
  top: -15px;
  bottom: auto;
  left: 45%;
  transform: rotateZ(90deg);
`
