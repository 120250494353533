import DragAndDrop from '@/components/ui/drag-n-drop-form-style'
import Multiselect from '@/components/ui/multiselect'
import { OptionsProps } from '@/components/ui/multiselect/types'
import UploadInput from '@/components/ui/upload-input'
import ViewFile from '@/components/ui/view-file'
import { validateFunction } from '@/utils/form-errors'
import { useEffect, useState } from 'react'
import SelectComponent from '../../../select'
import { SampleDto } from '../../types'
import { SelectSection } from './styled'

type SampleSectionTyping = {
  options: SampleDto[]
  dispatch: (type: string, file?: File) => void
}

export const AddSample = ({ options, dispatch }: SampleSectionTyping) => {
  const [selectedOption, setType] = useState<number>(0)

  useEffect(() => {
    if (options[selectedOption].file) {
      const newEmptyOption = options.findIndex((option) => !option.file)
      setType(newEmptyOption)
    }
  }, [options[selectedOption]])

  return (
    <>
      <SelectSection>
        <p>Type</p>
        <SelectComponent
          options={options
            .filter((val) => !val.file)
            .map((val, i) => ({
              id: i,
              value: val.type,
              label:
                val.type === 'Data Analytics'
                  ? 'Data Quality Report'
                  : val.type,
            }))}
          value={
            selectedOption !== null && {
              id: 1,
              value: options[selectedOption]?.type,
              label:
                options[selectedOption]?.type === 'Data Analytics'
                  ? 'Data Quality Report'
                  : options[selectedOption]?.type,
            }
          }
          onChange={(e: any) => {
            const option = options.findIndex(
              (option) => option.type === e.value,
            )
            setType(option)
          }}
        />
      </SelectSection>
      <DragAndDrop
        dispatch={(file?: File) => {
          if (selectedOption !== null) {
            dispatch(options[selectedOption].type, file)
          }
        }}
      />
    </>
  )
}
