import { useCallback, useEffect, useRef, useState } from 'react'
import {
  Wrapper,
  Header,
  Title,
  Column,
  ColumnWrapper,
  ChatWrapper,
  InputWrapper,
  Button,
  Input,
  MessagesWrapper,
  DropFileOverlay,
  ErrorWrapper,
} from './styled'
import logo from '@/assets/images/safe.png'
import ChatBox from '../chat-box'
import { IChat, IChatAttachment, IChatProduct } from './types'
import { JSONValue, Message, Paginator } from '@twilio/conversations'
import { useAppSelector } from '@/app/hooks'
import { ClipLoader } from 'react-spinners'
import {
  useUpdateConvoAnonymousUser,
  useUpdateConvoTimestamp,
} from '@/services/conversations/convesations.service'
import moment from 'moment'
import FileUploadIcon from '@mui/icons-material/AttachFileOutlined'
import { Alert, IconButton, Snackbar, Tooltip } from '@mui/material'
import { useDropzone, FileRejection, ErrorCode } from 'react-dropzone'
import {
  Close,
  FilePresentOutlined,
  DashboardCustomizeOutlined,
} from '@mui/icons-material'
import uploadFile from '@/utils/uploadFile'
import { Listing } from '@/services/listing/types'
import ListingDialog from '../listing-modal'
import { setVisibility } from '@/utils/update-listing'

const MAX_FILE_SIZE = 5 // Expressed in megabytes.
const MAX_FILE_SIZE_ERROR = `File is larger than ${MAX_FILE_SIZE}mb.`
const INVALID_FILE_TYPE_ERROR = `Invalid file type. We support images (png, jpg), documents (word, ppt, xls, txt) and compressed files (.zip, .tar, .rar)`

const Chat = ({ conversation, id, anonymous: anonymousProp }: IChat) => {
  const [anonymous, setAnonymous] = useState(anonymousProp)
  const [newMessage, setNewMessage] = useState('')
  const [buttonLoading, setButtonLoading] = useState(false)
  const [showListingDialog, setShowListingDialog] = useState(false)
  const [fileError, setFileError] = useState('')
  const { userMail, userRole } = useAppSelector((state) => state.authUser)
  const [messages, setMessages] = useState<Paginator<Message> | undefined>()
  const { mutate } = useUpdateConvoTimestamp()
  const { mutate: makeVisible } = useUpdateConvoAnonymousUser(() => {
    setAnonymous(false)
  })
  const [file, setFile] = useState<File | undefined>()
  const [product, setProduct] = useState<Listing | undefined>()

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const f = acceptedFiles[0]
      setFile(acceptedFiles[0])
      setNewMessage(`${f.name} (${(f.size / 1024 / 1024).toFixed(2)}mb)`)
    }
  }, [])

  const onDropRejected = useCallback((fileRejections: FileRejection[]) => {
    const error = fileRejections?.map((e) => e.errors[0])[0]
    let message = error.message
    if (error.code === ErrorCode.FileTooLarge) {
      message = MAX_FILE_SIZE_ERROR
    } else if (error.code === ErrorCode.FileInvalidType) {
      message = INVALID_FILE_TYPE_ERROR
    }
    setFileError(message)
  }, [])

  const { getRootProps, getInputProps, isDragActive, open, fileRejections } =
    useDropzone({
      onDrop,
      noClick: true,
      maxFiles: 1,
      multiple: false,
      accept: {
        'image/*': ['.jpg', '.jpge', '.png'],
        'text/csv': ['.csv'],
        'application/msword': ['.doc'],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          ['.docx'],
        'application/pdf': ['.pdf'],
        'application/vnd.ms-powerpoint': ['.ppt'],
        'application/vnd.openxmlformats-officedocument.presentationml.presentation':
          ['.pptx'],
        'application/vnd.rar': ['.rar'],
        'application/rtf': ['.rtf'],
        'application/x-tar': ['.tar'],
        'text/plain': ['.txt'],
        'application/vnd.ms-excel': ['.xls'],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
          '.xlsx',
        ],
        'application/zip': ['.zip'],
      },
      maxSize: MAX_FILE_SIZE * 1024 * 1024,
      onError: (e) => {
        setFileError(e.message)
      },
      onDropRejected,
    })

  const onMessageAdded = () => {
    getMessages()
  }

  const getMessages = async () => {
    try {
      const msgs = await conversation.getMessages(100)
      const lastReadMsgIdx = await conversation.getMessagesCount()
      // console.info(lastReadMsgIdx)
      if (lastReadMsgIdx > 0) {
        conversation.updateLastReadMessageIndex(lastReadMsgIdx - 1)
      }
      setMessages(msgs)
    } catch {
      console.log('error')
    }
  }

  const addToWhitelist = async (product: Listing) => {
    const p = await conversation.getParticipants()
    const a = p.filter((a) => a.identity !== userMail)
    const identity = a[0].identity?.split('@')[1]
    if (product.id && identity && !product.whitelist.includes(identity)) {
      const newWhitelist = [identity, ...product.whitelist]
      await setVisibility(product.id, 'restricted', newWhitelist)
    }
  }

  const sendMessage = async () => {
    if (newMessage) {
      setButtonLoading(true)
      let attributes: Record<string, any> | IChatAttachment | IChatProduct = {}
      if (file) {
        const filePath = await uploadFile(file, { returnUUID: false })
        const attr: IChatAttachment = {
          hasAttachment: true,
          fileName: file.name,
          fileSize: file.size,
          fileType: file.type,
          href: filePath,
        }
        attributes = attr
        setFile(undefined)
      } else if (product) {
        const attr: IChatProduct = {
          hasProduct: true,
          listingId: product.id || '',
          title: product.title || '',
          href: `https://${window.location.host}/product/${product.id}`,
        }
        attributes = attr
        if (product.visibility === 'restricted') {
          await addToWhitelist(product)
        }
        setProduct(undefined)
      }

      await conversation.sendMessage(newMessage, attributes as JSONValue)
      await mutate({ id, timestamp: moment().unix() * 1000 })
      setNewMessage('')
      const lastReadMsgIdx = await conversation.getMessagesCount()
      conversation.updateLastReadMessageIndex(lastReadMsgIdx - 1)

      setButtonLoading(false)
    }
  }

  const btnClick = () => {
    makeVisible({ id })
  }

  useEffect(() => {
    getMessages()
    conversation.on('messageAdded', onMessageAdded)

    return () => {
      conversation.off('messageAdded', onMessageAdded)
    }
  }, [])

  const clearAttachment = () => {
    setFile(undefined)
    setProduct(undefined)
    setNewMessage('')
  }

  const openListingDialog = () => {
    setShowListingDialog(true)
  }

  const closeListingDialog = () => {
    setShowListingDialog(false)
  }

  const onSelectListing = (listing: Listing) => {
    setProduct(listing)
    setNewMessage(listing.title || '')
    closeListingDialog()
  }

  const handleClose = () => {
    setFileError('')
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        open={!!fileError}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {fileError}
        </Alert>
      </Snackbar>
      {showListingDialog && (
        <ListingDialog
          onClose={closeListingDialog}
          onSave={onSelectListing}
          anonymous={anonymous}
        />
      )}
      <Wrapper>
        {userRole === 'buyer' && anonymous === true && (
          <>
            <Header>
              <ColumnWrapper>
                <Column>
                  <Title>Status</Title>
                  You are anonymous to the Seller.
                </Column>
                <Column>
                  <Title>Make yourself visible</Title>
                  <Button onClick={btnClick}>Visible</Button>
                </Column>
              </ColumnWrapper>
            </Header>
          </>
        )}
        <ChatWrapper {...getRootProps()}>
          {isDragActive && <DropFileOverlay>DROP FILE HERE</DropFileOverlay>}
          <MessagesWrapper>
            {messages &&
              messages.items.map((message: Message, index: number) =>
                message.author == userMail ? (
                  <ChatBox
                    key={index}
                    author={message.author}
                    message={message.body}
                    date={message.dateCreated}
                    attributes={message.attributes}
                    isAlignLeft={true}
                  />
                ) : (
                  <ChatBox
                    key={index}
                    author={
                      userRole === 'seller' && anonymous
                        ? 'Anonymous Buyer'
                        : message.author
                    }
                    message={message.body}
                    date={message.dateCreated}
                    attributes={message.attributes}
                    isAlignLeft={false}
                  />
                ),
              )}
          </MessagesWrapper>
        </ChatWrapper>
        <InputWrapper
          style={{ justifyContent: 'normal', alignItems: 'center' }}
        >
          {(file || product) && (
            <>
              <Tooltip title="Remove">
                <IconButton onClick={clearAttachment}>
                  <Close />
                </IconButton>
              </Tooltip>
              {file && <FilePresentOutlined />}
              {product && <DashboardCustomizeOutlined />}
            </>
          )}
          <Input
            autoComplete={'off'}
            flex={1}
            onChange={(e) => setNewMessage(e.target.value)}
            id="input"
            value={newMessage}
            disabled={!!file || !!product}
          />
          <Tooltip title="Send File">
            <IconButton onClick={open}>
              <input {...getInputProps()} />
              <FileUploadIcon />
            </IconButton>
          </Tooltip>
          {userRole === 'seller' && (
            <Tooltip title="Send Product">
              <IconButton onClick={openListingDialog}>
                <DashboardCustomizeOutlined />
              </IconButton>
            </Tooltip>
          )}
          <Button onClick={sendMessage}>
            {buttonLoading ? (
              <ClipLoader color="#ffffff" loading={buttonLoading} size={15} />
            ) : (
              'Send message'
            )}
          </Button>
        </InputWrapper>
      </Wrapper>
    </>
  )
}

export default Chat
