import { useState } from 'react'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { CloseButton, Item, ItemWrapper } from './styled'

import { MultiselectProps } from './types'

const Multiselect = ({
  options,
  isCreatable,
  isMulti,
  showValues,
  getValue,
  state,
}: MultiselectProps) => {
  const [selectedOptions, setSelectedOptions] = useState<any[]>(
    state ? state : [],
  )

  const setStates = (value: any) => {
    getValue(value)
    setSelectedOptions(value)
  }
  const onChange = (value: any) => {
    if (selectedOptions && selectedOptions.length < 5) {
      setStates(value)
    }
  }

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      height: '54px',
    }),
    dropdownIndicator: (provided: any, state: any) => ({
      ...provided,
      transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
      transition: '250ms',
      color: '#000000',
      '&:hover': {
        color: '#000000',
      },
    }),
  }

  return (
    <>
      {isCreatable ? (
        <CreatableSelect
          isMulti={isMulti}
          options={options}
          styles={customStyles}
          onChange={onChange}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary: 'black',
            },
          })}
          components={{ IndicatorSeparator: () => null }}
          placeholder="Type and press ‘Enter’ to add new..."
          value={selectedOptions}
          controlShouldRenderValue={showValues || false}
          closeMenuOnSelect={false}
          isClearable={false}
        />
      ) : (
        <Select
          isMulti={isMulti}
          options={options}
          styles={customStyles}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary: 'black',
            },
          })}
          components={{ IndicatorSeparator: () => null }}
          closeMenuOnSelect={false}
          onChange={onChange}
          placeholder=""
          controlShouldRenderValue={showValues || false}
          isClearable={false}
          isOptionDisabled={() => selectedOptions.length > 4}
          value={selectedOptions}
        />
      )}
      {selectedOptions.length > 0 && !showValues && (
        <ItemWrapper>
          {selectedOptions.length > 0 &&
            selectedOptions.map((value: any, index) => (
              <Item key={index}>
                {value.value}
                <CloseButton
                  onClick={() => {
                    setStates([
                      ...selectedOptions.slice(0, index),
                      ...selectedOptions.slice(
                        index + 1,
                        selectedOptions.length,
                      ),
                    ])
                  }}
                />
              </Item>
            ))}
        </ItemWrapper>
      )}
    </>
  )
}

export default Multiselect
