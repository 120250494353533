import { useEffect, useState } from 'react'
import { useAppSelector } from '../../../../app/hooks'
import { axios } from '@lib/axios'
import TabComponent from '../components/tab'

const Tab = () => {
  const baseUrl = process.env.REACT_APP_URL_BASE
  const [listings, setListings] = useState([])
  const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const sellerCompanyId = useAppSelector((state) => state.authUser.companyId)

  useEffect(() => {
    setIsLoading(true)
    axios
      .get(`${baseUrl}/api/v1/ccompanies/${sellerCompanyId}/listings`)
      .then(function (response: any) {
        setError(false)
        setListings(response.data)
        setIsLoading(false)
      })
      .catch(function (error: any) {
        setError(true)
        console.log(error)
      })
  }, [])

  return (
    <TabComponent
      listings={listings}
      setListings={setListings as any}
      error={error}
      setError={setError}
      isLoading={isLoading}
    />
  )
}

export default Tab
