import { Button, DialogWrapper } from './styled'
import Modal from '@/components/ui/modal'

const UpdateDealModal = () => {
  return (
    <Modal buttonLabel={'Update Deal'}>
      <DialogWrapper>Hola</DialogWrapper>
    </Modal>
  )
}

export default UpdateDealModal
