import { TabsProduct } from './types'

export const tabs: TabsProduct[] = [
  { label: 'Info & Quality', id: 'infoQuality' }, //fixed
  { label: 'Preview & Analytics', id: 'previewAnalytics' },
  { label: 'Coverage', id: 'coverage' },
  { label: 'Delivery & Pricing', id: 'delivery' }, //fixed
  { label: 'Legal & Compliance', id: 'legal' },
  { label: 'FAQ', id: 'faq' },
  // { label: 'Reviews', id: 'reviews' },
]
