import { useState } from 'react'
import Header from '../header/index'
import TabsNavigation from '@/components/tabs-navigation'
import { Container } from '@mui/material'
import { TabsBase } from '@/components/tabs-navigation/types'
import Active from './active-tab'
import { EmptyStateWrapper } from '../posting/styled'
import { ResultsTitle } from '@/components/grid-cards/styled'

interface IDealsProps {}

const Deals: React.FC<IDealsProps> = (props) => {
  const tabs: TabsBase[] = [
    { label: 'Active', Component: Active, id: '1' },
    { label: 'Requires Update', Component: Active, id: '2' },
    { label: 'Unread', Component: Active, id: '3' },
    { label: 'Signed', Component: Active, id: '4' },
    { label: 'Lost', Component: Active, id: '5' },
  ]

  return (
    <Container
      maxWidth={'xl'}
      sx={{ padding: '30px' }}
      style={{ position: 'relative' }}
    >
      <div style={{ display: 'flex' }}>
        <Header
          title={'Pipeline'}
          description={'Sync your marketplace pipeline with your CRM system.'}
        ></Header>
      </div>
      <EmptyStateWrapper>
        <ResultsTitle>API Coming Soon!</ResultsTitle>
      </EmptyStateWrapper>
      {/*<TabsNavigation tabs={tabs} backgroundMarginOffset={'42%'} /> */}
    </Container>
  )
}

export default Deals
