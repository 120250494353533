import styled from 'styled-components'

export const Divider = styled.div`
  width: 45px;
  height: 6px;
  background-color: #000000;
  margin-bottom: 16px;
`
export const Error = styled.p`
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
  color: #ff0000;
`

export const Title = styled.h1`
  font-size: 36px;
  line-height: 44px;
  font-weight: 300;
  margin-bottom: 16px;
`

export const FormGroup = styled.form`
  display: flex;
  flex-direction: column;
`

export const Button = styled.button.attrs(({ type }) => ({
  type: type || 'button',
}))`
  background-color: #060606;
  border: 1px solid #060606;
  color: #ffffff;
  font-size: 14px;
  line-height: 22px;
  padding: 17px 34px;
  cursor: pointer;
  letter-spacing: 2px;
  text-transform: uppercase;
`

export const TextWrapper = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  color: #000000;
  text-decoration: none;
  margin-bottom: 55px;
`
