import { Drawer, ListItemText, MenuItem, MenuList } from '@mui/material'
import Box from '@mui/material/Box'
import * as React from 'react'
import { useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import useHover from '@hooks/useHover'
import Routes from '../../routing/dashboard-routes/routes'
import { IconSidebar, MenuItemSidebar } from './styled'
import storage from '@/utils/storage'

interface ISidebarProps {}

const drawerWidth = 70

const Sidebar: React.FC<ISidebarProps> = (props) => {
  const [isOpen, setIsOpen] = useState(true)
  const [isHovered, hoversHandlers] = useHover(false)
  const location = useLocation()
  const userRole = storage.getUserRole()

  const activeRoute = (routeName: any) => {
    return location.pathname === routeName ? true : false
  }

  const filterByRole = (route: any) =>
    userRole
      ? route.userRole === userRole || route.userRole === 'both'
      : route.isPublic

  const drawer = (
    <MenuList>
      {Routes.filter((route) => !route.isHidden)
        .filter((route) => filterByRole(route))
        .map((prop, key) => {
          return (
            <NavLink
              to={prop.path}
              style={{ textDecoration: 'none' }}
              key={key}
              target={prop?.target || ''}
            >
              <MenuItem
                selected={activeRoute(prop.path)}
                sx={{
                  display: 'flex',
                  justifyContent: isHovered ? 'flex-start' : 'center',
                  '&.Mui-selected': {
                    backgroundColor: '#F3F3F3',
                    borderLeft: '5px solid #000000',
                    transition: 'all .2s',
                  },
                }}
              >
                <IconSidebar
                  src={activeRoute(prop.path) ? prop.iconDark : prop.iconLight}
                />
                {isHovered && (
                  <MenuItemSidebar selected={activeRoute(prop.path)}>
                    {prop.sidebarName}
                  </MenuItemSidebar>
                )}
              </MenuItem>
            </NavLink>
          )
        })}
    </MenuList>
  )

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            overflow: 'hidden',
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            transition: 'width .4s',
            '&:hover': {
              width: '250px',
            },
          },
        }}
        {...hoversHandlers}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  )
}

export default Sidebar
