import styled from 'styled-components'

export const LabelMetric = styled.label`
  font-weight: 700;
  font-size: 9px;
  color: #858585;
  text-transform: uppercase;
`

export const ValueMetric = styled.span`
  font-weight: 700;
  font-size: 26px;
  line-height: 38px;
  color: #000000;
`

export const ValueDesc = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: #aaaaaa;
`
