import { IconButton } from '@mui/material'
import { useState, forwardRef } from 'react'

export const IconButtonWrapper = forwardRef<any, IconButtonType>(
  (props, ref) => {
    const { size, onClick, tooltip, children } = props
    const [isHover, setIsHover] = useState(false)
    return (
      <IconButton
        size={size}
        onClick={onClick}
        onMouseOver={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        ref={ref}
      >
        {children}
        {isHover && tooltip && tooltip}
      </IconButton>
    )
  },
)
