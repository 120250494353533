import { TabsPosting } from './types'

export const tabs: TabsPosting[] = [
  {
    label: 'All',
    id: 'all',
  },
  {
    label: 'Active',
    id: 'active',
  },
]
