import * as React from 'react'
import { Separator } from './styled'

export interface ISeparatorProps {
  margin?: string
}

const GradientSeparator: React.FC<ISeparatorProps> = ({ margin }) => {
  return <Separator margin={margin} />
}

export default GradientSeparator
