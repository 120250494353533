import { Button } from '@/components'
import InputComponent from '@/components/ui/input'
import { queryClient } from '@/lib/react-query'
import {
  useGetIndustries,
  useUpdateCompanyProfile,
} from '@/services/storefront/storefront.service'
import { CompanyProfile } from '@/services/storefront/types'
import { useState } from 'react'
import { ClipLoader } from 'react-spinners'
import { FormTitle, FormWrapper, InputTitle, SavedChanges } from '../styled'
import { IFormProps } from '../types'
import CustomMultiSelect from '@/pages/storefront-admin/custom-multi-select'
import { components } from 'react-select'
import makeAnimated from 'react-select/animated'

const Pricing: React.FC<IFormProps> = ({ id, companyProfile }) => {
  const [savedChanges, setSavedChanges] = useState(false)
  const [pricingInput, setPricingInput] = useState<string | undefined>(
    companyProfile.pricingDescription,
  )
  const [useCasesInput, setUseCasesInput] = useState<string | undefined>(
    companyProfile.useCaseDescription,
  )
  const [industriesInput, setIndustriesInput] = useState<string[] | undefined>(
    companyProfile.industries,
  )

  let updatedCompanyProfile: CompanyProfile = { ...companyProfile }

  const Option = (props: any) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{' '}
          <label>{props.label}</label>
        </components.Option>
      </div>
    )
  }

  const MultiValue = (props: any) => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  )

  const animatedComponents = makeAnimated()

  const { mutate, isLoading } = useUpdateCompanyProfile(() => {
    setSavedChanges(true)
    queryClient.invalidateQueries({ queryKey: ['company-profile'] })
  })

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    updatedCompanyProfile = {
      ...updatedCompanyProfile,
      pricingDescription: pricingInput,
      useCaseDescription: useCasesInput,
      industries: industriesInput,
    }

    mutate({ id: id, companyProfile: updatedCompanyProfile })
  }

  const {
    data: industries,
    isLoading: isLoadingIndustries,
    isError: isErrorIndustries,
  } = useGetIndustries()

  const handleChange = (selected: any) => {
    const updatedInd = selected.map((item: any) => item.value)
    setIndustriesInput(updatedInd)
  }

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <FormTitle>Pricing</FormTitle>
      <InputTitle>Pricing Description</InputTitle>
      <InputComponent
        title="Tell buyers how your products are priced - pricing models and price points."
        maxCharacters={1000}
        isTextArea
        placeholder="e.g. subscription model, one time payment per dataset, etc."
        value={pricingInput}
        getValue={(e: any) => setPricingInput(e.target.value)}
      ></InputComponent>
      <FormTitle>Use Case</FormTitle>
      <InputTitle>Use Cases</InputTitle>
      <InputComponent
        title="Please provide some detailed explanations of use cases that you fulfill and how you can assist customers to support them in their challenges."
        maxCharacters={1000}
        isTextArea
        placeholder="Describe focus areas are in terms of use cases."
        value={useCasesInput}
        getValue={(e: any) => setUseCasesInput(e.target.value)}
      ></InputComponent>
      <InputTitle>Industries</InputTitle>
      <div>Tell our buyers what industries you specialize in.</div>

      {!isLoadingIndustries && (
        <CustomMultiSelect
          defaultValue={
            companyProfile.industries && !!companyProfile.industries[0]
              ? companyProfile.industries!.map((industry) => {
                  return { label: industry, value: industry }
                })
              : undefined
          }
          options={industries?.data.map((industry: string) => {
            return { label: industry, value: industry }
          })}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{ Option, MultiValue, animatedComponents }}
          onChange={handleChange}
          allowSelectAll={true}
        />
      )}

      <div>Select up to 5 of your focus industries</div>
      <Button variant="primary" width={'100%'} type="submit">
        {isLoading ? (
          <ClipLoader color="#ffffff" size={12} />
        ) : (
          <span>SAVE CHANGES</span>
        )}
      </Button>

      {savedChanges && <SavedChanges>Changes Saved!</SavedChanges>}
    </FormWrapper>
  )
}

export default Pricing
