import { Button, CTAWrapper } from './styled'
import { useNavigate } from 'react-router'

const Buttons = () => {
  const navigate = useNavigate()
  return (
    <CTAWrapper>
      <Button
        icon
        color="#ffffff"
        backgroundColor="#000000"
        padding="16px 42px 16px 42px"
        onClick={() => navigate('/product-create-listing')}
      >
        New Product
      </Button>
    </CTAWrapper>
  )
}

export default Buttons
