import { AppBarHeader, Sidebar, TabsNavigation } from '@/components'
import { TabsBase } from '@/components/tabs-navigation/types'
import { useNavigate, useParams } from 'react-router'
import Tab from './tab'
import {
  ArrowButton,
  ArrowIcon,
  BodyContainer,
  Container,
  DetailHeader,
  HeaderTitle,
} from './styled'
import { useEffect, useRef, useState } from 'react'
import storage from '@/utils/storage'
import { axios } from '@/lib/axios'

const PostingProposals = () => {
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const baseUrl = process.env.REACT_APP_URL_BASE
  const token = storage.getToken()
  const { proposalId } = useParams()
  const postingTitle = useRef<string | undefined>()

  const tabs: TabsBase[] = [
    { label: 'New', Component: Tab, id: '1' },
    { label: 'In Conversation', Component: Tab, id: '2' },
    { label: 'Declined/Expired', Component: Tab, id: '3' },
  ]

  useEffect(() => {
    setLoading(true)
    getProposalsByPostingId().then(() => {
      setLoading(false)
    })
  }, [proposalId])

  const getProposalsByPostingId = () => {
    const res = axios
      .get(`${baseUrl}/api/v1/postings/${proposalId}/proposals`)
      .then(function (response: any) {
        postingTitle.current = response.data.posting.title
        return response
      })
      .catch(function (error: any) {
        console.log(error)
      })
    return res
  }

  return (
    <Container>
      <Sidebar />
      <Container flexDirection="column">
        <AppBarHeader />
        {!loading && (
          <>
            <DetailHeader>
              <ArrowButton onClick={() => navigate(-1)}>
                <ArrowIcon />
              </ArrowButton>
              <HeaderTitle>{postingTitle.current}</HeaderTitle>
            </DetailHeader>
            <BodyContainer>
              <TabsNavigation tabs={tabs} backgroundMarginOffset={'42%'} />
            </BodyContainer>
          </>
        )}
      </Container>
    </Container>
  )
}

export default PostingProposals
