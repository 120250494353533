import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  isLoggedIn: false,
  error: '',
  userId: null,
  userRole: null,
}

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginPending: (state) => {
      state.isLoading = true
    },
    loginSuccess: (state) => {
      ;(state.isLoading = false), (state.isLoggedIn = true), (state.error = '')
    },
    loginFail: (state, { payload }) => {
      ;(state.isLoading = false), (state.error = payload)
    },
    setUserId: (state, { payload }) => {
      state.userId = payload
    },
    setUserRole: (state, { payload }) => {
      state.userRole = payload
    },
  },
})

const { reducer, actions } = loginSlice

export const { loginPending, loginSuccess, loginFail, setUserId, setUserRole } =
  actions

export default reducer
