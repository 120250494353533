import { ExtractFnReturnType } from '@/lib/react-query'
import { useQuery } from 'react-query'
import { getFilters } from './filters.api'

type GetFiltersQueryFnType = typeof getFilters

export const useGetFilters = () => {
  return useQuery<ExtractFnReturnType<GetFiltersQueryFnType>>({
    queryKey: ['filters'],
    queryFn: () => getFilters(),
  })
}
