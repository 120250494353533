import useHover from '@hooks/useHover'
import { Grid } from '@mui/material'
import * as React from 'react'
import { Link } from 'react-router-dom'
import {
  CardContainer,
  CardDescription,
  CardSubtitle,
  CardTitle,
  CheckIcon,
  VerifiedLabel,
} from './styled'
import { ICardProps } from './types'

const Card: React.FC<ICardProps> = ({
  companyName,
  title,
  verified,
  isInstantPurchase,
  description,
  contentSubTitle,
  contentBody,
  contentFooter,
  id,
}) => {
  console.log(Card)
  const [isHovered, hoversHandlers] = useHover(false)

  const renderSubtitle = () => {
    return contentSubTitle ? (
      contentSubTitle
    ) : (
      <CardSubtitle>{companyName}</CardSubtitle>
    )
  }

  const renderVerified = () => (
    <>
      <Grid item xs={9} md={9} xl={8}>
        <VerifiedLabel>VERIFIED</VerifiedLabel>
      </Grid>
      <Grid
        item
        xs={3}
        md={3}
        xl={4}
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <CheckIcon />
      </Grid>
    </>
  )

  const renderBody = () => {
    return contentBody ? contentBody : <></>
  }

  const renderFooter = () => {
    return contentFooter ? contentFooter : <></>
  }

  return (
    <Link
      to={`/product/${id}`}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: 'none' }}
    >
      <CardContainer isHovered={isHovered} {...hoversHandlers}>
        <Grid
          container
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          spacing={2}
        >
          <Grid item xs={7} md={6} xl={7}>
            {renderSubtitle()}
          </Grid>
          {verified && (
            <Grid container item xs={4} spacing={1}>
              {renderVerified()}
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <CardTitle>{title}</CardTitle>
        </Grid>
        <Grid item xs={12}>
          <CardDescription maxRows={5}>{description}</CardDescription>
        </Grid>
        <Grid item xs={12}>
          {renderBody()}
        </Grid>
        <Grid item xs={12}>
          {renderFooter()}
        </Grid>
      </CardContainer>
    </Link>
  )
}

export default Card
