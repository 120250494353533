import { Grid } from '@mui/material'
import * as React from 'react'
import { ResultsTitle, ProductCount } from './styled'
import { IGridCardsProps } from './types'
import FilterGrid from '@/pages/dashboard/explorer/filter-grid'
import { EmptyStateWrapper, Info } from '@/pages/dashboard/posting/styled'
import { ClipLoader } from 'react-spinners'

const GridCards: React.FC<IGridCardsProps> = ({
  productCount,
  filterAttributes,
  injectFilter,
  filterTitle,
  dataObject,
  cards,
}) => {
  const { isLoading, isError } = dataObject
  const supportEmail = process.env.SUPPORT_EMAIL

  return (
    <Grid container spacing={2} sx={{ padding: '0 20px', marginTop: '20px' }}>
      {/* <Grid container item xs={2} direction="column">
        <FilterGrid
          filterTitle={filterTitle}
          injectFilter={injectFilter}
          filterAttributes={filterAttributes}
        />
      </Grid> */}
      {cards.length > 0 && (
        <ProductCount>
          Viewing {productCount.from}-{productCount.to} of {productCount.total}{' '}
          items
        </ProductCount>
      )}
      <Grid container xs={1}></Grid>
      <Grid
        container
        direction="row"
        item
        justifyContent={'center'}
        xs={10}
        spacing={2}
        sx={{ marginTop: '10px' }}
      >
        {isLoading ? (
          <ClipLoader size={30} />
        ) : (
          isError && (
            <Grid item xs={8}>
              <EmptyStateWrapper>
                <ResultsTitle>
                  Haven't found what you're looking for?
                </ResultsTitle>
                <Info>
                  {' '}
                  <a href={`mailto:${supportEmail}`}>
                    Contact Kamba's Sourcing Team
                  </a>{' '}
                  <span>for Help</span>
                </Info>
              </EmptyStateWrapper>
            </Grid>
          )
        )}

        {cards}
      </Grid>
    </Grid>
  )
}

export default GridCards
