import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Wrapper = styled.div`
  padding: 40px 21px 0 32px;
`

export const Input = styled.textarea`
  width: 100%;
  height: 123px;
  padding: 14px 18px;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  color: #000000b2;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  &:focus {
    border: 1px solid #000000;
  }
  &:focus-visible {
    border: 1px solid #00000;
    outline: 1px solid #000000;
  }
  margin-bottom: 16px;
`

export const CardsContainer = styled.div`
  max-width: 940px;
  margin: 0 auto;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  column-gap: 18px;
  margin-bottom: 44px;
`

export const HeaderColLeft = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  font-size: 27px;
  line-height: 44px;
  span {
    color: #8b8b8b;
    font-weight: 500;
  }
`

export const HeaderColRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 16px;
`

export const Button = styled.button.attrs(({ type }) => ({
  type: type || 'button',
}))`
  border: 1px solid #000000;
  background-color:  #000000;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  padding: '11px 50px 11px 50px'};
  height: 55px;
  cursor: pointer;
  margin: 0;
  width: 50%;
  text-transform: uppercase;
`

export const TransparentButton = styled.button.attrs(({ type }) => ({
  type: type || 'button',
}))`
    border: 1px solid #000000;
    background-color:  transparent;
    color: #000000;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    padding: '11px 50px 11px 50px'};
    height: 55px;
    cursor: pointer;
    margin: 0;
    width: 50%;
    text-transform: uppercase;
  `

export const CardsCols = styled.div`
  width: 100%;
  display: flex;
  column-gap: 32px;
  padding-bottom: 60px;
`

export const CardsColLeft = styled.div`
  width: 100%;
  max-width: 340px;
  background-color: #e9e9e9;
  padding: 27px 27px 46px;
`

export const CardsColLeftTitle = styled.h3`
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  color: #868686;
  margin-bottom: 4px;
`

export const CardsColLeftText = styled.p`
  font-weight: 700;
  width: 270px;
  font-size: 18px;
  line-height: 24px;
  color: #565656;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-bottom: 20px;
`

export const CompanyInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #565656;
  column-gap: 12px;
  margin-bottom: 20px;
`

export const HeadquarterLabel = styled.div`
  width: auto;
  display: inline-flex;
  align-items: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: #565656;
  column-gap: 8px;
  margin-bottom: 20px;
  padding: 5px;
  background-color: #ffffff;
  margin-right: 5px;
`

export const ClientMessage = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
  color: #565656;
`

export const CardsColRight = styled.div`
  width: 100%;
  max-width: 600px;
  padding: 36px 36px 36px 50px;
  background-color: #ffffff;
`

export const CardsColRightTitle = styled.h3`
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 12px;
  span {
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    text-decoration-line: underline;
    color: #000000;
    cursor: pointer;
  }
`

export const Commission = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
  margin: 38px 0 12px;
  span {
    color: #868686;
  }
`

export const Terms = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #868686;
  margin-bottom: 12px;
`

export const TextLink = styled(Link)`
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  text-decoration-line: underline;
  color: #000000;
`

export const CTAWrapper = styled.div`
  display: flex;
  column-gap: 20px;
  margin-top: 30px;
`

export const Subtitle = styled.p`
  font-weight: 500;
  font-size: 22px;
  line-height: 151.3%;
  color: #000000;
  margin-bottom: 20px;
`

export const Divider = styled.div`
  height: 6px;
  width: 45px;
  background-color: #000000;
  margin-bottom: 16px;
`

export const Title = styled.p`
  font-weight: 300;
  font-size: 36px;
  line-height: 44px;
  color: #000000;
`

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #d9d9d9;
  margin: 38px 0 48px;
`
export const DeliveryText = styled.span`
  text-transform: capitalize;
`
