import styled from 'styled-components'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'

export const ResourcesContainer = styled.div`
  padding: 36px 32px;
  display: flex;
  flex-direction: column;
  border: 1px solid #95a4b2;
  background-color: white;
  color: #404040;
  gap: 22px;
`
export const Colaborate = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
export const Invite = styled.div`
  font-weight: 700;
  text-decoration: underline;
  margin-bottom: 15px;
  color: black;
`
export const NeedSupport = styled.div``
export const Profile = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`
export const Contact = styled.div``

export const ProfilePic = styled(AccountCircleOutlinedIcon)`
  width: 50px !important;
  height: 50px !important;
`
export const ProfileInfo = styled.div``

export const StyledText = styled.div``
