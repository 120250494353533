import { TabsPosting } from './types'
import { TabsBase } from '@/components/tabs-navigation/types'
import LeadList from './lead-list'
import PendingProposalList from './pending-proposal-list'
import AcceptedProposalList from './accepted-proposal-list'
import CanceledProposalList from './canceled-proposal-list'

export const tabs: TabsBase[] = [
  {
    label: 'To Review',
    Component: LeadList,
    id: TabsPosting.toreview,
  },
  {
    label: 'Proposed',
    Component: PendingProposalList,
    id: TabsPosting.proposed,
  },
  {
    label: 'Accepted',
    Component: AcceptedProposalList,
    id: TabsPosting.accepted,
  },
  {
    label: 'Declined/Expired',
    Component: CanceledProposalList,
    id: TabsPosting.canceled,
  },
]
