import styled from 'styled-components'
import { IconProps, LabelProps } from './types'

export const IconSidebar = styled.img.attrs<IconProps>(({ src }) => ({
  src: src,
  alt: 'icon',
}))<IconProps>`
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : '119px')};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: #000000;
`

export const MenuItemSidebar = styled.p<LabelProps>`
  font-weight: 600;
  cursor: pointer;
  color: ${({ selected }) => (selected ? '#000000' : '#9b9b9b')};
  font-size: 16px;
`
