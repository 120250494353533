import { Button, GradientSeparator, Icon, Title } from '@/components'
// import { TimeChart } from '@/components/charts'
import { Grid } from '@mui/material'
import * as React from 'react'
import storage from '@/utils/storage'
import { createCsv } from '@/utils/preview'
import { ImgAnalytics } from './img'
import { useNavigate, useParams } from 'react-router'
import getUuid from '@/utils/getUuid'
import getPresignedUrl from '@/utils/getPresignedUrl'
// @ts-ignore
import RightArrowIcon from '@assets/images/icons/RightArrowIcon'
import { axios } from '@/lib/axios'
import Modal from '@/components/ui/modal'
import { DialogWrapper } from './styled'
import { useAppSelector } from '@/app/hooks'

interface IDataAnalyticsProps {
  chartIQFile?: { file: File; url: string }
  dataAnalytics?: string
  companyId?: string
}

const dataAnalyticsBucket = process.env.BUCKET_HTML_DATA_ANALYTICS

const DataAnalytics: React.FC<IDataAnalyticsProps> = ({
  chartIQFile,
  dataAnalytics,
  companyId,
}) => {
  const [data, setData] = React.useState<{ Date: string; Value: number }[]>([])
  const [presignedUrl, setPresignedUrl] = React.useState<string | undefined>(
    undefined,
  )
  const navigate = useNavigate()
  const userRole = useAppSelector((state) => state.authUser.userRole)
  const userCompany = useAppSelector((state) => state.authUser.companyId)
  const isLoggedIn = storage.getUserId() ? true : false
  const handleCreateAccount = () => navigate(`/create-account`)
  const unAuthorizedAxios = require('axios')

  const renderDialogFooter = () => (
    <Button variant="secondary" onClick={handleCreateAccount}>
      CREATE AN ACCOUNT
    </Button>
  )

  const obtainFileData = async () => {
    const fetchData = await createCsv(chartIQFile?.file!)
    setData(
      fetchData.map((val: any) => ({
        Date: val.Date,
        Value: parseFloat(val.Value),
      })),
    )
  }

  /*const obtainAnalytics = async (url: string) => {
    const uuid = getUuid(url)
    const presignedUrl = await getPresignedUrl(
      `output_${uuid}.html`,
      dataAnalyticsBucket,
    )
    setPresignedUrl(presignedUrl)
  }*/

  const getUrl = (url: string) => {
    let uuid: string = ''
    if (!url.startsWith('http')) {
      uuid = url
    } else {
      uuid = getUuid(url)
    }
    const res = unAuthorizedAxios
      .get(
        `https://${dataAnalyticsBucket}.s3.amazonaws.com/output_${uuid}.html`,
      )
      .then(function (response: any) {
        setPresignedUrl(response.data)
        return response
      })
      .catch(function (error: any) {
        console.log(error)
      })
    return res
  }

  /*const obtainAnalytics = async (url: string) => {
    const uuid = getUuid(url)
    const presignedUrl = await getPresignedUrl(
      `output_${uuid}.html`,
      dataAnalyticsBucket,
    )
    setPresignedUrl(presignedUrl)
  }*/

  React.useEffect(() => {
    if (chartIQFile && chartIQFile.file.type.includes('csv')) {
      obtainFileData()
    }
  }, [chartIQFile])

  React.useEffect(() => {
    if (dataAnalytics) {
      getUrl(dataAnalytics) //obtainAnalytics
    }
  }, [dataAnalytics])

  // const createTimeChart = () =>
  //   chartIQFile?.file.type.includes('csv') && data.length > 0 ? (
  //     <TimeChart
  //       isVisible={
  //         isLoggedIn && (userRole === 'buyer' || userCompany === companyId)
  //       }
  //       dialogDescription="Create an account to preview this sample"
  //       dialogContentFooter={renderDialogFooter()}
  //       data={data}
  //     />
  //   ) : (
  //     <ImgAnalytics url={chartIQFile?.url!} />
  //   )

  /*const checkIfDataset = () => {
    const res = axios
      .get(`${baseUrl}/api/v1/listings/${id}/datasets`)
      .then(function (response: any) {
        console.log(response)
        return response
      })
      .catch(function (error: any) {
        console.log(error)
      })
    return res
  }*/

  return (
    <Grid container spacing={1}>
      {presignedUrl && (
        <Grid item xs={12} sx={{ paddingLeft: '0 !important' }}>
          <Title boldText="Data Quality Report" fontSize="30px" />
          <Grid item xs={4} paddingLeft={0}>
            {isLoggedIn ? (
              <div style={{ marginTop: '10px' }}>
                <Modal buttonLabel={'VIEW DATA QUALITY REPORT'}>
                  <DialogWrapper
                    dangerouslySetInnerHTML={{ __html: presignedUrl }}
                  />
                </Modal>
              </div>
            ) : (
              <Button
                variant="primary"
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                disabled={true}
              >
                VIEW DATA QUALITY REPORT
                <Icon src={RightArrowIcon}></Icon>
              </Button>
            )}
          </Grid>
          <GradientSeparator margin="30px 0" />
        </Grid>
      )}
      {chartIQFile && (
        <>
          {/* <Title
            boldText="Data Analytics"
            description="Move beyond traditional charts to custom data visualizations that intuitively explain and engage you with actionable, tradable data. Bring complex data-sets to life."
            fontSize="30px"
          />
          {createTimeChart()}
          <GradientSeparator margin="30px 0" /> */}
        </>
      )}
    </Grid>
  )
}

export default DataAnalytics
