import { Button, Icon, InputBlock } from '@/components'
import { ClickAwayListener, Grid, Tooltip } from '@mui/material'
/* @ts-ignore */
import CopyIcon from '@assets/images/icons/CopyIcon'
import * as React from 'react'
import {
  ShareTitle,
  ShareTitleBold,
  ShareTitleWrapper,
  ShareWrapper,
} from './styled'
import { useState } from 'react'

interface IShareProps {}

const Share: React.FC<IShareProps> = (props) => {
  const currentLocation = window.location.href
  const [openToolTip, setOpenToolTip] = useState(false)

  const handleCopyClipBoard = () => {
    navigator.clipboard.writeText(currentLocation)
    setOpenToolTip(true)
    closeTooltip()
  }

  const handleTooltipClose = () => {
    setOpenToolTip(false)
  }

  const closeTooltip = () => {
    setTimeout(() => {
      setOpenToolTip(false)
    }, 2000)
  }

  return (
    <Grid container spacing={1}>
      <ShareWrapper>
        <Grid item xs={5} container direction="column">
          <ShareTitleWrapper>
            <Grid item xs={12}>
              <ShareTitleBold>Share It</ShareTitleBold>
            </Grid>
            <Grid item xs={12}>
              <ShareTitle>With Your Team</ShareTitle>
            </Grid>
          </ShareTitleWrapper>
        </Grid>
        <Grid item xs={4} container>
          <Grid item xs={12}>
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <Tooltip
                title="COPIED!"
                placement="top"
                onClose={handleTooltipClose}
                open={openToolTip}
                disableHoverListener={true}
              >
                <span>
                  <Button
                    variant="primary"
                    height={'100%'}
                    onClick={handleCopyClipBoard}
                    display="flex"
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    COPY URL
                    <Icon margin="0px 10px" src={CopyIcon}></Icon>
                  </Button>
                </span>
              </Tooltip>
            </ClickAwayListener>
          </Grid>
        </Grid>
      </ShareWrapper>
    </Grid>
  )
}

export default Share
