import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import ClipLoader from 'react-spinners/ClipLoader'
import * as yup from 'yup'
import {
  Container,
  LoginWrapper,
  Logo,
  LoginCard,
  InputBlock,
  RadioButton,
} from '@/components'
import {
  Divider,
  Title,
  FormGroup,
  Button,
  Line,
  Label,
  TextLink,
  TextWrapper,
  PrivacyWrapper,
  RadioWrapper,
  // Radio,
  Error,
  Text,
} from './styled'
import { useResendCode } from '@/services/resend-code/resend-code.service'
import { useNavigate } from 'react-router'
import {
  RadioButtonStyled,
  RadioLabel,
} from '@/components/ui/radio-button/styled'
import { AxiosError } from 'axios'

type CreateAccountData = {
  userName: string
  password: string
  role: string
}

const schema = yup.object({
  userName: yup.string().required('Email is required'),
  password: yup.string().required('Password is required'),
  role: yup.string().required('Role is required'),
})

const CreateAccount = () => {
  const baseUrl = process.env.REACT_APP_URL_BASE
  const axios = require('axios').default
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [username, setUsername] = useState<string>()
  const [errorMessage, setErrorMessage] = useState('')

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<CreateAccountData>({
    resolver: yupResolver(schema),
    defaultValues: {
      role: '',
    },
  })

  const workEmailRegister = { ...register('userName') }
  const passwordRegister = { ...register('password') }

  const fetchCreateAccount = ({
    userName,
    password,
    role,
  }: CreateAccountData) => {
    const res = axios
      .post(`${baseUrl}/api/v1/signup`, {
        username: userName,
        password: password,
        role: role,
      })
      .then(function (response: any) {
        return response
      })
      .catch(function (error: any) {
        return error
      })

    return res
  }

  const onSubmit = handleSubmit(async (data: CreateAccountData) => {
    setUsername(data.userName)
    setError(false)
    try {
      const isSignUp = await fetchCreateAccount(data)
      if (isSignUp.data?.ok) {
        return setSuccess(true)
      } else if (isSignUp instanceof AxiosError) {
        setErrorMessage(isSignUp.response?.data.response)
        return setError(true)
      }
    } catch (error) {
      return setError(true)
    }
  })
  const navigate = useNavigate()
  const {
    mutate: resendCode,
    isError,
    isLoading,
  } = useResendCode(() => {
    navigate(`/email-verification-code/?username=${username}`)
  })
  const resendInstructions = () => {
    if (username) {
      resendCode({ username: username })
    }
  }

  const goToTandC = () => navigate(`/terms-and-conditions`)

  return (
    <Container height={'100%'} minHeight={'100vh'}>
      <LoginWrapper>
        <Logo />
        <LoginCard>
          {!success && (
            <>
              <Divider />
              <Title>Create account</Title>
              <FormGroup onSubmit={onSubmit}>
                <InputBlock
                  label="Work Email"
                  isMail
                  onChange={workEmailRegister.onChange}
                  type="email"
                  params={workEmailRegister}
                  errorMessage={errors.userName?.message}
                />
                <InputBlock
                  label="Password"
                  type="password"
                  isPassword
                  onChange={passwordRegister.onChange}
                  params={passwordRegister}
                  errorMessage={errors.password?.message}
                  showStrenghtBar
                />
                <Label>What are you?</Label>
                <RadioWrapper>
                  <RadioButtonStyled
                    {...register('role', { required: true })}
                    type="radio"
                    value="buyer"
                    id="buyer"
                  />
                  <RadioLabel>Buyer</RadioLabel>
                  <RadioButtonStyled
                    {...register('role', { required: true })}
                    type="radio"
                    value="seller"
                    id="seller"
                  />
                  <RadioLabel>Supplier</RadioLabel>
                  {errors?.role && <Error>{errors.role.message}</Error>}
                </RadioWrapper>
                <Button type="submit">
                  {isSubmitting ? (
                    <ClipLoader
                      color="#ffffff"
                      loading={isSubmitting}
                      size={15}
                    />
                  ) : (
                    `Create account `
                  )}
                </Button>
                {error && !errorMessage && (
                  <Error>An error ocurred. Please try again.</Error>
                )}
                {error && errorMessage && <Error>{errorMessage}</Error>}
              </FormGroup>
              <PrivacyWrapper>
                By creating an account you agree to our{' '}
                <TextLink to="/privacy-policy" rel="noopener noreferrer">
                  Privacy Policy
                </TextLink>{' '}
                and{' '}
                <TextLink
                  to="/terms-and-conditions/terms-of-service"
                  rel="noopener noreferrer"
                >
                  Terms of Service.
                </TextLink>
              </PrivacyWrapper>
              <Line />
              <TextWrapper>
                Already have an account?{' '}
                <TextLink to="/login" rel="noopener noreferrer">
                  Log In
                </TextLink>
              </TextWrapper>
              {/* <TextWrapper>
                Did not receive your confirmation email?{' '}
                <TextLink to="/create-account" rel="noopener noreferrer">
                  Resend confirmation
                </TextLink>
              </TextWrapper> */}
            </>
          )}
          {success && (
            <>
              <Title marginBottom="24px">
                Email sent, <br />
                check your inbox
              </Title>
              <Text>
                Check your email to verify your account and start finding the
                right data for you.
              </Text>
              <Button onClick={resendInstructions} type="submit">
                {isSubmitting ? (
                  <ClipLoader
                    color="#ffffff"
                    loading={isSubmitting}
                    size={15}
                  />
                ) : (
                  `Resend verification instructions`
                )}
              </Button>
              {/* <div style={{ marginTop: '24px' }}>
                <TextWrapper>
                  Need help?{' '}
                  <TextLink to="/" rel="noopener noreferrer">
                    Contact Us
                  </TextLink>
                </TextWrapper>
              </div> */}
            </>
          )}
        </LoginCard>
      </LoginWrapper>
    </Container>
  )
}

export default CreateAccount
