import { Button } from '@/components'
import styled from 'styled-components'

interface IButProps {
  marginRight?: number
  marginBottom?: number
}

export const ButtonWrapper = styled(Button)<IButProps>(
  ({ marginRight, marginBottom }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: 'auto',
    marginRight: marginRight ? `${marginRight}px` : 0,
    marginBottom: marginBottom ? `${marginBottom}px` : 0,
  }),
)

// export const AbsoluteWrapper = styled.div<AbsoluteWrapperProps>`
//   position: absolute;
//   top: ${({ top }) => (top ? top : '-237px')};
//   right: ${({ right }) => (right ? right : '-425px')}; ;

export const IconContainer = styled.div`
  margin-right: 12px;
`
