import styled from 'styled-components'
import { IStatusTextProps } from './types'

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`

export const RightSideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const StatusText = styled.span<IStatusTextProps>`
  display: inline-block;
  align-items: flex-start;
  color: ${({ active }) => (active ? '#2fa14f' : '#B8B8B8')};
  font-weight: 700;
  text-align: left;
  padding: 5px 0;
  font-size: 18px;
  line-height: 22px;
`

export const TextLine = styled.div`
  display: flex;
  align-items: center;
`

export const CtaWrapper = styled.div`
  margin-top: 5px;
  width: 100%;
`

export const ButtonText = styled.p`
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
`

export const ButtonContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`
