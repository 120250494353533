import { GridCards } from '@/components'
import { SuppliersGridCards } from '@/components/grid-cards/grid-cards-wrappers/suppliers-grid-cards'
import * as React from 'react'
import { filterAttributes } from '../data-sets/filterAttributes'
import { IExploreProps } from '../types'

const DataSuppliers: React.FC<IExploreProps> = ({ id }) => {
  const currentTab = { typeOfData: id }
  return (
    <SuppliersGridCards
      filterTitle={'Data Suppliers'}
      currentTab={currentTab}
    />
  )
}

export default DataSuppliers
