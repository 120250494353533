import { ApiResponse } from '@services/types'
import { axios } from '../../lib/axios'

import { LoginCredentialsDTO, LoginResponse } from './types'

type ApiLoginResponse = ApiResponse<LoginResponse>

export const loginWithEmailAndPassword = (
  data: LoginCredentialsDTO,
): Promise<ApiLoginResponse> => {
  return axios.post('/login', data)
}

// export const getUser = (
//   data: LoginCredentialsDTO,
// ): Promise<ApiLoginResponse> => {
//   // return axios.post('/login', data)
// }
