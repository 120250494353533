import { ExtractFnReturnType } from '@/lib/react-query'
import { useQuery } from 'react-query'
import { getUsers } from './users.api'

type GetFiltersQueryFnType = typeof getUsers

export const useGetUsers = () => {
  return useQuery<ExtractFnReturnType<GetFiltersQueryFnType>>({
    queryKey: ['users'],
    queryFn: () => getUsers(),
  })
}
