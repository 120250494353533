import { useState, useEffect, useRef, SyntheticEvent } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Arrow from '../../../../assets/images/icons/left-arrow'
import { useNavigate } from 'react-router'
import ClipLoader from 'react-spinners/ClipLoader'
import {
  Button,
  CompanyCol,
  CompanyRow,
  CompanyUser,
  CTAWrapper,
  PipelineCard,
  PipelineCardCompany,
  PipelineCardRow,
  PipelineCardWrapper,
  PipelineCol,
  PipelineColTitle,
  PipelineTable,
  Stage,
  ToggleButton,
  ToggleIcon,
  ToggleViewContainer,
} from './styled'
import { useAppSelector } from '@/app/hooks'
import { axios } from '@/lib/axios'
import moment from 'moment'

function createData(
  id: string,
  company: string,
  logo: string,
  user: string,
  userAvatar: string,
  owner: string,
  ownerAvatar: string,
  dealSize: number,
  acceptedTime: string,
  lastUpdated: string,
  stage: string,
) {
  return {
    id,
    company,
    logo,
    user,
    userAvatar,
    owner,
    ownerAvatar,
    dealSize,
    acceptedTime,
    lastUpdated,
    stage,
  }
}

const Active = () => {
  const [loading, setLoading] = useState(false)
  const [showPipeline, setShowPipeline] = useState(false)
  const [tableButtonActive, setTableButtonActive] = useState(true)
  const [pipelineButtonActive, setPipelineButtonActive] = useState(false)
  const navigate = useNavigate()
  const userCompany = useAppSelector((state) => state.authUser.companyId)
  const ccompanyWorkers = useRef<any>()
  const deals = useRef<any>()
  const baseUrl = process.env.REACT_APP_URL_BASE

  useEffect(() => {
    setLoading(true)
    getCCompanyWorkers().then(() => {
      getDealsByCompany().then((data) => {
        deals.current = data.data
        setLoading(false)
      })
    })
  }, [userCompany])

  const getDealsByCompany = () => {
    const res = axios
      .get(`${baseUrl}/api/v1/ccompany/${userCompany}/deals`)
      .then(function (response: any) {
        return response
      })
      .catch(function (error: any) {
        return error
      })
    return res
  }

  const getCCompanyWorkers = () => {
    const res = axios
      .get(`${baseUrl}/api/v1/ccompanies/${userCompany}/users`)
      .then(function (response: any) {
        ccompanyWorkers.current = response.data
        return response
      })
      .catch(function (error: any) {
        console.log(error)
      })
    return res
  }

  const productOwner = (productOwnerId: string) => {
    const owner = ccompanyWorkers.current?.find(
      (worker: any) => worker.id == productOwnerId,
    )
    return owner.username
  }

  const getDaysAgo = (date: Date) => {
    var updateDate = new Date(date)
    var now = new Date(Date.now())
    var diffDays = parseInt((now - updateDate) / (1000 * 60 * 60 * 24), 10)
    return diffDays
  }

  const columnPrice = (items: any, stage: string) => {
    let priceSum: number = 0
    let numberOfDeals: number = 0
    let price: number
    items
      .filter((item) => {
        return item.stage == stage
      })
      .map((item, i) => {
        if (!item.dealSize) {
          price = 0
        } else {
          price = item.dealSize
        }
        numberOfDeals = i + 1
        priceSum = price + priceSum
      })

    if (numberOfDeals > 1) {
      return (
        <p>
          {numberOfDeals} Deals - {priceSum}$
        </p>
      )
    } else if (numberOfDeals === 1) {
      return (
        <p>
          {numberOfDeals} Deal - {priceSum}$
        </p>
      )
    } else {
      return <p>0 Deals</p>
    }
  }

  const handleShowPipeline = (value: string) => {
    if (value === 'table') {
      setShowPipeline(false)
      setTableButtonActive(true)
      setPipelineButtonActive(false)
    } else if (value === 'pipeline') {
      setShowPipeline(true)
      setTableButtonActive(false)
      setPipelineButtonActive(true)
    }
  }

  return (
    <>
      {loading ? (
        <ClipLoader color="#ffffff" loading={loading} size={15} />
      ) : (
        <>
          <ToggleViewContainer style={{ alignSelf: 'center' }}>
            <ToggleButton
              onClick={() => handleShowPipeline('table')}
              active={tableButtonActive}
            >
              Table <ToggleIcon active={tableButtonActive} />
            </ToggleButton>
            <ToggleButton
              onClick={() => handleShowPipeline('pipeline')}
              active={pipelineButtonActive}
            >
              Pipeline
              <ToggleIcon active={pipelineButtonActive} />
            </ToggleButton>
          </ToggleViewContainer>
          {!showPipeline ? (
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Company</TableCell>
                    <TableCell>Owner</TableCell>
                    <TableCell>Stage</TableCell>
                    <TableCell>Deal Size</TableCell>
                    <TableCell>Accepted At</TableCell>
                    <TableCell>Last Updated</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {deals?.current
                    ?.sort((a: any, b: any) => {
                      const dateA = new Date(a.updatedAt!)
                      const dateB = new Date(b.updatedAt!)
                      return dateB.getTime() - dateA.getTime()
                    })
                    .map((row: any) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <CompanyRow>
                            {/*<img
                            src={row.buyerObject.avatar}
                            alt="logo"
                            style={{ width: '44px', height: '44px' }}
                  />*/}
                            <CompanyCol>
                              {/*row.company*/}
                              <CompanyUser>
                                {row.buyerObject.usernameBuyer}
                              </CompanyUser>
                            </CompanyCol>
                          </CompanyRow>
                        </TableCell>
                        <TableCell>
                          <CompanyUser>
                            {productOwner(row.sellerId)}
                          </CompanyUser>
                        </TableCell>
                        <TableCell>
                          <Stage>{row.stage}</Stage>
                        </TableCell>
                        <TableCell>
                          {!!row.dealSize ? (
                            <CompanyUser>${row.dealSize}</CompanyUser>
                          ) : (
                            <CompanyUser>{'Not set'}</CompanyUser>
                          )}
                        </TableCell>
                        <TableCell>
                          {getDaysAgo(row.updatedAt) !== 0 ? (
                            <CompanyUser>
                              {getDaysAgo(row.createdAt)} days ago
                            </CompanyUser>
                          ) : (
                            <CompanyUser>Today</CompanyUser>
                          )}
                        </TableCell>
                        <TableCell>
                          <CompanyUser>
                            {moment(new Date(row.updatedAt)).format(
                              'MMM Do, YYYY',
                            )}
                          </CompanyUser>
                        </TableCell>
                        <TableCell>
                          <CTAWrapper>
                            <Button
                              color="#000000"
                              onClick={() =>
                                navigate(`/deal-conversation/${row.id}`)
                              }
                            >
                              Messages &amp; Details
                            </Button>
                          </CTAWrapper>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <PipelineTable>
              <PipelineCol>
                <PipelineColTitle>Introduced</PipelineColTitle>
                {columnPrice(deals?.current, 'introduced')}
                <PipelineCardWrapper>
                  {deals?.current
                    .filter((row) => {
                      return row.stage == 'introduced'
                    })
                    .map((row) => (
                      <PipelineCard
                        onClick={() => navigate(`/deal-conversation/${row.id}`)}
                      >
                        <PipelineCardRow>
                          <PipelineCardCompany>
                            {/*<img
                              src={row.logo}
                              alt="logo"
                              style={{ width: '20px', height: '20px' }}
                    />*/}
                            {row.company}
                          </PipelineCardCompany>
                          <img
                            src={Arrow}
                            alt="arrow"
                            style={{
                              transform: 'rotate(180deg)',
                              height: '10px',
                            }}
                          />
                        </PipelineCardRow>
                        <PipelineCardRow>
                          {productOwner(row.sellerId)}
                          {row.dealSize ? (
                            <span
                              style={{ fontWeight: '700', color: '#000000' }}
                            >
                              {row.dealSize}
                            </span>
                          ) : (
                            <span
                              style={{ fontWeight: '700', color: '#000000' }}
                            >
                              Not set
                            </span>
                          )}
                        </PipelineCardRow>
                      </PipelineCard>
                    ))}
                </PipelineCardWrapper>
              </PipelineCol>
              <PipelineCol>
                <PipelineColTitle>Solution Pitched</PipelineColTitle>
                {columnPrice(deals?.current, 'solution-pitched')}
                <PipelineCardWrapper>
                  {deals?.current
                    .filter((row) => {
                      return row.stage == 'solution-pitched'
                    })
                    .map((row) => (
                      <PipelineCard
                        onClick={() => navigate(`/deal-conversation/${row.id}`)}
                      >
                        <PipelineCardRow>
                          <PipelineCardCompany>
                            {/*<img
                              src={row.logo}
                              alt="logo"
                              style={{ width: '20px', height: '20px' }}
                    />*/}
                            {row.company}
                          </PipelineCardCompany>
                          <img
                            src={Arrow}
                            alt="arrow"
                            style={{
                              transform: 'rotate(180deg)',
                              height: '10px',
                            }}
                          />
                        </PipelineCardRow>
                        <PipelineCardRow>
                          {productOwner(row.sellerId)}
                          {row.dealSize ? (
                            <span
                              style={{ fontWeight: '700', color: '#000000' }}
                            >
                              {row.dealSize}
                            </span>
                          ) : (
                            <span
                              style={{ fontWeight: '700', color: '#000000' }}
                            >
                              Not set
                            </span>
                          )}
                        </PipelineCardRow>
                      </PipelineCard>
                    ))}
                </PipelineCardWrapper>
              </PipelineCol>
              <PipelineCol>
                <PipelineColTitle>Evaluating Data</PipelineColTitle>
                {columnPrice(deals?.current, 'evaluating-data')}
                <PipelineCardWrapper>
                  {deals?.current
                    .filter((row) => {
                      return row.stage == 'evaluating-data'
                    })
                    .map((row) => (
                      <PipelineCard
                        onClick={() => navigate(`/deal-conversation/${row.id}`)}
                      >
                        <PipelineCardRow>
                          <PipelineCardCompany>
                            {/*<img
                              src={row.logo}
                              alt="logo"
                              style={{ width: '20px', height: '20px' }}
                    />*/}
                            {row.company}
                          </PipelineCardCompany>
                          <img
                            src={Arrow}
                            alt="arrow"
                            style={{
                              transform: 'rotate(180deg)',
                              height: '10px',
                            }}
                          />
                        </PipelineCardRow>
                        <PipelineCardRow>
                          {productOwner(row.sellerId)}
                          {row.dealSize ? (
                            <span
                              style={{ fontWeight: '700', color: '#000000' }}
                            >
                              {row.dealSize}
                            </span>
                          ) : (
                            <span
                              style={{ fontWeight: '700', color: '#000000' }}
                            >
                              Not set
                            </span>
                          )}
                        </PipelineCardRow>
                      </PipelineCard>
                    ))}
                </PipelineCardWrapper>
              </PipelineCol>
              <PipelineCol>
                <PipelineColTitle>Proposal Made</PipelineColTitle>
                {columnPrice(deals?.current, 'proposal-made')}
                <PipelineCardWrapper>
                  {deals?.current
                    .filter((row) => {
                      return row.stage == 'proposal-made'
                    })
                    .map((row) => (
                      <PipelineCard
                        onClick={() => navigate(`/deal-conversation/${row.id}`)}
                      >
                        <PipelineCardRow>
                          <PipelineCardCompany>
                            {/*<img
                              src={row.logo}
                              alt="logo"
                              style={{ width: '20px', height: '20px' }}
                    />*/}
                            {row.company}
                          </PipelineCardCompany>
                          <img
                            src={Arrow}
                            alt="arrow"
                            style={{
                              transform: 'rotate(180deg)',
                              height: '10px',
                            }}
                          />
                        </PipelineCardRow>
                        <PipelineCardRow>
                          {productOwner(row.sellerId)}
                          {row.dealSize ? (
                            <span
                              style={{ fontWeight: '700', color: '#000000' }}
                            >
                              {row.dealSize}
                            </span>
                          ) : (
                            <span
                              style={{ fontWeight: '700', color: '#000000' }}
                            >
                              Not set
                            </span>
                          )}
                        </PipelineCardRow>
                      </PipelineCard>
                    ))}
                </PipelineCardWrapper>
              </PipelineCol>
              <PipelineCol>
                <PipelineColTitle>Negotiating Deal</PipelineColTitle>
                {columnPrice(deals?.current, 'negotiating-deal')}
                <PipelineCardWrapper>
                  {deals?.current
                    .filter((row) => {
                      return row.stage == 'negotiating-deal'
                    })
                    .map((row) => (
                      <PipelineCard
                        onClick={() => navigate(`/deal-conversation/${row.id}`)}
                      >
                        <PipelineCardRow>
                          <PipelineCardCompany>
                            {/*<img
                              src={row.logo}
                              alt="logo"
                              style={{ width: '20px', height: '20px' }}
                    />*/}
                            {row.company}
                          </PipelineCardCompany>
                          <img
                            src={Arrow}
                            alt="arrow"
                            style={{
                              transform: 'rotate(180deg)',
                              height: '10px',
                            }}
                          />
                        </PipelineCardRow>
                        <PipelineCardRow>
                          {productOwner(row.sellerId)}
                          {row.dealSize ? (
                            <span
                              style={{ fontWeight: '700', color: '#000000' }}
                            >
                              {row.dealSize}
                            </span>
                          ) : (
                            <span
                              style={{ fontWeight: '700', color: '#000000' }}
                            >
                              Not set
                            </span>
                          )}
                        </PipelineCardRow>
                      </PipelineCard>
                    ))}
                </PipelineCardWrapper>
              </PipelineCol>
              <PipelineCol noBorder>
                <PipelineColTitle>Pending Delivery</PipelineColTitle>
                {columnPrice(deals?.current, 'pending-delivery')}
                <PipelineCardWrapper>
                  {deals?.current
                    .filter((row) => {
                      return row.stage == 'pending-delivery'
                    })
                    .map((row) => (
                      <PipelineCard
                        onClick={() => navigate(`/deal-conversation/${row.id}`)}
                      >
                        <PipelineCardRow>
                          <PipelineCardCompany>
                            {/*<img
                              src={row.logo}
                              alt="logo"
                              style={{ width: '20px', height: '20px' }}
                    />*/}
                            {row.company}
                          </PipelineCardCompany>
                          <img
                            src={Arrow}
                            alt="arrow"
                            style={{
                              transform: 'rotate(180deg)',
                              height: '10px',
                            }}
                          />
                        </PipelineCardRow>
                        <PipelineCardRow>
                          {productOwner(row.sellerId)}
                          {row.dealSize ? (
                            <span
                              style={{ fontWeight: '700', color: '#000000' }}
                            >
                              {row.dealSize}
                            </span>
                          ) : (
                            <span
                              style={{ fontWeight: '700', color: '#000000' }}
                            >
                              Not set
                            </span>
                          )}
                        </PipelineCardRow>
                      </PipelineCard>
                    ))}
                </PipelineCardWrapper>
              </PipelineCol>
            </PipelineTable>
          )}
        </>
      )}
    </>
  )
}

export default Active
