export const categories = [
  'Ad Fraud Data',
  'Address Data',
  'Advertising Data',
  'Aggregated Clickstream Data',
  'Aggregated Foot Traffic Data',
  'Aggregated Transaction Data',
  'Agricultural Data',
  'AI & ML Training Data',
  'Air Quality Index',
  'Airbnb Data',
  'Airbnb Listings Data',
  'Airbnb Occupancy Rate Data',
  'Airport Data',
  'Alibaba Data',
  'AliExpress Data',
  'Alternative Credit Data',
  'Alternative Data',
  'Amazon Data',
  'Amazon Sellers Data',
  'Android App Data',
  'Annotated Imagery Data',
  'Anonymous IP Data',
  'APAC Location Data',
  'App Download Data',
  'App Install Data',
  'App Market Data',
  'App Revenue Data',
  'App Session Data',
  'App Store Data',
  'App Usage Data',
  'Asia Location Data',
  'Asset Pricing Data',
  'Atmosphere Data',
  'Audience Data',
  'Audience Targeting Data',
  'Automobile Transaction Data',
  'Automotive Data',
  'Aviation Data',
  'B2B Buyer Intent Data',
  'B2B Contact Data',
  'B2B Decision Maker Data',
  'B2B Email Data',
  'B2B Identity Graph Data',
  'B2B Intent Data',
  'B2B Kontaktdaten',
  'B2B Leads Data',
  'B2B Marketing Data',
  'B2B Transaction Data',
  'B2C Contact Data',
  'Bank Credit Rating Data',
  'Bank Transaction Data',
  'Bankruptcy Data',
  'Beauty & Cosmetics Data',
  'Bigcommerce Data',
  'Bing Data',
  'Bitcoin Price Data',
  'Bond Credit Rating Data',
  'Bond Data',
  'Bond Pricing Data',
  'Brand Affinity Data',
  'Brand Data',
  'Brand Sentiment Data',
  'Building Footprint Data',
  'Business Listings Data',
  'Business Location Data',
  'Business Ownership Data',
  'Business Website Data',
  'Campaign & Election Data',
  'Canada Location Data',
  'Car Data',
  'Car Ownership Data',
  'Car Rental Data',
  'Car Traffic Data',
  'Card Holder Data',
  'Cell Tower Data',
  'CEO Contact Data',
  'Clickstream Data',
  'Climate Data',
  'Clinical Data',
  'Commercial Property Data',
  'Commercial Real Estate Data',
  'Commercial Vehicle Data',
  'Commodity Data',
  'Commodity Prices Data',
  'Company Address Data',
  'Company Data',
  'Company Funding Data',
  'Company HQ Data',
  'Company IP Data',
  'Company Registry Data',
  'Compensation Survey Data',
  'Connected TV Data',
  'Consensus Estimates Data',
  'Consented Consumer Data',
  'Construction Project Leads Data',
  'Consumer Affluence Data',
  'Consumer Age Data',
  'Consumer Behavior Data',
  'Consumer Credit Rating Data',
  'Consumer Donation Data',
  'Consumer Education Data',
  'Consumer Employment Data',
  'Consumer Finance Data',
  'Consumer Gender Data',
  'Consumer Geography Data',
  'Consumer Healthcare Data',
  'Consumer Housing Data',
  'Consumer Identity Data',
  'Consumer Identity Graph Data',
  'Consumer Income Data',
  'Consumer Intent Data',
  'Consumer Lifestyle Data',
  'Consumer Loans Data',
  'Consumer Marital Status Data',
  'Consumer Marketing Data',
  'Consumer Panel Data',
  'Consumer Propensity Data',
  'Consumer Purchase Data',
  'Consumer Review Data',
  'Consumer Sentiment Data',
  'Consumer Spending Data',
  'Consumer Transaction Data',
  'Consumer Travel History Data',
  'Cookie - Hashed Email Data',
  'Cookieless Identity Data',
  'Core Financial Data',
  'Corporate & Company ESG Data',
  'Corporate Actions Data',
  'Corporate Activism Data',
  'Corporate Contact Data',
  'Corporate Credit Rating Data',
  'Corporate Loan Data',
  'Court Data',
  'COVID-19 Data',
  'Coworking Data',
  'CPG Data',
  'Credit Card Transaction Data',
  'Credit Default Swap (CDS) Data',
  'Cross-Device Identity Data',
  'Cryptocurrency Data',
  'CSR Data',
  'Currency Data',
  'Custom Audience Data',
  'Cyber Risk Data',
  'Debit Card Transaction Data',
  'Debt Capital Structure Data',
  'Delayed Market Data',
  'Demographic Data',
  'Derivatives Data',
  'Derivatives Reference Data',
  'Deterministic Identity Data',
  'Device Graph Data',
  'Diesel Price Data',
  'Digital Asset Data',
  'Digital Audience Data',
  'Digital Receipt Data',
  'Digital Shelf Data',
  'Direct Marketing Data',
  'Dividend Data',
  'Domain Name Data',
  'E-POS Data',
  'E-Receipt Data',
  'Earning Calls Data',
  'eBay Data',
  'Ecommerce Company Data',
  'Ecommerce Customer Data',
  'Ecommerce Data',
  'Ecommerce Leads Data',
  'Ecommerce Market Data',
  'Ecommerce Merchant Data',
  'Ecommerce Product Data',
  'Ecommerce Purchases Data',
  'Ecommerce Sales Data',
  'Ecommerce Store Data',
  'Economic Data',
  'Education Industry Data',
  'Electric Vehicle Charging Stations Data',
  'Electronic Health Record (EHR) Data',
  'Electronic Payment Data',
  'Electronics Transaction Data',
  'Email Address Data',
  'Email Receipt Data',
  'Employee Data',
  'Employee Profile Data',
  'Energy Consumption Data',
  'Enterprise B2B Contact Data',
  'Equity Market Data',
  'ESG Country Rating Data',
  'ESG Data',
  'ESG Defense & Weapons Data',
  'ESG Equities Data',
  'ESG Fund Rating Data',
  'ESG Risk Data',
  'ESG Sentiment Data',
  'Esports Data',
  'ETF Data',
  'EU Location Data',
  'Event Data',
  'Executive Compensation Data',
  'Fashion & Apparel Data',
  'Filings Data',
  'Financial Entities Data',
  'Financial Institution Credit Rating Data',
  'Firmographic Data',
  'First-Party Identity Data',
  'Fixed Income Data',
  'Fixed Income Pricing Data',
  'Fixed Income Reference Data',
  'Flexible Workspace Data',
  'Flight Aggregator Data',
  'Flight Data',
  'FMCG Data',
  'Food & Grocery Data',
  'Food & Grocery Transaction Data',
  'Foot Traffic Data Europe & EU',
  'Foot Traffic Data UK',
  'Foot Traffic Data USA',
  'Foot Traffic Data',
  'Footfall Data',
  'Forex Data',
  'Fraud Detection Data',
  'Freight Data',
  'Fuel Data',
  'Fund Data',
  'Fundraising & Donor Data',
  'Futures Data',
  'Futures Price Data',
  'Gaming Data',
  'Gaming Market Data',
  'Gas Station Data',
  'Gas Station Location Data',
  'Gasoline Price Data',
  'Geodemographic Data',
  'Geographic Data',
  'Geological Data',
  'Gift Card Data',
  'GIS Data',
  'Global Clickstream Data',
  'Global Identity Data',
  'Global Location Data',
  'Global POI Data',
  'Global Real Estate Data',
  'Global Trade Data',
  'Global Transaction Data',
  'Global Weather Data',
  'Google Data',
  'Google SERP Data',
  'Government & Congressional Data',
  'GPS Location Data',
  'Greenhouse Gas Emissions Data',
  'Grocery Store Location Data',
  'Healthcare Industry Leads Data',
  'Healthcare Marketing Data',
  'Healthcare Provider (HCP) Data',
  'HERE Map Data',
  'Historical Clickstream Data',
  'Historical Location Data',
  'Historical Weather Data',
  'Home Ownership Data',
  'Hotel Rates & Pricing Data',
  'House Price Data',
  'Household-Level Consumer Data',
  'Household-Level Identity Data',
  'HR Data',
  'Identity Data EU',
  'Identity Data LATAM',
  'Identity Data US',
  'Identity Data',
  'Identity Graph Data',
  'Identity Linkage Data',
  'Implied Volatility Data',
  'Import Export Data',
  'In-Market Audience Data',
  'In-store Data',
  'Indices Data',
  'Individual-Level Consumer Data',
  'Individual-Level Identity Data',
  'Industrial Transaction Data',
  'Infrastructure Data',
  'Install Base Data',
  'Insurance Data',
  'Intellectual Property Data',
  'Interest Data',
  'Interest Rate Data',
  'Interest-based Audience Data',
  'Intraday Stock Data',
  'IoT Data',
  'IP Address Data',
  'IP Proxy Data',
  'IP To Geolocation Data',
  'IPO Data',
  'Job Postings Data',
  'Land Use Data',
  'LATAM Location Data',
  'Life Stage Data',
  'LinkedIn Data',
  'Litigation Data',
  'Local Weather Data',
  'Location Data',
  'Location Sentiment Data',
  'Loyalty Card Data',
  'Machine Learning (ML) Data',
  'MAID - Hashed Email Data',
  'MAID - PII Data',
  'Malware Data',
  'Manufacturing Company Data',
  'Map Data',
  'Marine Data',
  'Marine Traffic Data',
  'Maritime Data',
  'Market Data',
  'Marketing Data',
  'Medical Claims Data',
  'Medical Imagery Data',
  'MENA Location Data',
  'Merger & Acquisition Data',
  'Metal Pricing Data',
  'Metocean Data',
  'Mobile Attribution Data',
  'Mobile Audience Data',
  'Mobile Clickstream Data',
  'Mobile Device Location Data',
  'Mobile IP Data',
  'Mobile Location Data',
  'Mobile Network Coverage',
  'Mobility Data',
  'Monzo Transaction Data',
  'Mortgage Data',
  'Mortgage Liens Data',
  'Mortgage Rates Data',
  'MQL Leads Data',
  'Mutual Funds Data',
  'NAICS Data',
  'Natural Language Processing (NLP) Data',
  'Netflix Data',
  'New Homeowner Data',
  'News Data',
  'Oceanographic Data',
  'Odds & Betting Data',
  'Oil & Gas Data',
  'Oil Refinery Data',
  'Online Marketplace Data',
  'Online Purchase Data',
  'Online Search Trends Data',
  'Online Shopping Data',
  'Online Travel Agency (OTA) Data',
  'Opinion Polling Data',
  'Opt-In Consumer Data',
  'Opt-In Identity Data',
  'Options Data',
  'Options Price Data',
  'OTC Data',
  'OTC Derivatives Data',
  'OTC Reference Data',
  'OTT (Over-the-Top) Data',
  'Ownership Data',
  'Panel Survey Data',
  'Parking Availability Data',
  'Patent Data',
  'Patient Data',
  'PayPal Transaction Data',
  'Petrol Price Data',
  'Pharma Data',
  'Phone Number Data',
  'Podcast Data',
  'POI Data APAC',
  'POI Data Australia',
  'POI Data Canada',
  'POI Data Europe & EU',
  'POI Data India',
  'POI Data UK',
  'POI Data USA',
  'POI Sentiment Data',
  'POI Visitation Data',
  'Point of Interest (POI) Data',
  'Point-of-Sale (POS) Data',
  'Political Risk Data',
  'Pollen Data',
  'POS Transaction Data',
  'Pricing Data',
  'Private Company Data',
  'Private Equity Data',
  'Product Data',
  'Product Ownership Data',
  'Product Review Data',
  'Property Data',
  'Property Listings Data',
  'Property Market Data',
  'Property Owner Data',
  'Property Transaction Data',
  'Proprietary Market Data',
  'Psychographic Data',
  'Public Real Estate Data',
  'Public Sector Data',
  'Purchase Behavior Data',
  'Purchase History Data',
  'Purchase Intent Data',
  'Quantitative Model Data',
  'Rail Traffic Data',
  'Raw Clickstream Data',
  'Raw Ecommerce Data',
  'Raw ESG Data',
  'Raw Location Data',
  'Real Estate Funds Data',
  'Real Estate Investment Trust (REIT) Data',
  'Real Estate Market Data',
  'Real Estate Transaction Data',
  'Real Estate Valuation Data',
  'Real-Time Clickstream Data',
  'Real-Time Foot Traffic Data',
  'Real-Time Location Data',
  'Real-Time Market Data',
  'Real-Time Real Estate Data',
  'Real-Time Weather Data',
  'Receipt Data',
  'Reference Data',
  'Regulatory Company Data',
  'Remote Working Data',
  'Research Data',
  'Residential Property Data',
  'Residential Real Estate Data',
  'Restaurant & Food Delivery Transaction Data',
  'Restaurant Data',
  'Restaurant Location Data',
  'Retail Data',
  'Retail Location Data',
  'Retail Market Data',
  'Retail Sales Data',
  'Retail Store Data',
  'Retail Transaction Data',
  'Ride-Sharing Data',
  'Road Condition Data',
  'Road Data',
  'Royalty Rates Data',
  'Salary Data',
  'Sales Transaction Data',
  'Satellite Data',
  'Satellite Imagery Data',
  'School Data',
  'SDK Location Data',
  'Search Engine Data',
  'Securities Reference Data',
  'Seller Ratings Data',
  'Semantic Website Data',
  'Sentiment Data',
  'SEO Data',
  'SERP Data',
  'Share Market Data',
  'Shipment Data',
  'Shipping & Delivery Data',
  'Shopify Data',
  'Shopper Data',
  'Shopping Intent Data',
  'Short Interest Data',
  'Short-Term Rental Data',
  'SIC Data',
  'Ski Resort Data',
  'SKU-Level Transaction Data',
  'Small Business Contact Data',
  'Social Media Data',
  'Solar Energy Data',
  'Special Offer & Promotion Data',
  'Sports Data',
  'Startup Data',
  'Stock Fundamental Data',
  'Stock Keeping Unit (SKU) Data',
  'Stock Market Data',
  'Stock Market Sentiment Data',
  'Stock Price Data',
  'Stock Quote Data',
  'Store Location Data',
  'Streaming Data',
  'Student Marketing Data',
  'Surface Data',
  'Sustainability Data',
  'Swaps Data',
  'Synthetic Data',
  'Tech Install Data',
  'Technographic Data',
  'Telecom Data',
  'Telemarketing Data',
  'Telemedicine Data',
  'Third-Party Audience Data',
  'Tickerized Transaction Data',
  'Tourism Transaction Data',
  'Tourist Attraction Data',
  'Trade Data',
  'Trademark Data',
  'Trading Data',
  'Traffic Data',
  'Transaction Data APAC',
  'Transaction Data EU',
  'Transaction Data LATAM',
  'Transaction Data UK',
  'Transaction Data US',
  'Transaction Data',
  'Travel Booking Data',
  'Travel Data',
  'Travel Intent Data',
  'Travel Transaction Data',
  'Trip Data',
  'Trucking Fleet Data',
  'TV Viewership Data',
  'UK B2B Data',
  'UK Location Data',
  'UN Sustainable Development Data',
  'Urban Planning Data',
  'US Clickstream Data',
  'US Location Data',
  'Vacation Package Data',
  'Vacation Rental Data',
  'Vehicle Location Data',
  'Venture Capital Data',
  'Video Game Usage Data',
  'Visit Data',
  'Voter Data',
  'Voter Turnout Data',
  'Water Data',
  'Wearables Data',
  'Weather Data',
  'Web Activity Data',
  'Web Browsing Data',
  'Web Scraping Data',
  'Web Search Data',
  'Web Traffic Data',
  'Wildfires Data',
  'Wind Power Data',
  'Workplace Diversity Data',
  'YouTube Data',
  'Zip-Code Level Consumer Data',
  'Zoom Data',
]

export const countryList = [
  'Worldwide',
  'Afghanistan',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas ',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia (Plurinational State of)',
  'Bonaire, Sint Eustatius and Saba',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cayman Islands',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos (Keeling) Islands',
  'Colombia',
  'Comoros',
  'Congo (the Democratic Republic of the)',
  'Congo',
  'Cook Islands',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Curaçao',
  'Cyprus',
  'Czechia',
  "Côte d'Ivoire",
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Falkland Islands [Malvinas]',
  'Faroe Islands',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories',
  'Gabon',
  'Gambia ',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard Island and McDonald Islands',
  'Holy See ',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran (Islamic Republic of)',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  "Korea (the Democratic People's Republic of)",
  'Korea (the Republic of)',
  'Kuwait',
  'Kyrgyzstan',
  "Lao People's Democratic Republic",
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia (Federated States of)',
  'Moldova (the Republic of)',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger ',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine, State of',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Republic of North Macedonia',
  'Romania',
  'Russian Federation ',
  'Rwanda',
  'Réunion',
  'Saint Barthélemy',
  'Saint Helena, Ascension and Tristan da Cunha',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Martin (French part)',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten (Dutch part)',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia and the South Sandwich Islands',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Svalbard and Jan Mayen',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan',
  'Tajikistan',
  'Tanzania, United Republic of',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom of Great Britain and Northern Ireland',
  'United States Minor Outlying Islands',
  'United States of America',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela (Bolivarian Republic of)',
  'Viet Nam',
  'Virgin Islands (British)',
  'Virgin Islands (U.S.)',
  'Wallis and Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
  'Åland Islands',
]

export const useCase = [
  'Advertising',
  'Alternative Investment',
  'Business Intelligence (BI)',
  'Catchment Area Analysis',
  'Consumer Data Enrichment',
  'Finance',
  'Financial Data Enrichment',
  'Financial Services',
  'Islamic Finance',
]

export const delivery = [
  'S3 Bucket',
  'SFTP',
  'Email',
  'UI Export',
  'REST API',
  'SOAP API',
  'Streaming API',
  'Feed API',
]

export const licence = [
  'One-off purchase',
  'Monthly License',
  'Yearly License',
  'Usage-based',
]
